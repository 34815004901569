import React, {useEffect, useState} from 'react';
import styles from 'styles/global/toggleSwitch.module.css';

const ToggleSwitch = ({isChecked, parentSetChecked, prop, disabled}) => {


    useEffect(() => {
        setCheckState(isChecked);
    }, [isChecked]);

    const [checkState, setCheckState] = useState();

    const toggleCheckState = () => {
        if(disabled)
            return;

        parentSetChecked(!checkState, prop);
        // setCheckState(!checkState);
    }
    return (
        <label className={`${styles.switch} ${disabled ? styles.disabledLine : null}`}>
            <input onChange={toggleCheckState} checked={checkState} type="checkbox"/>
                <span className={`${styles.slider} ${styles.round}`}></span>
        </label>
    )
}
export default ToggleSwitch
