import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const steps = ['Select a few intents', 'Edit the intents', 'Test the agent'];

export default function FirstAgentWizard({close}) {
  const [activeStep, setActiveStep] = React.useState(0);

  const isStepOptional = (step) => {
    return step === 1;
  };


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                {label}
                </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <div className='d-flex justify-content-center flex-column align-items-center mt-5'>
                <h2>That&apos;s it! - you are ready to configure your own agent!</h2>
                <img style={{width:"40%", marginTop:"2rem"}} src="/repair.png" />
            </div>
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {activeStep == 0 && (
                <div className='d-flex align-items-center justify-content-center gap-5'>
                    <div>
                        <h3 style={{color:"#2e2e7b"}}>First select a few intents from the intent selection.</h3>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                            quis nostrud exercitation ullamco laboris.</span>
                    </div>
                    <img style={{width:"40%"}} src="/step1.jpg" />
                </div>
            )}
             {activeStep == 1 && (
                <div className='d-flex align-items-center flex-column justify-content-center gap-5'>
                     <div>
                        <h3 style={{color:"#2e2e7b"}}>Edit your intents by giving them helpfull responses. Do not forget to activate your intent in the right top corner.</h3>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                            quis nostrud exercitation ullamco laboris.</span>
                    </div>
                    <img style={{width:"100%"}} src="/step2.jpg" />
                </div>
            )}
             {activeStep == 2 && (
                <div className='d-flex align-items-center justify-content-center gap-5'>
                     <div>
                         <h3 style={{color:"#2e2e7b"}}>Test your agent in a chatbox by clicking "Try me" on the right side of your screen. This way you can validate the responses of your agent before going live.</h3>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                            quis nostrud exercitation ullamco laboris.</span>
                    </div>
                    <img style={{width:"30%"}} src="/step3.jpg" />
                </div>
            )}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}