import DropdownSearch from 'components/global/dropdownSearch';
import React, { useEffect, useState } from 'react';
import { getApiCompanies, getApiCompany, deleteCompany, createCompany, updateCompany } from 'services/companyService';
import { getApiAgentsByCompany } from 'services/agentService';
import FormText from 'components/global/formText';
import Snackbar from '@mui/material/Snackbar';
import Loading from 'components/global/loading';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from 'styles/admin/configuration/createCompanyPanel.module.css';
import { useProgressIndicatorContext } from 'contexts/ProgressIndicatorContext';
import { updateUserActionHistoryList } from 'services/userService';
import { useAuth } from 'contexts/AuthContext';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const CreateCompanyPanel = () => {
    const { currentUser } = useAuth();
    const { setIndicatorState, resetIndicatorState, setInfoMessage, loadingState } = useProgressIndicatorContext();
    const [companies, setCompanies] = useState([]);

    const [isNewCompany, setIsNewCompany] = useState(true);
    const [openToast, setOpenToast] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [companyHasAgents, setCompanyHasAgents] = useState(false);

    const [company, setCompany] = useState(null);
    const [selectedSecret, setSelectedSecret] = React.useState(null);

    
    useEffect(() => {
        getCompanies();
    }, []);

    const getCompanies = async () => {
        var companyApiResponse = await getApiCompanies();
        setCompanies(companyApiResponse);
    }

    const setSelected = (e) => {
        if(e == "new"){
            var d = {
                companyName: '',
                contactPerson: '',
                email: '',
                phone: '',
                website: '',
                integrations:{},
                installedIntegrations:{},
                secrets:{},
            }
            setIsNewCompany(true);
            setCompany(d);
            setCompanyHasAgents(false);
        }else{
            getCompany(e.id);
            setIsNewCompany(false);
        }
    }

    const getCompany = async (id) => {
        var retrievedCompany = await getApiCompany(id);
        retrievedCompany.company = companies.filter(x => x.id == retrievedCompany.id)[0];
        setCompany(retrievedCompany);
        setIsNewCompany(false);

        await getApiAgentsByCompany(id).then(x => {
            if(x.length > 0){
                setCompanyHasAgents(true);
            }else{
                setCompanyHasAgents(false);
            }
        })
    }

    const handleChange = (prop) => (event) => {
        setCompany({ ...company, [prop]: event.target.value });
    }


    const deleteCompanyAction = () => {
        setOpenDialog(true);
        
    }

    const addNewSecret = () => {
        if(selectedSecret != null && selectedSecret.name != ''){
            var newCompany = {...company};
            var secretName = selectedSecret.name;
            var secretsObj = {...newCompany.secrets};
            secretsObj[secretName] = selectedSecret;
            // delete secretsObj[secretName].name;
            newCompany.secrets = secretsObj;
            setCompany(newCompany);
        }

        var newSecret = {
            type:"configmap",
            name:"",
            lastRefreshed:"",
            refreshTimeInMinutes:"",
            value:"",
            refreshHook:"",
        };
        setSelectedSecret(newSecret);
      }
      
      const deleteSecret = () => {
        var newCompany = {...company};
        var secretsObj = {...newCompany.secrets};
        delete secretsObj[selectedSecret.name];
        newCompany.secrets = secretsObj;
        setCompany(newCompany);

        setSelectedSecret(null);
      }

    const setSelectedSecretValue = (val) => {
        var secretName = val.name;

        if(selectedSecret != null){
            var secretsObj = {...company.secrets};
            secretsObj[secretName] = selectedSecret;
            // delete secretsObj[secretName].name;
            setCompany({...company, secrets: secretsObj});
        }

        setSelectedSecret({...company.secrets[secretName], name: secretName})
    }
    const updateSecretType = (e) => {
        var type = e.name;
        setSelectedSecret({...selectedSecret, "type": type});
      }

      const updateSecretHook = (e) => {
        var webhook = e.target.value;
        setSelectedSecret({...selectedSecret, "refreshHook": webhook});
      }

      const updateSecretInputs = (prop) => (e) => {
        var value = e.target.value;
        setSelectedSecret({...selectedSecret, [prop]:value});
      }

      const updateSecretName= (prop) => (e) => {
        var value = e.target.value.toUpperCase();
        setSelectedSecret({...selectedSecret, "name":value});
      }

      const handleCloseDialogDisagree = () => {
        setOpenDialog(false);
      };
      const handleCloseDialogAgree = () => {
        setOpenDialog(false);
        setIndicatorState("saving", "Removing company.");
        deleteCompany({id: company.id}).then(result => {
            if(!result.isError){
                setSelected("new");
                getCompanies();
                resetIndicatorState("Company has been removed successfully.");
            }else{
                resetIndicatorState(result.message);
            }
        });
      };

      const submit = () => {
        var newCompany = company;
            
        if(newCompany.company == ""){
            setInfoMessage("Name not valid.");
            setOpenToast(true);
        }

        if(selectedSecret != null && selectedSecret.name != ''){
            var secretName = selectedSecret.name;
            var secretsObj = {...newCompany.secrets};
            secretsObj[secretName] = selectedSecret;
            // delete secretsObj[secretName].name;
            newCompany.secrets = secretsObj;
        }

        setIndicatorState("saving", (isNewCompany ? "Creating" : "Updating") + " company. this may take several minutes.");
        if(isNewCompany){
            createCompany(newCompany).then(result => {
                if(!result.isError){
                    setSelected("new");
                    getCompanies();
                    resetIndicatorState("Company has been successfully created.");
                    updateUserActionHistoryList(currentUser.uid, "Core", "Created company: \"" + newCompany.name + "\"");
                }else{
                    resetIndicatorState(result.message);
                }
            });
        }else{
            updateCompany(newCompany).then(result => {
                if(!result.isError){
                    setCompany(result.object);
                    resetIndicatorState("Company has been successfully created.");
                    updateUserActionHistoryList(currentUser.uid, "Core", "Updated company: \"" + newCompany.name + "\"");
                }else{
                    resetIndicatorState(result.message);
                }
            });
        }
        setSelectedSecret(null);
        
    }

    return (
        <>
            <div className={styles.widgetHeader}>
                <div className={styles.headerTitle}>Company</div>
                <DropdownSearch data={companies} type="company" selected={company?.company} setSelected={setSelected}/>
                <div onClick={() => setSelected("new")} className={styles.buttonStyle}>Create new company</div>
            </div>
            {company && (
            <div className={styles.formPanel}>
                <div className={styles.formRow}>
                    <div className={styles.formElementContainer}>
                        <FormText isDisabled={!isNewCompany} title="Company name" formId="companyName" value={company?.companyName} handleChange={handleChange}/>
                    </div>
                </div>
                <div className={styles.formRow}>
                    <div className={styles.formElementContainer}>
                        <FormText title="Contactperson" formId="contactPerson" value={company?.contactPerson} handleChange={handleChange}/>
                    </div>
                </div>
                <div className={styles.formRow}>
                    <div className={styles.formElementContainer}>
                        <FormText title="Email" formId="email" value={company?.email} handleChange={handleChange}/>
                    </div>
                    <div className={styles.formElementContainer}>
                        <FormText title="Phone" formId="phone" value={company?.phone} handleChange={handleChange}/>
                    </div>
                    <div className={styles.formElementContainer}>
                        <FormText title="Website" formId="website" value={company?.website} handleChange={handleChange}/>
                    </div>
                </div>

                <div className={styles.secretsContainer}>
                    <div className={styles.secretsContainerHeader}>
                        <div className={styles.dropdownContainer}>
                            <div className={styles.dropdownHeader}>Secrets and configs:</div>
                            <DropdownSearch data={Object.keys(company.secrets).map(key => {return {name:key}})} type="configmap or secret" selected={{name: selectedSecret?.name}} setSelected={setSelectedSecretValue}/>   
                            <button onClick={addNewSecret} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.newSecretButton}`}>New</button>
                            {selectedSecret && (
                                <button onClick={deleteSecret} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.newSecretButton}`}>Delete</button>
                            )}
                        </div>
                        {/* isDisabled={selectedSecret != "new"} */}
                        {selectedSecret &&(
                            <div className={styles.selectedSecretContainer}>
                                <div className={styles.secretColumn}>
                                    <div className={styles.hookSelection}>
                                        <DropdownSearch data={[{name:"configmap"},{name:"secret"}]} type="type" selected={{name:selectedSecret.type}} setSelected={updateSecretType}/>   
                                    </div>
                                </div>
                                <div className={styles.secretColumn}>
                                    <FormText formId={"name"} title={"name"} value={selectedSecret.name} handleChange={updateSecretName}/>
                                </div>
                                <div className={styles.secretColumn}>
                                    <FormText formId="value" title="value" value={selectedSecret.value} handleChange={updateSecretInputs}/>
                                </div>
                                {selectedSecret.type == "secret" && (
                                    <>
                                        <div className={styles.secretColumn}>
                                            <FormText formId="refreshTimeInMinutes" title="refreshTimeInMinutes" value={selectedSecret.refreshTimeInMinutes} handleChange={updateSecretInputs}/>
                                        </div>
                                        <div className={styles.secretColumn}>
                                            <FormText isDisabled={true} formId="lastRefreshed" title="lastRefreshed" value={selectedSecret.lastRefreshed} handleChange={updateSecretInputs}/>
                                        </div>
                                        <div className={styles.secretColumn}>
                                            <div className={styles.hookSelection}>
                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                <InputLabel id="demo-select-small">Refresh hook</InputLabel>
                                                <Select
                                                    native
                                                    defaultValue=""
                                                    labelId="demo-select-small"
                                                    id="grouped-native-select"
                                                    value={selectedSecret.refreshHook}
                                                    label="Webhook"
                                                    title="Refresh hook"
                                                    onChange={updateSecretHook}
                                                >
                                                    <option value=""><em></em></option>
                                                    {Object.entries(company.installedIntegrations).sort((a, b) => (a[0] > b[0]) ? 1 : -1).map((obj) => (
                                                        <optgroup label={obj[0]}>
                                                            {company.installedIntegrations[obj[0]].map(integrationHook => (
                                                                <option value={obj[0]+"|"+integrationHook}>{integrationHook}</option>
                                                            ))}
                                                        </optgroup>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                                {/* <DropdownSearch data={Object.entries(agent?.installedIntegrations).filter(x => x[1].settings.isTokenRefreshWebhook).map(key => {return {name:key[0]}})} type="refreshHook" selected={{name:selectedSecret.refreshHook}} setSelected={updateSecretHook}/>    */}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {/* {Object.entries(agent.secrets[selectedSecret])?.sort((a, b) => (a[0] > b[0]) ? 1 : -1)?.map(valueEntry => {
                                    const [valueKey, valueValue] = valueEntry;
                                    return <div key={valueKey+""+valueValue} className={styles.secretColumn}>
                                        {valueKey == "refreshHook" ? (
                                            <div className={styles.hookSelection}>
                                                <DropdownSearch data={Object.keys(agent?.webhooks).map(key => {return {name:key}})} type="webhook" selected={{name: valueValue}} setSelected={updateSecretHook}/>   
                                            </div>
                                        ): (
                                        )}
                                        </div>
                                })} */}
                                {/* <div className={`${styles.secretColumn} ${styles.hookSaveButton}`}>Save</div> */}
                            </div>
                        )}
                        {/* {Object.entries(agent.secrets).map(entry => {
                            const [key, value] = entry;
                            return <div className={styles.secretRow}>
                                
                                <div className={styles.secretColumn}>{key}</div>
                               
                            </div>
                        })} */}
                    </div>
                </div>

                <div className={styles.buttons}>
                        {!loadingState &&
                            <>
                                <button disabled={company.name == ""} onClick={submit} className={`${styles.buttonStyle} ${styles.submitButton} ${company.name == "" ? styles.disabledButton : null}`}>{isNewCompany ? 'Create' : 'Update'}</button>
                                {!isNewCompany && !companyHasAgents &&
                                    <button onClick={deleteCompanyAction} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}>Delete</button>
                                }
                                {companyHasAgents && <div className={styles.deleteMessage}>Delete all agents before deleting the company!</div>}
                            </>
                        }
                    </div>
            </div>
            )} 
            

        <Dialog
            open={openDialog}
            onClose={handleCloseDialogDisagree}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Delete company confirmation!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete company: {company?.name} and all its agents?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDisagree} autoFocus>No</Button>
          <Button onClick={handleCloseDialogAgree}>Yes</Button>
        </DialogActions>
      </Dialog>
        </>
    )

}
export default CreateCompanyPanel;
