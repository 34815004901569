import React, { Component } from 'react';

const Response = () => {
    
        return (
            <div>
                Response
            </div>
        );
}

export default Response;