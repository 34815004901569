import React, { Component, useState, useEffect, useRef, setState } from 'react';
import { Table, Card, Button } from 'react-bootstrap'
import { db } from '../firebase.js'
import { collection, getDocs, addDoc, updateDoc, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore'
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Entity = () => {
    const synonymArray = [];
    var phrasesNlArray = [];
    var phrasesEnArray = [];
    var phrasesDeArray = [];

    const [entities, setEntities] = useState([]);
    const [entity, setEntity] = useState();
    const [language, setLanguage] = useState("nl");


    const [entityWord, setEntityWord] = useState();

    const entityReference = collection(db, "entities");

    const entityRef = useRef();
    const wordRef = useRef();

    const nlWordRef = useRef();
    const enWordRef = useRef();
    const deWordRef = useRef();

    useEffect(() => {
        getEntities();
    }, [])

    useEffect(() => {
        if (entity != null) {

            const entityWordRef = entityWord?.key ?? Object.keys(entity[language])[0];
            handleClick(language, entityWordRef);
        }
    }, [entity])

    const getEntities = async () => {
        await getDocs(entityReference).then((data) => {
            var parsedData = data.docs.map((doc) => ({ id: doc.id }));
            setEntities(parsedData)
            getEntity(parsedData[0].id);
        });
    }

    const clickEntity = async (id) => {
        setEntityWord(null);
        getEntity(id);
    }
    const getEntity = async (id) => {
        const docRef = doc(db, "entities", id);
        await getDoc(docRef).then((docSnap) => {
            const data = { ...docSnap.data(), id: id };
            setEntity(data);
        })
    }
    const addEntity = async (entity) => {
        if (entity == "")
            return;

        const capitalized = await capitalizeFirstLetter(entity);
        const docRef = doc(db, 'entities', capitalized);
        setDoc(docRef, {
            nl: {},
            de: {},
            en: {}
        }).then(() => {
            getEntities();
            entityRef.current.value = "";
        })
    }
    const deleteEntity = async (id) => {
        let isConfirmed = window.confirm("Are you sure you want to delete this entity?");
        if (isConfirmed) {
            
            var nlUsedSynonyms = [];
            var enUsedSynonyms = [];
            var deUsedSynonyms = [];
            Object.keys(entity["nl"]).map(x => entity["nl"][x].split(";").forEach(i => nlUsedSynonyms.push("["+i+"]")));
            Object.keys(entity["en"]).map(x => entity["en"][x].split(";").forEach(i => enUsedSynonyms.push("["+i+"]")));
            Object.keys(entity["de"]).map(x => entity["de"][x].split(";").forEach(i => deUsedSynonyms.push("["+i+"]")));

            if (
                (phrasesNlArray.filter(input => nlUsedSynonyms.some(word => input.includes(word)))[0] != null) ||
                (phrasesEnArray.filter(input => enUsedSynonyms.some(word => input.includes(word)))[0] != null) ||
                (phrasesDeArray.filter(input => deUsedSynonyms.some(word => input.includes(word)))[0] != null)
            ) {
                alert("can't delete entity. Synonym in use in trainingphrase");
                return;
            }

            const docRef = doc(db, "entities", id);
            deleteDoc(docRef).then(() => {
                getEntities();
            })
        }
    }
    const updateEntity = async (lang, word) => {
        if (word != "") {
            const data = { ...entity[lang], [word]: word };
            const newFields = { [lang]: data };
            const docRef = doc(db, 'entities', entity.id);
            updateDoc(docRef, newFields);
            getEntity(entity.id);

            nlWordRef.current.value = "";
            enWordRef.current.value = "";
            deWordRef.current.value = "";
        }
    }

    const capitalizeFirstLetter = async (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const deleteEntityWord = async (entityWord, lang) => {
        let isConfirmed = window.confirm("Are you sure you want to delete this entity word?");
        if (isConfirmed) {

            var nlUsedSynonyms = [];
            var enUsedSynonyms = [];
            var deUsedSynonyms = [];
            entity["nl"][entityWord].split(";").forEach(i => nlUsedSynonyms.push("["+i+"]"));
            entity["nl"][entityWord].split(";").forEach(i => enUsedSynonyms.push("["+i+"]"));
            entity["nl"][entityWord].split(";").forEach(i => deUsedSynonyms.push("["+i+"]"));

            if (
                (phrasesNlArray.filter(input => nlUsedSynonyms.some(word => input.includes(word)))[0] != null) ||
                (phrasesEnArray.filter(input => enUsedSynonyms.some(word => input.includes(word)))[0] != null) ||
                (phrasesDeArray.filter(input => deUsedSynonyms.some(word => input.includes(word)))[0] != null)
            ) {
                alert("can't delete entityword. Synonym in use in trainingphrase");
                return;
            }

            const data = { ...entity[lang] };
            delete data[entityWord];
            const newFields = { [lang]: data };
            const docRef = doc(db, 'entities', entity.id);
            updateDoc(docRef, newFields);
            getEntity(entity.id);
        }
    }

    function handleClick(lang, e) {
        let d = [];
        if (entity[lang][e] != null) {
            d = entity[lang][e].split(';');
        }
        var data = {
            key: e,
            lang: lang,
            value: d
        }

        setEntityWord(data);
    }
    function setKey(lang) {
        setLanguage(lang);
        handleClick(lang, Object.keys(entity[lang])[0])
    }
    const handleKeyDown = event => {
        if (event.key === 'Enter' && wordRef.current.value != "") {
            addSynonym(wordRef.current.value, language);
            wordRef.current.value = "";
        }
    };

    function deleteSynonym(deleteItem) {
        const searchForDeleteItem = "[" + deleteItem + "]";
        if (
            (language.toLocaleLowerCase() == "nl" && phrasesNlArray.filter(i => i.indexOf(searchForDeleteItem) > -1)[0] != null) ||
            (language.toLocaleLowerCase() == "en" && phrasesEnArray.filter(i => i.indexOf(searchForDeleteItem) > -1)[0] != null) ||
            (language.toLocaleLowerCase() == "de" && phrasesDeArray.filter(i => i.indexOf(searchForDeleteItem) > -1)[0] != null)
        ) {
            alert("can't delete synonym. Synonym in use in trainingphrase");
            return;
        }

        const key = entityWord.key;
        const dataText = entity[language][key];
        const data = dataText.split(";");

        if (deleteItem == key) {
            window.alert("Can't delete same item as key");
            return;
        }

        if (data.length == 1) {
            window.alert("Can't delete last item of row");
            return;
        }
        var index = data.indexOf(deleteItem);
        if (index !== -1) {
            data.splice(index, 1);
            const dataTextNew = data.join(";");

            const dataComplete = { ...entity[language], [key]: dataTextNew };
            const newFields = { [language]: dataComplete };

            const docRef = doc(db, 'entities', entity.id);
            updateDoc(docRef, newFields);
            getEntity(entity.id);
        }

    }
    function addSynonym(synonym) {
        const synonymExist = synonymArray.filter(x => x.language == language && x.value == synonym.toLowerCase())[0];

        if (synonymExist != null) {
            alert("Synoniem bestaat al in entity: " + synonymExist.key);
            return;
        }

        const key = entityWord.key;
        const dataText = entity[language][key];
        const data = dataText.split(";");
        data.push(synonym);
        const dataTextNew = data.join(";");

        const dataComplete = { ...entity[language], [key]: dataTextNew };

        const newFields = { [language]: dataComplete };

        const docRef = doc(db, 'entities', entity.id);
        updateDoc(docRef, newFields);
        getEntity(entity.id);
    }
    const getPhrases = async () => {
        const entityReference = collection(db, "intents");
        await getDocs(entityReference).then((data) => {
            var parsedData = data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
            Object.keys(parsedData).forEach(key => {
                phrasesNlArray = phrasesNlArray.concat(parsedData[key].nlPhrases);
                phrasesEnArray = phrasesEnArray.concat(parsedData[key].enPhrases);
                phrasesDeArray = phrasesDeArray.concat(parsedData[key].dePhrases);
            })
        })
    }
    getPhrases();
    const getSynonyms = async () => {
        const entityReference = collection(db, "entities");
        await getDocs(entityReference).then((data) => {
            var parsedData = data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));

            parsedData.forEach((d) => {
                Object.keys(d["nl"]).forEach(key => {

                    d["nl"][key].split(";").forEach((syn) => {

                        synonymArray.push(
                            {
                                language: "nl",
                                key: key,
                                value: syn.toLowerCase()
                            }
                        );
                    })
                });
                Object.keys(d["de"]).forEach(key => {

                    d["de"][key].split(";").forEach((syn) => {

                        synonymArray.push(
                            {
                                language: "de",
                                key: key,
                                value: syn.toLowerCase()
                            }
                        );
                    })
                });
                Object.keys(d["en"]).forEach(key => {

                    d["en"][key].split(";").forEach((syn) => {

                        synonymArray.push(
                            {
                                language: "en",
                                key: key,
                                value: syn.toLowerCase()
                            }
                        );
                    })
                });

            })
        });
    }
    getSynonyms();

    return (

        <div className='d-flex '>
            <div className='d-flex p-2 flex-column' style={{ height: "100vh", background: "#444" }}>
                <h1 style={{ color: "#858585" }}>Entities</h1>
                <ListGroup as="ol">
                    <div className='d-flow'>
                        <input ref={entityRef} type="text" />
                        <Button variant="primary" size="sm" onClick={() => addEntity(entityRef.current.value)}>ADD</Button>
                    </div>
                    {entities.map((e) => {
                        return (
                            <ListGroup.Item style={{ width: "200px" }} active={entity && entity.id == e.id}
                                as="li" key={encodeURIComponent.id} onClick={() => clickEntity(e.id)}
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{e.id}</div>
                                </div>
                                <Badge bg="warning" pill onClick={() => deleteEntity(e.id)}> X
                                </Badge>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </div>
            <div style={{ backgroundColor: "#565656" }}>
                <Tabs
                    defaultActiveKey="nl"
                    id="uncontrolled-tab-example"
                    onSelect={(k) => setKey(k)}
                    className="mb-3">
                    <Tab eventKey="nl" title="NL">
                        {entity && (
                            <div className='p-2'>
                                <div className='d-flow'>
                                    <input ref={nlWordRef} type="text" />
                                    <Button variant="primary" size="sm" onClick={() => updateEntity("nl", nlWordRef.current.value)}>ADD</Button>
                                </div>
                                <Card style={{ width: '18rem' }}>
                                    <ListGroup variant="flush">
                                        {
                                            Object.keys(entity.nl).map((key, index) => {
                                                return <ListGroup.Item active={entityWord && entityWord.key == key} action onClick={() => handleClick("nl", key)}>{key}

                                                    <Badge bg="warning" pill onClick={() => deleteEntityWord(key, "nl")}> X
                                                    </Badge>
                                                </ListGroup.Item>
                                            })
                                        }
                                    </ListGroup>
                                </Card>
                            </div>
                        )}
                    </Tab>
                    <Tab eventKey="en" title="EN">
                        {entity && (
                            <div className='p-2'>
                                <div className='d-flow'>
                                    <input ref={enWordRef} type="text" />
                                    <Button variant="primary" size="sm" onClick={() => updateEntity("en", enWordRef.current.value)}>ADD</Button>
                                </div>
                                <Card style={{ width: '18rem' }}>
                                    <ListGroup variant="flush">
                                        {
                                            Object.keys(entity.en).map((key, index) => {
                                                return <ListGroup.Item active={entityWord && entityWord.key == key} action onClick={() => handleClick("en", key)}>{key}
                                                    <Badge bg="warning" pill onClick={() => deleteEntityWord(key, "en")}> X
                                                    </Badge>
                                                </ListGroup.Item>
                                            })
                                        }
                                    </ListGroup>
                                </Card>
                            </div>
                        )}
                    </Tab>
                    <Tab eventKey="de" title="DE">
                        {entity && (
                            <div className='p-2'>
                                <div className='d-flow'>
                                    <input ref={deWordRef} type="text" />
                                    <Button variant="primary" size="sm" onClick={() => updateEntity("de", deWordRef.current.value)}>ADD</Button>
                                </div>
                                <Card style={{ width: '18rem' }}>
                                    <ListGroup variant="flush">
                                        {
                                            Object.keys(entity.de).map((key, index) => {
                                                return <ListGroup.Item active={entityWord && entityWord.key == key} action onClick={() => handleClick("de", key)}>{key}
                                                    <Badge bg="warning" pill onClick={() => deleteEntityWord(key, "de")}> X
                                                    </Badge>
                                                </ListGroup.Item>
                                            })
                                        }
                                    </ListGroup>
                                </Card>
                            </div>
                        )}
                    </Tab>
                </Tabs>
            </div>

            <div className='d-flex p2  flex-fill'>
                {entityWord && (
                    <div className='d-flex flex-column flex-fill'>
                        <h1>Synonyms</h1>
                        <h2>{entityWord.key}</h2>
                        <div className='synonym-input-field d-flex'>
                            {
                                entityWord.value.map(element => {
                                    return <div onClick={() => deleteSynonym(element)} className='word'>{element}</div>
                                })
                            }
                            <input onKeyDown={handleKeyDown} ref={wordRef} type="text" />

                        </div>
                    </div>
                )
                }
            </div>





            {/* <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Netherlands</th>
                        <th>English</th>
                        <th>German</th>
                    </tr>
                </thead>
                <tbody>
                    {entities.map((entity) => {
                        return (
                            <tr key={entity.id}>
                                <td>{entity.id}</td>
                                <td>{entity.nl}</td>
                                <td>{entity.en}</td>
                                <td>{entity.de}</td>
                            </tr>)
                    })}
                </tbody>
            </Table> */}
        </div>
    );
}

export default Entity;