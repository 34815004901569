import { useAgentContext } from "contexts/AgentContext";
import { useAuth } from "contexts/AuthContext";
import { useProgressIndicatorContext } from "contexts/ProgressIndicatorContext";
import { useEffect, useState } from "react";
import { getApiCompany } from "services/companyService";
import styles from 'styles/admin/agent/agentLiveInfo.module.css';

const AgentLiveInfo = () => {
    const { setIndicatorState, resetIndicatorState, setInfoMessage, loadingState } = useProgressIndicatorContext();
    const { agent, setContextAgent } = useAgentContext();
    const [company, setCompany] = useState();
    const { currentUser, currentDbUser } = useAuth();

    useEffect(() => {
        if(agent != null) {
            getCompany();
        }
    }, [agent]);

    const
        getCompany = async () => {
            setCompany(await getApiCompany(agent.companyId));
        };

    return (
        <>
            {/* {agent != null && company != null && (
                <>
                <h3 className={styles.agentStatus}>Agent status</h3>
                        <div className={styles.agentStates}>
                            {Object.entries(agent.languages).map(entry => {
                            const [key,value] = entry;
                                return (
                                    <ul>
                                        <li >
                                            <span>{key.toUpperCase()}</span>
                                            <span className={value['liveSince'] != null && value['liveSince'] != "" ? styles.agentIsActive : styles.agentInactive}>
                                                {value['liveSince'] != null && value['liveSince'] != "" && '(LIVE)'}
                                            </span>
                                        </li>
                                        <li><strong>Phonenumber:</strong> {value['phonenumber']}</li>
                                        <li><strong>Live since:</strong> {value['liveSince']}</li>
                                    </ul>)
                            })}
                        </div>
                    
                </>
            )} */}
        </>
    )
}

export default AgentLiveInfo;