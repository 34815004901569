import React, { useState, useEffect, useMemo } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { getApiAgents, getApiAllAgents } from "../../../services/agentService";
import { MdOutlineManageAccounts } from "react-icons/md";
import { SlArrowDown } from "react-icons/sl";
import { TbWebhook } from "react-icons/tb";

import styles from "styles/admin/integration/integrationConfiguratorPage.module.css";
import WebhookList from "./webhookList";
import WebhookStepList from "./webhookStepList";
import IntegrationStepBuilder from "./integrationStepBuilder";
import AvailableVariables from "./availableVariables";
import Agent from "components/Agent";
import IntegrationConfigurator from "./integrationConfigurator";
import { getAllIntegrations } from "../../../services/integrationService";
import { convertArrayToObject } from "global/globalFunctions";
import { getApiCompanies, getApiCompany } from "services/companyService";
import { getCatalogsService } from "services/catalogService";

const IntegrationConfiguratorPage = () => {
  const [company, setCompany] = useState();
  const [availableVariables, setAvailableVariables] = useState();
  const [editPage, setEditPage] = useState("integrationSettings");

  const [integration, setIntegration] = useState();
  const [catalogs, setCatalogs] = useState([]);
  const [catalog, setCatalog] = useState();
  const [integrationStep, setIntegrationStep] = useState({ [""]: {} });

  const [selectedIntegrationObject, setSelectedIntegrationObject] = useState({
    integration: "",
    integrationHook: "",
    integrationHookStep: "",
  });

  const [companies, setCompanies] = useState([]);
  const { currentDbUser } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [catalogMenuOpen, setCatalogMenuOpen] = useState(false);

  useEffect(() => {
    if (company == null) return;
  }, [integration]);

  useEffect(() => {
    if (company == null) return;

    integrationUpdated();
  }, [selectedIntegrationObject]);

  useEffect(() => {
    if (!currentDbUser) return;

    getCompanies();
    getCatalogs();
  }, [currentDbUser]);

  const getCompanies = async () => {
    if (currentDbUser.isAdmin) {
      var retrievedCompanies = await getApiCompanies();
      var integrations = await getAllIntegrations();
      var integrationObjects = convertArrayToObject(integrations, "name");

      var integrationCatalog = {
        name: "Integration catalog",
        integrations: integrationObjects,
      };
      retrievedCompanies.push(integrationCatalog);
      setCompanies(retrievedCompanies);

      if (company == null) {
        setCompany(integrationCatalog);
      } else {
        if (company.name == "Integration catalog") {
          setCompany(integrationCatalog);
        } else {
          setCompany(
            retrievedCompanies.filter((x) => x.name == company.name)[0]
          );
        }
      }
    } else {
      var retrievedCompanies = await getApiCompany(currentDbUser.companyId);
      setCompanies([...retrievedCompanies]);
      if (company == null) {
        getAndSetCompanyFromLocalStorage([...retrievedCompanies]);
      }
    }
  };
  const getCatalogs = () => {
    getCatalogsService().then((res) => {
      setCatalogs(res);
      setCatalog(res[0]);
    });
  };

  const getAndSetCompanyFromLocalStorage = (retrievedCompanies) => {
    const companyName = localStorage.getItem("selectedCompany");
    var selectCompany = retrievedCompanies.filter(
      (x) => x.name == companyName
    )[0];
    if (selectCompany != null) {
      setCompany(selectCompany);
    } else {
      setCompany(retrievedCompanies[0]);
    }
  };

  const setIntegrationObject = (integrationObj) => {
    if (integrationObj == "") {
      setSelectedIntegrationObject({
        integration: "",
        integrationHook: "",
        integrationHookStep: "",
      });
      setEditPage("integrationSettings");
      setAvailableVariables([]);
    } else {
      setSelectedIntegrationObject({
        integration: integrationObj,
        integrationHook: "",
        integrationHookStep: "",
      });
      setEditPage("integrationSettings");
      setAvailableVariables([]);
    }
  };

  const setIntegrationHookObject = (integrationObj) => {
    setSelectedIntegrationObject({
      integration: integrationObj.integration,
      integrationHook: integrationObj.name,
      integrationHookStep: "",
    });
    setEditPage("integrationSettings");
    setAvailableVariables([]);
  };

  const setIntegrationObjectHookStep = (integrationHookStepObj) => {
    setSelectedIntegrationObject({
      ...selectedIntegrationObject,
      integrationHookStep: integrationHookStepObj,
    });

    setEditPage("integrationStep");
  };

  const updateSelectedIntegration = (integrationVal) => {
    setSelectedIntegrationObject({
      ...selectedIntegrationObject,
      integration: integrationVal,
      integrationHook: "",
    });
    // update integration list
    setTimeout(() => {
      getCompanies();
    }, 1200);
  };

  const updateSelectedIntegrationHook = (integrationHook) => {
    setSelectedIntegrationObject({
      ...selectedIntegrationObject,
      integrationHook: integrationHook,
    });
    // update integration list
    setTimeout(() => {
      getCompanies();
    }, 500);
  };
  const updateSelectedIntegrationHookStep = (integrationHookStep) => {
    setSelectedIntegrationObject({
      ...selectedIntegrationObject,
      integrationHookStep: integrationHookStep,
    });
    // update integration list
    setTimeout(() => {
      getCompanies();
    }, 500);
  };

  const filteredCompanies = useMemo(() => {
    // Create copy of item list
    var updatedList = [...companies];
    // Include all elements which includes the search query
    return (updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
    }));
  }, [companies, searchQuery]);

  const openDropDownSearch = () => {
    setMenuOpen(!menuOpen);
  };
  const companySelected = async (e) => {
    setSelectedIntegrationObject({
      integration: "",
      integrationHook: "",
      integrationHookStep: "",
    });
    setCompany(e);
    localStorage.setItem("selectedCompany", e.name);
    setSearchQuery("");
    openDropDownSearch();
    setEditPage("integrationSettings");
    setAvailableVariables([]);
  };
  const catalogSelected = (c) => {
    setCatalog(c);
    openDropDownSearchCatalog();
  };
  const openDropDownSearchCatalog = () => {
    setCatalogMenuOpen(!catalogMenuOpen);
  };

  const integrationUpdated = (tabValue) => {
    getCompanies();
    if (editPage == "integrationStep") {
      var variables = [];
      if (company.secrets != null) {
        Object.entries(company.secrets).map((x) =>
          variables.push({ key: x[0], type: "secret" })
        );
      }
      var DEFAULT_AVAILABLE = [
        "AGENT",
        "SESSION_ID",
        "INTENT",
        "QUESTION",
        "PHONENUMBER",
        "PHONENUMBERCALLED",
        "CONTACTTYPE",
        "PHONEID",
        "PHONE_MAPPING_QUEUE",
        "PHONE_QUEUE",
        "DATETIME_NOW",
      ];
      DEFAULT_AVAILABLE.forEach((x) =>
        variables.push({ key: x, type: "variable" })
      );

      if (selectedIntegrationObject.integrationHookStep != "") {
        var integrationObj =
          company.integrations[selectedIntegrationObject.integration].hooks[
            selectedIntegrationObject.integrationHook
          ].steps[selectedIntegrationObject.integrationHookStep];

        if (integrationObj != null) {
          var inputWebhook =
            company.integrations[selectedIntegrationObject.integration].hooks[
              selectedIntegrationObject.integrationHook
            ].steps[integrationObj.inputWebhook];

          if (inputWebhook != null) {
            inputWebhook.exportParameters.map((x) =>
              variables.push({ key: x, type: "variable" })
            );
          }
          if (integrationObj.settings.requestOrdernumber) {
            variables.push({ key: "ORDERNUMBER", type: "variable" });
          }
          if (integrationObj.settings.requestPhonenumber) {
            variables.push({ key: "PHONENUMBER", type: "variable" });
          }
          if (tabValue >= 7) {
            integrationObj.exportParameters.map((variable) => {
              variables.push({ key: variable, type: "variable" });
            });
          }
        }
      }

      setAvailableVariables(variables);
    }
  };

  const editIntegrationSettings = () => {
    setEditPage("integrationSettings");
    setAvailableVariables([]);
    setSelectedIntegrationObject({
      ...selectedIntegrationObject,
      integrationHookStep: "",
    });
  };

  const selectedIntegrationSteps = () => {
    if (!company?.integrations) return null;

    if (
      selectedIntegrationObject.integration == "" ||
      selectedIntegrationObject.integrationHook == ""
    )
      return null;

    return Object.keys(
      company.integrations[selectedIntegrationObject.integration]?.hooks[
        selectedIntegrationObject.integrationHook
      ]?.steps
    );
  };

  return (
    <div className={`${styles.integration}`}>
      <div className={`${styles.header} ${styles.widget}`}>
        <h1>Integration builder</h1>
        <h3>Build your integrations</h3>
      </div>

      <div className={`${styles.agentsContainer} ${styles.widget}`}>
        <div className={styles.optionsAgentSelection}>
          <div className={styles.optionsHeader}>
            <MdOutlineManageAccounts className={styles.optionsIcon} /> Select
            company
          </div>
          <div className={styles.dropdown}>
            <button onClick={openDropDownSearch} className={styles.dropbtn}>
              {company?.name}{" "}
              <SlArrowDown
                className={`${styles.dropdownIcon} ${
                  menuOpen ? styles.rotate : null
                }`}
              />
            </button>
            <div
              id={styles.myDropdown}
              className={`${styles["dropdown-content"]} ${
                menuOpen ? styles.show : null
              }`}
            >
              <input
                value={searchQuery}
                type="text"
                placeholder="Zoeken"
                id={styles.myInput}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {filteredCompanies.map((s) => {
                return (
                  <span key={s.name} onClick={() => companySelected(s)}>
                    {s.name}
                  </span>
                );
              })}
              {filteredCompanies.length === 0 ? (
                <span className={styles.noResult}>Geen resultaten</span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.catalogContainer} ${styles.widget}`}>
        <div className={styles.optionsAgentSelection}>
          <div className={styles.optionsHeader}>
            <MdOutlineManageAccounts className={styles.optionsIcon} /> Select
            catalog
          </div>
          <div className={styles.dropdown}>
            <button
              onClick={openDropDownSearchCatalog}
              className={styles.dropbtn}
            >
              {catalog?.name}{" "}
              <SlArrowDown
                className={`${styles.dropdownIcon} ${
                  catalogMenuOpen ? styles.rotate : null
                }`}
              />
            </button>
            <div
              id={styles.myDropdown}
              className={`${styles["dropdown-content"]} ${
                catalogMenuOpen ? styles.show : null
              }`}
            >
              {catalogs.map((c) => {
                return (
                  <span key={c.name} onClick={() => catalogSelected(c)}>
                    {c.name}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.webhookContainer} ${styles.widget}`}>
        <div
          className={`${styles.optionsAgentSelection} ${styles.overflowYScroll}`}
        >
          <div className={styles.optionsHeader}>
            <TbWebhook className={styles.optionsIcon} /> Select integration
          </div>
          <WebhookList
            catalog={catalog}
            company={company}
            setParentIntegration={setIntegrationObject}
            setParentIntegrationHook={setIntegrationHookObject}
            selectedIntegration={{
              name: selectedIntegrationObject.integrationHook,
              integration: selectedIntegrationObject.integration,
            }}
          />
        </div>
      </div>
      <div className={`${styles.webhookStepContainer} ${styles.widget}`}>
        <div className={styles.optionsAgentSelection}>
          <div className={styles.optionsHeader}>
            <TbWebhook className={styles.optionsIcon} /> Select step
          </div>
          {company?.integrations && (
            <WebhookStepList
              editIntegrationSettings={editIntegrationSettings}
              integration={selectedIntegrationSteps()}
              setParentIntegrationStep={setIntegrationObjectHookStep}
              selectedIntegrationStep={
                selectedIntegrationObject.integrationHookStep
              }
            />
          )}
        </div>
      </div>
      <div className={`${styles.integrationContainer} ${styles.widget}`}>
        {editPage == "integrationStep" ? (
          <IntegrationStepBuilder
            catalog={catalog}
            integration={selectedIntegrationObject}
            company={company}
            integrationUpdated={integrationUpdated}
            updateSelectedIntegrationHookStep={
              updateSelectedIntegrationHookStep
            }
          />
        ) : (
          <IntegrationConfigurator
            catalog={catalog}
            integration={selectedIntegrationObject}
            company={company}
            updateSelectedIntegration={updateSelectedIntegration}
            updateSelectedIntegrationHook={updateSelectedIntegrationHook}
          />
        )}
      </div>

      <div className={`${styles.availableVariableContainer} ${styles.widget}`}>
        <div className={styles.optionsAgentSelections}>
          <div className={styles.optionsHeader}>
            <TbWebhook className={styles.optionsIcon} /> Available variables
          </div>
          <AvailableVariables availableVariables={availableVariables} />
        </div>
      </div>
    </div>
  );
};
export default IntegrationConfiguratorPage;
