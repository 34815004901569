import React, { useState, useRef, useMemo, useEffect } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";

import styles from "styles/admin/optimization/saveAndPublish.module.css";
import { useProgressIndicatorContext } from "contexts/ProgressIndicatorContext";
import { useAuth } from "contexts/AuthContext";
import {
  uploadIntentSheet,
  saveAgentFlows,
  saveAllAgentFlows,
  saveAgentQuestionFlow,
  saveAllAgentsQuestionFlow,
  publishAgent,
  publishAgents,
  updateAgentRoutegroupsAndPages,
  updateAllAgentRoutegroupsAndPages,
  backupCoreAgent,
  restoreAllAgents,
  restoreAgentApi,
  getApiAllAgents,
  createCoreAgent,
  restoreSpecificAgent,
} from "services/agentService";
import ToggleSwitch from "components/global/toggleSwitch";
import { updateUserActionHistoryList } from "services/userService";
import { Navigate } from "react-router-dom";
import { SlArrowDown } from "react-icons/sl";
import FormText from "components/global/formText";
import DropdownSearch from "components/global/dropdownSearch";
import { getCatalogsService } from "services/catalogService";
import { getFlowsService } from "services/flowService";
import CategorizedDropdownSearch from "components/global/categorizedDropdownSearch";
import { FormControlLabel, Switch } from "@mui/material";
import { deleteField } from "firebase/firestore";

const SaveAndPublish = () => {
  // const fileRef = useRef();
  const { currentDbUser, currentUser } = useAuth();
  const { setIndicatorState, resetIndicatorState, loadingState } =
    useProgressIndicatorContext();
  //   const [file, setFile] = useState();
  //   const [fileName, setFileName] = useState();

  //     const handleFileChange = (e) => {
  //       if (e.target.files) {
  //         setFile(e.target.files[0]);
  //         setFileName(e.target.files[0].name);
  //       }

  //     };

  const backupCore = () => {
    setIndicatorState("saving");
    backupCoreAgent({
      DialogflowId: flow.dialogflowId,
      name: flow.name,
      type: flow.type,
    }).then((x) => {
      resetIndicatorState("Backup updated for core agent!");
      updateUserActionHistoryList(
        currentUser.uid,
        "core",
        "Updated backup for core agent"
      );
    });
  };

  const importCore = () => {
    setIndicatorState("importing");
    restoreAllAgents({ coreAgentId: flow.id }).then((x) => {
      resetIndicatorState(
        `All agents successfully restored based on core ${flow.name}!`
      );
      updateUserActionHistoryList(
        currentUser.uid,
        "Core",
        `All agents restored based on core ${type}`
      );
    });
  };
  const importCoreForAgent = () => {
    setIndicatorState("importing");
    restoreSpecificAgent({ coreAgentId: flow.id, agentId: agent.id }).then(
      (x) => {
        resetIndicatorState(
          `Agent successfully restored based on core ${flow.name}!`
        );
        updateUserActionHistoryList(
          currentUser.uid,
          "Core",
          `Agent restored based on core ${type}`
        );
      }
    );
  };
  // const upload = () => {
  //     if(file != null)
  //     {
  //         setIndicatorState("uploading");
  //         if(fileName == "DGTL Assist - import sheet.xlsx"){
  //             const formData = new FormData();
  //             formData.append("formFile", file);
  //             formData.append("fileName", fileName);
  //             uploadIntentSheet(formData).then(x => {
  //                 resetIndicatorState("file succesfully uploaded");
  //                 setFile(null);
  //                 setFileName(null);
  //                 fileRef.current.value = null;
  //                 updateUserActionHistoryList(currentUser.uid, "Core", "Uploaded csv file");
  //             });
  //         }else{
  //             alert("Filename not right");
  //         }
  //     }
  // }
  // const update = () => {
  //     setIndicatorState("updating");
  //     if(allAgent){
  //         updateAllAgentRoutegroupsAndPages().then(x => {
  //             resetIndicatorState("All agents routegroups and pages refreshed!");
  //             updateUserActionHistoryList(currentUser.uid, "Core", "Refreshed routegroups and pages");
  //         });
  //     }else{
  //         let agentName =  type == "voice" ? agentVoice.name : agentChat.name
  //         updateAgentRoutegroupsAndPages({agentName: agentName}).then(x => {
  //             resetIndicatorState("Agents routegroups and pages refreshed!");
  //             updateUserActionHistoryList(currentUser.uid, agentName, "Refreshed routegroups and pages");
  //         });
  //     }
  // }
  const restoreAgent = (type) => {
    setIndicatorState("saving");
    if (allAgent) {
      restoreAllAgents({ agentName: "", type: type }).then((x) => {
        resetIndicatorState(
          `All agents successfully restored based on core ${type}!`
        );
        updateUserActionHistoryList(
          currentUser.uid,
          "Core",
          `All agents restored based on core ${type}`
        );
      });
    } else {
      let agentName = type == "voice" ? agentVoice.name : agentChat.name;
      restoreAgentApi({ agentName: agentName, type: type }).then((x) => {
        resetIndicatorState(
          `Agent successfully restored based on core ${type}!`
        );
        updateUserActionHistoryList(
          currentUser.uid,
          agentName,
          `Agent restored based on core ${type}`
        );
      });
    }
  };
  const newVersion = () => {
    setIndicatorState("saving");
    if (allAgent) {
      saveAllAgentFlows().then((x) => {
        resetIndicatorState("All agent flows successfully saved!");
        updateUserActionHistoryList(
          currentUser.uid,
          "Core",
          "Agent flow versions saved"
        );
      });
    } else {
      saveAgentFlows({ agentName: agentPublish.name }).then((x) => {
        resetIndicatorState("Agent flows successfully saved!");
        updateUserActionHistoryList(
          currentUser.uid,
          agentPublish.name,
          "Agent flow versions saved"
        );
      });
    }
  };
  const publish = () => {
    setIndicatorState("publishing");
    if (allAgent) {
      publishAgents().then((x) => {
        resetIndicatorState("Agents successfully published!");
        updateUserActionHistoryList(
          currentUser.uid,
          "Core",
          "New agent published to production environment"
        );
      });
    } else {
      publishAgent({ agentName: agentPublish.name }).then((x) => {
        resetIndicatorState("Agent successfully published!");
        updateUserActionHistoryList(
          currentUser.uid,
          agentPublish.name,
          "New agent published to production environment"
        );
      });
    }
  };

  const [agents, setAgents] = useState([]);
  useEffect(() => {
    if (!currentDbUser) return;

    if (!currentDbUser?.isAdmin) {
      Navigate("/admin/agents");
    }

    const getAgents = async () => {
      var retrievedAgents = await getApiAllAgents();
      setAgents(retrievedAgents);
    };
    getAgents();
    getCatalogs();
    getFlows();
  }, [currentDbUser]);

  const getCatalogs = () => {
    getCatalogsService().then((res) => {
      setCatalogs(res);
    });
  };
  const getFlows = () => {
    getFlowsService().then((res) => {
      setFlows(res);
    });
  };

  const [agentVoice, setAgentVoice] = useState({ name: "" });
  const [menuAgentVoiceOpen, setMenuAgentVoiceOpen] = useState(false);
  const [searchQueryAgentVoice, setSearchQueryAgentVoice] = useState("");
  const openDropDownVoiceAgentSearch = () => {
    setMenuAgentVoiceOpen(!menuAgentVoiceOpen);
  };
  const agentSelectedVoice = (e) => {
    setAgentVoice(e);
    setSearchQueryAgentVoice("");
    setMenuAgentVoiceOpen(false);
  };

  const filteredAgentsVoice = useMemo(() => {
    var updatedList = [...agents];
    return (updatedList = updatedList.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(searchQueryAgentVoice.toLowerCase()) !==
          -1 && item.agentType == "voice"
      );
    }));
  }, [agents, searchQueryAgentVoice]);

  const [agentChat, setAgentChat] = useState({ name: "" });
  const [menuAgentChatOpen, setMenuAgentChatOpen] = useState(false);
  const [searchQueryAgentChat, setSearchQueryAgentChat] = useState("");
  const openDropDownChatAgentSearch = () => {
    setMenuAgentChatOpen(!menuAgentChatOpen);
  };
  const agentSelectedChat = (e) => {
    setAgentChat(e);
    setSearchQueryAgentChat("");
    setMenuAgentChatOpen(false);
  };

  const filteredAgentsChat = useMemo(() => {
    var updatedList = [...agents];
    return (updatedList = updatedList.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(searchQueryAgentChat.toLowerCase()) !==
          -1 && item.agentType == "chat"
      );
    }));
  }, [agents, searchQueryAgentChat]);

  const [agentPublish, setAgentPublish] = useState({ name: "" });
  const [menuAgentPublishOpen, setMenuAgentPublishOpen] = useState(false);
  const [searchQueryAgentPublish, setSearchQueryAgentPublish] = useState("");
  const openDropDownPublishAgentSearch = () => {
    setMenuAgentPublishOpen(!menuAgentPublishOpen);
  };
  const agentSelectedPublish = (e) => {
    setAgentPublish(e);
    setSearchQueryAgentPublish("");
    setMenuAgentPublishOpen(false);
  };

  const filteredAgentsPublish = useMemo(() => {
    console.log(agents);
    var updatedList = [...agents];
    return (updatedList = updatedList.filter((item) => {
      return (
        item.name
          .toLowerCase()
          .indexOf(searchQueryAgentPublish.toLowerCase()) !== -1
      );
    }));
  }, [agents, searchQueryAgentPublish]);

  const [allAgent, setAllAgents] = useState(false);

  const [flow, setFlow] = useState({
    id: "",
    name: "",
    type: "",
    dialogflowId: "",
    catalogId: "",
    fields: [],
    firstOfType: true,
  });
  const [catalog, setCatalog] = useState(null);
  const [flows, setFlows] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const types = [
    { id: "chat", name: "chat" },
    { id: "voice", name: "voice" },
  ];

  const [type, setType] = useState("");
  const [dialogflowAgent, setDialogflowAgent] = useState(null);

  const setSelectedFlow = (f) => {
    if (f == "new") {
      setFlow({
        id: "",
        name: "",
        type: "",
        dialogflowId: "",
        catalogId: "",
        fields: [],
      });
      setCatalog(null);
    } else {
      setFlow(f);
      var cat = catalogs.filter((x) => x.id == f.catalogId)[0];
      setCatalog(cat);
      var type = types.filter((x) => x.id == f.type)[0];
      setType(type);
      var dialogflowAg = flows.filter(
        (x) => x.dialogflowId == f.dialogflowId && x.firstOfType
      )[0];
      setDialogflowAgent(dialogflowAg);
    }
  };

  const deleteFlowAction = () => {};

  const addNewFlowButton = () => {
    setIndicatorState(flow.id == "" ? "Create" : "Update" + " core agent");

    createCoreAgent(flow).then((result) => {
      setFlow(result.object);
      resetIndicatorState(
        "Core agent successfully " + flow.id == "" ? "created" : "updated" + "!"
      );
      updateUserActionHistoryList(
        currentUser.uid,
        flow.name,
        flow.id == "" ? "Created" : "Updated" + " new core agent"
      );
      getFlows();
    });
  };
  const handleChangeNewFlow = (prop) => (event) => {
    setFlow((prev) => ({ ...prev, [prop]: event.target.value }));
  };

  const setSelectedCatalog = (e) => {
    setCatalog(e);
    setFlow((prev) => ({ ...prev, catalogId: e.id }));
  };
  const setSelectedType = (e) => {
    setType(e);
    setFlow((prev) => ({ ...prev, type: e.name }));
  };
  const setDialogflowId = (e) => {
    setFlow((prev) => ({
      ...prev,
      dialogflowId: e.dialogflowId,
      firstOfType: false,
    }));
    setDialogflowAgent(e);
  };

  const updateIntentToggleProp = (id) => {
    if (flow.fields.indexOf(id) > -1) {
      setFlow((prev) => ({
        ...prev,
        fields: [...prev.fields.filter((x) => x != id)],
      }));
    } else {
      setFlow((prev) => ({ ...prev, fields: [...prev.fields, id] }));
    }
  };

  const flowsWithFields = () => {
    var f = flows.filter(
      (x) => catalogs.filter((y) => y.id == x.catalogId)[0]?.fields.length == 7
    );

    console.log(f);
    return f;
  };

  const [agent, setAgent] = useState();
  const setSelected = (ag) => {
    setAgent(ag);
  };

  return (
    <>
      {currentDbUser?.isSuperAdmin && (
        <div className={styles.widgets}>
          <div div className={`${styles.widget}`}>
            <div className={styles.widgetHeader}>
              <div className={styles.headerTitle}>Flows</div>
              <CategorizedDropdownSearch
                subHeader="type"
                data={flows}
                type="flow"
                selected={flow}
                setSelected={setSelectedFlow}
              />
              <div
                onClick={() => setSelectedFlow("new")}
                className={styles.buttonStyle}
              >
                add new Flow
              </div>
            </div>
            {flow && (
              <div className={styles.formPanel}>
                <div className={styles.formRow}>
                  <div className={styles.formElementContainer}>
                    <FormText
                      isDisabled={!flow.id == ""}
                      title="Name"
                      formId="name"
                      value={flow?.name}
                      handleChange={handleChangeNewFlow}
                    />
                  </div>
                </div>

                <div className={styles.formRow}>
                  <div className={styles.dropdownContainer}>
                    <div className={styles.dropdownHeader}>Type:</div>
                    <DropdownSearch
                      isDisabled={!flow.id == ""}
                      data={types}
                      type="type"
                      selected={type}
                      setSelected={setSelectedType}
                    />
                  </div>
                </div>

                {catalog?.fields.length == 7 && (
                  <div className={styles.formRow}>
                    <div className={styles.dropdownContainer}>
                      <div className={styles.dropdownHeader}>
                        Core agent (when you want to reuse a agent because they
                        are simular):
                      </div>
                      <DropdownSearch
                        isDisabled={!flow.id == ""}
                        data={flowsWithFields()}
                        type="type"
                        selected={dialogflowAgent}
                        setSelected={setDialogflowId}
                      />
                    </div>
                  </div>
                )}

                <div className={styles.dropdownContainer}>
                  <div className={styles.dropdownHeader}>Catalog:</div>
                  <DropdownSearch
                    isDisabled={!flow.id == ""}
                    data={catalogs}
                    type="catalog"
                    selected={catalog}
                    setSelected={setSelectedCatalog}
                  />
                </div>

                <div className={styles.newIntentContainerFieldsHeader}>
                  Flows:
                </div>
                <div className={styles.catalogCheckboxOptions}>
                  {catalog?.fields
                    .filter((x) => x.type == "flow")
                    .map((x) => (
                      <div>
                        <FormControlLabel
                          control={<Switch size="small" />}
                          label={x.name}
                          checked={flow.fields.indexOf(x.id) > -1}
                          name={x.id}
                          onChange={(e) => updateIntentToggleProp(x.id)}
                        />
                      </div>
                    ))}
                </div>

                <div className={styles.newIntentContainerFieldsHeader}>
                  Endings:
                </div>
                <div className={styles.catalogCheckboxOptions}>
                  {catalog?.fields
                    .filter((x) => x.type == "ending")
                    .map((x) => (
                      <div>
                        <FormControlLabel
                          control={<Switch size="small" />}
                          label={x.name}
                          checked={flow.fields.indexOf(x.id) > -1}
                          name={x.id}
                          onChange={(e) => updateIntentToggleProp(x.id)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div className={styles.buttonsNewFlow}>
              {!loadingState && (
                <>
                  <button
                    disabled={
                      flow.catalogId == "" || flow.name == "" || flow.type == ""
                    }
                    onClick={addNewFlowButton}
                    className={`${styles.buttonStyle} ${styles.submitButton} ${
                      flow.catalogId == "" || flow.name == "" || flow.type == ""
                        ? styles.disabledButton
                        : null
                    }`}
                  >
                    {flow.id == "" ? "Create" : "Update"}
                  </button>
                  {/* {!flow.id == '' &&
                                    <button onClick={deleteFlowAction} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}>Delete</button>
                                } */}
                </>
              )}
            </div>
            {flow.id && (
              <div className={styles.extraOptions}>
                <div className={styles.buttonRow}>
                  <div className={styles.buttonRowHeader}>
                    Update backup for core: {flow.type}
                  </div>
                  <button
                    onClick={backupCore}
                    className={`${styles.buttonStyle} ${styles.submitButton}`}
                  >
                    Update
                  </button>
                </div>
                <div className={styles.buttonRow}>
                  <div className={styles.buttonRowHeader}>
                    Import backup to all child agents and publish this new
                    version
                  </div>
                  <button
                    onClick={importCore}
                    className={`${styles.buttonStyle} ${styles.submitButton}`}
                  >
                    Publish new versions
                  </button>
                </div>

                <CategorizedDropdownSearch
                  data={agents}
                  subHeader="agentType"
                  type="agent"
                  selected={agent}
                  setSelected={setSelected}
                />
                {agent != null && (
                  <div className={styles.buttonRow}>
                    <div className={styles.buttonRowHeader}>
                      Import backup to agent and publish this new version
                    </div>
                    <button
                      onClick={importCoreForAgent}
                      className={`${styles.buttonStyle} ${styles.submitButton}`}
                    >
                      Publish new version for {agent.name}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* <div div className={`${styles.widget}`}>

                <div className={styles.chatboxOptionLine}>
                    <strong>Voice</strong>
                </div>
                    <div className={styles.buttonRow}>
                            <div className={styles.buttonRowHeader}>Backup core agent voice</div>
                            <div onClick={() => backup("voice")} className={styles.buttonRowActionButton}><button className={styles.button}>Backup</button></div>
                    </div>
                    <div className={styles.chatboxOptionLine}>
                    <div className={styles.chatboxOptionSelector}><ToggleSwitch prop="validateQuestion"
                                                                                parentSetChecked={(e) => setAllAgents(e)}
                                                                                className={styles.toggleSwitch}
                                                                                isChecked={allAgent}/>
                    </div>
                    
                    <div
                        className={styles.chatboxOptionDescription}>Do operation for all agents
                    </div>
                    <div className={styles.dropdownContainer}>
                        <div className={styles.dropdown}>
                        <button onClick={openDropDownVoiceAgentSearch} className={styles.dropbtn}>{agentVoice.name} <SlArrowDown className={`${styles.dropdownIcon} ${menuAgentVoiceOpen ? styles.rotate : null}`}/></button>
                            <div id={styles.myDropdown} className={`${styles["dropdown-content"]} ${menuAgentVoiceOpen ? styles.show : null}`}>
                                <input value={searchQueryAgentVoice} type="text" placeholder="Zoeken" id={styles.myInput} onChange={(e) => setSearchQueryAgentVoice(e.target.value)} />
                                {filteredAgentsVoice.map((s) => {return (<span key={s.name} onClick={() => agentSelectedVoice(s)}>{s.name}</span>)})}
                                {filteredAgentsVoice.length === 0 ? <span className={styles.noResult}>Geen resultaten</span> : null}
                            </div>
                        </div>
                    </div>
                </div>
                    <div className={styles.buttonRow}>
                        <div className={styles.buttonRowHeader}>Restore agent based on core voice for <strong>{allAgent ? "all agents" : agentVoice.name}</strong></div>
                        <div onClick={() => restoreAgent('voice')} className={styles.buttonRowActionButton}><button className={styles.button}>Restore agent(s)</button></div>
                </div> */}

          {/* <div className={styles.buttonRow}>
                        <div className={styles.buttonRowHeader}>Upload new intent/entity/phrases excel document</div>
                        <div className={styles.buttonRowActionButton}>
                            <input ref={fileRef} type="file" onChange={handleFileChange} />
                            {!loadingState && (
                                <button onClick={upload} className={styles.button}>Upload</button>
                            )}
                        </div>
                </div>
                
                <div className={styles.buttonRow}>
                        <div className={styles.buttonRowHeader}>Update intentstructure for <strong>{allAgent ? "all agents" : agent.name}</strong>with uploaded excel document</div>
                        <div onClick={update} className={styles.buttonRowActionButton}><button className={styles.button}>Update</button></div>
                </div> */}
          {/* </div> */}

          <div div className={`${styles.widget}`}>
            <div className={styles.chatboxOptionLine}>
              <strong>Chat</strong>
            </div>
            <div className={styles.buttonRow}>
              <div className={styles.buttonRowHeader}>
                Backup core agent chat
              </div>
              {/* <div onClick={() => backup("chat")} className={styles.buttonRowActionButton}><button className={styles.button}>Backup</button></div> */}
            </div>
            <div className={styles.chatboxOptionLine}>
              <div className={styles.chatboxOptionSelector}>
                <ToggleSwitch
                  prop="validateQuestion"
                  parentSetChecked={(e) => setAllAgents(e)}
                  className={styles.toggleSwitch}
                  isChecked={allAgent}
                />
              </div>

              <div className={styles.chatboxOptionDescription}>
                Do operation for all agents
              </div>
              <div className={styles.dropdownContainer}>
                <div className={styles.dropdown}>
                  <button
                    onClick={openDropDownChatAgentSearch}
                    className={styles.dropbtn}
                  >
                    {agentChat.name}{" "}
                    <SlArrowDown
                      className={`${styles.dropdownIcon} ${
                        menuAgentChatOpen ? styles.rotate : null
                      }`}
                    />
                  </button>
                  <div
                    id={styles.myDropdown}
                    className={`${styles["dropdown-content"]} ${
                      menuAgentChatOpen ? styles.show : null
                    }`}
                  >
                    <input
                      value={searchQueryAgentChat}
                      type="text"
                      placeholder="Zoeken"
                      id={styles.myInput}
                      onChange={(e) => setSearchQueryAgentChat(e.target.value)}
                    />
                    {filteredAgentsChat.map((s) => {
                      return (
                        <span key={s.name} onClick={() => agentSelectedChat(s)}>
                          {s.name}
                        </span>
                      );
                    })}
                    {filteredAgentsChat.length === 0 ? (
                      <span className={styles.noResult}>Geen resultaten</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttonRow}>
              <div className={styles.buttonRowHeader}>
                Restore agent based on core chat for{" "}
                <strong>{allAgent ? "all agents" : agentChat.name}</strong>
              </div>
              <div
                onClick={() => restoreAgent("chat")}
                className={styles.buttonRowActionButton}
              >
                <button className={styles.button}>Restore agent(s)</button>
              </div>
            </div>
          </div>

          <div div className={`${styles.widget}`}>
            <div className={styles.chatboxOptionLine}>
              <strong>Publish</strong>
            </div>
            <div className={styles.chatboxOptionLine}>
              <div className={styles.chatboxOptionSelector}>
                <ToggleSwitch
                  prop="validateQuestion"
                  parentSetChecked={(e) => setAllAgents(e)}
                  className={styles.toggleSwitch}
                  isChecked={allAgent}
                />
              </div>

              <div className={styles.chatboxOptionDescription}>
                Do operation for all agents
              </div>
              <div className={styles.dropdownContainer}>
                <div className={styles.dropdown}>
                  <button
                    onClick={openDropDownPublishAgentSearch}
                    className={styles.dropbtn}
                  >
                    {agentPublish.name}{" "}
                    <SlArrowDown
                      className={`${styles.dropdownIcon} ${
                        menuAgentPublishOpen ? styles.rotate : null
                      }`}
                    />
                  </button>
                  <div
                    id={styles.myDropdown}
                    className={`${styles["dropdown-content"]} ${
                      menuAgentPublishOpen ? styles.show : null
                    }`}
                  >
                    <input
                      value={searchQueryAgentPublish}
                      type="text"
                      placeholder="Zoeken"
                      id={styles.myInput}
                      onChange={(e) =>
                        setSearchQueryAgentPublish(e.target.value)
                      }
                    />
                    {filteredAgentsPublish.map((s) => {
                      return (
                        <span
                          key={s.name}
                          onClick={() => agentSelectedPublish(s)}
                        >
                          {s.name}
                        </span>
                      );
                    })}
                    {filteredAgentsPublish.length === 0 ? (
                      <span className={styles.noResult}>Geen resultaten</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.buttonRow}>
              <div className={styles.buttonRowHeader}>
                Create new version for{" "}
                <strong>{allAgent ? "all agents" : agentPublish.name}</strong>
              </div>
              <div
                onClick={newVersion}
                className={styles.buttonRowActionButton}
              >
                <button className={styles.button}>Create new version</button>
              </div>
            </div>

            <div className={styles.buttonRow}>
              <div className={styles.buttonRowHeader}>
                Publish new version to production for{" "}
                <strong>{allAgent ? "all agents" : agentPublish.name}</strong>
              </div>
              <div onClick={publish} className={styles.buttonRowActionButton}>
                <button className={styles.button}>Publish</button>
              </div>
            </div>

            {/* <div className={styles.buttonRow}>
                        <div className={styles.buttonRowHeader}>Upload new intent/entity/phrases excel document</div>
                        <div className={styles.buttonRowActionButton}>
                            <input ref={fileRef} type="file" onChange={handleFileChange} />
                            {!loadingState && (
                                <button onClick={upload} className={styles.button}>Upload</button>
                            )}
                        </div>
                </div>
                
                <div className={styles.buttonRow}>
                        <div className={styles.buttonRowHeader}>Update intentstructure for <strong>{allAgent ? "all agents" : agent.name}</strong>with uploaded excel document</div>
                        <div onClick={update} className={styles.buttonRowActionButton}><button className={styles.button}>Update</button></div>
                </div> */}
          </div>
        </div>
      )}
    </>
  );
};
export default SaveAndPublish;
