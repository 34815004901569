import React, { setState } from "react";
import { db } from "../firebase.js";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  where,
  query,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

const updateAgentIntentFields = async (agent) => {
  var currentAgent = await getApiAgent(agent.id);
  agent.secrets = currentAgent.secrets;
  const docRef = doc(db, "agents", agent.id);
  return await updateDoc(docRef, agent);
};

const getApiAllAgents = async () => {
  const reference = collection(db, "agents");

  return await getDocs(reference).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};

const getApiAgents = async (user) => {
  const reference = collection(db, "agents");
  const q = query(reference, where("companyId", "==", user.companyId));

  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};
const getApiAgentsByIdName = async (idName) => {
  const reference = collection(db, "agents");
  const q = query(reference, where("idName", "==", idName));

  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};
const getApiAgentsByAgentCatalogName = async (agentCatalogName) => {
  const reference = collection(db, "agents");
  const q = query(reference, where("agentCatalogName", "==", agentCatalogName));

  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};

const getApiAgentsByAgentCatalogNameNameAndChannel = async (
  agentCatalogName,
  channels
) => {
  const reference = collection(db, "agents");
  const q = query(reference, where("agentCatalogName", "==", agentCatalogName)); //, where("agentType","in", channels));

  return await getDocs(q).then((data) => {
    var response = data.docs.map((docSnap) => ({
      ...docSnap.data(),
      id: docSnap.id,
    }));
    if (channels == null) {
      return response;
    }
    console.log(response, channels);
    return response.filter(
      (x) => channels.indexOf(x.idName + "_" + x.agentType) > -1
    );
  });
};

const getApiAgentsByCompany = async (companyId) => {
  const reference = collection(db, "agents");
  const q = query(reference, where("companyId", "==", companyId));

  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};

const getApiAgent = async (id) => {
  const reference = doc(db, "agents", id);

  return await getDoc(reference).then((data) => {
    return { ...data.data(), id: id };
  });
};

const getDialogflowResponses = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/getDialogflowResponses",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const createAgent = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/createAgent",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const updateAgent = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/updateAgent",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const deleteAgent = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/deleteAgent",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const backupCoreAgent = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/backupCoreAgent",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const uploadIntentSheet = async (file) => {
  const requestOptions = {
    method: "POST",
    headers: { ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I" },
    body: file,
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/uploadIntentSheet",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const saveAgentFlows = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/saveAgentFlows",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const saveAllAgentFlows = async () => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/saveAllAgentFlows",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const restoreAllAgents = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/restoreAllAgents",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const restoreSpecificAgent = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/restoreSpecificAgent",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const restoreAgentApi = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/restoreAgent",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const saveAgentQuestionFlow = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/saveAgentQuestionFlow",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const saveAllAgentsQuestionFlow = async () => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL +
      "/agent/saveAllAgentsQuestionFlow",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const publishAgent = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/publishAgent",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const publishAgents = async () => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/publishAgents",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const updateAgentRoutegroupsAndPages = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL +
      "/agent/updateAgentRoutegroupsAndPages",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const updateAllAgentRoutegroupsAndPages = async () => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL +
      "/agent/updateAllAgentRoutegroupsAndPages",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const bindAgentToVoximplant = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/bindAgentToVoximplant",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const deleteAgentVoximplant = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/deleteAgentVoximplant",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const createCoreAgent = async (agent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(agent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/createCoreAgent",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const uploadDataSourceFile = async (file) => {
  const requestOptions = {
    method: "POST",
    headers: { ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I" },
    body: file,
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/uploadDataSourceFile",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const uploadDataSourceUrl = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/uploadDataSourceUrl",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const deleteDatasourceApi = async (source) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(source),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/agent/deleteDatasource",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const triggerOutboundCallApi = async (voornaam, number) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body:
      '{"Name":"' +
      voornaam +
      '", "Phone":"' +
      number +
      '", "email":"sdfsdfsdf"}',
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_WEBHOOK_URL +
      "/api/outbound/CreateOutboundCall",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export {
  triggerOutboundCallApi,
  restoreSpecificAgent,
  uploadDataSourceUrl,
  deleteDatasourceApi,
  uploadDataSourceFile,
  getApiAgentsByAgentCatalogName,
  getApiAgentsByIdName,
  getApiAgentsByAgentCatalogNameNameAndChannel,
  createCoreAgent,
  bindAgentToVoximplant,
  deleteAgentVoximplant,
  restoreAllAgents,
  restoreAgentApi,
  saveAgentFlows,
  saveAllAgentFlows,
  backupCoreAgent,
  getDialogflowResponses,
  saveAgentQuestionFlow,
  saveAllAgentsQuestionFlow,
  publishAgent,
  publishAgents,
  updateAgentRoutegroupsAndPages,
  updateAllAgentRoutegroupsAndPages,
  uploadIntentSheet,
  updateAgentIntentFields,
  getApiAllAgents,
  getApiAgentsByCompany,
  getApiAgents,
  getApiAgent,
  createAgent,
  updateAgent,
  deleteAgent,
};
