import React, {useEffect, useState, useMemo} from 'react';
import {BsPlusLg} from "react-icons/bs";

import styles from 'styles/admin/agent/intentSelector.module.css';
import {addCustomerIntent, getCustomerIntent, updateCustomerIntent} from "../../../services/intentService";
import {updateAgentIntentFields} from "../../../services/agentService";
import { useAgentContext } from 'contexts/AgentContext';
import {getCustomerIntents, getCoreIntents} from "../../../services/intentService";
import { IoConstructOutline } from 'react-icons/io5';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from 'components/queryClients/queryClient';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {BsCheck} from "react-icons/bs";
import { useProgressIndicatorContext } from 'contexts/ProgressIndicatorContext';
import { updateUserActionHistoryList } from 'services/userService';
import { useAuth } from 'contexts/AuthContext';
import { Dialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { FormText } from 'react-bootstrap';
import channels from 'constants/channels';
import { getFlowService } from 'services/flowService';
import { getCatalogService } from 'services/catalogService';

const IntentSelector = () => {
    
    const { currentUser } = useAuth();
    const { setIndicatorState, resetIndicatorState, setInfoMessage, loadingState } = useProgressIndicatorContext();
    const queryClient = useQueryClient();
    const { agent, setContextAgent, selectedIntent, refetchIntentsTrigger } = useAgentContext();
    const [searchQuery, setSearchQuery] = useState("");
    const [flow, setFlow] = useState();
    const [dbCatalog, setDbCatalog]=useState();
    const [selectedNewIntent, setSelectedIntent] = useState();
    const [onlyShowRecommendations, setOnlyShowRecommendations] = useState();
    const [selectedNewIntents, setSelectedNewIntents] = useState([]);
    const [showNewIntentPanel, setShowNewIntentPanel] = useState(false);
    const [newIntent, setNewIntent] = useState({
        name:"",
        examplePhrase:""
    });
    useEffect(() => {
        if (agent != null) {
            getFlow();
        }
    }, [agent]);

    const getFlow = () => {
        getFlowService(agent.coreAgentId).then(y => {
            setFlow(y);
            getCatalog(y);
        })
    }
    const getCatalog = (flowObj) => {
        if(flowObj == null){return;}
        getCatalogService(flowObj?.catalogId).then(result => {
            // used when editting a catalog to get the fields
            setDbCatalog(result);
        })
    }
    const intentsQuery = useQuery(
        ["coreAgentIntents", agent?.idName],
        () => getCoreIntents(agent?.idName, agent?.collection),
        {staleTime: 60000}
    );
    const intents = intentsQuery.data;
    

    const customerIntentsQuery = useQuery(
        ["customerAgentIntents", agent?.agentCatalogName],
        () => getCustomerIntents(agent?.agentCatalogName),
        {staleTime: 60000}
    );
    const customerIntents = customerIntentsQuery.data;

    const filteredIntents = useMemo(() => {
        if(intents === undefined)
            return null;

        // Create copy of item list
        let updatedList = [...intents.filter(x => x.nextStep != "clarification")];

        return updatedList.filter((item) => {
            if(onlyShowRecommendations){
                if(agent.intentRecommendations[item.id]){                  
                    return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
                }
            }else{
                return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
            }
        })
    },[intents, searchQuery, onlyShowRecommendations])

    const toggleNewIntentPanel = () => {
        setShowNewIntentPanel(!showNewIntentPanel);
    }

    const toggleSelectIntent = (intent) => {
        if(customerIntents.map(x => x.id).indexOf(intent.id) > -1)
            return;

        setSelectedIntent(null);

        if(selectedNewIntents.filter(x => x == intent)[0] != null){
            setSelectedNewIntents(prev => [...prev.filter(x => x != intent)]);
        }else{
            setSelectedNewIntents(prev => [...prev, intent]);
        }
    }
    

    const addIntent = () => {
        intentMutation.mutate();
    };

    const intentMutation = useMutation(
        () => {

            if(selectedNewIntent === null && selectedNewIntents.length == 0){
                return;
            }
            setIndicatorState(null);
    
            if(selectedNewIntent != null){
                selectedNewIntents.push(selectedNewIntent);
                setSelectedIntent(null);
            }

            selectedNewIntents.map(intRow => {
                intRow.status = "ready";
                intRow.isActive = true;
                intRow.invocations = agent.intentRecommendations[intRow.id] ?? 0;
                intRow.isEditable = intRow.nextStep != "clarification";
                //replace [agentname] with actual agent name
                {Object.entries(intRow.content).map((obj) => {
                    obj[1].response = obj[1].response.replace("[AGENT_NAME]", agent.name);
                })}

                intRow.customFields = getDefaultFields(intRow);

                intRow.firebaseId = intRow.id;// + "_" + agent.idName + "_" + agent.agentType;
                if(customerIntents.filter(x => x.id == intRow.id)[0] != null){
                    getCustomerIntent(agent?.agentCatalogName, intRow.id).then(res => {
                        var c = res[0];
                        
                        var currentChannel = agent.idName +"_"+agent.agentType

                        if(c.channels.indexOf(currentChannel) == -1){
                            c.channels.push(currentChannel);
                        }
                        updateCustomerIntent(c, agent?.agentCatalogName);
                        refetchIntentsTrigger();
                        return;   
                    });
                    return;                    
                }else{
                    intRow.channels = [agent.idName+"_"+agent.agentType];
                }

                addCustomerIntent(intRow, agent.agentCatalogName).then((res) => {
                    setSelectedIntent(null);
                });
    
                //delete selected intent from intent recommendations
                if(agent.intentRecommendations[intRow.id]){  
                    delete agent.intentRecommendations[intRow.id];
                }
                updateUserActionHistoryList(currentUser.uid, agent.name, "Added intent: \"" + intRow.name + "\"");
            })
            

        },
        {
            onSuccess: () => {
            queryClient.invalidateQueries(["customerAgentIntents"]);
            queryClient.refetchQueries("customerAgentIntents", { force: true });
            resetIndicatorState("Intent(s) succesfully added to collection.");
            updateAgentIntentFields(agent);
            setContextAgent(agent);
            setSelectedNewIntents([]);
        }},
    )

    const getDefaultFields = (intRow) => {
        let flowFields = flow.fields;
        let customFields = intRow.customFields.map(customField => {
            // if not checked or flowfield exists than keep it that way
            if(customField.checked == false || flowFields.indexOf(customField.id) != -1){
                return ({...customField});
            }
            //customField not known by parent flow
            else{
                return ({...customField, checked: false});
            }
        })
        return customFields;
    }
        
   
    const getChannels = (intRowId) => {
        var usedChannels = customerIntents.filter(x => x.id == intRowId).map(x => x.channels).flat();
        return channels.filter(x => usedChannels.indexOf(x.value) == -1).map(i => i.value);
    }
    
    const categories = () => {
        if(filteredIntents === null)
            return null;

        let categories = filteredIntents?.map((x) => x.category).sort();
        return [...new Set(categories.map(obj => obj))];
    }

    const selectIntent = (intent) => {
        if(rowAlreadySelected(intent))
            return;

        if(selectedNewIntent === intent) {
            setSelectedIntent(null);
            return;
        }

        setSelectedIntent(intent);
    }

    const rowAlreadySelected = (intent) => {
        var filteredCustomerIntents = [...customerIntents.filter(x => x.channels?.indexOf(agent.idName + "_" + agent.agentType) > -1 || x.channels == null)];
        return filteredCustomerIntents?.map(x => x.id).indexOf(intent.id) > -1;
    }

    const toggleOnlyShowRecommendations = () => {
        setOnlyShowRecommendations(!onlyShowRecommendations);
    }

    return (
        <>
        {agent && (
        <div className={`${styles.intentSelectorContainer} ${selectedIntent ? styles.editIntentContainerWidth : null}`}>
            <input id="searchIntents" placeholder="search" onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery} className={styles.newIntentSearchInput} type="text"/>
                    <div className={styles.intentSelectorFilter}>
                        <div onClick={() => toggleOnlyShowRecommendations()} className={styles.onlyRecommendations}>
                            <div className={`${styles.statusIcon} ${onlyShowRecommendations ? styles.checked : null}`} data-tooltip="The number shows how many times an intent has been triggered"><BsCheck/></div> 
                            <div className={styles.onlyRecommendationsLabel}>Only Recommendations</div>

                            {/* <FormControlLabel control={<Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />} label="Only recommendations" /> */}
                        </div>
                        <span className={styles.intentsCount}>{filteredIntents?.length} Intents</span>
                    </div>
                   
            <div className={styles.categoryContainer}>
                {intentsQuery.isLoading || customerIntentsQuery.isLoading ? (<h1>Loading</h1>) : (
                    categories().map((cat) => {
                        return (
                            <div key={cat} className={styles.category}>
                                <div className={styles.categoryName}>
                                    {cat}
                                    <span className={styles.intentNumber}> ({filteredIntents.filter(y => y.category === cat).length})</span>
                                </div>
                                {
                                    filteredIntents.filter(y => y.category === cat).map((row) => {
                                        return (
                                        <div key={row.id} className={`${styles.intentRow} ${selectedNewIntent === row ? styles.intentSelected : null}`}>
                                        <input checked={selectedNewIntents.filter(x => x == row)[0] != null} onChange={() => toggleSelectIntent(row)} disabled={rowAlreadySelected(row)} type="checkbox"/>
                                        <div onClick={() => selectIntent(row)}
                                                     className={`
                                                        ${styles.intentName}
                                                        ${rowAlreadySelected(row) ? styles.hideRow : null}
                                                        ${row.isPopular ? styles.popular : null}
                                                        ${row.status === "new"? styles.newAddedIntent : null}`}
                                                     data-id={row.id}
                                                     key={row.name}>
                                                        <div className={styles.nameLabel}>
                                                            {row.name}
                                                            {selectedNewIntent == row && (
                                                                <div className={`${styles.nameId}`}>{row.id}</div>
                                                            )}
                                                        </div>
                                                        <div className={styles.triggerCount}>{agent.intentRecommendations[row.id]}</div>
                                                        </div>
                                                        </div>)
                                    })
                                }
                            </div>
                        )
                    })
                )}
            </div>

            <div onClick={addIntent} className={`${styles.addNewIntent} ${!selectedNewIntent && selectedNewIntents.length == 0 ? styles.disable : null}`}><BsPlusLg className={styles.plusIcon}/>Add intent{selectedNewIntents.length > 1 ? "s" : ""}</div>
            {/* <div onClick={toggleNewIntentPanel} className={`${styles.addNewIntent}`}><BsPlusLg className={styles.plusIcon}/> New intent</div> */}

            {/* <Dialog 
                open={showNewIntentPanel} 
                // onClose={handleClickCloseMappingResponseConfigurator}
                >
                <DialogContent>
                    <h1>test</h1>
                    <div className={`${styles.formRow}`}>
                    <FormText title="name" formId="name" value={newIntent.name} handleChange={updateNewIntent}/>
                    </div>
                </DialogContent>
            </Dialog> */}
        </div>
        )}
        </>
    );
}

export default IntentSelector;
