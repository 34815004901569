import React, { setState, useState } from 'react';

const chat = async (agent, sessionId, message, language) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'ApiKey': 'r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I' },
        body: JSON.stringify({ Agent: agent, SessionId: sessionId, Message: message, Language: language })
    };
    return await fetch(process.env.REACT_APP_BRAIN_BACKEND_URL+'/agent/chatSession', requestOptions)
        .then(response => response.json())
        .then(data => {return data});

}
export {chat}
