import React, { useState, useEffect, useMemo } from 'react';
import {useAuth} from "../../../contexts/AuthContext";
import {getApiAgents, getApiAllAgents} from "../../../services/agentService";
import {MdOutlineManageAccounts} from "react-icons/md";
import {SlArrowDown} from "react-icons/sl";
import styles from 'styles/admin/callback/callback.module.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import TableGrid from 'components/global/tableGrid';
import RealtimeCallbackDB from 'components/global/realtimeCallbackDB';

import { realtimeDbs } from "../../../firebase";
import { getDatabase, ref, child, push, update, set, onValue } from "firebase/database";
import { updateUserActionHistoryList } from 'services/userService';
import CategorizedDropdownSearch from 'components/global/categorizedDropdownSearch';

const Callbacks = () => {
    const [callbackList, setCallbackList] = useState([]);

    const [agent, setAgent] = useState();
    const [agents, setAgents] = useState([]);
    const { currentDbUser, currentUser } = useAuth();
    const [searchQuery, setSearchQuery] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(()=>{
        if(!agent)
          return;
        
        setCallbackList([]);
        getCallbacks();
    },[agent]);

    const getCallbacks = () => {
        const query = ref(realtimeDbs, agent.idName.replace('.','_'));
        return onValue(query, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.exists()) {
                Object.values(data).map((callback) => {
                    setCallbackList((callbackList) => [...callbackList, callback]);
                });
            }
        });
    }

    const update = (editRowData) => {
        if(editRowData.id === undefined)
          return;
    
        const newValue = {...callbackList.filter(i => i.id == editRowData.id)[0], [Object.keys(editRowData.model)[0]]: Object.values(editRowData.model)[0].value};
        newValue.timestamp = (3-newValue.state) +"-"+ newValue.timestamp.substring(1);
    
        set(ref(realtimeDbs, agent+'/' + editRowData.id.replace('.','_')), newValue).then(() => {
          // Data saved successfully!
          updateUserActionHistoryList(currentUser.uid, agent.name, "Updated callback: \"" + editRowData.ticketNumber + "\"");
        })
        .catch((error) => {
          // The write failed...
          console.log('fail');
        });;
      }

    useEffect(()=>{
        if(!currentDbUser)
          return;

        const getAgents = async () => {
            if(currentDbUser.isAdmin){
                var retrievedAgents = await getApiAllAgents();
                setAgents(retrievedAgents);
                getAndSetAgentFromLocalStorage(retrievedAgents);
            }else{
                var retrievedAgents = await getApiAgents(currentDbUser);
                setAgents(retrievedAgents);
                getAndSetAgentFromLocalStorage(retrievedAgents);
            }
      };
      getAgents();
    },[currentDbUser]);
    
    const getAndSetAgentFromLocalStorage = (retrievedAgents) => {
        const agentName = localStorage.getItem('selectedAgent');
        var selectAgent = retrievedAgents.filter(x => x.name == agentName)[0];
        if(selectAgent != null){
            setAgent(selectAgent);
        }else{
            setAgent(retrievedAgents[0]);
        }
    }
    
    const filteredAgents = useMemo(() => {
        // Create copy of item list
        var updatedList = [...agents];
        // Include all elements which includes the search query
        return updatedList = updatedList.filter((item) => {
            return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
    }, [agents, searchQuery]);

    const openDropDownSearch =() => {
        setMenuOpen(!menuOpen);
    }
    const agentSelected = (e) => {
        setAgent(e);
        localStorage.setItem('selectedAgent', e.name);
        setSearchQuery("");
        openDropDownSearch();
    }
    
    return (
        <div className={`${styles.callback}`}>
            <div className={`${styles.header} ${styles.widget}`}>
                <h1>Callbacks</h1>
                <h3>Inzage in de callbacks</h3>
            </div>
            <div className={`${styles.agentSelection} ${styles.widget}`}>
                <div className={styles.optionsAgentSelection} >
                    <div className={styles.optionsHeader}><MdOutlineManageAccounts
                        className={styles.optionsIcon}/> Select agent
                    </div>
                    <div className={styles.dropdown} >
                        <CategorizedDropdownSearch data={filteredAgents} subHeader="agentType" type="agent" selected={agent} setSelected={agentSelected}/>

                        <div className={styles.labelTypeContainer}>
                            <div className={`${styles.typeLabel} ${agent?.agentType == 'chat' ? styles.typeLabelChat : null}`}>{agent?.agentType}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.grid} ${styles.widget}`}>
                {callbackList != null && (
                    <TableGrid rows={callbackList} update={update}/>
                )}
            </div>
        </div>

        
    )

}
export default Callbacks;
