import React from 'react';
import styles from 'styles/admin/menu/toggle.module.css';
import { BiCommentDetail } from "react-icons/bi";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const Toggle = ({menuExpanded, toggleParentMenu}) => {

    const toggleMenu = () => {
        toggleParentMenu();
    }
    return (
        <div className={styles.headerIcon}>
            {/* <BiCommentDetail className={menuExpanded ? styles.logo : styles.hide}/> */}
            <div onClick={toggleMenu} className={`${styles.menuItem} ${menuExpanded ? styles.rotate : ''}`}>
                <MdOutlineArrowBackIosNew className='icon'/><MdOutlineArrowBackIosNew className='icon'/>
            </div>
        </div>
    )
}
export default Toggle;
