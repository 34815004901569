
import React, { useState } from 'react';

import styles from 'styles/global/formMultiSelect.module.css';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

const FormSelect = ({title, value, key, isSelected, handleChange}) => {

    const setSelected = () => {
        handleChange(!isSelected);
    }
    return(
        <>
        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            {title && (
                <FormLabel component="legend">{title}</FormLabel>
            )}
            <FormGroup>
                <FormControlLabel
                    control={
                    <Checkbox checked={isSelected} onChange={setSelected} name={key} />
                    }
                    label={value}
                />
            </FormGroup>
        </FormControl>
        </>
    )
}

export default FormSelect;