import React, {useState} from 'react';
import styles from 'styles/admin/menu/menuitem.module.css';
import {useAuth} from "../../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';

const Logout = ({menuExpanded, title, children}) => {
    const navigate = useNavigate();

    const { logout } = useAuth();
    const [error, setError] = useState('');

    const handleLogout = async () => {
        setError("");
        try{
            await logout();
            navigate('/auth/login');
        }catch{
            setError("Log out failed!");
        }
    }

    return (
        <div onClick={handleLogout}
        className={`${styles.subMenuItem}
        ${menuExpanded ? '' : styles.menuIn}`}>
            {children}
            <div className={styles.linkText} style={{color:"#b6239c"}}>{title}</div>
            <span className={styles.tooltiptext}>{title}</span>
          </div>
    )
}
export default Logout;
