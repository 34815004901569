import React, { useState } from 'react';
import { Card, Button, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'


const Dashboard = () => {

    const navigate = useNavigate();

    const [error, setError] = useState('');
    const { logout, currentUser } = useAuth();
    
    async function handleLogout(){
        setError("");
        try{
            await logout();
            navigate('/auth/login');
        }catch{
            setError("Log out failed!");
        }
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Profile</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <strong>email:</strong> {currentUser.email}
                    <Link to="./update-profile" className='btn btn-primary w-100 mt-3'>Update profile</Link>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                <Button variant="link" onClick={handleLogout}>Log out</Button>
            </div>
        </>
    );
};

export default Dashboard;