import React, { useState, useEffect, useMemo } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

import styles from 'styles/admin/integration/deviation.module.css';

const flexContainer = {
    display: 'inline',
    flexDirection: 'row',
    //borderRight: '1px solid #ddd',
    marginLeft: '1rem'
};
const labelStyle = {
    marginRight: '0',
    width: '150px'
}
const checkboxStyle = {
    padding: '0'
}

const Deviation = ({name, checked, isActive, update, index = null}) => {
   
    const toggleIsActive = (active) => {
       // setIsActive(!isActive);
       if(!active){
            if(index != null){
                update(name, null, index);
            }else{
                update(name, null);
            }
       }else{
            if(index != null){
                update(name, checked, index);
            }else{
                update(name, checked);
            }
       }
    }
    const toggleIsChecked = (checked) => {
        if(index != null){
            update(name, checked, index);
        }else{
            update(name, checked);
        }
     }

    return(
        <>
            <div className={styles.deviationContainer}>
                <FormGroup style={flexContainer}>
                    <FormControlLabel style={labelStyle}
                    control={<Checkbox style={checkboxStyle} onChange={() => toggleIsActive(!isActive)} checked={isActive} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />} 
                    label={<Box component="div" fontSize={12}>{name}</Box>}/>
                    <Checkbox onChange={(e) => toggleIsChecked(e.target.checked)} style={checkboxStyle} checked={checked} disabled={!isActive} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />
                    
                </FormGroup>
            </div>
        </>
    )
}

export default Deviation;