import { db } from '../firebase.js'
import { collection, getDocs, addDoc, updateDoc, doc, getDoc, where, query, setDoc, deleteDoc } from 'firebase/firestore'


const getFlowsService = async () => {
    const reference = collection(db, "flows");

    return await getDocs(reference).then((data) => {
        return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
    })
}
const getFlowService = async (id) => {
    const docRef = doc(db, "flows", id);

    return getDoc(docRef).then((data) => {
        return {...data.data(), id: data.id};
    });
}
const getFlowsServiceInRange= async (ids) => {
    const reference = collection(db, "flows");
    const q = query(reference, where("id","in", ids));

    return await getDocs(q).then((data) => {
        return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
    })
}

export {getFlowsServiceInRange, getFlowsService, getFlowService}