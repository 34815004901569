import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import styles from 'styles/admin/optimizationReports/optimizationReports.module.css';
import {getOptimizations, deleteOptimization} from "../../../services/optimizationReportsService";
import TableGrid from 'components/global/tableGrid';
import { ConstructionOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import dateFormat, { masks } from "dateformat";
import { IoClose } from 'react-icons/io5';
import moment from 'moment'
import { updateUserActionHistoryList } from 'services/userService';
import { useAuth } from 'contexts/AuthContext';


const columns = [
    { field: 'id', headerName: 'Id', flex:0.5, hide: true},
    { field: 'input', headerName: 'Input', flex:2.5 },
    { field: 'response', headerName: 'Response', flex:1.5},
    { field: 'intent', headerName: 'Intent', flex:2 },
    { field: 'confidence', headerName: 'Confidence', flex:1 },
    { field: 'type', headerName: 'Type', flex:1}, 
    { field: 'language', headerName: 'Language', flex:0.5 },
    { field: 'agentName', headerName: 'AgentName', flex:1 },
    { field: 'dateTime', headerName: 'DateTime', flex:1 },
    { field: 'sessionId', headerName: 'SessionId', flex:1 },
    { field: 'delete', headerName: '', flex:0.5, renderCell: () => (<IoClose className={styles.closeIcon}/>) },
  ];
 

const OptimizationReports = () => {
    const { currentUser } = useAuth();

    const [optimizations, setOptimizations] = useState([]);
    useEffect(()=>{
        getOptimizations().then(res => {
            setOptimizations(res);
        })
    },[])


    const deleteOptimizationRecord = (id) => {
        deleteOptimization(id).then(res => {
            setOptimizations(prev => [...prev.filter(x => x.id != id)]);
            updateUserActionHistoryList(currentUser.uid, "Core", "Deleted optimization report: \"" + id + "\"");
        })
    }

    const handleOnCellClick = (e) => {
        if(e.field == "delete"){
            deleteOptimizationRecord(e.id);
        }
    }
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'dateTime',
          sort: 'asc',
        },
      ]);

    return (
        <div className={`${styles.callback}`}>
            <div className={`${styles.header} ${styles.widget}`}>
                <h1>Optimization reports</h1>
                <h3>Failed and unknown intent recognition</h3>
            </div>
            <div className={`${styles.optionMenu} ${styles.widget}`}>
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                }}
                >

                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                       outline: "none !important",
                    },
                 }}
                    rows={optimizations}
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    pageSize={100}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[20, 50, 100]}
                    rowHeight={25}
                    onCellClick={handleOnCellClick}
                    disableSelectionOnClick
                />
                </Box>
            </div>
        </div>

        
    )

}
export default OptimizationReports;
