import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert,InputGroup } from 'react-bootstrap'
import { useAuth } from 'contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { MdEmail, } from "react-icons/md";

const ForgotPassword = () => {

    const navigate = useNavigate();
    const emailRef = useRef()

    const { forgotPassword } = useAuth();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    

    function setEmail(){
        if(emailRef.current.value != "" && isValidEmail(emailRef.current.value)){
            setIsValid(true);
        }else {
            setIsValid(false);
        }
    }
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }
    async function handleSubmit(e){
        e.preventDefault();

        try{
            setMessage("");
            setError("");
            setLoading(true);
            await forgotPassword(emailRef.current.value);
            setMessage("Check your inbox for futher instructions!");
        }catch (err) {
            setError(err.message);
        }
        setLoading(false);
    }
    return (
        <>
            <div className="d-flex">
                <div className="d-flex p-2 flex-fill justify-content-left align-items-center" style={{ minHeight: "100vh" }}>
                    <Card className='border-0'>
                        <Card.Body style={{width:"400px", backgroundColor:"#f8f8fa"}}>
                            <Card.Title><h1 className="text-left dark">Reset password</h1></Card.Title>
                            <Card.Subtitle className="mb-4 text-muted"><h3 className="text-left">Enter your details below</h3></Card.Subtitle>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {message && <Alert variant="success">{message}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <InputGroup className="mb-3" style={{height:"35px"}}>
                                        <InputGroup.Text id="basic-addon1"><MdEmail className='dark'/></InputGroup.Text>
                                        <Form.Control type="email" required ref={emailRef} onChange={(e) => setEmail()}></Form.Control>
                                    </InputGroup>
                                </Form.Group>
                                
                                <Button style={{height:"35px"}} disabled={loading || !isValid} className="w-100 mt-3" type="submit">Reset password</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <div className="d-flex p-2 flex-column"  style={{ marginTop: "50px" }}>
                    <div><span className='mx-2'>Already an account?</span><Link to="/auth/login"><Button className='btn-outline'>Log in</Button></Link></div>
                    <span className='d-flex justify-content-end mt-auto mb-3'>Copyright 2023 @ DGTL Assist</span>
                </div>
            </div>


        </>
    );
}
export default ForgotPassword;