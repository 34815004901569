const toCamelCaseFormat = (sentence) => {

    if(!sentence)
        return "";
        
    const words = sentence.split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    let camelCase = words.join("");
    return camelCase.charAt(0).toLowerCase() + camelCase.slice(1);
}

const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

export {toCamelCaseFormat, convertArrayToObject}