import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, InputGroup } from "react-bootstrap";
import { useAuth } from "contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import {
  getAuth,
  getMultiFactorResolver,
  TotpMultiFactorGenerator,
} from "firebase/auth";

const Login = () => {
  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const rememberMeRef = useRef();
  const twoFactorRef = useRef();

  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loginError, setLoginError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  function setFormValidation() {
    if (
      emailRef.current.value != "" &&
      isValidEmail(emailRef.current.value) &&
      passwordRef.current.value != ""
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/admin/agents");
    } catch (err) {
      if (err.code == "auth/multi-factor-auth-required") {
        setLoginError(err);
        setTwoFactorCodeEnabled(true);
        setLoading(false);
        setTimeout(() => {
          twoFactorRef.current.focus();
        }, 100);
        return;
      } else {
        setError("Username or password wrong!");
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  }

  const [twoFactorCodeEnabled, setTwoFactorCodeEnabled] = useState(false);

  const submitTwoFactorCode = async (e) => {
    e.preventDefault();
    const mfaResolver = getMultiFactorResolver(getAuth(), loginError);
    const enrolledFactors = mfaResolver.hints.map((info) => info);

    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      enrolledFactors[0].uid,
      twoFactorRef.current.value
    );
    try {
      await mfaResolver.resolveSignIn(multiFactorAssertion);
      // Successfully signed in!
      navigate("/admin/agents");
    } catch (error) {
      setError("Invalid code!");
    }
  };
  return (
    <>
      <div className="d-flex">
        <div
          className="d-flex p-2 flex-fill justify-content-left align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <Card className="border-0">
            <Card.Body style={{ width: "400px", backgroundColor: "#f8f8fa" }}>
              <Card.Title>
                <h1 className="text-left dark">Log in to your digital agent</h1>
              </Card.Title>
              <Card.Subtitle className="mb-4 text-muted">
                <h3 className="text-left">Enter your login details below</h3>
              </Card.Subtitle>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                {!twoFactorCodeEnabled && (
                  <>
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text
                          style={{ height: "3.5rem", width: "4rem" }}
                          id="basic-addon1"
                        >
                          <MdEmail className="dark" />
                        </InputGroup.Text>
                        <Form.Control
                          style={{ height: "3.5rem" }}
                          type="email"
                          required
                          ref={emailRef}
                          onChange={(e) => setFormValidation()}
                        ></Form.Control>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password">
                      <div className="d-flex mt-2">
                        <Form.Label className="d-flex justify-content-start flex-fill form-label">
                          Password
                        </Form.Label>
                      </div>
                      <InputGroup className="mb-3">
                        <InputGroup.Text
                          style={{ height: "3.5rem", width: "4rem" }}
                          id="basic-addon1"
                        >
                          <FaLock className="dark" />
                        </InputGroup.Text>
                        <Form.Control
                          style={{ height: "3.5rem" }}
                          type="password"
                          required
                          ref={passwordRef}
                          onChange={(e) => setFormValidation()}
                        ></Form.Control>
                      </InputGroup>
                      <Form.Label className="d-flex justify-content-end flex-fill form-label">
                        <Link className="dark" to="/auth/forgot-password">
                          Forgot password?
                        </Link>
                      </Form.Label>
                    </Form.Group>
                    {/* <Form.Group id="remember">
                                        <div className='d-flex'>
                                            <Form.Check
                                            className='mt-2'
                                                type="switch"
                                                id="custom-switch"
                                                label="Remember me"
                                                ref={rememberMeRef}
                                            />
                                        </div>
                                    </Form.Group> */}
                    <Button
                      style={{ height: "3.5rem" }}
                      disabled={loading || !isValid}
                      className="w-100 mt-3"
                      type="submit"
                    >
                      Log in
                    </Button>
                  </>
                )}
              </Form>
              <Form onSubmit={submitTwoFactorCode}>
                {twoFactorCodeEnabled && (
                  <>
                    <Form.Group id="password">
                      <div className="d-flex mt-2">
                        <Form.Label className="d-flex justify-content-start flex-fill form-label">
                          2 Factor code
                        </Form.Label>
                      </div>
                      <InputGroup className="mb-3">
                        <InputGroup.Text
                          style={{ height: "3.5rem", width: "4rem" }}
                          id="basic-addon1"
                        >
                          <FaLock className="dark" />
                        </InputGroup.Text>
                        <Form.Control
                          style={{ height: "3.5rem" }}
                          type="text"
                          required
                          ref={twoFactorRef}
                        ></Form.Control>
                      </InputGroup>
                    </Form.Group>

                    <Button
                      type="submit"
                      style={{ height: "3.5rem" }}
                      disabled={
                        twoFactorRef.current != null &&
                        !twoFactorRef.current?.value != ""
                      }
                      className="w-100 mt-3"
                      onClick={submitTwoFactorCode}
                    >
                      Submit code
                    </Button>
                  </>
                )}
              </Form>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex p-2 flex-column" style={{ marginTop: "50px" }}>
          {/* <div><span className='mx-2' style={{ marginBottom: "6px" }}>Need an account?</span><Link to="/auth/signup"><Button className='btn-outline'>Sign up</Button></Link></div> */}
          {/* <span className='d-flex justify-content-end mt-auto mb-3'>Copyright 2022 @ DGTL Assist</span> */}
        </div>
      </div>
    </>
  );
};
export default Login;
