import DropdownSearch from "components/global/dropdownSearch";
import React, { useEffect, useMemo, useRef, useState } from "react";

import styles from "styles/admin/configuration/createAgentPanel.module.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormText from "components/global/formText";
import FormMultiSelect from "components/global/formMultiSelect";
import { getApiCompanies, getApiCompany } from "services/companyService";
import {
  createAgent,
  updateAgent,
  getApiAgent,
  getApiAllAgents,
  deleteAgent,
  bindAgentToVoximplant,
  deleteAgentVoximplant,
  getApiAgentsByCompany,
  uploadDataSourceFile,
  deleteDatasourceApi,
  uploadDataSourceUrl,
} from "services/agentService";
import {
  getCoreIntentCollections,
  importIntentsFromCsv,
  getDatasourceResponse,
  createDatestoreIndex,
  savePromptApi,
  fillAgentIntentsApi,
} from "services/intentService";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { setDefaultEventParameters, settings } from "firebase/analytics";
import FormSelect from "components/global/formSelect";
import Snackbar from "@mui/material/Snackbar";
import Loading from "components/global/loading";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import { useProgressIndicatorContext } from "contexts/ProgressIndicatorContext";
import TimePicker from "react-time-picker";
import { useAuth } from "contexts/AuthContext";
import { updateUserActionHistoryList } from "services/userService";
import { getFlowService, getFlowsService } from "services/flowService";
import CategorizedDropdownSearch from "components/global/categorizedDropdownSearch";
import { getCatalogsService } from "services/catalogService";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

//const { Storage } = require("@google-cloud/storage");

// const storage = new Storage({
//   projectId: "easyai-375612",
//   keyFilename: "gcp.json",

// });

const welcomeLinesVoice = [
  "Hallo. Ik ben de digitale assistent van AGENTNAME. Ik ga je met de juiste medewerker doorverbinden.",
  "Hallo. Ich bin der digital Assistent von AGENTNAME.  Ich verbinde Sie mit dem richtigen Agenten.",
  "Hello. I am the digital assistant of AGENTNAME. I will put you through to the right agent.",
];
const welcomeLinesChat = [
  "Hallo. Ik ben de digitale assistent van AGENTNAME. Waarmee kan ik je helpen?",
  "Hallo. Ich bin der digital Assistent von AGENTNAME.  Wie kann ich behilflich sein?",
  "Hello. I am the digital assistant of AGENTNAME. how can I be of assistance?",
];

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const CreateAgentPanel = () => {
  const fileRef = useRef();
  const { currentUser } = useAuth();
  const {
    setIndicatorState,
    resetIndicatorState,
    setInfoMessage,
    loadingState,
  } = useProgressIndicatorContext();
  const [isNewAgent, setIsNewAgent] = useState(true);
  const [openToast, setOpenToast] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [collections, setCollections] = React.useState([]);
  const [flows, setFlows] = useState([]);
  const [catalogs, setCatalogs] = useState([]);

  //const [toastMessage, setToastMessage] = useState("");
  //const [toastType, setToastType] = useState("success");
  //const [loading, setLoading] = useState(false);

  const [companys, setCompanys] = useState([]);
  const [agents, setAgents] = useState([]);
  const [companyAgents, setCompanyAgents] = useState([]);

  const [deletionDisabled, setDeletionDisabled] = useState(false);
  const [agent, setAgent] = useState(null);
  const [company, setCompany] = useState();
  const [flow, setFlow] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [responseMode, setresponseMode] = useState(null);
  const [selectedIndexForIntentFilling, setSelectedIndexForIntentFilling] =
    useState(null);

  useEffect(() => {
    getCompanys();
    getAgents();
    getCollections();
    getFlows();
    getCatalogs();
  }, []);

  const getCompanys = async () => {
    var companysApiResponse = await getApiCompanies();
    setCompanys(companysApiResponse);
  };
  const getAgents = async () => {
    var companysApiResponse = await getApiAllAgents();
    setAgents(companysApiResponse);
  };
  const getCompanyAgents = async (companyId) => {
    var companyAgentsApiResponse = await getApiAgentsByCompany(companyId);
    var res = [{ id: "", name: "" }, ...companyAgentsApiResponse];
    setCompanyAgents(res);
  };

  const getFlows = () => {
    getFlowsService().then((res) => {
      setFlows(res);
    });
  };
  const getAgent = async (id) => {
    var retrievedAgent = await getApiAgent(id);
    retrievedAgent.company = companys.filter(
      (x) => x.id == retrievedAgent.companyId
    )[0];
    setAgent(retrievedAgent);

    setDatasourcesIndexes(
      retrievedAgent?.datasource?.indexes?.map((x) => x.name)
    );

    var company = await getApiCompany(retrievedAgent.companyId);
    setCompany(company);
    var f = await getFlowService(retrievedAgent.coreAgentId);
    setFlow(f);
    setDeletionDisabled(retrievedAgent.settings.disableDeletion);
    setIsNewAgent(false);
  };

  const [file, setFile] = useState();

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }

    getBase64(file).then((base64File) => {
      var data = { agentId: agent.id, file: base64File };
      setIndicatorState("importing", "Importing intents from CSV file.");
      importIntentsFromCsv(data).then((result) => {
        if (!result.isError) {
          resetIndicatorState(
            `CSV intents succesfully imported. ${result} intent(s) geimporteerd.`
          );
          setFile(null);
          fileRef.current.value = null;
        } else {
          resetIndicatorState(
            "Something went wrong while importing CSV intents."
          );
        }
      });
    });
  };
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const getCatalogs = () => {
    getCatalogsService().then((res) => {
      setCatalogs(res);
    });
  };

  const goLive = (lang) => {
    setIndicatorState("Attaching", "Attaching number");
    bindAgentToVoximplant({ AgentId: agent.id, Language: lang }).then(
      (result) => {
        if (!result.isError) {
          resetIndicatorState("Agent successfully bound to number.");
          updateUserActionHistoryList(
            currentUser.uid,
            agent.name,
            "Set agent " + agent.name + " live."
          );
          getAgent(agent.id);
        } else {
          resetIndicatorState(result.message);
        }
      }
    );
  };

  const goLiveDelete = (lang) => {
    var confirmResult = window.confirm(
      "Are you sure you want to detach the number from the agent?"
    );
    if (confirmResult == true) {
      deleteAgentVoximplant({ AgentId: agent.id, Language: lang }).then(
        (result) => {
          if (!result.isError) {
            resetIndicatorState("Agent successfully unbound from number.");
            updateUserActionHistoryList(
              currentUser.uid,
              agent.name,
              "Agent: " + agent.name + " with language: " + lang + " unbound."
            );
            getAgent(agent.id);
          } else {
            resetIndicatorState(result.message);
          }
        }
      );
    }
  };
  const setSelected = (e) => {
    if (e == "new") {
      var d = {
        name: "",
        idName: "",
        envId: "",
        companyId: "",
        collection: "",
        whatsappPhoneId: "",
        whatsappPhoneToken: "",
        whatsappPhoneSessionTimeout: "1800",
        zendeskAppId: "",
        zendeskToken: "",
        coreAgentId: "",
        agentType: "",
        welcomeLine: {
          nl: welcomeLinesVoice[0],
          de: welcomeLinesVoice[1],
          en: welcomeLinesVoice[2],
        },
        languages: {},
        intentRecommendations: {},
        datasource: {
          //prompt: 'You are a phone chatbot, so your anwers need to be friendly but not to long. You answer questions based on the context. Do not come up with random answers. If the answer is not in de context answer with only three hashtags (###) and nothing else. Example: User:Why is grass green? ai: ###.Always answer in the same language as the question is given.',
          //lastCreatedIndex: '16-06-2023 15:24',
          sources: [],
          indexes: [],
        },
        settings: {
          handoverActivated: false,
          ticketingSystem: "Dashboard",
          createTicketIntegration: "",
          alwaysCreateTicketIntegration: "",
          preferedContact: "none",
          giveIntentRecommendations: false,
          ticketEmail: "",
          alwaysCreateTicket: false,
          ordernumberLength: "10",
          disableDeletion: true,
          isProductAdviceAgent: false,
          enhancedIntentRecognition: false,
          startupHook: "",
          emailHook: "",
          finishedHook: "",
        },
        humanHandoverSettings: {
          type: "",
          connectionString: "",
          username: "",
          password: "",
          workingHours: {
            sunday: { isWorkingDay: false, from: "", to: "" },
            monday: { isWorkingDay: false, from: "", to: "" },
            tuesday: { isWorkingDay: false, from: "", to: "" },
            wednesday: { isWorkingDay: false, from: "", to: "" },
            thursday: { isWorkingDay: false, from: "", to: "" },
            friday: { isWorkingDay: false, from: "", to: "" },
            saturday: { isWorkingDay: false, from: "", to: "" },
          },
          invokeWebhook: "",
          defaultHandoverQueue: "",
          createTicketBeforeHandover: false,
          phoneQueues: {},
          chatHandoverChannel: "",
        },
      };
      setIsNewAgent(true);
      setAgent(d);
    } else {
      getAgent(e.id);
      setIsNewAgent(false);
    }
  };

  const handleChange = (prop) => (event) => {
    setAgent({ ...agent, [prop]: event.target.value });
  };
  const handleWelcomeChange = (prop) => (e) => {
    var welcome = { ...agent.welcomeLine };
    welcome[prop] = e.target.value;

    setAgent({ ...agent, welcomeLine: welcome });
  };

  const handleLanguageChange = (e) => {
    var langs = {};
    Object.entries(e).map((o) => {
      langs[o[0]] = {
        phonenumber: "",
        conversationProfileId: "",
        scenarioId: "",
        applicationId: "",
        ruleId: "",
      };
    });
    setAgent({ ...agent, languages: langs });
  };
  const handleHandoverDefaultQueueChange = (value) => {
    var humanHandoverSettings = { ...agent.humanHandoverSettings };
    humanHandoverSettings.defaultHandoverQueue = value
      ? selectedPhoneQueue.name
      : "";
    setAgent({ ...agent, humanHandoverSettings: humanHandoverSettings });
  };
  const handleHandoverCreateTicketBeforeHandoverChange = (e) => {
    var humanHandoverSettings = { ...agent.humanHandoverSettings };
    humanHandoverSettings.createTicketBeforeHandover = e;
    setAgent({ ...agent, humanHandoverSettings: humanHandoverSettings });
  };
  const handleHandoverChange = (e) => {
    var humanHandoverSettings = { ...agent.humanHandoverSettings };

    humanHandoverSettings.type = e.name;
    setAgent({ ...agent, humanHandoverSettings: humanHandoverSettings });
  };
  const handleChatHandoverChannelChange = (e) => {
    var humanHandoverSettings = { ...agent.humanHandoverSettings };

    humanHandoverSettings.chatHandoverChannel = e.name;
    setAgent({ ...agent, humanHandoverSettings: humanHandoverSettings });
  };

  const handleSelectedAgentType = (type) => {
    var ag = { ...agent };
    ag.agentType = type;
    if (type.toLowerCase() == "chat") {
      ag.welcomeLine = {
        nl: welcomeLinesChat[0],
        de: welcomeLinesChat[1],
        en: welcomeLinesChat[2],
      };
    } else {
      ag.welcomeLine = {
        nl: welcomeLinesVoice[0],
        de: welcomeLinesVoice[1],
        en: welcomeLinesVoice[2],
      };
    }
    setAgent(ag);
  };

  const handleHandoverActivatedChange = (e) => {
    var settings = { ...agent.settings };
    var humanHandoverSettings = { ...agent.humanHandoverSettings };

    settings.handoverActivated = e;

    if (e && humanHandoverSettings.workingHours == null) {
      humanHandoverSettings.workingHours = {
        sunday: { isWorkingDay: false, from: "", to: "" },
        monday: { isWorkingDay: false, from: "", to: "" },
        tuesday: { isWorkingDay: false, from: "", to: "" },
        wednesday: { isWorkingDay: false, from: "", to: "" },
        thursday: { isWorkingDay: false, from: "", to: "" },
        friday: { isWorkingDay: false, from: "", to: "" },
        saturday: { isWorkingDay: false, from: "", to: "" },
      };
    }
    setAgent({
      ...agent,
      settings: settings,
      humanHandoverSettings: humanHandoverSettings,
    });
  };

  const handleWhatsappPhoneIdChange = (prop) => (e) => {
    var whatsappPhoneId = e.target.value;
    setAgent({ ...agent, whatsappPhoneId: whatsappPhoneId });
  };
  const handleWhatsappPhoneTokenChange = (prop) => (e) => {
    var whatsappPhoneToken = e.target.value;
    setAgent({ ...agent, whatsappPhoneToken: whatsappPhoneToken });
  };
  const handleWhatsappSessionTimeoutChange = (prop) => (e) => {
    var whatsappPhoneSessionTimeout = e.target.value;
    setAgent({
      ...agent,
      whatsappPhoneSessionTimeout: whatsappPhoneSessionTimeout,
    });
  };
  const handleZendeskAppIdChange = (prop) => (e) => {
    var zendeskAppId = e.target.value;
    setAgent({ ...agent, zendeskAppId: zendeskAppId });
  };
  const handleZendeskTokenChange = (prop) => (e) => {
    var zendeskToken = e.target.value;
    setAgent({ ...agent, zendeskToken: zendeskToken });
  };
  const handleHandoverUsernameChange = (prop) => (e) => {
    var humanHandoverSettings = { ...agent.humanHandoverSettings };

    humanHandoverSettings.username = e.target.value;
    setAgent({ ...agent, humanHandoverSettings: humanHandoverSettings });
  };
  const handleHandoverPasswordChange = (prop) => (e) => {
    var humanHandoverSettings = { ...agent.humanHandoverSettings };

    humanHandoverSettings.password = e.target.value;
    setAgent({ ...agent, humanHandoverSettings: humanHandoverSettings });
  };
  const handleHandoverConnectionStringChange = (prop) => (e) => {
    var humanHandoverSettings = { ...agent.humanHandoverSettings };

    humanHandoverSettings.connectionString = e.target.value;
    setAgent({ ...agent, humanHandoverSettings: humanHandoverSettings });
  };

  const handleCreateTicketIntegrationChange = (e) => {
    var settings = { ...agent.settings };
    settings.createTicketIntegration = e.target.value;

    setAgent({ ...agent, settings: settings });
  };

  const handleAlwaysCreateTicketIntegrationChange = (e) => {
    var settings = { ...agent.settings };
    settings.alwaysCreateTicketIntegration = e.target.value;

    setAgent({ ...agent, settings: settings });
  };

  const handleStartupHookChange = (e) => {
    var settings = { ...agent.settings };
    settings.startupHook = e.target.value;

    setAgent({ ...agent, settings: settings });
  };
  const handleFinishedHookChange = (e) => {
    var settings = { ...agent.settings };
    settings.finishedHook = e.target.value;

    setAgent({ ...agent, settings: settings });
  };

  const handleEmailHookChange = (e) => {
    var settings = { ...agent.settings };
    settings.emailHook = e.target.value;

    setAgent({ ...agent, settings: settings });
  };

  const handleAlwasyCreateTicketChange = (e) => {
    var settings = { ...agent.settings };
    settings.alwaysCreateTicket = e;

    if (!e) {
      settings.alwaysCreateTicketIntegration = "";
    }

    setAgent({ ...agent, settings: settings });
  };

  const handleGiveIntentRecommendationsChange = (e) => {
    var settings = { ...agent.settings };
    settings.giveIntentRecommendations = e;

    setAgent({ ...agent, settings: settings });
  };

  const handleIsProductAdviceAgentChange = (e) => {
    var settings = { ...agent.settings };
    settings.isProductAdviceAgent = e;

    setAgent({ ...agent, settings: settings });
  };

  const handleDisableDeletionChange = (e) => {
    var settings = { ...agent.settings };
    settings.disableDeletion = e;

    setAgent({ ...agent, settings: settings });
  };

  const handleEnhancedIntentRecognitionChange = (e) => {
    var settings = { ...agent.settings };
    settings.enhancedIntentRecognition = e;

    setAgent({ ...agent, settings: settings });
  };

  const handleSelectedTicketingSystem = (e) => {
    var settings = { ...agent.settings };

    settings.ticketingSystem = e.name;
    if (e.name != "email") {
      settings.ticketEmail = "";
    }
    setAgent({ ...agent, settings: settings });
  };

  const handleSelectedPreferedContact = (e) => {
    var settings = { ...agent.settings };

    settings.preferedContact = e.name;
    setAgent({ ...agent, settings: settings });
  };

  const handleTicketEmailChange = (prop) => (e) => {
    if (agent?.settings.ticketEmail == e.target.value) {
      return;
    }
    var settings = { ...agent.settings };
    settings.ticketEmail = e.target.value;

    setAgent({ ...agent, settings: settings });
  };
  const handleOrdernumberLengthChange = (prop) => (e) => {
    if (agent?.settings.ordernumberLength == e.target.value) {
      return;
    }
    var settings = { ...agent.settings };
    settings.ordernumberLength = e.target.value;

    setAgent({ ...agent, settings: settings });
  };
  const setSelectedCompany = (comp) => {
    setAgent({ ...agent, company: comp });
    getCompanyAgents(comp.id);
  };
  const setSelectedSharedCatalog = (selectedAgent) => {
    setAgent({ ...agent, agentCatalogName: selectedAgent.idName });
  };

  const textWithoutCode = (text) => {
    if (text == undefined) {
      return "";
    }

    return text.replace("AGENTNAME", agent?.name);
  };

  const setSelectedFlow = (f) => {
    setFlow(f);
    handleSelectedAgentType(f.type);
  };

  const submit = () => {
    var newAgent = agent;

    if (newAgent.name == "") {
      setInfoMessage("Name not valid.");
    }
    if (newAgent.company == null) {
      setInfoMessage("Company not valid.");
    }
    newAgent.name =
      newAgent.name.charAt(0).toUpperCase() + newAgent.name.slice(1);

    if (selectedPhoneQueue != null && selectedPhoneQueue.name != "") {
      newAgent.humanHandoverSettings.phoneQueues[selectedPhoneQueue.name] =
        selectedPhoneQueue;
    }
    // if only 1 queue exists. take this queue as default
    if (Object.keys(newAgent.humanHandoverSettings.phoneQueues).length == 1) {
      newAgent.humanHandoverSettings.defaultHandoverQueue = Object.entries(
        newAgent.humanHandoverSettings.phoneQueues
      )[0][1].name;
    }

    Object.entries(agent.languages).map((lang) => {
      newAgent.welcomeLine[lang[0]] = newAgent.welcomeLine[lang[0]].replace(
        "AGENTNAME",
        newAgent.name
      );
    });
    newAgent.companyId = newAgent.company.id;
    delete newAgent.company;

    newAgent.collection = catalogs.filter(
      (x) => x.id == flow.catalogId
    )[0].name;
    newAgent.coreAgentId = flow.id;
    newAgent.agentType = flow.type.toLowerCase();

    if (isNewAgent) {
      newAgent.idName = newAgent.name.replaceAll(" ", ".").toLowerCase();
      newAgent.dialogflowName =
        "implementation_" + newAgent.agentType + "_" + newAgent.idName;

      if (newAgent.agentCatalogName == null) {
        newAgent.agentCatalogName = newAgent.idName;
      }
    }

    setIndicatorState(
      "saving",
      (isNewAgent ? "Creating" : "Updating") +
        " agent. this may take several minutes."
    );

    if (isNewAgent) {
      createAgent(newAgent).then((result) => {
        if (!result.isError) {
          setSelected("new");
          getAgents();
          resetIndicatorState("Agent has been successfully created.");
          updateUserActionHistoryList(
            currentUser.uid,
            newAgent.name,
            'Created agent: "' + newAgent.name + '"'
          );
        } else {
          resetIndicatorState(result.message);
        }
      });
    } else {
      updateAgent(newAgent).then((result) => {
        if (!result.isError) {
          if (result.object.companyId != null) {
            result.object.company = companys.filter(
              (x) => x.id == result.object.companyId
            )[0];
          }
          setAgent(result.object);
          setDeletionDisabled(result.object.settings.disableDeletion);
          resetIndicatorState("Agent has been successfully updated.");
          updateUserActionHistoryList(
            currentUser.uid,
            newAgent.name,
            'Updated agent: "' + newAgent.name + '"'
          );
        } else {
          resetIndicatorState(result.message);
        }
      });
    }
  };

  const deleteAgentAction = () => {
    setOpenDialog(true);
  };

  const handleCloseDialogDisagree = () => {
    setOpenDialog(false);
  };
  const handleCloseDialogAgree = () => {
    setOpenDialog(false);
    setIndicatorState("saving", "Removing agent.");
    deleteAgent({ id: agent.id }).then((result) => {
      if (!result.isError) {
        setSelected("new");
        getAgents();
        resetIndicatorState(result.message);
      } else {
        resetIndicatorState("Agent has been removed successfully.");
      }
    });
  };

  const [selectedPhoneQueue, setSelectedPhoneQueue] = useState();
  const addNewPhoneQueue = () => {
    if (selectedPhoneQueue != null && selectedPhoneQueue.name != "") {
      var newAgent = { ...agent };
      newAgent.humanHandoverSettings.phoneQueues[selectedPhoneQueue.name] =
        selectedPhoneQueue;
      setAgent(newAgent);
    }
    setSelectedPhoneQueue({
      name: "",
      values: {},
    });
  };
  const deletePhoneQueue = () => {
    var newAgent = { ...agent };
    delete newAgent.humanHandoverSettings.phoneQueues[selectedPhoneQueue.name];
    setAgent(newAgent);

    setSelectedPhoneQueue(null);
  };

  const setSelectedPhoneQueueValue = (v) => {
    var queue = agent.humanHandoverSettings.phoneQueues[v.name];
    setSelectedPhoneQueue({
      name: queue.name,
      values: queue.values,
    });
  };
  const updatePhoneQueueName = (prop) => (e) => {
    setSelectedPhoneQueue({
      ...selectedPhoneQueue,
      name: e.target.value.toLowerCase().replace(" ", "_"),
    });
  };

  const updatePhoneQueueLanguageValue = (prop) => (e) => {
    var phoneQueue = { ...selectedPhoneQueue };
    phoneQueue.values[prop] = e.target.value;
    setSelectedPhoneQueue(phoneQueue);
  };

  const getCollections = () => {
    getCoreIntentCollections().then((result) => {
      getApiAllAgents().then((res) => {
        var agentNames = res.map((x) => x.name).filter(onlyUnique);

        let collections = result
          .filter((x) => agentNames.indexOf(x.replace("collection_", "")) == -1)
          .filter(onlyUnique)
          .map((x) => ({ collection: x, name: x, type: "collection" }));
        setCollections(
          collections.map((x) => ({ name: x.name.replace("collection_", "") }))
        );
      });
    });
  };
  const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  };

  const contactOptions = () => {
    //var ticketingsOptionList = Object.entries(agent.integrations).map(y => y[1]).filter(x => !x.settings.isTokenRefreshWebhook && x.inputWebhook == "").map(x => ({name: "Webhook."+x.name}))
    var contactOptionList = [
      { name: "none" },
      { name: "callback" },
      { name: "redirect" },
    ];
    return contactOptionList;
    //ticketingsOptionList.push({name:"Dashboard"});
    //ticketingsOptionList.push({name:"Email"});
    //return ticketingsOptionList.sort((a, b) => (a.name > b.name) ? 1 : -1);
  };
  const ticketingOptions = () => {
    //var ticketingsOptionList = Object.entries(agent.integrations).map(y => y[1]).filter(x => !x.settings.isTokenRefreshWebhook && x.inputWebhook == "").map(x => ({name: "Webhook."+x.name}))
    var ticketingsOptionList = [{ name: "Dashboard" }, { name: "Integration" }];
    return ticketingsOptionList;
    //ticketingsOptionList.push({name:"Dashboard"});
    //ticketingsOptionList.push({name:"Email"});
    //return ticketingsOptionList.sort((a, b) => (a.name > b.name) ? 1 : -1);
  };
  const filteredFlows = useMemo(() => {
    // Create copy of item list
    var activeCollection = agents.filter(
      (x) => x.idName == agent.agentCatalogName
    )[0]?.collection;
    var activeCollectionId = catalogs.filter(
      (x) => x.name == activeCollection
    )[0]?.id;

    if (activeCollectionId != null) {
      return [...flows.filter((x) => x.catalogId == activeCollectionId)];
    }
    // Include all elements which includes the search query
    return flows;
  }, [agent]);

  const humanHanoverOptions = () => {
    return [{ name: "SIP" }, { name: "PHONE" }, { name: "CHAT" }];
  };

  const chatHandoverChannelOptions = () => {
    return [{ name: "" }, { name: "Zendesk" }];
  };

  const humanHandoverValue = () => {
    if (agent?.settings?.humanHandover == null) return "";

    var keys = Object.keys(agent?.settings?.humanHandover);
    if (keys == []) return "";

    return keys[0] == null ? "" : keys[0];
  };

  const handleInvokeWebhookChange = async (e) => {
    var humanHandoverSettings = { ...agent.humanHandoverSettings };
    humanHandoverSettings.invokeWebhook = e.target.value;
    setAgent({ ...agent, humanHandoverSettings: humanHandoverSettings });
  };

  const createTicketIntegrationIsSet = () => {
    if (
      agent?.settings.ticketingSystem.toLowerCase() == "integration" &&
      agent?.settings.createTicketIntegration == ""
    ) {
      return false;
    }
    return true;
  };

  const setWorkingday = (day, value) => {
    var humanHandoverSettings = { ...agent.humanHandoverSettings };
    humanHandoverSettings.workingHours[day].isWorkingDay = value;
    if (humanHandoverSettings.workingHours[day].from == "") {
      humanHandoverSettings.workingHours[day].from = "08:00";
    }
    if (humanHandoverSettings.workingHours[day].to == "") {
      humanHandoverSettings.workingHours[day].to = "17:00";
    }
    setAgent((prev) => ({
      ...prev,
      humanHandoverSettings: humanHandoverSettings,
    }));
  };

  const setTime = (type, day, value) => {
    var humanHandoverSettings = { ...agent.humanHandoverSettings };
    humanHandoverSettings.workingHours[day][type] = value ?? "";

    setAgent((prev) => ({
      ...prev,
      humanHandoverSettings: humanHandoverSettings,
    }));
  };

  const [testDatasourceReponse, setTestDatasourceReponse] = useState();
  const [testDatasourceReponseResult, setTestDatasourceReponseResult] =
    useState();
  const [isLoadingDatasourceResponse, setIsLoadingDatasourceResponse] =
    useState(false);
  const [isGeneratingDatasourceIndex, setIsGeneratingDatasourceIndex] =
    useState(false);
  const getDatasourceResponseFromApi = () => {
    setTestDatasourceReponseResult("");
    setIsLoadingDatasourceResponse(true);
    getDatasourceResponse({
      agentId: agent.id,
      responseMode: responseMode,
      question: testDatasourceReponse,
      language: "nl",
      index: selectedIndex,
    }).then((x) => {
      setTestDatasourceReponseResult(x.object);
      setIsLoadingDatasourceResponse(false);
    });
  };
  const handleTestDatasourceReponse = (prop) => (e) => {
    setTestDatasourceReponse(e.target.value);
  };

  const generateDatasourceIndexFromApi = () => {
    var newSelectedModel = selectedModel == null ? "3.5" : selectedModel;
    var retVal = window.confirm(
      "Are you sure you want to (re)generate the index? This can take several minutes."
    );
    if (retVal == true) {
      var responseModeVar = responseMode ?? "short";
      setIndicatorState("creating", "Creating index");
      setIsGeneratingDatasourceIndex(true);
      createDatestoreIndex({
        agentId: agent.id,
        index: selectedIndex,
        responseMode: responseModeVar,
        model: newSelectedModel,
        prompt: agent.datasource.indexes.filter(
          (x) => x.name == selectedIndex
        )[0]?.prompt,
      }).then((resp) => {
        setIsGeneratingDatasourceIndex(false);
        console.log(resp);
        setAgent((prev) => ({
          ...prev,
          datasource: {
            ...prev.datasource,
            lastCreatedIndex: resp.object.LastCreatedIndex,
            tokens: resp.object.tokens,
          },
        }));

        resetIndicatorState(
          "Index succesfully build. " + resp.object.tokens + " tokens used."
        );
        return true;
      });
    } else {
      return false;
    }
  };
  const savePrompt = () => {
    setIndicatorState("creating", "Save prompt and responseMode");
    var responseModeVar = responseMode ?? "short";
    savePromptApi({
      agentId: agent.id,
      index: selectedIndex,
      model: selectedModel,
      responseMode: responseModeVar,
      prompt: agent.datasource.indexes.filter((x) => x.name == selectedIndex)[0]
        ?.prompt,
    }).then((resp) => {
      resetIndicatorState("Prompt saved!");
    });
  };

  const updateSourcePrompt = (prompt) => {
    if (
      agent.datasource.indexes.filter((x) => x.name == selectedIndex)[0] == null
    ) {
      setAgent((prev) => ({
        ...prev,
        datasource: {
          ...prev.datasource,
          indexes: [
            ...prev.datasource.indexes.filter((x) => x.name != selectedIndex),
            { name: selectedIndex, prompt: prompt },
          ],
        },
      }));
    } else {
      setAgent((prev) => ({
        ...prev,
        datasource: {
          ...prev.datasource,
          indexes: [
            ...prev.datasource.indexes.map((x) =>
              x.name == selectedIndex ? { ...x, prompt: prompt } : { x }
            ),
          ],
        },
      }));
    }
  };

  const updateSourceValue = (prop, value, obj) => {
    setAgent((prev) => ({
      ...prev,
      datasource: {
        ...prev.datasource,
        sources: prev.datasource.sources.map((x) =>
          x == obj ? { ...x, [prop]: value } : x
        ),
      },
    }));
  };

  const deleteDatasource = (source) => {
    let sourceObj = { ...source, agentId: agent.id };
    deleteDatasourceApi(sourceObj).then((res) => {
      if (res.object == "success") {
        resetIndicatorState("Document succesfully deleted.");
      } else {
        resetIndicatorState("Uploading document failed.");
      }
    });
    setAgent((prev) => ({
      ...prev,
      datasource: {
        ...prev.datasource,
        sources: prev.datasource.sources.filter((x) => x != source),
      },
    }));
  };

  const [newSource, setNewSource] = useState({
    type: "url",
    url: "",
    data: null,
    scrapeSubpages: false,
  });
  const addDataSource = () => {
    if (
      agent.datasource.sources.filter((x) => x.url == newSource.url).length > 0
    ) {
      resetIndicatorState("Bestand of url bestaat al.");
      return;
    }
    if (newSource.type == "url") {
      uploadDataSourceUrl({
        agentId: agent.id,
        index: selectedIndex,
        url: newSource.url,
        scrapeSubpages: newSource.scrapeSubpages,
      }).then((res) => {
        if (res.object == "success") {
          resetIndicatorState("Url succesfully uploaded.");
          newSource.url = newSource.data.name;
          setAgent((prev) => ({
            ...prev,
            datasource: {
              ...prev.datasource,
              sources: [...prev.datasource.sources, newSource],
            },
          }));
          setNewSource({
            type: "url",
            url: "",
            data: null,
            scrapeSubpages: false,
          });
        } else {
          resetIndicatorState("Uploading url failed.");
        }
      });
    }
    if (newSource.type == "document") {
      setIndicatorState("uploading", "Uploading document");
      const formData = new FormData();
      formData.append("formFile", newSource.data);
      formData.append("agentId", agent.id);
      formData.append("index", selectedIndex);
      uploadDataSourceFile(formData).then((res) => {
        if (res.object == "success") {
          resetIndicatorState("Document succesfully uploaded.");
          newSource.url = newSource.data.name;
          setAgent((prev) => ({
            ...prev,
            datasource: {
              ...prev.datasource,
              sources: [...prev.datasource.sources, newSource],
            },
          }));
          setNewSource({
            type: "url",
            url: "",
            data: null,
            scrapeSubpages: false,
          });
        } else {
          resetIndicatorState("Uploading document failed.");
        }
      });
    }
  };

  const loadFile = (event) => {
    setNewSource((prev) => ({ ...prev, data: event.target.files[0] }));
  };

  const changeSelectedIndex = (e) => {
    setSelectedIndex(e.target.value);
    setSelectedModel(
      agent?.datasource?.indexes.filter((x) => x.name == e.target.value)[0]
        ?.model
    );
    setresponseMode(
      agent?.datasource?.indexes.filter((x) => x.name == e.target.value)[0]
        ?.responseMode
    );
  };

  const changeSelectedIndexForIntentFilling = (e) => {
    setSelectedIndexForIntentFilling(e.target.value);
  };

  const changeSelectedModel = (e) => {
    setSelectedModel(e.target.value);
  };
  const changeresponseMode = (e) => {
    setresponseMode(e.target.value);
  };

  const [newIndexName, setNewIndexName] = useState("");

  const handleNewIndex = (prop) => (e) => {
    setNewIndexName(e.target.value);
  };
  const addNewIndex = () => {
    setDatasourcesIndexes((prev) => [...prev, newIndexName]);
    setNewIndexName("");

    var promptPlaceholder =
      "De Context informatie staat hieronder." +
      " \n---------------------\n{context_str}\n---------------------\nGebruik" +
      " alleen de informatie uit de context, geef een uitgebreid antwoord op de" +
      " vraag: {query_str}.\n Als het antwoord niet aanwezig is antwoord met ###\n";

    setIndicatorState("creating", "Creating index");
    var responseModeVar = responseMode ?? "short";
    savePromptApi({
      agentId: agent.id,
      index: newIndexName,
      responseMode: responseModeVar,
      prompt: promptPlaceholder,
    }).then((resp) => {
      resetIndicatorState("Index saved!");
    });
    getDatasourceResponseFromApi();
  };

  const fillAgentIntents = () => {
    setIndicatorState("uploading", "Filling agent intents");
    const data = {
      agentId: agent.id,
      datasource: selectedIndexForIntentFilling,
    };

    fillAgentIntentsApi(data).then((result) => {
      if (result.object == "success") {
        resetIndicatorState("Intents filled based on datasource.");
        setSelectedIndexForIntentFilling(null);
      } else {
        resetIndicatorState(
          "Something went wrong while filling agent intents."
        );
      }
    });
  };

  const [datasourcesIndexes, setDatasourcesIndexes] = useState([]);

  return (
    <div className={styles.scrollable}>
      <div className={styles.widgetHeader}>
        <div className={styles.headerTitle}>Agents</div>
        <CategorizedDropdownSearch
          data={agents}
          subHeader="agentType"
          type="agent"
          selected={agent}
          setSelected={setSelected}
        />
        <div onClick={() => setSelected("new")} className={styles.buttonStyle}>
          Create new agent
        </div>
      </div>
      {agent && (
        <div className={styles.formPanel}>
          <div className={styles.formRow}>
            <div className={styles.formElementContainer}>
              <FormText
                isDisabled={!isNewAgent}
                title="Name"
                formId="name"
                value={agent?.name}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.dropdownContainer}>
              <div className={styles.dropdownHeader}>Company:</div>
              <DropdownSearch
                isDisabled={!isNewAgent}
                data={companys}
                type="company"
                selected={agent?.company}
                setSelected={setSelectedCompany}
              />
            </div>
            {agent.company && (
              <div className={styles.dropdownContainer}>
                <div className={styles.dropdownHeader}>Share catalog from:</div>
                <DropdownSearch
                  isDisabled={!isNewAgent}
                  data={companyAgents}
                  type="shared catalog"
                  selected={{ name: agent?.agentCatalogName }}
                  setSelected={setSelectedSharedCatalog}
                />
              </div>
            )}
            <div className={styles.labelTypeContainer}>
              {agent?.agentType != "" && (
                <div
                  className={`${styles.typeLabel} ${
                    agent?.agentType == "chat" ? styles.typeLabelChat : null
                  }`}
                >
                  {agent?.agentType}
                </div>
              )}
            </div>
            <div className={styles.dropdownContainer}>
              <CategorizedDropdownSearch
                subHeader="type"
                isDisabled={!isNewAgent}
                data={filteredFlows}
                type="flow"
                selected={flow}
                setSelected={setSelectedFlow}
              />
              {/* <CategorizedDropdownSearch subHeader="type" isDisabled={!isNewAgent} data={flows} type="flow" selected={flow} setSelected={setSelectedFlow}/> */}
            </div>
          </div>
          <div className={styles.formHeaderText}></div>
          <div className={styles.formRow}>
            <FormMultiSelect
              title="Languages"
              values={[
                { key: "German", value: "de" },
                { key: "English", value: "en" },
                { key: "Dutch", value: "nl" },
              ]}
              selectedValues={agent == null ? [] : agent?.languages}
              handleChange={handleLanguageChange}
            />
            <div className={styles.formColumn}>
              {agent?.languages != null &&
                Object.entries(agent.languages)
                  .sort((a, b) => (a[0] < b[0] ? 1 : -1))
                  .map((lang) => (
                    <div className={styles.formRow} key={lang[0]}>
                      <div
                        className={`${styles.formElementContainer} ${styles.wFull}`}
                      >
                        <FormText
                          formId={lang[0]}
                          title={"Welcome text " + lang[0]}
                          value={textWithoutCode(agent["welcomeLine"][lang[0]])}
                          handleChange={handleWelcomeChange}
                        />
                        {agent.languages[lang[0]]?.phonenumber != "" ? (
                          <>
                            <div className={styles.phonenumberField}>
                              {agent.languages[lang[0]].phonenumber}
                            </div>
                            <div
                              className={styles.goLiveDelete}
                              onClick={() => goLiveDelete(lang[0])}
                            >
                              Delete
                            </div>
                          </>
                        ) : (
                          <div
                            className={styles.goLive}
                            onClick={() => goLive(lang[0])}
                          >
                            Go LIVE
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          <div className={styles.fileIntentImportContainer}>
            <div className={styles.fileIntentImportHeader}>
              Upload intent sheet
            </div>
            <div className={styles.fileIntentImportBody}>
              <input ref={fileRef} type="file" onChange={handleFileChange} />
              {file != null && (
                <button onClick={handleUploadClick}>Upload</button>
              )}
            </div>
          </div>
          <div className={styles.dropdownContainerRow}>
            <div className={styles.chatboxOptionLine}>
              <div className={`${styles.chatboxOptionDescription}`}>
                Fill agent intents
              </div>
              <div className={styles.chatboxOptionSelector}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-indexes">
                    Fill agent intents
                  </InputLabel>
                  <Select
                    native
                    defaultValue=""
                    labelId="demo-select-small-indexes"
                    id="grouped-native-select"
                    value={selectedIndexForIntentFilling}
                    label="Webhook"
                    onChange={changeSelectedIndexForIntentFilling}
                  >
                    <option value="">
                      <em></em>
                    </option>
                    {datasourcesIndexes
                      .filter(onlyUnique)
                      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                      .map((obj) => (
                        <option key={obj} value={obj}>
                          {obj}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </div>
              {selectedIndexForIntentFilling != null &&
                selectedIndexForIntentFilling != "" && (
                  <button
                    onClick={fillAgentIntents}
                    className={`${styles.buttonStyle} ${styles.submitButton}`}
                  >
                    Fill agent intents
                  </button>
                )}
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formElementContainer}>
              <FormText
                title="Ordernumber length"
                formId="ordernumberLength"
                value={agent?.settings.ordernumberLength}
                handleChange={handleOrdernumberLengthChange}
              />
            </div>
          </div>
          <div className={styles.formHeaderText}></div>
          <div className={styles.dropdownContainerRow}>
            <div className={styles.chatboxOptionLine}>
              {company?.installedIntegrations != null && (
                <>
                  <div className={`${styles.chatboxOptionDescription}`}>
                    Startup hook
                  </div>
                  <div className={styles.chatboxOptionSelector}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small-hook">
                        Startup hook
                      </InputLabel>
                      <Select
                        native
                        defaultValue=""
                        labelId="demo-select-small-hook"
                        id="grouped-native-select"
                        value={agent?.settings?.startupHook}
                        label="Webhook"
                        onChange={handleStartupHookChange}
                      >
                        <option value="">
                          <em></em>
                        </option>
                        {Object.entries(company.installedIntegrations)
                          .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                          .map((obj) => (
                            <optgroup key={obj[0]} label={obj[0]}>
                              {company.installedIntegrations[obj[0]].map(
                                (integrationHook) => (
                                  <option
                                    key={obj[0] + "|" + integrationHook}
                                    value={obj[0] + "|" + integrationHook}
                                  >
                                    {integrationHook}
                                  </option>
                                )
                              )}
                            </optgroup>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.dropdownContainerRow}>
            <div className={styles.chatboxOptionLine}>
              {company?.installedIntegrations != null && (
                <>
                  <div className={`${styles.chatboxOptionDescription}`}>
                    Finished hook (after handover)
                  </div>
                  <div className={styles.chatboxOptionSelector}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small-hook">
                        Finished hook
                      </InputLabel>
                      <Select
                        native
                        defaultValue=""
                        labelId="demo-select-small-hook"
                        id="grouped-native-select"
                        value={agent?.settings?.finishedHook}
                        label="Webhook"
                        onChange={handleFinishedHookChange}
                      >
                        <option value="">
                          <em></em>
                        </option>
                        {Object.entries(company.installedIntegrations)
                          .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                          .map((obj) => (
                            <optgroup key={obj[0]} label={obj[0]}>
                              {company.installedIntegrations[obj[0]].map(
                                (integrationHook) => (
                                  <option
                                    key={obj[0] + "|" + integrationHook}
                                    value={obj[0] + "|" + integrationHook}
                                  >
                                    {integrationHook}
                                  </option>
                                )
                              )}
                            </optgroup>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.dropdownContainerRow}>
            <div className={styles.chatboxOptionLine}>
              {company?.installedIntegrations != null && (
                <>
                  <div className={`${styles.chatboxOptionDescription}`}>
                    Email hook
                  </div>
                  <div className={styles.chatboxOptionSelector}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small-emailhook">
                        Email hook
                      </InputLabel>
                      <Select
                        native
                        defaultValue=""
                        labelId="demo-select-small-emailhook"
                        id="grouped-native-select"
                        value={agent?.settings?.emailHook}
                        label="Webhook"
                        onChange={handleEmailHookChange}
                      >
                        <option value="">
                          <em></em>
                        </option>
                        {Object.entries(company.installedIntegrations)
                          .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                          .map((obj) => (
                            <optgroup key={obj[0]} label={obj[0]}>
                              {company.installedIntegrations[obj[0]].map(
                                (integrationHook) => (
                                  <option
                                    key={obj[0] + "|" + integrationHook}
                                    value={obj[0] + "|" + integrationHook}
                                  >
                                    {integrationHook}
                                  </option>
                                )
                              )}
                            </optgroup>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
            </div>
          </div>
          <hr />
          <div className={styles.formRow}>
            <div className={styles.chatboxOptionLine}>
              <div className={styles.dropdownHeader}>Datasources:</div>

              <div className={`${styles.chatboxOptionDescription}`}>Index</div>
              <div className={styles.chatboxOptionSelector}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-indexes">Index</InputLabel>
                  <Select
                    native
                    defaultValue=""
                    labelId="demo-select-small-indexes"
                    id="grouped-native-select"
                    value={selectedIndex}
                    label="Webhook"
                    onChange={changeSelectedIndex}
                  >
                    <option value="">
                      <em></em>
                    </option>
                    {datasourcesIndexes
                      .filter(onlyUnique)
                      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                      .map((obj) => (
                        <option key={obj} value={obj}>
                          {obj}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <div className={`${styles.chatboxOptionDescription}`}>Model</div>
              <div className={styles.chatboxOptionSelector}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-indexes"></InputLabel>
                  <Select
                    native
                    defaultValue=""
                    labelId="demo-select-small-indexes"
                    id="grouped-native-select"
                    value={selectedModel}
                    label="Webhook"
                    onChange={changeSelectedModel}
                  >
                    <option value="">
                      <em></em>
                    </option>
                    {["3.5", "4"]
                      .filter(onlyUnique)
                      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                      .map((obj) => (
                        <option key={obj} value={obj}>
                          {obj}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <div className={`${styles.chatboxOptionDescription}`}>
                responseMode
              </div>
              <div className={styles.chatboxOptionSelector}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-indexes"></InputLabel>
                  <Select
                    native
                    defaultValue=""
                    labelId="demo-select-small-indexes"
                    id="grouped-native-select"
                    value={responseMode}
                    label="Webhook"
                    onChange={changeresponseMode}
                  >
                    <option value="">
                      <em></em>
                    </option>
                    {["short", "detailed", "long"]
                      .filter(onlyUnique)
                      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                      .map((obj) => (
                        <option key={obj} value={obj}>
                          {obj}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className={styles.formRow}>
            {selectedIndex && (
              <>
                <span>
                  Last index created on{" "}
                  {
                    agent?.datasource?.indexes.filter(
                      (x) => x.name == selectedIndex
                    )[0]?.lastCreatedIndex
                  }
                </span>
                <span>
                  Used tokens:{" "}
                  {
                    agent?.datasource?.indexes.filter(
                      (x) => x.name == selectedIndex
                    )[0]?.tokens
                  }
                </span>
                {isGeneratingDatasourceIndex ? (
                  <div className={styles.loaderContainer}>
                    <span className={styles.loader}></span>
                  </div>
                ) : (
                  <button
                    onClick={generateDatasourceIndexFromApi}
                    className={`${styles.buttonStyle} ${styles.margin} ${styles.submitButton}`}
                  >
                    Generate index
                  </button>
                )}

                <button
                  onClick={savePrompt}
                  className={`${styles.buttonStyle} ${styles.submitButton}`}
                >
                  Save prompt and responseMode
                </button>
              </>
            )}
          </div>
          {selectedIndex && (
            <div className={styles.formRow}>
              <textarea
                className={styles.datasourceResponseResult}
                onChange={(e) => updateSourcePrompt(e.target.value)}
                value={
                  agent?.datasource.indexes.filter(
                    (x) => x.name == selectedIndex
                  )[0]?.prompt
                }
              ></textarea>
            </div>
          )}
          <div className={styles.formRow}>
            <div className={styles.chatboxOptionLine}>
              <FormText
                title="New index name"
                formId="newIndexName"
                value={newIndexName}
                handleChange={handleNewIndex}
              />
              <button
                onClick={addNewIndex}
                className={`${styles.buttonStyle} ${styles.submitButton}`}
              >
                New index
              </button>
            </div>
          </div>
          {selectedIndex && (
            <>
              <div className={styles.border}>
                <div className={styles.formRow}>
                  <div className={styles.sources}>
                    {agent?.datasource?.sources
                      .filter((x) => x.index == selectedIndex)
                      .map((source) => (
                        <>
                          <div className={styles.datasourceRow}>
                            <div className={styles.datasourceRowtype}>
                              <div className={styles.chatboxOptionSelector}>
                                <FormControl
                                  sx={{ m: 1, minWidth: 120 }}
                                  size="small"
                                >
                                  <InputLabel id="demo-select-small-emailhook">
                                    Type
                                  </InputLabel>
                                  <Select
                                    native
                                    defaultValue=""
                                    labelId="demo-select-small-emailhook"
                                    id="grouped-native-select"
                                    value={source.type}
                                    label="Webhook"
                                    onChange={(e) =>
                                      updateSourceValue(
                                        "type",
                                        e.target.value,
                                        source
                                      )
                                    }
                                  >
                                    <option value="">
                                      <em></em>
                                    </option>
                                    {["url", "document"].map((obj) => (
                                      <option key={obj} value={obj}>
                                        {obj}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                            <div className={styles.datasourceRowValue}>
                              <input
                                className={styles.textInputField}
                                type="text"
                                value={source.url}
                                onChange={(e) =>
                                  updateSourceValue(
                                    "url",
                                    e.target.value,
                                    source
                                  )
                                }
                              ></input>
                              {/* <FormText title="Value" formId="value" value={source.url} handleChange={(e) => updateSourceValue("type", e, source)}/> */}
                            </div>
                            {source.type == "url" && (
                              <div className={styles.datasourceRowOption}>
                                <FormGroup>
                                  <FormControlLabel
                                    sx={{
                                      "& .MuiTypography-root": { fontSize: 14 },
                                    }}
                                    control={
                                      <Checkbox
                                        size="large"
                                        checked={source.scrapeSubpages}
                                        onChange={(e) =>
                                          updateSourceValue(
                                            "scrapeSubpages",
                                            e.target.checked,
                                            source
                                          )
                                        }
                                      />
                                    }
                                    label="Scrape subpages"
                                  />
                                </FormGroup>
                              </div>
                            )}

                            <div className={styles.datasourceRowAdd}>
                              <button
                                onClick={() => deleteDatasource(source)}
                                className={`${styles.buttonStyle} ${styles.submitButton}`}
                              >
                                <CloseIcon />
                              </button>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>

                <div className={styles.newSource}>
                  <>
                    <div className={styles.datasourceRow}>
                      <div className={styles.datasourceRowtype}>
                        <div className={styles.chatboxOptionSelector}>
                          <FormControl
                            sx={{ m: 1, minWidth: 120 }}
                            size="small"
                          >
                            <InputLabel id="demo-select-small-emailhook">
                              Type
                            </InputLabel>
                            <Select
                              native
                              defaultValue=""
                              labelId="demo-select-small-emailhook"
                              id="grouped-native-select"
                              value={newSource.type}
                              label="Webhook"
                              onChange={(e) =>
                                setNewSource((prev) => ({
                                  ...prev,
                                  type: e.target.value,
                                }))
                              }
                            >
                              <option value="">
                                <em></em>
                              </option>
                              {["url", "document"].map((obj) => (
                                <option key={obj} value={obj}>
                                  {obj}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className={styles.datasourceRowValue}>
                        {newSource.type == "url" && (
                          <input
                            className={styles.textInputField}
                            type="text"
                            value={newSource.url}
                            onChange={(e) =>
                              setNewSource((prev) => ({
                                ...prev,
                                url: e.target.value,
                              }))
                            }
                          ></input>
                        )}
                        {newSource.type == "document" && (
                          <input
                            className={styles.textInputField}
                            type="file"
                            onChange={loadFile}
                          ></input>
                        )}
                        {/* <FormText title="Value" formId="value" value={source.url} handleChange={(e) => updateSourceValue("type", e, source)}/> */}
                      </div>
                      {newSource.type == "url" && (
                        <div className={styles.datasourceRowOption}>
                          <FormGroup>
                            <FormControlLabel
                              sx={{ "& .MuiTypography-root": { fontSize: 14 } }}
                              control={
                                <Checkbox
                                  size="large"
                                  checked={newSource.scrapeSubpages}
                                  onChange={(e) =>
                                    setNewSource((prev) => ({
                                      ...prev,
                                      scrapeSubpages: e.target.checked,
                                    }))
                                  }
                                />
                              }
                              label="Scrape subpages"
                            />
                          </FormGroup>
                        </div>
                      )}
                      <div className={styles.datasourceRowAdd}>
                        <button
                          disabled={
                            (selectedIndex != "" &&
                              selectedIndex != null &&
                              newSource.data == "") ||
                            newSource.type == ""
                          }
                          onClick={addDataSource}
                          className={`${styles.buttonStyle} ${
                            styles.submitButton
                          } ${
                            newSource.value == "" ||
                            newSource.type == "" ||
                            selectedIndex == "" ||
                            selectedIndex == null
                              ? styles.disabledButton
                              : null
                          }`}
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.chatboxOptionLine}>
                  <FormText
                    title="Question"
                    formId="question"
                    value={testDatasourceReponse}
                    handleChange={handleTestDatasourceReponse}
                  />
                  {isLoadingDatasourceResponse ? (
                    <div className={styles.loaderContainer}>
                      <span className={styles.loader}></span>
                    </div>
                  ) : (
                    <button
                      onClick={getDatasourceResponseFromApi}
                      className={`${styles.buttonStyle} ${styles.submitButton}`}
                    >
                      Test
                    </button>
                  )}
                </div>
              </div>
              <div className={styles.formRow}>
                <textarea
                  className={styles.datasourceResponseResult}
                  value={testDatasourceReponseResult}
                ></textarea>
              </div>
            </>
          )}
          <hr />
          <div className={styles.dropdownContainer}>
            <div className={styles.dropdownHeader}>Prefered contact:</div>
            <DropdownSearch
              data={contactOptions()}
              type="Prefered contact"
              selected={{ name: agent?.settings.preferedContact }}
              setSelected={handleSelectedPreferedContact}
            />
          </div>
          <div className={styles.dropdownContainer}>
            <div className={styles.dropdownHeader}>Ticketing system:</div>
            <DropdownSearch
              data={ticketingOptions()}
              type="ticketing system"
              selected={{ name: agent?.settings.ticketingSystem }}
              setSelected={handleSelectedTicketingSystem}
            />
          </div>
          {company &&
            agent?.settings.ticketingSystem.toLowerCase() == "integration" && (
              <div className={styles.chatboxOptionLine}>
                <div className={`${styles.chatboxOptionDescription}`}>
                  Create ticket integration
                </div>
                <div className={styles.chatboxOptionSelector}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small">Integration</InputLabel>
                    <Select
                      native
                      defaultValue=""
                      labelId="demo-select-small"
                      id="grouped-native-select"
                      value={agent?.settings?.createTicketIntegration}
                      label="Webhook"
                      onChange={handleCreateTicketIntegrationChange}
                    >
                      <option value="">
                        <em></em>
                      </option>
                      {Object.entries(company.installedIntegrations)
                        .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                        .map((obj) => (
                          <optgroup key={obj[0]} label={obj[0]}>
                            {company.installedIntegrations[obj[0]].map(
                              (integrationHook) => (
                                <option
                                  key={obj[0] + "|" + integrationHook}
                                  value={obj[0] + "|" + integrationHook}
                                >
                                  {integrationHook}
                                </option>
                              )
                            )}
                          </optgroup>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}
          <div className={styles.formElementContainer}>
            {agent?.settings.ticketingSystem == "Email" && (
              <FormText
                title="Email to send tickets to"
                formId="ticketEmail"
                value={agent?.settings.ticketEmail}
                handleChange={handleTicketEmailChange}
              />
            )}
            <FormSelect
              title="Give intent recommendations"
              value="Yes"
              isSelected={agent?.settings.giveIntentRecommendations}
              handleChange={handleGiveIntentRecommendationsChange}
            />

            <FormSelect
              title="Always create a ticket. (also when user abrupt hangup)"
              value="Yes"
              isSelected={agent?.settings.alwaysCreateTicket}
              handleChange={handleAlwasyCreateTicketChange}
            />

            <FormSelect
              title="Handover to a human"
              value="Yes"
              isSelected={agent?.settings.handoverActivated}
              handleChange={handleHandoverActivatedChange}
            />

            <FormSelect
              title="Agent is only doing product advice flows"
              value="Yes"
              isSelected={agent?.settings.isProductAdviceAgent}
              handleChange={handleIsProductAdviceAgentChange}
            />

            <FormSelect
              title="Disable deletion of the agent"
              value="Yes"
              isSelected={agent?.settings.disableDeletion}
              handleChange={handleDisableDeletionChange}
            />

            <FormSelect
              title="Enhanced intent recognition"
              value="Yes"
              isSelected={agent?.settings.enhancedIntentRecognition}
              handleChange={handleEnhancedIntentRecognitionChange}
            />
          </div>

          {agent?.settings.alwaysCreateTicket && company && (
            <div className={styles.chatboxOptionLine}>
              <div className={`${styles.chatboxOptionDescription}`}>
                Always create ticket integration
              </div>
              <div className={styles.chatboxOptionSelector}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small">Integration</InputLabel>
                  <Select
                    native
                    defaultValue=""
                    labelId="demo-select-small"
                    id="grouped-native-select"
                    value={agent?.settings?.alwaysCreateTicketIntegration}
                    label="Webhook"
                    onChange={handleAlwaysCreateTicketIntegrationChange}
                  >
                    <option value="">
                      <em></em>
                    </option>
                    {Object.entries(company.installedIntegrations)
                      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                      .map((obj) => (
                        <optgroup key={obj[0]} label={obj[0]}>
                          {company.installedIntegrations[obj[0]].map(
                            (integrationHook) => (
                              <option
                                key={obj[0] + "|" + integrationHook}
                                value={obj[0] + "|" + integrationHook}
                              >
                                {integrationHook}
                              </option>
                            )
                          )}
                        </optgroup>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          {agent?.settings.handoverActivated && (
            <>
              <div className={styles.workingHoursConfiguratorHeader}>
                Working hours
              </div>
              <div className={styles.workingHoursConfigurator}>
                {Object.entries(agent?.humanHandoverSettings?.workingHours)
                  .sort((a, b) => days.indexOf(a[0]) - days.indexOf(b[0]))
                  .map((entry) => {
                    const [key, value] = entry;
                    return (
                      <div key={key} className={styles.workingHoursRow}>
                        <div className={styles.dayLabel}>{key}</div>
                        <div className={styles.isWorkingDay}>
                          <FormSelect
                            value="Open"
                            isSelected={value.isWorkingDay}
                            handleChange={(e) => setWorkingday(key, e)}
                          />
                        </div>
                        <div className={styles.workingDayFrom}>
                          {value.isWorkingDay && (
                            <div className={styles.workingHoursRow}>
                              <div className={styles.workingLabel}>from:</div>
                              <TimePicker
                                locale="nl-NL"
                                disableClock={true}
                                onChange={(e) => setTime("from", key, e)}
                                value={value.from}
                                format={"HH:mm"}
                                maxDetail="minute"
                              />
                            </div>
                          )}
                        </div>
                        <div className={styles.workingDayTo}>
                          {value.isWorkingDay && (
                            <div className={styles.workingHoursRow}>
                              <div className={styles.workingLabel}>to:</div>
                              <TimePicker
                                locale="nl-NL"
                                disableClock={true}
                                onChange={(e) => setTime("to", key, e)}
                                value={value.to}
                                format={"HH:mm"}
                                maxDetail="minute"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
          <div className={styles.formElementContainer}>
            <div className={styles.dropdownContainer}>
              <div className={styles.dropdownHeader}>Whatsapp:</div>
              <FormText
                title="Whatsapp PhoneId"
                formId="PhoneId"
                value={agent?.whatsappPhoneId}
                handleChange={handleWhatsappPhoneIdChange}
              />
              <FormText
                title="Whatsapp Token"
                formId="Token"
                value={agent?.whatsappPhoneToken}
                handleChange={handleWhatsappPhoneTokenChange}
              />
              <FormText
                title="Whatsapp SessionTimeout (in seconds)"
                formId="SessionTimeout"
                value={agent?.whatsappPhoneSessionTimeout}
                handleChange={handleWhatsappSessionTimeoutChange}
              />
            </div>
          </div>

          <div className={styles.dropdownContainer}>
            <div className={styles.dropdownHeader}>Human handover:</div>
            <DropdownSearch
              data={humanHanoverOptions()}
              type="human handover type"
              selected={{ name: agent?.humanHandoverSettings?.type }}
              setSelected={handleHandoverChange}
            />
          </div>
          <div className={styles.humanHandoverSettings}>
            {agent?.humanHandoverSettings?.type == "SIP" && (
              <>
                <div className={`${styles.formRow} ${styles.noGap}`}>
                  <FormText
                    title="Sip username (refer as SIP_USERNAME)"
                    formId="SipUsername"
                    value={agent?.humanHandoverSettings?.username}
                    handleChange={handleHandoverUsernameChange}
                  />
                  <FormText
                    title="Sip password (refer as SIP_PASSWORD)"
                    formId="SipPassword"
                    value={agent?.humanHandoverSettings?.password}
                    handleChange={handleHandoverPasswordChange}
                  />
                </div>
                <FormText
                  title="Sip connectionstring (example: PHONE_QUEUE*TICKET_NUMBER@wwl.wildixin.com"
                  formId="SipConnectionString"
                  value={agent?.humanHandoverSettings?.connectionString}
                  handleChange={handleHandoverConnectionStringChange}
                />
              </>
            )}

            <div className={styles.dropdownContainer}>
              <div className={styles.dropdownHeader}>
                Chat handover channel:
              </div>
              <DropdownSearch
                data={chatHandoverChannelOptions()}
                type="chat handover channel"
                selected={{
                  name: agent?.humanHandoverSettings?.chatHandoverChannel,
                }}
                setSelected={handleChatHandoverChannelChange}
              />
            </div>

            {agent?.humanHandoverSettings?.chatHandoverChannel == "Zendesk" && (
              <div className={styles.formElementContainer}>
                <div className={styles.dropdownContainer}>
                  <div className={styles.dropdownHeader}>Zendesk:</div>
                  <FormText
                    title="Zendesk AppId"
                    formId="AppId"
                    value={agent?.zendeskAppId}
                    handleChange={handleZendeskAppIdChange}
                  />
                  <FormText
                    title="Zendesk Token"
                    formId="Token"
                    value={agent?.zendeskToken}
                    handleChange={handleZendeskTokenChange}
                  />
                </div>
              </div>
            )}

            <div className={styles.secretsContainerHeader}>
              <FormSelect
                title="Create ticket before handover (refer as TICKET_NUMBER)"
                value="Yes"
                isSelected={
                  agent?.humanHandoverSettings?.createTicketBeforeHandover
                }
                handleChange={handleHandoverCreateTicketBeforeHandoverChange}
              />
              <div className={styles.chatboxOptionLine}>
                <div className={`${styles.chatboxOptionDescription}`}>
                  Trigger phonequeue integration
                </div>
                <div className={styles.chatboxOptionSelector}>
                  {company && (
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small">
                        Phonequeue Integration
                      </InputLabel>
                      <Select
                        native
                        defaultValue=""
                        labelId="demo-select-small"
                        id="grouped-native-select"
                        value={agent?.humanHandoverSettings.invokeWebhook}
                        label="Webhook"
                        onChange={handleInvokeWebhookChange}
                      >
                        <option value="">
                          <em></em>
                        </option>
                        {Object.entries(company.installedIntegrations)
                          .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                          .map((obj) => (
                            <optgroup key={obj[0]} label={obj[0]}>
                              {company.installedIntegrations[obj[0]].map(
                                (integrationHook) => (
                                  <option
                                    key={obj[0] + "|" + integrationHook}
                                    value={obj[0] + "|" + integrationHook}
                                  >
                                    {integrationHook}
                                  </option>
                                )
                              )}
                            </optgroup>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </div>

              <div className={styles.dropdownContainer}>
                <div className={styles.dropdownHeader}>Phonequeues:</div>
                <DropdownSearch
                  data={Object.keys(
                    agent.humanHandoverSettings.phoneQueues
                  ).map((key) => {
                    return { name: key };
                  })}
                  type="phonequeue"
                  selected={{ name: selectedPhoneQueue?.name }}
                  setSelected={setSelectedPhoneQueueValue}
                />
                <button
                  onClick={addNewPhoneQueue}
                  className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.newSecretButton}`}
                >
                  New
                </button>
                {selectedPhoneQueue && (
                  <button
                    onClick={deletePhoneQueue}
                    className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.newSecretButton}`}
                  >
                    Delete
                  </button>
                )}
              </div>
              {selectedPhoneQueue != null && (
                <div className={styles.marginTop}>
                  <div className={`${styles.formRow} ${styles.newRow}`}>
                    <FormText
                      title="phoneQueueName (refer as PHONE_QUEUE)"
                      formId="phoneQueueName"
                      value={selectedPhoneQueue?.name}
                      handleChange={updatePhoneQueueName}
                    />
                    <div className={styles.rightPart}>
                      <FormSelect
                        title=""
                        value="Is default queue"
                        isSelected={
                          agent?.humanHandoverSettings.defaultHandoverQueue ==
                          selectedPhoneQueue?.name
                        }
                        handleChange={handleHandoverDefaultQueueChange}
                      />
                    </div>
                  </div>
                  <span className={styles.infoMessage}>
                    Phone queues need a pipe sign for a phonequeue mapping. If
                    no mapping is needed then both sides of the pipe need to be
                    equal: Example: 116|116
                  </span>
                  <div className={`${styles.formRow} ${styles.newRow}`}>
                    {Object.entries(agent.languages)
                      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                      .map((key) => (
                        <div key={key[0]} className={styles.infoValueRow}>
                          <FormText
                            title={key[0]}
                            formId={key[0]}
                            value={selectedPhoneQueue.values[key[0]] || ""}
                            handleChange={updatePhoneQueueLanguageValue}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttons}>
            {!loadingState && (
              <>
                <button
                  disabled={
                    agent.name == "" ||
                    agent.company == null ||
                    flow == null ||
                    !createTicketIntegrationIsSet()
                  }
                  onClick={submit}
                  className={`${styles.buttonStyle} ${styles.submitButton} ${
                    agent.name == "" ||
                    flow == null ||
                    agent.company == null ||
                    !createTicketIntegrationIsSet()
                      ? styles.disabledButton
                      : null
                  }`}
                >
                  {isNewAgent ? "Create" : "Update"}
                </button>
                {!isNewAgent && !deletionDisabled && (
                  <button
                    onClick={deleteAgentAction}
                    className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}
                  >
                    Delete
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialogDisagree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete agent confirmation!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete agent: {agent?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDisagree} autoFocus>
            No
          </Button>
          <Button onClick={handleCloseDialogAgree}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CreateAgentPanel;
