import React, { useState, useCallback } from 'react';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import styles from 'styles/global/tableGrid.module.css';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import dateFormat, { masks } from "dateformat";
import Projects from './realtimeCallbackDB';
import moment from 'moment';


const stateOptions = [{ value: 0, label: 'New' },
{ value: 1, label: 'In progress' },
{ value: 2, label: 'Not answered' },
{ value: 3, label: 'Solved' }];

  
const getStateEnumLabel = (value) =>stateOptions.find(x => x.value === value).label

const columns = [
  { field: 'id', headerName: 'ID', flex:0.5, hide:true },
  { field: 'ticketNumber', headerName: 'TicketNumber', flex:1 },
  { field: 'phonenumber', headerName: 'Phonenumber', flex:1, 
      renderCell: ({id, value}) => (<a href={"tel:"+value}>{value}</a>)},
  { field: 'category', headerName: 'Category', flex:2 },
  { field: 'question', headerName: 'Question', flex:4},
  { field: 'ordernumber', headerName: 'Ordernumber', flex:1 },
  { field: 'comment', headerName: 'Comment', flex:3, editable: true },
  { field: 'date', headerName: 'Date', flex:1 , valueFormatter: params =>  moment(params?.value, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD")},
  { field: 'time', headerName: 'Time', flex:1 },

  { field: 'language', headerName: 'Language', flex:1 },
  { field: 'timestamp', headerName: 'Timestamp', hide: true }, 
  { field: 'state', headerName: 'State', flex:1,  editable: true, type: "singleSelect",
      valueOptions: () => stateOptions, 
      valueFormatter:  ({ id, value })  => getStateEnumLabel(value), 
      cellClassName: (params) =>
        clsx({
          "cell-style--new": params.value == 0,
          "cell-style--inprogress": params.value == 1,
          "cell-style--notanswered": params.value == 2,
          "cell-style--solved": params.value == 3
        }),
  }
];

export default function TableGrid({rows, update}) {
  

const [sortModel, setSortModel] = React.useState([
  {
    field: 'timestamp',
    sort: 'desc',
  },
]);
const [editRowData, setEditRowData] = React.useState({});

const setUpdatedRowsState = React.useCallback(
  (model) => {
    const editedIds = Object.keys(model);

    // user stops editing when the edit model is empty
    if (editedIds.length === 0) {

      //update on firebase
      update(editRowData)

    } else {
      setEditRowData({id:editedIds[0], model: model[editedIds[0]]});
    }
  },
  [editRowData]
);

  return (
    <div style={{ height: '100%', width: '100%', padding:'10px 0' }}>
<Box
      sx={{
        height: '100%',
        width: '100%',
        '& .row-style--new': {
          //bgcolor: (theme) => "#D3E1FC",
          borderLeft: "5px solid #D3E1FC",
          '&:hover': {
            bgcolor: (theme) =>"#D3E1FC"
          },
        },
        '& .cell-style--new': {
          bgcolor: (theme) => "#D3E1FC"
        },
        '& .row-style--inprogress': {
          //bgcolor: (theme) => "#D9F3EA",
          borderLeft: "5px solid #D9F3EA",
          '&:hover': {
            bgcolor: (theme) => "#D9F3EA"
          },
        },
        '& .cell-style--inprogress': {
          bgcolor: (theme) => "#D9F3EA"
        },
        '& .row-style--notanswered': {
          //bgcolor: (theme) => "#FDF3D1",
          borderLeft: "5px solid #FDF3D1",
          '&:hover': {
            bgcolor: (theme) => "#FDF3D1",
          },
        },
        '& .cell-style--notanswered': {
          bgcolor: (theme) => "#FDF3D1"
        },
        '& .row-style--solved': {
          bgcolor: (theme) => "#f7f9fa",
          color: "#b1b2b3",
          borderLeft: "5px solid #f7f9fa",
          '&:hover': {
            bgcolor: (theme) => "#f7f9fa"
        },
        }, 
        '& .cell-style--solved': {
          bgcolor: (theme) => "#f7f9fa"
        }
      }}
    >

      <DataGrid
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        pageSize={100}
        getRowId={(row) => row.id}
        rowsPerPageOptions={[20, 50, 100]}
        rowHeight={25}
        getRowClassName={(params) => `row-style--${getStateEnumLabel(params.row.state).replace(" ","").toLowerCase()}`}
        disableSelectionOnClick
        onEditRowsModelChange={(state) => setUpdatedRowsState(state)} 
        //checkboxSelection
      />
    </Box>
    </div>
  );
}