import React, {useState, useEffect, useMemo} from 'react';
import { useQuery } from '@tanstack/react-query';
import {getApiAgents} from '../../../services/agentService';
import styles from 'styles/admin/agent/agentList.module.css';
import AgentListRow from "./agentListRow";
import NewAgentWizard from "./newAgentWizard";
import {useAuth} from '../../../contexts/AuthContext'
import {getCustomerIntents} from "../../../services/intentService";
import { useAgentContext } from 'contexts/AgentContext';

const AgentList = ({setCategory, category}) => {
    const { agent } = useAgentContext();
    const {currentUser} = useAuth();

    const {isError, isSuccess, isLoading, data, error} = useQuery(
        ["customerAgentIntents", agent?.agentCatalogName],
        () => getCustomerIntents(agent?.agentCatalogName),
        {staleTime: 60000}
    );
    const intents = data;

    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const filteredCategories = useMemo(() => {
        // Create copy of item list
        var updatedList = [...categories];
        // Include all elements which includes the search query
        return updatedList.filter((item) => {
            return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
    },[categories, searchQuery]);

    const [createNewAgentState, setCreateNewAgentState] = useState(false);


    useEffect(() => {
        setCategories([]);
        getIntentCategories();
    }, [agent, intents]);

    const getIntentCategories = async () => {
        if(agent === undefined || intents === undefined)
            return;

        let updatedIntentList = [...intents.filter(x => x.channels?.indexOf(agent.idName+"_"+agent.agentType) > -1)];

        let cats = updatedIntentList.map((intent) => {return  {name: intent.category, numberOfIntents: updatedIntentList.filter(y => y.category === intent.category).length}});
        cats.push({name: "All categories", numberOfIntents: updatedIntentList.length});
        let sortedCats = [...new Map(cats.map((item) => [item.name, item])).values()];

        setCategories(sortedCats);
        setCategory(sortedCats.filter(x => x.name === "All categories")[0]);
    };

    const createNewAgent = () => {
        setCreateNewAgentState(!createNewAgentState);
    }

    const selectCategory = (category) => {
        setCategory(category);
    }

    return (
        <>
            <div className={`${styles.flipCardInner} ${createNewAgentState ? styles.flipped : ''}`}>

                <div className={styles.flipCardFront}>

                    <div className={styles.agentList}>
                        {/*<div onClick={createNewAgent} className={styles.createAgentButton}>Nieuwe agent aanmaken</div>*/}


                        <div className={styles.list}>
                            <input value={searchQuery} placeholder="search" onChange={(e) => setSearchQuery(e.target.value)} className={styles.newAgentSearchInput}
                                   type="text"/>
                            {/*<div className={styles.agentNumber}>{filteredAgents.length} assistent{filteredAgents.length > 1 ? "en" : ""}</div>*/}

                            {
                                Array.isArray(filteredCategories) ?
                                    filteredCategories.sort((a, b) => (a.name > b.name) ? 1 : -1).map((cat) => (
                                        <AgentListRow key={cat.name} category={cat} parentSelectCategory={selectCategory}
                                                      selectedCategory={category}/>
                                    )) : null
                            }

                        </div>
                    </div>
                </div>
                <div className={styles.flipCardBack}>
                    <NewAgentWizard createNewAgent={createNewAgent}/>
                </div>
            </div>
        </>
    );
}
export default AgentList;
