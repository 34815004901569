import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import styles from 'styles/admin/optimizationReports/optimizationReports.module.css';

import TableGrid from 'components/global/tableGrid';
import { ConstructionOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import dateFormat, { masks } from "dateformat";
import { IoClose } from 'react-icons/io5';
import moment from 'moment'
import { updateUserActionHistoryList } from 'services/userService';
import { useAuth } from 'contexts/AuthContext';
import { getLogging } from 'services/loggingService';


const columns = [
    { field: 'id', headerName: 'Id', flex:0.5, hide: true},
    { field: 'user', headerName: 'User', flex:2 },
    { field: 'agent', headerName: 'Agent', flex:2},
    { field: 'action', headerName: 'Action', flex:2 },
    { field: 'parsedData', headerName: 'DateTime', flex:1 },
  ];
 

const Logging = () => {
    const { currentUser } = useAuth();

    const [logging, setLogging] = useState([]);
    useEffect(()=>{
        getLogging().then(res => {
            setLogging(res);
        })
    },[])


    const [sortModel, setSortModel] = React.useState([
        {
          field: 'parsedData',
          sort: 'desc',
        },
      ]);

      const updateSort = (model) => {
        setSortModel(model)
      }

    return (
        <div className={`${styles.callback}`}>
            <div className={`${styles.header} ${styles.widget}`}>
                <h1>Logging</h1>
                <h3>User actions</h3>
            </div>
            <div className={`${styles.optionMenu} ${styles.widget}`}>
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                }}
                >

                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                       outline: "none !important",
                    },
                 }}
                    rows={logging}
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={(model) => updateSort(model)}
                    pageSize={100}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[20, 50, 100]}
                    rowHeight={25}
                />
                </Box>
            </div>
        </div>

        
    )

}
export default Logging;
