import React, {useEffect, useState} from 'react';
import { AgentProvider } from 'contexts/AgentContext';
import {
    QueryClientProvider,
  } from '@tanstack/react-query'


import styles from 'styles/admin/agent/agents.module.css';


import AgentList from './agentList';
import AgentInfo from './agentInfo';
import Intents from './intents';
import IntentSelector from "./intentSelector";
import EditIntent from "./editIntent";
import Chatbox from "./chatbox";
import { useQueryClient } from 'components/queryClients/queryClient';
import AgentLiveInfo from './agentLiveInfo';



const Agents = () => {
    const queryClient = useQueryClient();
    const [category, setCategory] = useState();

    return (
        <AgentProvider>
            <QueryClientProvider client={queryClient}>
            <div className={`${styles.agent}`}>
                <div className={`${styles.header} ${styles.widget}`}>
                    <div className={styles.headerText}>
                        <h1>Digital assistant</h1>
                        <h3>Configure your digital assistant.</h3>
                    </div>
                    <div className={styles.headerMenu}>
                        <AgentLiveInfo/>
                    </div>
                </div>

                <div className={`${styles.optionMenu} ${styles.widget}`}>
                    <AgentInfo/>
                </div>

                <div className={`${styles.agentsContainer} ${styles.widget}`}>
                    <AgentList setCategory={setCategory} category={category}/>
                </div>

                <div className={`${styles.intents} ${styles.widget}`}>
                    <Intents category={category}/>
                </div>
                <div className={`${styles.editIntent} ${styles.widget}`}>
                    <EditIntent category={category}/>
                </div>

                <div className={`${styles.addIntentContainer} ${styles.widget}`}>
                    <IntentSelector/>
                </div>
                <Chatbox/>
            </div>
            </QueryClientProvider>
        </AgentProvider>
    )
}
export default Agents;
