import React, {useEffect, useContext, useMemo, useState} from 'react';

import styles from 'styles/admin/agent/intents.module.css';
import {getAllCoreIntents, getCustomerIntents} from "../../../services/intentService";
import {BsArrowsExpand} from "react-icons/bs";
import {IoClose} from "react-icons/io5";
import {BsCheck} from "react-icons/bs";

import Intent from './intent';
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { useAgentContext } from 'contexts/AgentContext';
import FirstAgentWizard from './firstAgentWizard';
import { Dialog, DialogContent } from '@mui/material';

const Intents = ({category}) => {

    const { agent, selectedIntent, refetchIntents } = useAgentContext();
    const [searchQuery, setSearchQuery] = useState("");
    const [coreIntents, setCoreIntents] = useState();
    const [selectedStateFilter, setSelectedStateFilter] = useState(["ready","inProgress"]);
    const [isActiveFilter, setIsActiveFilter] = useState(null);
    const [sortBy, setSortBy] = useState('invocations');
    const [reverseListOrder, setReverseListOrder] = useState(true);
    const [orderedList, setOrderedList] = useState([]);
    const [firstAgentWizardPopup, setFirstAgentWizardPopup] = useState(false);
    
    
    const [isPlaying, setIsPlaying] = useState();

    const [collapseToggleState, setCollapseToggleState] = useState(false);

    const {isError, isSuccess, isLoading, data, error, refetch} = useQuery(
        ["customerAgentIntents", agent?.agentCatalogName],
        () => getCustomerIntents(agent?.agentCatalogName),
        {staleTime: 60000}
    );
    const intents = data;

    useEffect(() => {
        refetch();
    }, [refetchIntents]);

    useEffect(() => {
        getAllCoreIntents().then(x => {
            setCoreIntents(x);
        })
    }, []);

    
    const setSort = (prop) => {

        if(sortBy == prop){
            setReverseListOrder(!reverseListOrder);
        }else{
            setSortBy(prop);
        }
    }


    const doReverse = () => {
        var result = filteredIntents?.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1);
        setOrderedList(reverseListOrder ? result?.reverse() : result);
    };

    const filteredIntents = useMemo(() => {
        if(intents == null || category === undefined)
            return;

        // Create copy of item list
        let updatedList = [...intents.filter(x => {
            if( x.channels?.indexOf(agent.idName+"_"+agent.agentType) > -1){
                return x;
            }
        })];

        if(category?.name !== "All categories") {
            updatedList = updatedList.filter(x => x.category.toLowerCase() === category.name.toLowerCase());
        }

        // loop over each agent language to search in all languages
        let filteredList = Object.entries(agent.languages).map((key) => {
            return updatedList.filter((item) => {
                    return (
                        item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
                        item.id.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
                        item.exampleQuestion.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
                        item.content[key[0]].response.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1)
                }
            )
        });
        // flatten the arrays in to one array
        let flattenList = filteredList.flat(1);
        let flatten = [...new Set(flattenList)];
        
        let tempFilteredList = flatten.filter(x => selectedStateFilter.indexOf(x.status) > -1);
        if(isActiveFilter == null) {
            return tempFilteredList;//.filter(x => x.isActive == true || x.isActive == false);

        }else{
            return tempFilteredList.filter(x => isActiveFilter === x.isActive);
        }
    },[intents, category, searchQuery, selectedStateFilter, isActiveFilter]);

    
    useEffect(() => {
        doReverse();
    },[sortBy, reverseListOrder, intents, filteredIntents])

    const totalInvocations = intents?.map(x => x.invocations)?.reduce((a, c) => a + c, 0);

    
    const maxInvocations = intents?.map(x => x.invocations).sort().reverse()[0];

    
    const toggleStateSelector = (state) => {
        // if contains then remove
        if(selectedStateFilter.indexOf(state) > -1){
            let d = selectedStateFilter.filter(item => item !== state);
            setSelectedStateFilter(d);
        }
        // else add to array
        else{
            setSelectedStateFilter(selectedStateFilter => [... selectedStateFilter, state]);
        }
    }
    const toggleActiveSelector = () => {
        let value = null;
        if(isActiveFilter === true)
            value = false;

        if(isActiveFilter === false)
            value = null;

        if(isActiveFilter === null)
            value = true;

        setIsActiveFilter(value);
    }
    
    const stopSpeak = () => {
        var player = document.getElementById("audioPlayer");
        player.pause();
        setIsPlaying(null);
    }
    const startSpeak = (lang, text, id) => {
        setIsPlaying(id);
        let waveNet = lang === "nl" ? "nl-NL-Wavenet-E" : "de-DE-Wavenet-C";
        let language = lang.toUpperCase();
        let settings = {
            "audioConfig": {
                "audioEncoding": "LINEAR16",
                "pitch": 0,
                "speakingRate": 1
            },
            "input": {
                "ssml": text
            },
            "voice": {
                "languageCode": language,
                "name": waveNet,
                'ssmlGender': 'FEMALE'
            }
        };

        const doRequest = async () => {
            await axios.post(`https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyAFAKNgxTKPnCOKm1di80AFyWiCVkEgMsM`, settings)
                .then(res => {
                    let data = res.data;

                    var player = document.getElementById("audioPlayer");
                    player.src = "data:audio/wav;base64," + data.audioContent;
                    player.play();
                    player.addEventListener('ended', (event) => {
                        player.pause();
                        setIsPlaying(null);
                    });
                })
        }
        doRequest();
    }


    const filterBySearch = (event) => {
        // Access input value
        setSearchQuery(event.target.value);
    };

  
    const collapseToggle = () => {
        setCollapseToggleState(!collapseToggleState);
    }

    const clearSearch = () => {
        setSearchQuery("");
    }

    const handleClickCloseFirstAgentWizardPopup = () => {
        setFirstAgentWizardPopup(false);
    }
    return (
        <>
         {filteredIntents && coreIntents && (filteredIntents.length > 0 || searchQuery != "") && (
            <div className={styles.searchContainer}>
                <div onClick={() => setSort("name")} className={`${styles.gridName} ${styles.sortable}`}>Name
                    {/*<SlArrowDown className={styles.sortIcon}/>*/}
                    {/*<SlArrowDown className={styles.sortIcon}/>*/}
                </div>
                {!selectedIntent && (
                    <>
                        <div className={styles.gridSearch}>
                            <BsArrowsExpand onClick={collapseToggle} className={`${styles.expandIcon} ${collapseToggleState ? styles.expandIconActive : null}`}/>
                            <input id="searchIntents" placeholder="search" onChange={filterBySearch}
                                   className={styles.newAgentSearchInput} type="text" value={searchQuery}/>
                            <IoClose onClick={clearSearch} className={styles.clearSearch}/>

                            {/* <div className={styles.intentNumber}> */}
                                {/* <div className={styles.intentsTotal}> */}
                                    {/* <strong>{filteredIntents.length}</strong> intent{filteredIntents.length > 1 ? "s" : ""} */}
                                {/* </div> */}
                                {/* <div className={styles.intentsActive}>
                                    <div onClick={toggleActiveSelector} data-tooltip="This represents the active state of the intent" className={`${styles.statusIcon} ${styles[activeState()]}`}>
                                        {activeState() == "inActive" ? <IoClose/> : <BsCheck/>  }
                                        </div> {activeStateNumber()}
                                </div> */}
                                {/* <div onClick={() => toggleStateSelector("inProgress")} data-tooltip="This means that the intent needs some attention. It is possible that not all fields are filled in" className={`${styles.statusIcon} ${hasState("inProgress") ? styles.inProgress : null}`}><BsCheck/></div> {filteredIntents.filter(x => x.status === "inProgress").length}
                                <div onClick={() => toggleStateSelector("ready")} data-tooltip="This means that the intent is ready" className={`${styles.statusIcon} ${hasState("ready") ? styles.ready : null}`}><BsCheck/></div> {filteredIntents.filter(x => x.status === "ready").length} */}
                            {/* </div> */}
                        </div>
                        <div onClick={toggleActiveSelector} className={styles.gridStatus}>Active
                            {/*<SlArrowDown className={styles.sortIcon}/>*/}
                            {/*<SlArrowDown className={styles.sortIcon}/>*/}
                        </div>
                        <div onClick={() => setSort("invocations")} className={`${styles.gridPopularity} ${styles.sortable}`}>Popularity
                            {/*<SlArrowDown className={styles.sortIcon}/>*/}
                            {/*<SlArrowDown className={styles.sortIcon}/>*/}
                        </div>
                    </>
                )}

                {/* <div className={styles.gridEdit}>Edit</div> */}


            </div>
         )}
         
        {filteredIntents && filteredIntents.length == 0 && searchQuery == "" && (
            <div class={styles.whereToBegin}>
                {/* <h1>Where to begin</h1>
                <FirstAgentWizard close={handleClickCloseFirstAgentWizardPopup}/> */}
             </div>
        )}
            {
                Array.isArray(filteredIntents) ?
                    orderedList?.map((intent) => (
                        <Intent maxInvocations={maxInvocations} selectedIntent={selectedIntent} isPlaying={isPlaying} collapseToggleState={collapseToggleState}
                                startSpeakParent={startSpeak} stopSpeakParent={stopSpeak} key={agent.id + intent.id}
                                intent={intent} coreIntent={coreIntents?.filter(i => i.id == intent.id)[0]}/>
                    )) : null
            }
            <audio id="audioPlayer"/>


            <Dialog  fullWidth
                open={firstAgentWizardPopup} 
                 onClose={handleClickCloseFirstAgentWizardPopup}
                >
                <DialogContent>
                    <FirstAgentWizard close={handleClickCloseFirstAgentWizardPopup}/>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Intents;
