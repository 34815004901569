import DropdownSearch from 'components/global/dropdownSearch';
import React, { useEffect, useState } from 'react';

import styles from 'styles/admin/configuration/configuration.module.css';
import CreateAgentPanel from './CreateAgentPanel';
import CreateCompanyPanel from './CreateCompanyPanel';
import CreateUserPanel from './CreateUserPanel';
import {useAuth} from "../../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom'

const Configuration = () => {
    const { currentDbUser } = useAuth();
    const navigate = useNavigate();

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedAgent, setSelectedAgent] = useState(null);
    
    useEffect(()=>{
        if(currentDbUser == null)
            return;

        if(!currentDbUser.isSuperAdmin){
            navigate("/admin/agents");
        }
    },[currentDbUser]);
    
    return (
        <div className={`${styles.configuration}`}>
            <div className={`${styles.header} ${styles.widget}`}>
                <h1>Configuration</h1>
                <h3>Configurate users, customers and agents</h3>
            </div>

            <div className={`${styles.users} ${styles.widget}`}>
                    <CreateUserPanel/>
            </div>
            <div className={`${styles.customers} ${styles.widget}`}>
                <div className={styles.widgetHeader}>
                    <CreateCompanyPanel/>
                </div>
            </div>
            <div className={`${styles.agents} ${styles.widget}`}>
                <div className={styles.widgetHeader}>
                    <CreateAgentPanel/>
                </div>
            </div>
        </div>
    )

}
export default Configuration;
