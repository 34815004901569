import moment from 'moment';
import { db } from '../firebase.js'
import { collection, getDocs, doc,deleteDoc } from 'firebase/firestore'


const getOptimizations = async () => {
    const reference = collection(db, "optimizations");

    return await getDocs(reference).then((data) => {
        return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id, dateTime: moment(docSnap.data().dateTime, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") }));
    })
}
const deleteOptimization = async (id) => {
    return await deleteDoc(doc(db, "optimizations", id));
}

export {getOptimizations, deleteOptimization}
