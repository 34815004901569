const ACTIONS = {
    UPDATE_INTEGRATION: "UPDATE_INTEGRATION",
    UPDATE_NAME: "UPDATE_NAME",
    UPDATE_DESCRIPTION: "UPDATE_DESCRIPTION",
    UPDATE_REQUIRED_FIELDS: "UPDATE_REQUIRED_FIELDS",
    DELETE_REQUIRED_FIELDS: "DELETE_REQUIRED_FIELDS",
    UPDATE_ICON: "UPDATE_ICON"
}
const reader = new FileReader();

const IntegrationGeneralReducer = (state, action) => {
    switch(action.type){
        case ACTIONS.UPDATE_INTEGRATION:
            return updateIntegration(state, action);
        case ACTIONS.UPDATE_NAME:
            return updateName(state, action);
        case ACTIONS.UPDATE_DESCRIPTION:
            return updateDescription(state, action);
        case ACTIONS.UPDATE_REQUIRED_FIELDS:
            return updateRequiredFields(state, action);
        case ACTIONS.DELETE_REQUIRED_FIELDS:
            return deleteRequiredFields(state, action);
        case ACTIONS.UPDATE_ICON:
            return updateIcon(state, action);
        default:
            throw new Error();
    }
}

const updateIntegration = (state, action) => {return action.payload.integration};
const updateName = (state, action) => {return {...state, name: action.payload.name}};
const updateDescription = (state, action) => {return {...state, description: action.payload.description}};
const updateIcon = (state, action)  => {return {...state, icon: action.payload.icon};};

const updateRequiredFields = (state, action) => {
    var newState = {...state};
    var field = action.payload.field;
    newState.requiredFields[field.name] = field
    return newState;
};
const deleteRequiredFields = (state, action) => {
    var newState = {...state};
    var field = action.payload.field;
    delete newState.requiredFields[field.name];
    return newState;
};

export {ACTIONS, IntegrationGeneralReducer};