import React, { useEffect, useState, useRef, useMemo } from "react";
import { IoAdd, IoClose } from "react-icons/io5";
import { SlArrowDown } from "react-icons/sl";
import { HiSpeakerWave } from "react-icons/hi2";
import { FiCheck } from "react-icons/fi";
import { AiOutlineWarning } from "react-icons/ai";
import { IoMdInformationCircleOutline } from "react-icons/io";

import { MdSaveAlt, MdOutlineDelete } from "react-icons/md";
import { BsStopFill } from "react-icons/bs";

import styles from "styles/admin/agent/editIntent.module.css";
import ToggleSwitch from "../../global/toggleSwitch";
import {
  saveCustomerIntent,
  deleteCustomerIntent,
  getCustomerIntent,
  addCustomerIntent,
  updateCustomerIntent,
  getCustomerIntents,
  getDatasourceResponse,
  getAllCoreIntentsByCollection,
  getCoreIntent,
} from "../../../services/intentService";
import axios from "axios";
import { useAgentContext } from "contexts/AgentContext";
import { useQuery, useMutation } from "@tanstack/react-query";
import { confirm } from "react-confirm-box";

import Loading from "components/global/loading";
import { useQueryClient } from "components/queryClients/queryClient";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getAllIntegrations } from "services/integrationService";
import { ConversationDemoTranlations } from "./conversationDemoTranlations";
import { useProgressIndicatorContext } from "contexts/ProgressIndicatorContext";
import { updateUserActionHistoryList } from "services/userService";
import { useAuth } from "contexts/AuthContext";
import { getGptTemplatesService } from "services/gptService";
import { getApiCompany } from "services/companyService";
import ReactSelect from "react-select";
import channels from "constants/channels";
import { getCatalogService } from "services/catalogService";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  getFlowService,
  getFlowsService,
  getFlowsServiceInRange,
} from "services/flowService";
import {
  getApiAgents,
  getApiAgentsByIdName,
  getApiAgentsByAgentCatalogNameNameAndChannel,
  getApiAgentsByAgentCatalogName,
} from "services/agentService";
import { getDatasourceCollectionsByCompany } from "services/datasourceService";
import { Button } from "react-bootstrap";

const EditIntent = () => {
  const { currentUser } = useAuth();
  const {
    setIndicatorState,
    resetIndicatorState,
    setInfoMessage,
    loadingState,
  } = useProgressIndicatorContext();
  const queryClient = useQueryClient();
  const { agent, setContextSelectedIntent, selectedIntent } = useAgentContext();
  const inputTextArea = useRef(null);
  const smsTextArea = useRef(null);
  const maxLength = 400;
  const [isPlaying, setIsPlaying] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [disableRequestOrdernumber, setDisableRequestOrdernumber] =
    useState(false);
  const [coreIntent, setCoreIntent] = useState();
  const [intent, setIntent] = useState([]);
  const [intents, setIntents] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [invokeWebhook, SetInvokeWebhook] = useState("");
  const [gptTemplates, setGptTemplates] = useState(null);
  const conversationDemoTranlations = new ConversationDemoTranlations();

  const [response, setResponse] = useState();
  const [company, setCompany] = useState();
  const [integrations, setIntegrations] = useState();
  const [smsResponse, setSmsResponse] = useState();
  const [customChannels, setCustomChannels] = useState();

  const [sendSms, setSendSms] = useState(false);
  const [disableEndingSelector, setDisableEndingSelector] = useState(false);

  const [channels, setChannels] = useState([]);
  const [dbCatalog, setDbCatalog] = useState();
  const [agentCatalog, setAgentCatalog] = useState([]);
  const [flows, setFlows] = useState([]);
  const [datasources, setDatasources] = useState([]);
  const [warningShowed, setWarningShowed] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    if (
      intent != null &&
      selectedIntent !== null &&
      selectedIntent !== undefined
    ) {
      getIntent();
      getIntents();
      getChannels(selectedIntent.id);
      setDisableRequestOrdernumber(false);
    }
  }, [selectedIntent]);

  useEffect(() => {
    if (agent != null) {
      let defaultLanguage = Object.entries(agent.languages)[0][0];
      defaultLanguage = "nl";
      setSelectedLanguage(defaultLanguage);
      getCompany();
      getIntegrations();
      getDatasources();
    }
  }, [agent]);

  useEffect(() => {
    if (flows[0] != null) {
      getCatalog();
    }
  }, [flows]);

  const getCompany = async () => {
    let comp = await getApiCompany(agent.companyId);
    setCompany(comp);
    getGptTemplates(comp);
  };

  const getIntegrations = async () => {
    var integrations = await getAllIntegrations();
    setIntegrations(integrations);
  };

  const filteredIntegrations = useMemo(() => {
    if (company == null || integrations == null) {
      return;
    }

    Object.entries(company.installedIntegrations).map((i) => {
      var compIntegration = Object.entries(company.integrations).filter(
        (u) => u[0] == i[0] && u[1].catalogId == flows[0]?.catalogId
      );
      var generalIntegration = Object.entries(integrations).filter(
        (u) => u[0] == i[0] && u[1].catalogId == flows[0]?.catalogId
      );
      if (compIntegration == null && generalIntegration == null) {
        delete company.installedIntegrations[i[0]];
      }
    });
    return company.installedIntegrations;
  }, [company, integrations]);

  const getChannels = async (intRowId) => {
    var customerIntents = await getCustomerIntent(
      agent?.agentCatalogName,
      intRowId
    );
    var ch = customerIntents.map((x) => x.firebaseId);
    setChannels(ch);
  };

  const getIntents = async () => {
    getAllCoreIntentsByCollection(agent.collection).then((x) => {
      setIntents(x);
    });
  };

  const getDatasources = () => {
    getDatasourceCollectionsByCompany(agent.companyId).then((x) => {
      setDatasources(
        x.map((s) => {
          if (s.agentId == "" || s.agentId == agent.id) {
            return s;
          }
        })
      );
    });
  };
  const handleDatasourceChange = (e) => {
    setIntent((prev) => ({ ...prev, datasource: e.target.value }));
  };

  const getIntent = async () => {
    setWarningShowed(false);
    //let retrievedIntent = await getApiCustomerIntent(selectedIntent.id, agent.name);

    var catalogIntent = await getCoreIntent(selectedIntent.id);
    setCoreIntent(catalogIntent);

    setIntent(selectedIntent);
    setResponse(selectedIntent.content[selectedLanguage].response);
    setSmsResponse(selectedIntent.content[selectedLanguage].smsResponse);

    getSameAgents(selectedIntent.channels);
    getCatalog();

    if (
      selectedIntent.invokeWebhook != "" &&
      selectedIntent.invokeWebhook != null
    ) {
      SetInvokeWebhook(selectedIntent.invokeWebhook || "");
      const [integrationName, integrationHook] =
        selectedIntent.invokeWebhook?.split("|");
      var integration = await getRightIntegration(
        integrationName,
        integrationHook
      );
      if (integration.settings.ordernumberRequired) {
        setDisableRequestOrdernumber(true);
      }
    } else {
      SetInvokeWebhook("");
    }
  };

  const openDropDownSearch = () => {
    setMenuOpen(!menuOpen);
  };

  const getGptTemplates = (comp) => {
    getGptTemplatesService(comp?.companyName).then((x) => {
      setGptTemplates(x);
    });
  };

  const stopSpeak = () => {
    var player = document.getElementById("audioPlayer");
    player.pause();
    setIsPlaying(false);
  };
  const startSpeak = () => {
    setIsPlaying(true);
    let waveNet = "nl-NL-Wavenet-E";
    let languageCode = "nl-NL";
    if (selectedLanguage == "de") {
      waveNet = "de-DE-Wavenet-C";
      languageCode = "de-DE";
    }
    if (selectedLanguage == "en") {
      waveNet = "en-US-Neural2-F";
      languageCode = "en-us";
    }
    let settings = {
      audioConfig: {
        audioEncoding: "LINEAR16",
        pitch: 0,
        speakingRate: 1,
      },
      input: {
        ssml: response,
      },
      voice: {
        languageCode: languageCode,
        name: waveNet,
        // 'ssmlGender': 'FEMALE'
      },
    };
    const doRequest = async () => {
      await axios
        .post(
          `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyAFAKNgxTKPnCOKm1di80AFyWiCVkEgMsM`,
          settings
        )
        .then((res) => {
          let data = res.data;

          var player = document.getElementById("audioPlayer");
          player.src = "data:audio/wav;base64," + data.audioContent;
          player.play();
          player.addEventListener("ended", (event) => {
            player.pause();
            setIsPlaying(null);
          });
        });
    };
    doRequest();
  };
  const getRightIntegration = async (integrationName, integrationHook) => {
    var integration =
      company.integrations[integrationName]?.hooks[integrationHook];
    // if integration == null, check if integration exist for specific
    if (integration == null) {
      integration =
        company.integrations[integrationName]?.hooks[
          integrationHook + "_" + selectedLanguage.toUpperCase()
        ];
    }

    if (integration == null) {
      var integrations = await getAllIntegrations();
      var integration = integrations.filter(
        (x) => x.name == integrationName
      )[0];
      integration = integration.hooks[integrationHook];
    }
    return integration;
  };
  const handleDivergentHandoverPhoneQueueChange = (event) => {
    let val = event.target.value;
    setIntent({ ...intent, divergentHandoverQueue: val });
  };
  const handleGptIntegrationChange = (event) => {
    let val = event.target.value;
    setIntent({ ...intent, gptIntegration: val });
  };
  const handleInvokeIntentChange = async (event) => {
    let val = event.target.value;
    setIntent({ ...intent, invokeIntent: val });
  };
  const handleInvokeWebhookChange = async (event) => {
    let val = event.target.value;

    SetInvokeWebhook(val);
    if (val == "") {
      setDisableEndingSelector(false);
      setDisableRequestOrdernumber(false);
      setChecked(false, "yN0V56DfqicnhkASn28y");
    } else {
      setDisableEndingSelector(true);
      const [integrationName, integrationHook] = val.split("|");
      SetInvokeWebhook(integrationName + "|" + integrationHook);
      var integration = await getRightIntegration(
        integrationName,
        integrationHook
      );

      if (integration != null) {
        if (integration.settings.ordernumberRequired) {
          setChecked(true, "yN0V56DfqicnhkASn28y");
          setDisableRequestOrdernumber(true);
        } else {
          setChecked(false, "yN0V56DfqicnhkASn28y");
          setDisableRequestOrdernumber(false);
        }
      }
    }
  };

  useEffect(() => {
    if (disableEndingSelector) {
      setIntent((prev) => ({
        ...prev,
        customFields: prev.customFields.map((x) =>
          x.type != "ending" ? x : { ...x, checked: false }
        ),
      }));
    }
  }, [disableEndingSelector]);

  const saveDialog = async () => {
    var currentChannel = agent.idName + "_" + agent.agentType;
    if (intent.channels.filter((x) => x != currentChannel).length > 0) {
      let dialogResponse = await confirm(
        "Dit is een gedeelde intent over meerdere agents. Weet je zeker dat je deze wilt opslaan voor alle agents?"
      );
      if (dialogResponse) {
        save();
      }
    } else {
      save();
    }
  };

  const save = async () => {
    intent.content[selectedLanguage].response = response;
    intent.content[selectedLanguage].smsResponse = smsResponse;
    intent.invokeWebhook = invokeWebhook;

    setIntent(intent);

    let smsValueMissing = false;
    Object.entries(agent.languages).forEach((x) => {
      if (
        intent.content[x[0]].smsResponse?.trim() === "" ||
        intent.content[x[0]].smsResponse === null ||
        intent.content[x[0]].smsResponse === undefined
      ) {
        smsValueMissing = true;
      }
    });
    if (
      smsValueMissing &&
      intent.customFields.filter((x) => x.id == "LP0y01PrAY5LCQpY3DOR")[0]
        .checked
    ) {
      alert(
        "Sms text is required in all languages when this option is checked!"
      );
      return;
    }

    let isValid = true;
    Object.entries(agent.languages).forEach((x) => {
      if (
        intent.content[x[0]].response.trim() === "" ||
        intent.content[x[0]].response === null ||
        intent.content[x[0]].response === undefined
      ) {
        isValid = false;
      }
    });

    intent.status = isValid ? "ready" : "inProgress";

    UpdateIntent();
  };

  const UpdateIntent = () => {
    intentUpdateMutation.mutate();
    updateUserActionHistoryList(
      currentUser.uid,
      agent.name,
      'Updated intent: "' + intent.name + '"'
    );
  };
  const DeleteIntent = async () => {
    const areYouSureConfirm = await confirm(
      "Are you sure you want to delete this intent from your agent?"
    );
    if (areYouSureConfirm) {
      var currentChannel = agent.idName + "_" + agent.agentType;
      var intentl = { ...intent };
      intentl.channels = [
        ...intentl.channels.filter((x) => x != currentChannel),
      ];
      if (intentl.channels.length == 0) {
        intentDeleteMutation.mutate();
      } else {
        setIntent(intentl);
        updateCustomerIntent(intentl, agent?.agentCatalogName);
        setContextSelectedIntent(intentl);
      }
      close();

      updateUserActionHistoryList(
        currentUser.uid,
        agent.name,
        "Deleted channel " + currentChannel + " from " + intent.name
      );
    }
  };

  const intentDeleteMutation = useMutation(
    () => {
      setIndicatorState("saving", null);
      deleteCustomerIntent(intent, agent.agentCatalogName);
    },
    {
      onSuccess: () => {
        resetIndicatorState("Intent succesfully deleted.");
        close();
        queryClient.invalidateQueries(["customerAgentIntents"]);
        queryClient.refetchQueries("customerAgentIntents", { force: true });
      },
    }
  );

  const intentUpdateMutation = useMutation(
    () => {
      setIndicatorState("saving", null);
      saveCustomerIntent(intent, agent.agentCatalogName);
    },
    {
      onSuccess: () => {
        resetIndicatorState("Intent succesfully updated.");

        queryClient.invalidateQueries(["customerAgentIntents"]);
        queryClient.refetchQueries("customerAgentIntents", { force: true });
      },
      onError: () => {},
    }
  );

  const updateResponse = (e) => {
    if (!warningShowed && invokeWebhook != undefined && invokeWebhook != "") {
      setOpenDialog(true);
      setWarningShowed(true);
      return;
    }
    setResponse(e.target.value);
  };

  const updateSmsTextResponse = (e) => {
    setSmsResponse(e.target.value);
  };

  const close = () => {
    stopSpeak();
    setContextSelectedIntent(null);
    setMenuOpen(false);
  };

  const setLanguage = (lang) => {
    intent.content[selectedLanguage].response = response;
    intent.content[selectedLanguage].smsResponse = smsResponse;

    setSelectedLanguage(lang);
    setResponse(intent.content[lang].response);
    setSmsResponse(intent.content[lang].smsResponse ?? "");

    setMenuOpen(false);
  };
  const getLanguageTranslation = (lang) => {
    switch (lang) {
      case "nl":
        return "Netherlands";
        break;
      case "de":
        return "German";
        break;
      case "en":
        return "English";
        break;
      default:
        return "";
    }
  };

  const setActiveSwitchDialog = async (value) => {
    var currentChannel = agent.idName + "_" + agent.agentType;
    if (intent.channels.filter((x) => x != currentChannel).length > 0) {
      let dialogResponse = await confirm(
        "Dit is een gedeelde intent over meerdere agents. Weet je zeker dat je de actief status wilt wijzigen voor alle agents?"
      );
      if (dialogResponse) {
        setActiveSwitch(value);
      }
    } else {
      setActiveSwitch(value);
    }
  };
  const setActiveSwitch = (value) => {
    if (value) {
      let isValid = true;
      Object.entries(agent.languages).forEach((x) => {
        if (
          intent.content[x[0]].response.trim() === "" ||
          intent.content[x[0]].response === null ||
          intent.content[x[0]].response === undefined
        ) {
          isValid = false;
        }
      });
      if (!isValid) {
        let confirmOutput = window.confirm(
          "Are you sure you wan't to activate the intent? It looks like not all responses are filled."
        );
        if (confirmOutput) {
          setIntent((prevState) => ({
            ...prevState,
            isActive: value,
          }));
        }
      } else {
        setIntent((prevState) => ({
          ...prevState,
          isActive: value,
        }));
      }
    } else {
      setIntent((prevState) => ({
        ...prevState,
        isActive: value,
      }));
    }
  };
  const setChecked = (value, propId) => {
    setIntent((prevState) => ({
      ...prevState,
      customFields: [
        ...prevState.customFields.map((i) =>
          i.id != propId ? { ...i } : { ...i, checked: value }
        ),
      ],
    }));
  };

  const setSendSmsResponse = () => {
    var smsResponse = conversationDemoTranlations["canWeSendSms"];
    Object.keys(agent.languages).map((lang) => {
      intent.content[lang].response = smsResponse[lang];
    });
    setResponse(intent.content[selectedLanguage].response);
  };

  const parsedWelcomeLine = (welcome) => {
    var welcomeParsed = welcome.replace(/\<(.*?)\>/, "");
    if (selectedLanguage == "nl") {
      return welcomeParsed.replace("[HELLO]", "Hallo");
    }
    if (selectedLanguage == "de") {
      return welcomeParsed.replace("[HELLO]", "Hallo");
    }
    if (selectedLanguage == "en") {
      return welcomeParsed.replace("[HELLO]", "Hello");
    }
  };

  const updateSelectedIntent = (e) => {
    setIndicatorState("updating", null);
    var selection = e.target.value;
    var currentChannel = agent.idName + "_" + agent.agentType;

    getCustomerIntents(agent?.agentCatalogName).then((intentsa) => {
      intentsa.map((intentl) => {
        // only pick the intents with the same id
        if (intentl.id == intent.id) {
          if (intentl.firebaseId == selection) {
            if (intentl.channels.indexOf(currentChannel) == -1) {
              intentl.channels.push(currentChannel);
            }
            updateCustomerIntent(intentl, agent?.agentCatalogName);
            setContextSelectedIntent(intentl);
          } else {
            intentl.channels = [
              ...intentl.channels.filter((x) => x != currentChannel),
            ];
            if (intentl.channels.length == 0) {
              deleteCustomerIntent(intentl, agent?.agentCatalogName);
            } else {
              updateCustomerIntent(intentl, agent?.agentCatalogName);
            }
          }
        }
      });
      resetIndicatorState("Assigned intent succesfully updated.");
    });
  };

  const addNewIntentFromType = () => {
    // remove current agent from previous intent channels
    var updatedIntent = {
      ...intent,
      channels:
        intent.channels?.filter(
          (x) => x != agent.idName + "_" + agent.agentType
        ) || [],
    };
    if (updatedIntent.channels.length == 0) {
      deleteCustomerIntent(updatedIntent, agent?.agentCatalogName);
    } else {
      setIntent(updatedIntent);
      updateCustomerIntent(updatedIntent, agent?.agentCatalogName);
    }

    var currIntent = {
      ...intent,
      channels: [agent.idName + "_" + agent.agentType],
      firebaseId: intent.id + "_" + agent.idName + "_" + agent.agentType,
    };

    addCustomerIntent(currIntent, agent.agentCatalogName).then((res) => {
      setContextSelectedIntent(currIntent);
    });
  };

  const getSameAgents = async (channels) => {
    if (agent == null) {
      return;
    }

    getApiAgentsByAgentCatalogNameNameAndChannel(
      agent.agentCatalogName,
      channels
    ).then((x) => {
      var coreAgentIds = x.map((u) => u.coreAgentId).filter(onlyUnique);
      getFlows(coreAgentIds).then((y) => {
        setFlows(y);
      });
    });
  };

  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  const getCatalog = () => {
    if (flows[0] == null) {
      return;
    }
    getCatalogService(flows[0]?.catalogId).then((result) => {
      // used when editting a catalog to get the fields
      setDbCatalog(result);
    });
  };
  const getFlows = (ids) => {
    return getFlowsServiceInRange(ids).then((res) => {
      return res;
    });
  };
  const catalogFields = (type) => {
    var fieldArray = flows.map((u) => u.fields).flat();
    if (intent.channels?.length > 1 && flows.length > 1) {
      fieldArray = toFindDuplicates(fieldArray);
    }

    return dbCatalog?.fields.filter(
      (y) => fieldArray.indexOf(y.id) > -1 && y.type == type
    );
  };

  const handleCloseDialogAgree = () => {
    setOpenDialog(false);
  };

  const toFindDuplicates = (arry) =>
    arry.filter((item, index) => arry.indexOf(item) !== index);

  const updateIntentTogglePropCustomField = (e) => {
    setIntent((prev) => ({
      ...prev,
      customFields: prev.customFields.map((x) =>
        x.id != e.target.name ? x : { ...x, checked: e.target.checked }
      ),
    }));
  };
  const updateIntentPropSelectCustomFieldEnding = (e) => {
    if (intent.customFields.filter((x) => x.id == e.target.value)[0] == null) {
      setIntent((prev) => ({
        ...prev,
        customFields: [
          ...prev.customFields,
          { id: e.target.value, checked: true, type: "ending" },
        ],
      }));
    } else {
      setIntent((prev) => ({
        ...prev,
        customFields: prev.customFields.map((x) =>
          x.type != "ending"
            ? x
            : x.id == e.target.value
            ? { ...x, checked: true }
            : { ...x, checked: false }
        ),
      }));
    }

    // if id is equal to sendSms then set response value
    if (e.target.value == "LP0y01PrAY5LCQpY3DOR") {
      setSendSmsResponse();
    }
  };

  const finishValue = useMemo(() => {
    if (intent == null || flows[0] == null || dbCatalog == null) {
      return "";
    }
    var result = intent?.customFields
      .filter(
        (x) =>
          catalogFields("ending")
            .map((u) => u.id)
            .indexOf(x.id) > -1
      )
      .filter((x) => x.checked)[0]?.id;
    if (result == null) {
      result = "";
    }
    return result;
  }, [dbCatalog, intent]);

  useEffect(() => {
    getCatalog();
  }, [intent]);

  const selectedEndingDescription = useMemo(() => {
    if (flows[0] == null || intent == null) {
      return;
    }

    var endings = catalogFields("ending");
    var trueFields = intent.customFields
      .filter((u) => u.checked)
      .map((a) => a.id);
    var description = endings.filter((x) => trueFields.indexOf(x.id) > -1)[0]
      ?.description;
    return (
      description ||
      "This is de default ending. No specific ending is triggered."
    );
  }, [selectedIntent, intent]);

  const getCustomField = (fieldName) => {
    var id = dbCatalog.fields.filter((x) => x.name == fieldName)[0]?.id;
    if (id != null) {
      var checked = intent.customFields.filter((x) => x.id == id)[0]?.checked;
      return checked;
    }
    return false;
  };

  const [isLoadingDatasourceResponse, setIsLoadingDatasourceResponse] =
    useState(false);
  const generateResponseBasedOnDatasource = () => {
    setIsLoadingDatasourceResponse(true);
    getDatasourceResponse({
      agent: agent.name,
      question: intent.content[selectedLanguage].questions[0],
      language: selectedLanguage,
    }).then((x) => {
      setResponse(x.object);
      setIsLoadingDatasourceResponse(false);
    });
  };
  return (
    <>
      {intent &&
        selectedLanguage &&
        intent.content &&
        flows != null &&
        dbCatalog != null && (
          <div
            className={`${styles.editIntentContainer} ${
              selectedIntent ? styles.editIntentContainerWidth : null
            } ${
              !intent.isActive && selectedIntent
                ? styles.notActiveContainerNotifier
                : null
            }`}
          >
            <div className={styles.chatboxHeader}>
              <div className={styles.editIntentName}>
                <div className={styles.titles}>
                  <div className={styles.titleName}>{intent?.name}</div>
                  <div className={styles.titleId}>{intent?.id}</div>
                </div>
              </div>

              <div className={styles.chatboxContentHeader}>
                {/* {customChannels != null && ( */}
                <div className={styles.channel}>
                  <FormControl
                    sx={{ m: 1, width: 150, margin: 0 }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-version">
                      version
                    </InputLabel>
                    <Select
                      native
                      labelId="demo-select-small-version"
                      id="grouped-native-select-handover-queue"
                      value={intent?.firebaseId || ""}
                      label="Version"
                      onChange={updateSelectedIntent}
                    >
                      {channels.map((obj) => (
                        <option value={obj}>
                          {obj
                            .replace(intent.id + "_", "")
                            .replace(intent.id, "default")}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {channels.filter(
                    (x) =>
                      x ==
                      intent.id + "_" + agent.idName + "_" + agent.agentType
                  )[0] == null && (
                    <IoAdd
                      className={styles.close}
                      onClick={addNewIntentFromType}
                    />
                  )}
                </div>
                {/* )} */}
                {agent != null && company != null && (
                  <div className={styles.dropdown}>
                    <button
                      onClick={openDropDownSearch}
                      className={styles.dropbtn}
                    >
                      {getLanguageTranslation(selectedLanguage)}
                      <SlArrowDown
                        className={`${styles.dropdownIcon} ${
                          menuOpen ? styles.rotate : null
                        }`}
                      />
                    </button>
                    <div
                      id={styles.myDropdown}
                      className={`${styles["dropdown-content"]} ${
                        menuOpen ? styles.show : null
                      }`}
                    >
                      {Object.entries(agent.languages)
                        .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                        .map((key) => {
                          return (
                            <span
                              key={key[0]}
                              onClick={() => setLanguage(key[0])}
                            >
                              {getLanguageTranslation(key[0])}
                            </span>
                          );
                        })}
                    </div>
                  </div>
                )}
                {!intent.isEditable && (
                  <div className={styles.notEditable}>Not editable</div>
                )}

                <ToggleSwitch
                  prop="isActive"
                  parentSetChecked={setActiveSwitchDialog}
                  isChecked={intent.isActive}
                  className={styles.toggleSwitch}
                />
              </div>

              <IoClose className={styles.close} onClick={close} />
            </div>

            <div className={styles.columnContainer}>
              <div className={styles.chatboxContent}>
                <div className={styles.chatboxContainer}>
                  <div className={`${styles.dialog} ${styles.right}`}>
                    {parsedWelcomeLine(agent.welcomeLine[selectedLanguage])}
                  </div>
                  <div className={`${styles.dialog} ${styles.right}`}>
                    {
                      conversationDemoTranlations["callReason"][
                        selectedLanguage
                      ]
                    }
                  </div>

                  <div className={`${styles.dialog}`}>
                    {coreIntent.content[selectedLanguage].questions[0]}
                  </div>

                  {/* validateQuestion */}
                  {getCustomField("validateQuestion") && (
                    <>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {intent.content[selectedLanguage].validation}
                      </div>
                      <div className={`${styles.dialog}`}>
                        {conversationDemoTranlations["yes"][selectedLanguage]}
                      </div>
                    </>
                  )}
                  <div
                    className={`${styles.dialog} ${styles.right} ${styles.userPrompt}`}
                  >
                    {response}
                  </div>

                  {/* validateAnswer */}
                  {getCustomField("validateAnswer") && (
                    <>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["didAnswerHelp"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>
                        {conversationDemoTranlations["yes"][selectedLanguage]}
                      </div>
                    </>
                  )}

                  {/* requestIdentifier */}
                  {getCustomField("requestIdentifier") && (
                    <>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["ordernumberAvailable"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>
                        {conversationDemoTranlations["yes"][selectedLanguage]}
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["ordernumberFillIn"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>123456789</div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["ordernumberRecieved"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>
                        {conversationDemoTranlations["yes"][selectedLanguage]}
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["perfect"][
                            selectedLanguage
                          ]
                        }
                      </div>
                    </>
                  )}

                  {getCustomField("sendSMS") ? (
                    <>
                      <div className={`${styles.dialog}`}>
                        {
                          conversationDemoTranlations["yesPlease"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["sendSmsToThisNumber"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>
                        {conversationDemoTranlations["yes"][selectedLanguage]}
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["sendSmsNow"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["moreHelp"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>
                        {conversationDemoTranlations["no"][selectedLanguage]}
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["noMoreHelp"][
                            selectedLanguage
                          ]
                        }
                      </div>
                    </>
                  ) : null}
                  {/* preferHandover */}
                  {getCustomField("preferHandover") ? (
                    <>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["humanHandover"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>
                        {conversationDemoTranlations["yes"][selectedLanguage]}
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["humanHandoverYes"][
                            selectedLanguage
                          ]
                        }
                      </div>
                    </>
                  ) : null}
                  {/* preferCallback */}
                  {getCustomField("preferCallback") ? (
                    <>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["callback"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>
                        {
                          conversationDemoTranlations["ratherNot"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["noProblem"][
                            selectedLanguage
                          ]
                        }{" "}
                        {
                          conversationDemoTranlations["humanHandover"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>
                        {conversationDemoTranlations["yes"][selectedLanguage]}
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["humanHandoverYes"][
                            selectedLanguage
                          ]
                        }
                      </div>
                    </>
                  ) : null}
                  {/* forceCallback */}
                  {getCustomField("forceCallback") ? (
                    <>
                      <div className={`${styles.dialog}`}>
                        {
                          conversationDemoTranlations["speakToEmployee"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["noEmployeeAvailable"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["callback"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>
                        {conversationDemoTranlations["no"][selectedLanguage]}
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["noProblem"][
                            selectedLanguage
                          ]
                        }{" "}
                        {
                          conversationDemoTranlations["moreHelp"][
                            selectedLanguage
                          ]
                        }
                      </div>
                      <div className={`${styles.dialog}`}>
                        {conversationDemoTranlations["no"][selectedLanguage]}
                      </div>
                      <div className={`${styles.dialog} ${styles.right}`}>
                        {
                          conversationDemoTranlations["noMoreHelp"][
                            selectedLanguage
                          ]
                        }
                      </div>
                    </>
                  ) : null}

                  {!getCustomField("preferHandover") &&
                    !getCustomField("sendSMS") &&
                    !getCustomField("forceCallback") &&
                    !getCustomField("preferCallback") && (
                      <>
                        <div className={`${styles.dialog} ${styles.right}`}>
                          {
                            conversationDemoTranlations["moreHelp"][
                              selectedLanguage
                            ]
                          }
                        </div>
                        <div className={`${styles.dialog}`}>
                          {conversationDemoTranlations["no"][selectedLanguage]}
                        </div>
                        <div className={`${styles.dialog} ${styles.right}`}>
                          {
                            conversationDemoTranlations["noMoreHelp"][
                              selectedLanguage
                            ]
                          }
                        </div>
                      </>
                    )}
                </div>
              </div>
              <div className={styles.chatboxOptions}>
                <div className={styles.chatboxOptionLine}>
                  <span>Conversation Options</span>
                  {agent.datasource?.lastCreatedIndex != null &&
                    agent.datasource?.lastCreatedIndex != "" && (
                      <>
                        {isLoadingDatasourceResponse ? (
                          <div className={styles.loaderContainer}>
                            <span className={styles.loader}></span>
                          </div>
                        ) : (
                          <div
                            className={`${styles.saveBtn} ${styles.actionBtn} ${styles.generateBtn}`}
                            onClick={generateResponseBasedOnDatasource}
                          >
                            {" "}
                            Generate
                          </div>
                        )}
                      </>
                    )}

                  <div className={styles.dialogResponseOptions}>
                    <div
                      data-tooltip="It's recommended to keep your answer shorter then 400 signs. This results in a better user expierience."
                      className={`${styles.intentResponseSigns} ${
                        response?.length > maxLength
                          ? styles.warningColor
                          : styles.successColor
                      }`}
                    >
                      {response?.length}/{maxLength}
                    </div>
                    {isPlaying ? (
                      <BsStopFill
                        className={`${styles.listen} ${styles.stopPlaying}`}
                        onClick={stopSpeak}
                      />
                    ) : (
                      <HiSpeakerWave
                        className={styles.listen}
                        onClick={startSpeak}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.chatboxOptionLine}>
                  {intent.isEditable ? (
                    <textarea
                      ref={inputTextArea}
                      value={response}
                      onChange={updateResponse}
                      rows="4"
                      className={styles.textArea}
                    ></textarea>
                  ) : (
                    <div className={`${styles.dialog} ${styles.right}`}>
                      {response}
                    </div>
                  )}
                </div>

                {/* <div className={`${styles.chatboxOptionDescription} ${!intent.isEditable || intent.finishConversation || sendSms ? styles.disabledLine : null}`}>ChatGPT template
                        {intent.isEditable && !sendSms && !intent.finishConversation  && (
                            <div className={styles.infoIcon}
                                 data-tooltip="Trigger a ChatGPT conversation.">
                                <IoMdInformationCircleOutline/></div>
                        )}
                        </div> 
                         <div className={styles.chatboxOptionSelector}>
                        <FormControl sx={{ m: 1, minWidth: 190, margin: 0 }} size="small" disabled={!intent.isEditable || intent.finishConversation || sendSms}>
                            <InputLabel id="demo-select-small">ChatGPT template</InputLabel>
                            <Select
                                native
                                defaultValue=""
                                disabled={!intent.isEditable || intent.finishConversation || sendSms}
                                labelId="demo-select-small"
                                id="grouped-native-select-gpt-template"
                                value={intent?.gptIntegration || ""}
                                label="Webhook"
                                onChange={handleGptIntegrationChange}
                            >
                                <option value=""><em></em></option>
                                {gptTemplates.map((obj) => (
                                    <option value={obj.name}>{obj.name}</option>
                                ))}
                            </Select>
                            </FormControl>
                        </div> */}

                <div className={styles.chatboxOptionLine}>
                  <div
                    className={`${styles.chatboxOptionDescription} ${
                      !intent.isEditable ? styles.disabledLine : null
                    }`}
                  >
                    Divergent phonequeue
                    {intent.isEditable && (
                      <div
                        className={styles.infoIcon}
                        data-tooltip="You can set a divergent phonequeue when this intent is triggered."
                      >
                        <IoMdInformationCircleOutline />
                      </div>
                    )}
                  </div>
                  <div className={styles.chatboxOptionSelector}>
                    <FormControl
                      sx={{ m: 1, minWidth: 190, margin: 0 }}
                      size="small"
                      disabled={!intent.isEditable}
                    >
                      <InputLabel id="demo-select-small">
                        Divergent queue
                      </InputLabel>
                      <Select
                        native
                        defaultValue=""
                        disabled={!intent.isEditable}
                        labelId="demo-select-small"
                        id="grouped-native-select-handover-queue"
                        value={intent?.divergentHandoverQueue || ""}
                        label="Webhook"
                        onChange={handleDivergentHandoverPhoneQueueChange}
                      >
                        <option value="">
                          <em></em>
                        </option>
                        {Object.entries(
                          agent.humanHandoverSettings?.phoneQueues
                        )
                          .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                          .map((obj) => (
                            <option value={obj[0]}>{obj[0]}</option>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className={styles.chatboxOptionLine}>
                  <div
                    className={`${styles.chatboxOptionDescription} ${
                      !intent.isEditable || sendSms ? styles.disabledLine : null
                    }`}
                  >
                    Trigger intent
                    {intent.isEditable && !sendSms && (
                      <div
                        className={styles.infoIcon}
                        data-tooltip="You can trigger an other intent."
                      >
                        <IoMdInformationCircleOutline />
                      </div>
                    )}
                  </div>
                  <div className={styles.chatboxOptionSelector}>
                    <FormControl
                      sx={{ m: 1, minWidth: 190, margin: 0 }}
                      size="small"
                      disabled={!intent.isEditable || sendSms}
                    >
                      <InputLabel id="demo-select-small">Intent</InputLabel>
                      <Select
                        native
                        defaultValue=""
                        disabled={!intent.isEditable || sendSms}
                        labelId="demo-select-small"
                        id="grouped-native-select-integration"
                        value={intent?.invokeIntent}
                        label="Intent"
                        onChange={handleInvokeIntentChange}
                      >
                        <option value="">
                          <em></em>
                        </option>
                        {intents
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((obj) => (
                            <option value={obj.id}>{obj.name}</option>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className={styles.chatboxOptionLine}>
                  <div
                    className={`${styles.chatboxOptionDescription} ${
                      !intent.isEditable || intent.finishConversation || sendSms
                        ? styles.disabledLine
                        : null
                    }`}
                  >
                    ChatGPT template
                    {intent.isEditable &&
                      !sendSms &&
                      !intent.finishConversation && (
                        <div
                          className={styles.infoIcon}
                          data-tooltip="Trigger a ChatGPT conversation."
                        >
                          <IoMdInformationCircleOutline />
                        </div>
                      )}
                  </div>
                  <div className={styles.chatboxOptionSelector}>
                    <FormControl
                      sx={{ m: 1, minWidth: 190, margin: 0 }}
                      size="small"
                      disabled={
                        !intent.isEditable ||
                        intent.finishConversation ||
                        sendSms
                      }
                    >
                      <InputLabel id="demo-select-small">
                        ChatGPT template
                      </InputLabel>
                      <Select
                        native
                        defaultValue=""
                        disabled={
                          !intent.isEditable ||
                          intent.finishConversation ||
                          sendSms
                        }
                        labelId="demo-select-small"
                        id="grouped-native-select-gpt-template"
                        value={intent?.gptIntegration || ""}
                        label="Webhook"
                        onChange={handleGptIntegrationChange}
                      >
                        <option value="">
                          <em></em>
                        </option>
                        {gptTemplates.map((obj) => (
                          <option value={obj.name}>{obj.name}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className={styles.chatboxOptionLine}>
                  <div
                    className={`${styles.chatboxOptionDescription} ${
                      !intent.isEditable || sendSms ? styles.disabledLine : null
                    }`}
                  >
                    Trigger integration
                    {intent.isEditable && !sendSms && (
                      <div
                        className={styles.infoIcon}
                        data-tooltip="You can trigger a integration to get data from external sources."
                      >
                        <IoMdInformationCircleOutline />
                      </div>
                    )}
                  </div>
                  <div className={styles.chatboxOptionSelector}>
                    <FormControl
                      sx={{ m: 1, minWidth: 190, margin: 0 }}
                      size="small"
                      disabled={!intent.isEditable || sendSms}
                    >
                      <InputLabel id="demo-select-small">
                        Integration
                      </InputLabel>
                      <Select
                        native
                        defaultValue=""
                        disabled={!intent.isEditable || sendSms}
                        labelId="demo-select-small"
                        id="grouped-native-select-integration"
                        value={invokeWebhook}
                        label="Webhook"
                        onChange={handleInvokeWebhookChange}
                      >
                        <option value="">
                          <em></em>
                        </option>
                        {Object.entries(filteredIntegrations)
                          .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                          .map((obj) => (
                            <optgroup label={obj[0]}>
                              {company.installedIntegrations[obj[0]].map(
                                (integrationHook) => (
                                  <option
                                    value={obj[0] + "|" + integrationHook}
                                  >
                                    {integrationHook}
                                  </option>
                                )
                              )}
                            </optgroup>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className={styles.chatboxOptionLine}>
                  <div
                    className={`${styles.chatboxOptionDescription} ${
                      !intent.isEditable || intent.finishConversation || sendSms
                        ? styles.disabledLine
                        : null
                    }`}
                  >
                    Datasource
                    {intent.isEditable &&
                      !sendSms &&
                      !intent.finishConversation && (
                        <div
                          className={styles.infoIcon}
                          data-tooltip="Trigger a Datasource."
                        >
                          <IoMdInformationCircleOutline />
                        </div>
                      )}
                  </div>
                  <div className={styles.chatboxOptionSelector}>
                    <FormControl
                      sx={{ m: 1, minWidth: 190, margin: 0 }}
                      size="small"
                      disabled={
                        !intent.isEditable ||
                        intent.finishConversation ||
                        sendSms
                      }
                    >
                      <InputLabel id="demo-select-small">Datasource</InputLabel>
                      <Select
                        native
                        defaultValue=""
                        disabled={
                          !intent.isEditable ||
                          intent.finishConversation ||
                          sendSms
                        }
                        labelId="demo-select-small"
                        id="grouped-native-select-gpt-template"
                        value={intent?.datasource || ""}
                        label="Webhook"
                        onChange={handleDatasourceChange}
                      >
                        <option value="">
                          <em></em>
                        </option>
                        {datasources.map((obj) => (
                          <option value={obj.id}>{obj.name}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <span className={styles.subHeader}>Select flows</span>
                <div className={`${styles.formRow} ${styles.fieldsContainer}`}>
                  {catalogFields("flow")?.map((field) => (
                    <div className={styles.fieldRow}>
                      <FormControlLabel
                        key={field.id}
                        control={<Switch size="small" />}
                        label={field.label}
                        disabled={
                          field.id == "yN0V56DfqicnhkASn28y" &&
                          disableRequestOrdernumber
                        }
                        checked={
                          intent?.customFields?.filter(
                            (x) => x.id == field.id
                          )[0]?.checked
                        }
                        name={field.id}
                        onChange={updateIntentTogglePropCustomField}
                      />
                    </div>
                  ))}
                </div>

                <span className={styles.subHeader}>Select specific ending</span>

                <div className={styles.formRow}>
                  <FormControl fullWidth>
                    <InputLabel id="endingLabel">Ending</InputLabel>
                    <Select
                      size="small"
                      labelId="endingLabel"
                      name="ending"
                      disabled={disableEndingSelector}
                      id="nextStepSelect"
                      value={finishValue}
                      label="Ending"
                      onChange={updateIntentPropSelectCustomFieldEnding}
                    >
                      <MenuItem value=""></MenuItem>
                      {catalogFields("ending")
                        ?.sort((a, b) => (a.label > b.label ? 1 : -1))
                        .map((field) => (
                          <MenuItem value={field.id}>{field.label}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.endingDescription}>
                  {selectedEndingDescription}
                </div>

                {intent.customFields.filter(
                  (x) => x.id == "LP0y01PrAY5LCQpY3DOR"
                )[0].checked && (
                  <div className={styles.sendSmsAttachment}>
                    <label>
                      Sms text:
                      <textarea
                        rows="3"
                        ref={smsTextArea}
                        value={smsResponse}
                        onChange={updateSmsTextResponse}
                      ></textarea>
                    </label>
                  </div>
                )}

                {!loadingState && (
                  <div className={styles.actionContainer}>
                    <div
                      className={`${styles.deleteBtn} ${styles.actionBtn}`}
                      onClick={DeleteIntent}
                    >
                      {" "}
                      Delete
                    </div>
                    <div
                      className={`${styles.saveBtn} ${styles.actionBtn}`}
                      onClick={saveDialog}
                    >
                      {" "}
                      Save
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "Are you sure you want to modify the copy? There is already copy included in a integration or datasource."
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to modify the copy? There is already copy
            included in a integration or datasource
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogAgree}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditIntent;
