import React, { useState } from 'react';

import styles from 'styles/global/formtext.module.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';

const FormText = ({title, value, handleChange, formId, isDisabled, isPassword = false}) => {
    return (
        <>
            <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                    variant="outlined"
                    fullWidth
                    label={title}
                    type={isPassword?"password":"text"}
                    size="small"
                    value={value}
                    disabled={isDisabled}
                    onChange={handleChange(formId)}
                    />
            </FormControl>
        </>
    )
}
export default FormText;