import React, {useState, useMemo, useEffect} from 'react';
import styles from 'styles/admin/agent/agentList.module.css';

const WebhookList = ({company, setParentIntegration, setParentIntegrationHook, selectedIntegration, catalog}) => {

    const selectIntegration = (name) => {
        setParentIntegration(name);
    }
    const selectIntegrationHook = (integrationHook) => {
        setParentIntegrationHook(integrationHook);
    }

    const addNewWebhook = () => {
        setParentIntegration("");
    }
    
    const [searchQuery, setSearchQuery] = useState("");

    const filteredIntegrations = useMemo(() => {
        if(company == null)
            return;

            
        // Create copy of item list
        //var updatedList = [...Object.entries(agent.integrations).sort((a, b) => (a[0] > b[0]) ? 1 : -1)].map(x => ({integration: x[0], name: Object.keys(x[1].hooks)[0] || ""}));
        var updatedList = [];
        Object.entries(company.integrations).filter(x => x[1].catalogId == catalog.id).forEach(integration => {
            updatedList.push({integration: integration[0], name: ""})
            Object.keys(integration[1].hooks).forEach(i => (
                updatedList.push({integration: integration[0], name: i || ""})
                ));
        });

        // Include all elements which includes the search query
        return updatedList.filter((item) => {
            return item?.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
    },[company, searchQuery, catalog]);


    const uniqueIntegrations = () =>  [...new Set(filteredIntegrations.map(x => x.integration))];
    return (
        <div className={`${styles.flipCardInner}`}>

                <div className={styles.agentList}>
                    {/*<div onClick={createNewAgent} className={styles.createAgentButton}>Nieuwe agent aanmaken</div>*/}
                    <div>
                        <input value={searchQuery} placeholder="search" onChange={(e) => setSearchQuery(e.target.value)} className={styles.newAgentSearchInput}
                                type="text"/>
                        {/*<div className={styles.agentNumber}>{filteredAgents.length} assistent{filteredAgents.length > 1 ? "en" : ""}</div>*/}


                        {   filteredIntegrations && (                             
                            uniqueIntegrations().sort((a, b) => (a > b) ? 1 : -1).map(x => (
                                <div key={x}>
                                <div onClick={() => selectIntegration(x)} className={styles.integrationHeader}>{x} ({filteredIntegrations.filter(y => y.integration == x && y.name != "").length})</div>
                                {
                                    filteredIntegrations.filter(y => y.integration == x).sort((a, b) => (a.name > b.name) ? 1 : -1).map((integration) => (
                                        <div onClick={() => selectIntegrationHook(integration)} className={`${styles.integrationRow} ${selectedIntegration.integration == x && selectedIntegration?.name == integration.name ? styles.selectedIntegrationRow : ''}`} key={integration.name}>{integration.name}</div>
                                    ))
                                }
                                </div>
                            )))
                                
                        }
                    </div>
                </div>
                <div onClick={addNewWebhook} className={styles.addNewWebhook}>
                    Add integration
                </div>
        </div>
    );
}
export default WebhookList;
