import React from 'react';
import { Outlet } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { Container } from 'react-bootstrap';
import styles from 'styles/auth/auth.module.css';

const Auth = () => {
    return (
        <div className="d-flex">
            <div className={styles.dgtlassistLogo}></div>
            <div className="p-2 flex-fill bg" style={{ width: "35%"}}>
                <div className="d-flex">
                    <div className="d-flex p-2 flex-fill justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
                        <Card className='border-0' style={{backgroundColor:"transparent"}}>
                            <Card.Body>
                                <Card.Title className={styles.cardTitle}><div className={styles.brainLogo}></div></Card.Title>
                                <Card.Subtitle className="text-muted"><h2 className="text-center white">Agent Management System</h2></Card.Subtitle>
                                <h3 className='mt-5 text-center application-description white'>A powerful, yet easy to use application for managing your digital agents!</h3>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="p-2 flex-fill" style={{ width: "65%", backgroundColor:"#f8f8fa" }}>
                <Container style={{ minHeight: "100vh" }}>
                    <Outlet />
                </Container>
            </div>
        </div>
    );
}

export default Auth;