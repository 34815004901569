import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import styles from 'styles/admin/optimization/dialogflowResponses.module.css';
import {getDialogflowResponses} from "../../../services/agentService";
import TableGrid from 'components/global/tableGrid';
import { ConstructionOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import dateFormat, { masks } from "dateformat";
import { IoClose } from 'react-icons/io5';
import moment from 'moment'
import { useProgressIndicatorContext } from 'contexts/ProgressIndicatorContext';


const columns = [
    { field: 'id', headerName: 'id', flex:0.3, hide: true},
    { field: 'flowName', headerName: 'flowName', flex:0.5},
    { field: 'pageName', headerName: 'pageName', flex:0.5},
    { field: 'condition', headerName: 'condition', flex:1, hide: true},
    { field: 'routeCondition', headerName: 'routeCondition', flex:1 , hide: true},
    { field: 'nl', headerName: 'nl', flex:1 },
    { field: 'de', headerName: 'de', flex:1 },
    { field: 'en', headerName: 'en', flex:1 },
  ];
 

const DialogflowResponses = ({agent}) => {
    const { setIndicatorState, resetIndicatorState } = useProgressIndicatorContext();

    const [dialogflowResponses, setDialogflowResponses] = useState([]);
    useEffect(()=>{
        if(agent == null)
            return;

        setIndicatorState("Loading");
        getDialogflowResponses({"agentName": agent.name}).then(res => {
            setDialogflowResponses(res.object);
            resetIndicatorState();
        })
    },[agent])


    const [sortModel, setSortModel] = React.useState([
        {
          field: 'dateTime',
          sort: 'asc',
        },
      ]);

    return (
        <div className={`${styles.callback}`}>
            <div className={`${styles.optionMenu} ${styles.widget}`}>
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                }}
                >

                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                       outline: "none !important",
                    },
                 }}
                    rows={dialogflowResponses}
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    pageSize={100}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[20, 50, 100]}
                    rowHeight={25}
                    disableSelectionOnClick
                />
                </Box>
            </div>
        </div>

        
    )

}
export default DialogflowResponses;
