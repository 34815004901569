
import React, { useState } from 'react';

import styles from 'styles/global/formMultiSelect.module.css';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

const FormMultiSelect = ({title, values, selectedValues, handleChange}) => {

    const isSelected = (e) => {
        if(selectedValues != null && selectedValues != undefined){
            var keys = Object.entries(selectedValues).map((lang) => (lang[0]));
            return keys.indexOf(e) != -1;
        }
    }

    const setSelected = (x) =>  {
        
        let isAlreadyChecked = isSelected(x.value);
        var selection = selectedValues || [];
        if(isAlreadyChecked){
            delete selection[x.value];
        }else{
            selection[x.value] = '';
        }
        handleChange(selection);
    }
    return(
        <>
        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            <FormLabel component="legend">{title}</FormLabel>
            <FormGroup>
                {values.sort((a, b) => (a.key > b.key) ? 1 : -1).map((x) => (
                    <FormControlLabel key={x.key}
                        control={
                        <Checkbox checked={isSelected(x.value)} onChange={() => setSelected(x)} name={x.value} />
                        }
                        label={x.key}
                    />
                ))}
            </FormGroup>
        </FormControl>
        </>
    )
}

export default FormMultiSelect;