import React, { setState } from "react";
import { db } from "../firebase.js";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  where,
  query,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

const addIntegrationDb = async (integration) => {
  return await setDoc(doc(db, "integrations", integration.name), integration);
};

const updateIntegrationDb = async (integration) => {
  const docRef = doc(db, "integrations", integration.id);
  return await updateDoc(docRef, integration);
};
const getIntegration = async (id) => {
  const docRef = doc(db, "integrations", id);
  return getDoc(docRef).then((data) => {
    return { ...data.data(), id: data.id };
  });
};

const getAllIntegrations = async () => {
  const reference = collection(db, "integrations");

  return await getDocs(reference).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};

const getIntegrationsForCompany = async (companyId) => {
  const docRef = doc(db, "companies", companyId);
  return getDoc(docRef).then((data) => {
    return { ...data.data(), id: data.id };
  });
};

export {
  getIntegrationsForCompany,
  getAllIntegrations,
  getIntegration,
  updateIntegrationDb,
  addIntegrationDb,
};
