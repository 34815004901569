import React, { useContext, useState } from 'react';

const AgentContext = React.createContext();

export function useAgentContext(){
    return useContext(AgentContext);
}
export function AgentProvider({ children }) {
    const [agent, setAgent] = useState();
    const [refetchIntents, setRefetchIntents] = useState();
    const [selectedIntent, setSelectedIntent] = useState();

    const setContextAgent = (agent) => {
        setAgent(agent);
        setSelectedIntent(null);
    }
    
    const refetchIntentsTrigger = () => {
        setRefetchIntents(Date.now);
    }
    
    const setContextSelectedIntent = (intent) => {
        setSelectedIntent(intent);
        setRefetchIntents(Date.now);
    }

    const value = {
        agent,
        setContextAgent,
        refetchIntents,
        refetchIntentsTrigger,
        selectedIntent,
        setContextSelectedIntent
    }

    return (
        <AgentContext.Provider value={value}>
            {children}
        </AgentContext.Provider>
    );
}