import React, { useState, useEffect, useMemo } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { getApiAgentsByCompany } from "../../../services/agentService";
import { MdOutlineManageAccounts } from "react-icons/md";
import styles from "styles/admin/datasource/datasource.module.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import CategorizedDropdownSearch from "components/global/categorizedDropdownSearch";
import {
  deleteCompanyDatasource,
  deleteCompanyDatasourceCollection,
  getApiCompanies,
  getApiCompaniesForUser,
  uploadCompanyDataSourceFile,
} from "services/companyService";
import {
  addDatasourceCollectionToDb,
  deleteDatasourceCollection,
  getDatasourceCollectionsByCompany,
  updateDatasourceCollectionToDb,
} from "services/datasourceService";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Button } from "react-bootstrap";
import { useProgressIndicatorContext } from "contexts/ProgressIndicatorContext";
import {
  createCompanyDatestoreIndex,
  getCompanyDatasourceResponse,
} from "services/intentService";

const Datasource = () => {
  const {
    setIndicatorState,
    resetIndicatorState,
    setInfoMessage,
    loadingState,
  } = useProgressIndicatorContext();
  const [agents, setAgents] = useState([]);
  const [company, setCompany] = useState();
  const [question, setQuestion] = useState();
  const [companies, setCompanies] = useState([]);
  const { currentDbUser, currentUser } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [datasourceCollections, setDatasourceCollections] = useState([]);
  const [datasourceCollection, setDatasourceCollection] = useState();
  const [datasource, setDatasource] = useState();

  const [chatStore, setChatStore] = useState();

  const [
    datasourceCollectionTestResponse,
    setDatasourceCollectionTestResponse,
  ] = useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialog2, setOpenDialog2] = React.useState(false);
  const [openDialog3, setOpenDialog3] = React.useState(false);

  useEffect(() => {
    if (!company) return;

    getDatasourceCollections();

    getAgents();
  }, [company]);

  const getDatasourceCollections = () => {
    getDatasourceCollectionsByCompany(company.id).then((x) => {
      setDatasourceCollections(x);
    });
  };

  const selectDatasourceCollection = (collection) => {
    setDatasourceCollection(collection);
    setChatStore(null);
  };
  const getAgents = async () => {
    var retrievedAgents = await getApiAgentsByCompany(company.id);
    setAgents(retrievedAgents);
  };

  const conversationPrompt =
    "Je bent een helpdesk medewerker voor Prijsvrij.\n" +
    "Hieronder staat de context:\n" +
    "\n---------------\n{context_str}\n---------------\n\n" +
    "Instructies:\n" +
    "Je helpt bij het geven van uitgebreide antwoorden over de context.\n" +
    "Als niet precies duidelijk is wat de klant vraagt, stel je een verdiepingsvraag.\n" +
    "Je gebruikt geen informatie die niet uit de gegeven context komt.\n";

  const qandAPrompt =
    "Je bent een medewerker van een klantenservice.\n" +
    "De context informatie staat hieronder.\n" +
    "\n---------------------\n{context_str}\n---------------------\n\n" +
    "Gebruik alleen de informatie uit de context en geen eigen informatie. \n" +
    "Geef een uitgebreid antwoord op de vraag: {query_str}. \n" +
    "Stuur altijd de bron mee terug.\n" +
    "Als het antwoord niet aanwezig is antwoord enkel '###' en niks anders.\n";

  const addNewDatasourceCollection = () => {
    const collection = {
      agentId: "",
      companyId: company.id,
      createdAt: "",
      lastIndexBuild: "",
      model: "3.5",
      name: "",
      responseMode: "long",
      prompt: qandAPrompt,
      refreshDay: "",
      refreshRate: "",
      tokensUsedForIndexing: "",
      sources: [],
    };
    setDatasourceCollection(collection);
    setDatasource(null);
  };

  const addNewDatasource = () => {
    const collection = {
      id: Math.floor(Math.random() * 1000000),
      type: "url",
      url: "",
      scrapeSubpages: false,
    };
    setDatasource(collection);
    setDatasourceCollection((prev) => ({
      ...prev,
      sources: [...prev?.sources, collection],
    }));
  };

  const saveDatasource = () => {
    // if (
    //   datasourceCollection.sources.filter((x) => x.url == datasource.url)
    //     .length > 0
    // ) {
    //   resetIndicatorState("Bestand of url bestaat al.");
    //   return;
    // }
    const formData = new FormData();
    formData.append("company", company.name.toLowerCase());
    formData.append("companyId", company.id);
    formData.append("index", datasourceCollection.name);

    if (datasource.type == "file") {
      formData.append("type", "file");
      formData.append("formFile", datasource.data);
      formData.append("url", datasource.data.fileName?.toLowerCase());
    }
    if (datasource.type == "url") {
      formData.append("type", "url");
      formData.append("url", datasource.url);
    }
    console.log(formData);
    uploadCompanyDataSourceFile(formData).then((res) => {});
    setDatasourceCollection((prev) => ({
      ...prev,
      sources: prev.sources.map((x) =>
        x.id == datasource.id ? { ...datasource, data: null } : x
      ),
    }));
  };
  const deleteDatasource = () => {
    setDatasourceCollection((prev) => ({
      ...prev,
      sources: prev.sources.filter((x) => x.id != datasource.id),
    }));

    if (datasource.type == "file") {
      let sourceObj = {
        company: company.name.toLowerCase(),
        index: datasourceCollection.name,
        URL: datasource.url,
      };
      deleteCompanyDatasource(sourceObj).then((res) => {});
    }

    setDatasource();
  };

  useEffect(() => {
    if (datasourceCollection != null && datasource != null) {
      saveCollectionInfo();
    }
  }, [datasourceCollection]);

  const saveCollectionInfo = () => {
    if (datasourceCollection?.name != "") {
      setIndicatorState("saving", "Saving collection");
      if (datasourceCollection.id == null || datasourceCollection.id == "") {
        addDatasourceCollectionToDb(datasourceCollection).then((x) => {
          setDatasourceCollection((prev) => ({ ...prev, id: x.id }));
          getDatasourceCollections();
          resetIndicatorState("Collection saved");
        });
      } else {
        updateDatasourceCollectionToDb(datasourceCollection).then((x) => {
          getDatasourceCollections();
          resetIndicatorState("Collection saved");
        });
      }
    }
  };

  const generateIndex = () => {
    setIndicatorState("generating", "Generating index");
    const obj = {
      company: company.name.toLowerCase(),
      companyId: company.id,
      index: datasourceCollection.name,
      prompt: datasourceCollection.prompt,
      model: datasourceCollection.model,
      responseMode: datasourceCollection.responseMode,
      sources: datasourceCollection.sources.map((x) => ({
        type: x.type,
        url: x.url,
        scrapeSubpages: x.scrapeSubpages,
      })),
    };
    createCompanyDatestoreIndex(obj).then((resp) => {
      console.log(resp);
      var currentdate = new Date();
      var datetime =
        currentdate.getDate() +
        "-" +
        (currentdate.getMonth() + 1) +
        "-" +
        currentdate.getFullYear() +
        " @ " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds();

      setDatasourceCollection((prev) => ({
        ...prev,
        lastIndexBuild: datetime,
        tokensUsedForIndexing: resp?.object?.tokens,
      }));
      resetIndicatorState("Index generated");
    });
  };

  const deleteHistory = () => {
    setChatStore(null);
    setIndicatorState("Deleting", "Deleting history");
    resetIndicatorState("History deleted");
  };

  const testIndex = () => {
    setDatasourceCollectionTestResponse("");
    setIndicatorState("Retrieving", "Retrieving response");
    const obj = {
      company: company.name.toLowerCase(),
      companyId: company.id,
      index: datasourceCollection.name,
      prompt: datasourceCollection.prompt,
      question: question,
      language: "nl",
      model: datasourceCollection.model,
      chatStore: chatStore,
      responseMode: datasourceCollection.responseMode,
    };
    getCompanyDatasourceResponse(obj).then((resp) => {
      var result = resp.object;
      setDatasourceCollectionTestResponse(result.response);
      setChatStore(result.chatStore);
      resetIndicatorState("Reponse retrieved");
    });
  };

  useEffect(() => {
    if (!currentDbUser) return;

    const getCompanies = async () => {
      if (currentDbUser.isAdmin) {
        var retrievedCompanies = await getApiCompanies();
        setCompanies(retrievedCompanies);
      } else {
        var retrievedCompanies = await getApiCompaniesForUser(currentDbUser);
        setCompanies(retrievedCompanies);
      }
    };

    getCompanies();
  }, [currentDbUser]);

  const filteredCompanies = useMemo(() => {
    // Create copy of item list
    var updatedList = [...companies];
    // Include all elements which includes the search query
    return (updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
    }));
  }, [companies, searchQuery]);

  const openDropDownSearch = () => {
    setMenuOpen(!menuOpen);
  };
  const companySelected = (e) => {
    setCompany(e);
    setSearchQuery("");
    openDropDownSearch();
  };

  const setField = (field, value) => {
    setDatasourceCollection((prev) => ({ ...prev, [field]: value }));
  };

  const setResponseModeField = (value) => {
    var newPrompt = datasourceCollection.prompt;
    if (newPrompt == qandAPrompt && value == "conversation") {
      newPrompt = conversationPrompt;
    }
    if (newPrompt == conversationPrompt && value != "conversation") {
      newPrompt = qandAPrompt;
    }
    setDatasourceCollection((prev) => ({
      ...prev,
      responseMode: value,
      prompt: newPrompt,
    }));
  };

  const setDatasourceField = (field, value) => {
    setDatasource((prev) => ({ ...prev, [field]: value }));
  };

  const handleCloseDialogDisagree = () => {
    setOpenDialog(false);
  };
  const handleCloseDialogAgree = () => {
    setOpenDialog(false);
    deleteCollection();
  };

  const handleCloseDialogDisagree2 = () => {
    setOpenDialog2(false);
  };
  const handleCloseDialogAgree2 = () => {
    setOpenDialog2(false);
    deleteDatasource();
  };
  const deleteCollection = () => {
    deleteDatasourceCollection(datasourceCollection).then((x) => {
      setTimeout(() => {
        let sourceObj = {
          company: company.name.toLowerCase(),
          index: datasourceCollection.name,
        };

        deleteCompanyDatasourceCollection(sourceObj).then((x) => {
          getDatasourceCollections();
          setDatasourceCollection();
          setQuestion("");
          setDatasourceCollectionTestResponse("");
          setDatasource(null);
          setChatStore(null);
        });
      }, 300);
    });
  };

  const loadFile = (event) => {
    setDatasource((prev) => ({
      ...prev,
      data: event.target.files[0],
      url: event.target.files[0].name,
    }));
  };

  const handleCloseDialogDisagree3 = () => {
    setOpenDialog3(false);
  };
  const handleCloseDialogAgree3 = () => {
    setOpenDialog3(false);
    generateIndex();
  };
  return (
    <div className={`${styles.callback}`}>
      <div className={`${styles.header} ${styles.widget}`}>
        <h1>Datasources</h1>
      </div>
      <div className={`${styles.agentSelection} ${styles.widget}`}>
        <div className={styles.optionsAgentSelection}>
          <div className={styles.optionsHeader}>
            <MdOutlineManageAccounts className={styles.optionsIcon} /> Select
            company
          </div>
          <div className={styles.dropdown}>
            <CategorizedDropdownSearch
              data={filteredCompanies}
              subHeader="agentType"
              type="company"
              selected={company}
              setSelected={companySelected}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.collectionSelection} ${styles.widget}`}>
        {company != null && (
          <div
            onClick={addNewDatasourceCollection}
            className={styles.buttonStyle}
          >
            Add new datasource collection
          </div>
        )}
        <div className={styles.list}>
          {datasourceCollections.map((x) => (
            <div
              key={x.id}
              className={`${styles.listItem} ${
                datasourceCollection?.id == x.id ? styles.listItemSelected : ""
              }`}
              onClick={() => selectDatasourceCollection(x)}
            >
              {x.name}
            </div>
          ))}
        </div>
      </div>

      <div className={`${styles.collectionInfo} ${styles.widget}`}>
        {datasourceCollection != null && (
          <>
            <div className={styles.formRow}>
              <div className={styles.inputContainer}>
                <label for="name" className={styles.inputLabel}>
                  Name
                </label>
                <input
                  className={styles.inputField}
                  name="name"
                  id="name"
                  disabled={
                    datasourceCollections.filter(
                      (x) => x.name == datasourceCollection?.name
                    )[0] != null
                  }
                  type="text"
                  value={datasourceCollection?.name}
                  onChange={(e) =>
                    setField("name", e.target.value.toLowerCase())
                  }
                />
              </div>
              <div className={styles.inputContainer}>
                <label for="agent" className={styles.inputLabel}>
                  Agent
                </label>
                <select
                  className={styles.inputField}
                  name="agent"
                  id="agent"
                  type="text"
                  value={datasourceCollection?.agentId}
                  onChange={(e) => setField("agentId", e.target.value)}
                >
                  <option value=""></option>
                  {agents.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.name} - {x.agentType}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.inputContainer}>
                <label for="model" className={styles.inputLabel}>
                  Model
                </label>
                <select
                  className={styles.inputField}
                  name="model"
                  id="model"
                  type="text"
                  value={datasourceCollection?.model}
                  onChange={(e) => setField("model", e.target.value)}
                >
                  <option value="3.5">3.5</option>
                  <option value="4">4</option>
                </select>
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.inputContainer}>
                <label for="refreshRate" className={styles.inputLabel}>
                  Refresh rate
                </label>
                <select
                  className={styles.inputField}
                  name="refreshRate"
                  id="refreshRate"
                  type="text"
                  value={datasourceCollection?.refreshRate}
                  onChange={(e) => setField("refreshRate", e.target.value)}
                >
                  <option value=""></option>
                  <option value="daily">daily</option>
                  <option value="weekly">weekly</option>
                  <option value="montly">montly</option>
                  <option value="yearly">yearly</option>
                </select>
              </div>
              <div className={styles.inputContainer}>
                <label for="refreshDay" className={styles.inputLabel}>
                  Refresh day
                </label>
                <select
                  className={styles.inputField}
                  name="refreshDay"
                  id="refreshDay"
                  type="text"
                  value={datasourceCollection?.refreshDay}
                  onChange={(e) => setField("refreshDay", e.target.value)}
                >
                  <option value=""></option>
                  <option value="monday">monday</option>
                  <option value="tuesday">tuesday</option>
                  <option value="wednesday">wednesday</option>
                  <option value="thursday">thursday</option>
                  <option value="friday">friday</option>
                  <option value="saturday">saturday</option>
                  <option value="sunday">sunday</option>
                </select>
              </div>
              <div className={styles.inputContainer}>
                <label for="responseMode" className={styles.inputLabel}>
                  Response mode
                </label>
                <select
                  className={styles.inputField}
                  name="responseMode"
                  id="responseMode"
                  type="text"
                  value={datasourceCollection?.responseMode}
                  onChange={(e) => setResponseModeField(e.target.value)}
                >
                  <option value="short">short</option>
                  <option value="detail">detail</option>
                  <option value="long">long</option>
                  <option value="conversation">conversation</option>
                </select>
              </div>
            </div>

            <div className={styles.formRow}>
              <div
                className={`${styles.inputContainer} ${styles.inputContainerWide}`}
              >
                <label for="prompt" className={styles.inputLabel}>
                  Prompt
                </label>
                <textarea
                  rows="7"
                  className={styles.inputField}
                  name="prompt"
                  id="prompt"
                  value={datasourceCollection?.prompt}
                  onChange={(e) => setField("prompt", e.target.value)}
                />
              </div>
            </div>
            <div className={styles.flexRow}>
              {datasourceCollection.lastIndexBuild && (
                <div className={styles.fontText}>
                  <b>Last generated at:</b>{" "}
                  {datasourceCollection.lastIndexBuild} - <b>Tokens used:</b>{" "}
                  {datasourceCollection.tokensUsedForIndexing}
                </div>
              )}
              <div
                onClick={generateIndex}
                className={`${styles.buttonStyle} ${
                  datasourceCollection?.name == "" ? styles.disabledButton : ""
                }`}
              >
                Generate
              </div>
              <div
                onClick={saveCollectionInfo}
                className={`${styles.buttonStyle} ${
                  datasourceCollection?.name == "" ? styles.disabledButton : ""
                }`}
              >
                Save
              </div>
              <div
                onClick={() => setOpenDialog(true)}
                className={`${styles.buttonStyle} ${
                  styles.buttonStyleWarning
                } ${
                  datasourceCollection?.id == "" ||
                  datasourceCollection?.id == null
                    ? styles.disabledButton
                    : ""
                }`}
              >
                Delete
              </div>
            </div>
          </>
        )}
      </div>

      <div className={`${styles.collectionTest} ${styles.widget}`}>
        <div className={styles.formRow}>
          <div
            className={`${styles.inputContainer} ${styles.inputContainerWide}`}
          >
            <label for="test" className={styles.inputLabel}>
              Test
            </label>
            <div className={styles.inline}>
              <input
                className={styles.inputField}
                name="test"
                id="test"
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              <div onClick={() => testIndex()} className={styles.buttonStyle}>
                Send
              </div>
              <div
                onClick={() => deleteHistory()}
                className={`${styles.buttonStyle} ${styles.buttonStyleWarning}`}
              >
                Delete history
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formRow}>
          <div
            className={`${styles.inputContainer} ${styles.inputContainerWide}`}
          >
            <label for="response" className={styles.inputLabel}>
              Response
            </label>
            <textarea
              rows="7"
              className={styles.inputField}
              value={datasourceCollectionTestResponse}
              name="response"
              id="response"
            />
          </div>
        </div>
      </div>

      <div className={`${styles.datasourceInfo} ${styles.widget}`}>
        {datasource != null && (
          <>
            <div className={styles.formRow}>
              <div
                className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
              >
                <label for="datasourceType" className={styles.inputLabel}>
                  Type
                </label>
                <select
                  className={styles.inputField}
                  name="datasourceType"
                  id="datasourceType"
                  disabled={datasource.url != ""}
                  type="text"
                  value={datasource?.type}
                  onChange={(e) => setDatasourceField("type", e.target.value)}
                >
                  <option value="url">url</option>
                  <option value="file">file</option>
                </select>
              </div>
              {datasource?.type == "url" ? (
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerLarge}`}
                >
                  <label for="url" className={styles.inputLabel}>
                    Url
                  </label>
                  <input
                    className={styles.inputField}
                    name="url"
                    id="url"
                    type="text"
                    value={datasource?.url}
                    onChange={(e) => setDatasourceField("url", e.target.value)}
                  />
                </div>
              ) : datasource.url == "" || datasource.url == null ? (
                <input
                  className={styles.textInputField}
                  type="file"
                  onChange={loadFile}
                ></input>
              ) : (
                <div className={styles.fileName}>{datasource?.url}</div>
              )}
            </div>
            <div className={styles.formRow}>
              {datasource?.type == "url" && (
                <div className={`${styles.inputContainer}`}>
                  <label for="scrapeSubpages" className={styles.inputLabel}>
                    Scrape subpages
                  </label>
                  <select
                    className={styles.inputField}
                    name="scrapeSubpages"
                    id="scrapeSubpages"
                    type="text"
                    value={datasource?.scrapeSubpages}
                    onChange={(e) =>
                      setDatasourceField(
                        "scrapeSubpages",
                        e.target.value == "true"
                      )
                    }
                  >
                    <option value={true}>yes</option>
                    <option value={false}>no</option>
                  </select>
                </div>
              )}
              <div
                onClick={saveDatasource}
                className={`${styles.buttonStyle} ${styles.buttonRight}`}
              >
                Save
              </div>
              <div
                onClick={() => setOpenDialog2(true)}
                className={`${styles.buttonStyle} ${
                  datasource?.id == null ? styles.disabledButton : ""
                }`}
              >
                Delete
              </div>
            </div>
          </>
        )}
      </div>
      <div className={`${styles.datasources} ${styles.widget}`}>
        {datasourceCollection != null && (
          <div onClick={addNewDatasource} className={styles.buttonStyle}>
            Add new datasource
          </div>
        )}

        {datasourceCollection?.sources?.map((x) => (
          <div
            key={x.id}
            className={`${styles.listItem} ${
              datasource?.id == x.id ? styles.listItemSelected : ""
            }`}
            onClick={() => setDatasource(x)}
          >
            {x.url}
          </div>
        ))}
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialogDisagree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this collection"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the collection{" "}
            {datasourceCollection?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDisagree} autoFocus>
            No
          </Button>
          <Button onClick={handleCloseDialogAgree}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog2}
        onClose={handleCloseDialogDisagree2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this datasource"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the datasource {datasource?.url}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDisagree2} autoFocus>
            No
          </Button>
          <Button onClick={handleCloseDialogAgree2}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog3}
        onClose={handleCloseDialogDisagree3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this index"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to generate the index?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDisagree3} autoFocus>
            No
          </Button>
          <Button onClick={handleCloseDialogAgree3}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Datasource;
