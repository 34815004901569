import React, { useState, useEffect, useMemo } from 'react';
import {useAuth} from "../../../contexts/AuthContext";
import {getApiAgents, getApiAllAgents} from "../../../services/agentService";
import {MdOutlineManageAccounts} from "react-icons/md";
import {SlArrowDown} from "react-icons/sl";

import styles from 'styles/admin/report/reports.module.css';
import CategorizedDropdownSearch from 'components/global/categorizedDropdownSearch';

const Reports = () => {
    const [agent, setAgent] = useState();
    const [agents, setAgents] = useState([]);
    const { currentDbUser } = useAuth();
    const [searchQuery, setSearchQuery] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);
    const [reportQueryParam, setReportQueryParam] = useState()
    const [reportUrl, setReportUrl] = useState()

    useEffect(() => {
        setQueryParam()
    }, [agent])

    const setQueryParam = () => {
        if(agent != null){
            setReportQueryParam({
                "ds599.agentid": agent.dialogflowAgentId,
                "ds600.agentid": agent.dialogflowAgentId
              })
        }
    }

    useEffect(() => {
        setUrl();
    },[reportQueryParam])

    const setUrl = () => {
        if(agent == null){
            return null;
        }

        var urlEncoded = encodeURIComponent(JSON.stringify(reportQueryParam));
        if(currentDbUser?.isAdmin){
            setReportUrl("https://lookerstudio.google.com/embed/reporting/369316ff-dd86-4052-95b1-47cf90b38cbc/page/eWfbC?params="+urlEncoded);
        }else{
            setReportUrl("https://lookerstudio.google.com/embed/reporting/85f08e3e-bb68-4f26-b290-085814d5d62a/page/eWfbC?params="+urlEncoded);
        }
    }

    useEffect(()=>{
        if(!currentDbUser)
          return;

        const getAgents = async () => {
            if(currentDbUser.isAdmin){
                var retrievedAgents = await getApiAllAgents();
                setAgents(retrievedAgents);
                getAndSetAgentFromLocalStorage(retrievedAgents);
            }else{
                var retrievedAgents = await getApiAgents(currentDbUser);
                setAgents(retrievedAgents);
                getAndSetAgentFromLocalStorage(retrievedAgents);
            }
      };
      getAgents();
    },[currentDbUser]);
    
    const getAndSetAgentFromLocalStorage = (retrievedAgents) => {
        const agentName = localStorage.getItem('selectedAgent');
        var selectAgent = retrievedAgents.filter(x => x.name == agentName)[0];
        if(selectAgent != null){
            setAgent(selectAgent);
        }else{
            setAgent(retrievedAgents[0]);
        }
    }

    const filteredAgents = useMemo(() => {
        // Create copy of item list
        var updatedList = [...agents];
        // Include all elements which includes the search query
        return updatedList = updatedList.filter((item) => {
            return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
    }, [agents, searchQuery]);

    const openDropDownSearch =() => {
        setMenuOpen(!menuOpen);
    }
    const agentSelected = (e) => {
        setAgent(e);
        localStorage.setItem('selectedAgent', e.name);
        setSearchQuery("");
        openDropDownSearch();
    }

    return (
        <div className={`${styles.report}`}>
            <div className={`${styles.header} ${styles.widget}`}>
                <h1>Reports</h1>
                <h3>Insight into how your agent is performing</h3>
            </div>


            <div className={`${styles.agentSelection} ${styles.widget}`}>
                <div className={styles.optionsAgentSelection} >
                    <div className={styles.optionsHeader}><MdOutlineManageAccounts
                        className={styles.optionsIcon}/> Select agent
                    </div>
                    <div className={styles.dropdown} >
                        <CategorizedDropdownSearch data={filteredAgents} subHeader="agentType" type="agent" selected={agent} setSelected={agentSelected}/>

                        <div className={styles.labelTypeContainer}>
                            <div className={`${styles.typeLabel} ${agent?.agentType == 'chat' ? styles.typeLabelChat : null}`}>{agent?.agentType}</div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className={`${styles.reportContainer} ${styles.widget}`}>
                {agent && (
                    <iframe width="100%" height="100%" src={reportUrl} frameborder="0"></iframe>
                )}
            </div>
        </div>



    )

}
export default Reports;
