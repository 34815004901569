import React, { useContext, useState } from 'react';

const ProgressIndicatorContext = React.createContext();

export function useProgressIndicatorContext(){
    return useContext(ProgressIndicatorContext);
}
export function ProgressIndicatorProvider({ children }) {
    const [loadingState, setLoadingState] = useState();
    const [message, setMessage] = useState();

    const setIndicatorState = (newState, message) => {
        setLoadingState(newState);
        setMessage(message);
    }
    const resetIndicatorState = (message) => {
        setLoadingState(null);
        if(message != null){
            setMessage(message);
        }
    }
    const setInfoMessage = (message) => {
        setMessage(message);
    }

    const value = {
        loadingState,
        message,
        setInfoMessage,
        setIndicatorState,
        resetIndicatorState,
    }

    return (
        <ProgressIndicatorContext.Provider value={value}>
            {children}
        </ProgressIndicatorContext.Provider>
    );
}