import React, { setState } from "react";
import { db } from "../firebase.js";
import {
  collection,
  getDocs,
  updateDoc,
  setDoc,
  doc,
  deleteDoc,
  getDoc,
  where,
  query,
  or,
  and,
} from "firebase/firestore";

import { toCamelCaseFormat } from "../global/globalFunctions";

const getAllCoreIntents = async () => {
  const reference = collection(db, "intents_catalogus");
  return await getDocs(reference).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};
const getAllCoreIntentsByCollection = async (coll) => {
  const reference = collection(db, "intents_catalogus");
  const q = query(reference, where("collection", "==", coll));
  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};
const getAllCoreIntentsByAgent = async (agent) => {
  const reference = collection(db, "intents_catalogus");
  const q = query(reference, where("agentSpecific", "==", agent));
  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};

const getCoreIntentCollections = async () => {
  const reference = collection(db, "intents_catalogus");
  return await getDocs(reference).then((data) => {
    return data.docs.map(
      (docSnap) => "collection_" + docSnap.data().collection
    );
  });
};
const updateCoreIntent = async (intent) => {
  const docRef = doc(db, "intents_catalogus", intent.id);
  await updateDoc(docRef, intent);
};
const addCoreIntent = async (intent) => {
  return await setDoc(doc(db, "intents_catalogus", intent.id), intent);
};

const deleteCoreIntent = async (id) => {
  const reference = collection(db, "intents_catalogus");
  const idWithDot = id + ".";

  const q = query(
    reference,
    or(
      where("id", "==", id),
      and(where("id", ">=", idWithDot), where("id", "<=", idWithDot + "\uf8ff"))
    )
  );

  const docsToDelete = await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ id: docSnap.id }));
  });

  docsToDelete.map(async (x) => {
    const docRef = doc(db, "intents_catalogus", x.id);
    return await deleteDoc(docRef);
  });
};
const createFinetunedModel = async (finetunedModel) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(finetunedModel),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/intent/createFinetunedModel",
    requestOptions
  )
    .then((response) => response.text())
    .then((data) => {
      return data;
    });
};

const createVertexExport = async (vertexExportRequest) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(vertexExportRequest),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/intent/createVertexExport",
    requestOptions
  )
    .then((response) => response.text())
    .then((data) => {
      return data;
    });
};

const GetGptExampleQuestionsForSummary = async (intent) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(intent),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL +
      "/intent/GetGptExampleQuestionsForSummary",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

//oud
const getCoreIntents = async (name, catalog) => {
  if (name == undefined || catalog == undefined) return [];

  const reference = collection(db, "intents_catalogus");

  const q1 = query(
    reference,
    where("isActive", "==", true),
    where("collection", "==", catalog)
  );

  const q2 = query(
    reference,
    where("isActive", "==", true),
    where("agentSpecific", "==", name)
  );

  var set1 = await getDocs(q1).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
  var set1 = set1.filter((x) => x.agentSpecific == null);

  var set2 = await getDocs(q2).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
  return [...set1, ...set2];
};

const getCoreIntent = async (id) => {
  const reference = collection(db, "intents_catalogus");

  const q = query(reference, where("id", "==", id));

  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data() }))[0];
  });
};

const getCustomerIntent = async (agentName, id) => {
  if (agentName == undefined) return [];

  if (agentName.indexOf("_") != 0) {
    agentName = agentName.split("_")[0];
  }
  agentName = "agent_" + agentName.toLowerCase();
  const reference = collection(db, agentName);

  const q = query(reference, where("id", "==", id));

  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data() }));
  });
};

const getCustomerIntents = async (agentName) => {
  if (agentName == undefined) return [];

  if (agentName.indexOf("_") != 0) {
    agentName = agentName.split("_")[0];
  }
  agentName = "agent_" + agentName.toLowerCase();
  const reference = collection(db, agentName);

  return await getDocs(reference).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data() }));
  });
};
const deleteCustomerIntent = async (intent, agentName) => {
  if (agentName.indexOf("_") != 0) {
    agentName = agentName.split("_")[0];
  }
  agentName = "agent_" + agentName.toLowerCase();
  return await deleteDoc(doc(db, agentName, intent.firebaseId));
};

const updateCustomerIntentOnIdTemp = async (intent, agentName) => {
  if (agentName.indexOf("_") != 0) {
    agentName = agentName.split("_")[0];
  }
  agentName = "agent_" + agentName.toLowerCase();
  const docRef = doc(db, agentName, intent.id);
  return await updateDoc(docRef, intent);
};

const updateCustomerIntent = async (intent, agentName) => {
  if (agentName.indexOf("_") != 0) {
    agentName = agentName.split("_")[0];
  }
  agentName = "agent_" + agentName.toLowerCase();
  const docRef = doc(db, agentName, intent.firebaseId);
  return await updateDoc(docRef, intent);
};

const addCustomerIntent = async (intent, agentName) => {
  if (agentName.indexOf("_") != 0) {
    agentName = agentName.split("_")[0];
  }
  agentName = "agent_" + agentName.toLowerCase();
  return await setDoc(doc(db, agentName, intent.firebaseId), intent);
};

const saveCustomerIntent = async (intent, agentName) => {
  if (agentName.indexOf("_") != 0) {
    agentName = agentName.split("_")[0];
  }
  agentName = "agent_" + agentName.toLowerCase();
  const docRef = doc(db, agentName, intent.firebaseId);
  return await updateDoc(docRef, intent);
};

const getPromptByApi = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/intent/getPrompt",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const importIntentsFromCsv = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/intent/importIntentsFromCsv",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const fillAgentIntentsApi = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/intent/fillAgentIntents",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const createDatestoreIndex = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/intent/createDatestoreIndex",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};
const createCompanyDatestoreIndex = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL +
      "/intent/createCompanyDatestoreIndex",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const savePromptApi = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/intent/savePrompt",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const getDatasourceResponse = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/intent/GetDatastoreResponse",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const getCompanyDatasourceResponse = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL +
      "/intent/GetCompanyDatastoreResponse",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const helpSolvingIssueApi = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ApiKey: "r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I",
    },
    body: JSON.stringify(data),
  };
  return await fetch(
    process.env.REACT_APP_BRAIN_BACKEND_URL + "/intent/HelpSolvingIssue",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export {
  getCoreIntent,
  fillAgentIntentsApi,
  savePromptApi,
  createDatestoreIndex,
  getDatasourceResponse,
  getPromptByApi,
  helpSolvingIssueApi,
  createFinetunedModel,
  GetGptExampleQuestionsForSummary,
  updateCustomerIntentOnIdTemp,
  getCustomerIntent,
  getAllCoreIntentsByAgent,
  getAllCoreIntentsByCollection,
  getCoreIntentCollections,
  importIntentsFromCsv,
  createVertexExport,
  deleteCoreIntent,
  addCoreIntent,
  updateCoreIntent,
  getAllCoreIntents,
  getCoreIntents,
  getCustomerIntents,
  deleteCustomerIntent,
  updateCustomerIntent,
  addCustomerIntent,
  saveCustomerIntent,
  createCompanyDatestoreIndex,
  getCompanyDatasourceResponse,
};
