import React, {useState, useMemo} from 'react';
import styles from 'styles/admin/agent/agentList.module.css';
import {useAuth} from '../../../contexts/AuthContext'
import { useAgentContext } from 'contexts/AgentContext';

const AvailableVariables = ({availableVariables}) => {



    const [searchQuery, setSearchQuery] = useState("");

    const filteredVariables = useMemo(() => {
        if(availableVariables == null)
            return [{key:'', type:''}];

        // Include all elements which includes the search query
        return availableVariables.filter((item) => {
            return item.key.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
    },[availableVariables, searchQuery]);


    return (
        <>
            <div className={`${styles.flipCardInner}`}>

                <div className={styles.flipCardFront}>

                    <div className={styles.agentList}>
                        {/*<div onClick={createNewAgent} className={styles.createAgentButton}>Nieuwe agent aanmaken</div>*/}


                        <div>
                            <input value={searchQuery} placeholder="search" onChange={(e) => setSearchQuery(e.target.value)} className={styles.newAgentSearchInput}
                                   type="text"/>
                            {/*<div className={styles.agentNumber}>{filteredAgents.length} assistent{filteredAgents.length > 1 ? "en" : ""}</div>*/}
                            <div className={styles.listSubTitle}>variables</div>
                            {                                
                                Array.isArray(filteredVariables) ?
                                filteredVariables.filter(x => x.type == "variable").sort((a, b) => (a.key > b.key) ? 1 : -1).map((variable) => (
                                        <div className={styles.integrationRow} key={variable.key}>{variable.key}</div>
                                    )) : null
                            }
                            <div className={styles.listSubTitle}>secrets</div>
                            
                             {                                
                                Array.isArray(filteredVariables) ?
                                filteredVariables.filter(x => x.type == "secret").sort((a, b) => (a.key > b.key) ? 1 : -1).map((variable) => (
                                        <div className={styles.integrationRow} key={variable.key}>{variable.key}</div>
                                    )) : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AvailableVariables;
