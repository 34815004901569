import React from 'react';
import styles from 'styles/global/loading.module.css';


const Loading = ({size = "sm"}) => {
    var px = size == "sm" ? "16px" : "50px";

    const sizeStyle = {
        width: px,
        height: px
      };

    return (
        <div style={sizeStyle} className={styles['lds-ring']}><div style={sizeStyle}></div><div style={sizeStyle}></div><div style={sizeStyle}></div><div style={sizeStyle}></div></div>
    )
}
export default Loading;