import { useAuth } from 'contexts/AuthContext';
import React, {useEffect, useMemo, useState} from 'react';
import {SlArrowDown} from "react-icons/sl";
import {MdOutlineManageAccounts} from "react-icons/md";
import styles from 'styles/admin/intentStructure/intentStructure.module.css';
import { addCoreIntent, createFinetunedModel, createVertexExport, deleteCoreIntent, getAllCoreIntents, getAllCoreIntentsByAgent, getAllCoreIntentsByCollection, getCoreIntentCollections, getPromptByApi, helpSolvingIssueApi, updateCoreIntent } from 'services/intentService';
import { Button, FormText } from 'react-bootstrap';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, OutlinedInput, Select, Switch, TextField, TextareaAutosize } from '@mui/material';
import { useTheme } from 'styled-components';
import { useProgressIndicatorContext } from 'contexts/ProgressIndicatorContext';
import IntentModel from './intentModel';
import { getApiAgent, getApiAllAgents } from 'services/agentService';
import { createCatalogsService, getCatalogsService, updateCatalogsService } from 'services/catalogService';
import FormSelect from 'components/global/formSelect';
import CloseIcon from '@mui/icons-material/Close';
import { updateUserActionHistoryList } from 'services/userService';
import CategorizedDropdownSearch from 'components/global/categorizedDropdownSearch';
import { GetGptExampleQuestionsForSummary } from 'services/intentService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
PaperProps: {
    style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 250,
    },
},
};
const defaultFields = [
    {id:'zuO1DAK5OJ1xY9QWMPUj',defaultChecked:false,name:'validateQuestion',type:'flow',label:'Validate question', description:'Validate if the question is understood right.'},
    {id:'yN0V56DfqicnhkASn28y',defaultChecked:false,name:'requestIdentifier',type:'flow',label:'Request identifier', description:'Request the identifier.'},
    {id:'anU3K7AEnL7CTEO0VsKo',defaultChecked:false,name:'validateAnswer',type:'flow',label:'Validate answer', description:'Validate if the given answer was right.'},
    {id:'pctE230RfrRdV0ltHKpp',defaultChecked:false,name:'preferHandover',type:'ending',label:'Handover to human', description:'Prefer a handover.'},
    {id:'gNqIPuR3anfVv2OJiZzB',defaultChecked:false,name:'preferCallback',type:'ending',label:'Callback (prefered)', description:'Prefer a callback.'},
    {id:'NIuFp8jiXzvaPS7mSnKf',defaultChecked:false,name:'forceCallback',type:'ending',label:'Callback (Forced)', description:'Force a callback.'},
    {id:'LP0y01PrAY5LCQpY3DOR',defaultChecked:false,name:'sendSMS',type:'ending',label:'Send SMS', description:'Send a sms to the customer.'},
    {id:'Tfhx9MTSR6M8YLkejQJj',defaultChecked:false,name:'sendEmail',type:'ending',label:'Send Email', description:'Send a email to the customer.'}]
    
const IntentStructure = () => {
    const { setIndicatorState, resetIndicatorState, setInfoMessage, loadingState } = useProgressIndicatorContext();
    const { currentDbUser, currentUser } = useAuth();
    const [searchQuery, setSearchQuery] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);
    const [langMenuOpen, setLangMenuOpen] = useState(false);
    const [intentSummaryHelperDialog, setIntentSummaryHelperDialog] = useState(false);
    
    const [catalogs, setCatalogs]=useState([]);
    const [dbCatalogs, setDbCatalogs]=useState([]);
    const [selectedCatalog, setSelectedCatalog]=useState({id:"",name:"",type:""});
    
    const [allExistingIntentsForCollection, setAllExistingIntentsForCollection]=useState([]);
    const [intents, setIntents]=useState([]);
    const [selectedIntent, setSelectedIntent]=useState(null);
    const theme = useTheme();
    const [intentContexts, setIntentContexts] = React.useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [createNewIntent, setCreateNewIntent] = useState({id:"",name:"",parent:""});
    const [selectedLanguage, setSelectedLanguage] = useState("nl");
    
    const [openNewCatalogDialog, setOpenNewCatalogDialog] = useState(false);
    const [createNewCatalog, setCreateNewCatalog] = useState({name:'',fields:defaultFields});
    const [oldIntentNameId, setOldIntentNameId] = useState();

    const [selectedCatalogObj, setSelectedCatalogObj] = useState();
    const [promptQuestion, setPromptQuestion] = useState("");

    const [openDialogCategory, setOpenDialogCategory] = useState(false);
    const [createNewCategory, setCreateNewCategory] = useState({name:"", parent:""});

    const [intentSummaryHelper, setIntentSummaryHelper] = useState({triggeredIntent:'', intentThatMustBeTriggered:'', question:'', response:'', optimization: ''});
  
    const createNewIntentPopup = () => {
        setCreateNewIntent({name:"",parent:selectedIntent?.id || "", category: selectedIntent?.category || ""});
        setOpenDialog(true);
    }

    const createNewCategoryPopup = () => {
        setCreateNewCategory({name:"", parent:""});
        setOpenDialogCategory(true);
    }
    const editNewCategoryPopup = () => {
        setCreateNewCategory(createNewCategory);
        setOpenDialogCategory(true);
    }
    const setSelectedCategory = (cat) => {
        setCreateNewCategory(cat);
    }
    
    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setIntentContexts(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    
    useEffect(() => {
        getCatalogs();
    },[])
    useEffect(() => {
        getAllIntents();
    },[selectedCatalog])
    
    useEffect(() => {
        if(selectedIntent != null){
            // updateIntentPropSelect({target:{name:"clarificationQuestionType",value:"open"}})
            if(selectedIntent.nextStep == "finish"){
                setSelectedIntent(prev => ({...prev, "clarificationQuestionType":null}))
            }
        }
    },[selectedIntent?.nextStep])
    
    const getAllIntents = () => {
        let collection = selectedCatalog.name.replace("collection_","").replace("agent_","").toLowerCase();
        let agentIntents = [];
        // collection is of type agent then get the collection of this agent
        if(selectedCatalog.type == "agent"){
            getAllCoreIntentsByAgent(collection.replace(" ",".")).then(res => {
                agentIntents = res;
                getAllCoreIntentsByCollection(selectedCatalog.collection).then(intents => {
                    setAllExistingIntentsForCollection(intents);
                    let filteredIntents = intents.filter(x => x.agentSpecific == null || x.agentSpecific == '');
                    let mergedIntents = filteredIntents.concat(agentIntents);
                    setIntents(mergedIntents);
                })
            });
        }else{
            getAllCoreIntentsByCollection(collection).then(intents => {
                setAllExistingIntentsForCollection(intents);
                let filteredIntents = intents.filter(x => x.agentSpecific == null || x.agentSpecific == '');
                setIntents(filteredIntents);
            })
        }

        
    }
    const setIntent = (i) => {
        setOldIntentNameId({id: i.id, name: i.name.toLowerCase().replace(' ','.')})
        setSelectedIntent(i);
        setIntentContexts([...i.context.replaceAll('|',',').split(',')])
    }

    const getCatalogs = () => {

        getCatalogsService().then(result => {
            // used when editting a catalog to get the fields
            setDbCatalogs(result);
            getApiAllAgents().then(res => {
                let collections = result.map(x => ({"collection":x.name, "name": x.name, "type":"collection"}));

                let agents = res.map(x => ({"collection":x.collection, "type":"agent", "name": x.name, "idName": x.idName}));
                
                agents = agents.filter((value, index, self) => {
                    return self.findIndex(v => v.name === value.name) === index;
                  })

                collections = collections.concat(agents);
                setCatalogs(collections);
                setSelectedCatalog(collections[0]);

                 var selectedDbCatalog = result.filter(x => x.name == collections[0].name)[0];
                setSelectedCatalogObj(selectedDbCatalog);
            })
        })
    }

    const openDropDownSearch =() => {
        setMenuOpen(!menuOpen);
    }
    const openDropDownLangSearch = () => {
        setLangMenuOpen(!langMenuOpen);
    }
    const catalogSelected = (e) => {
        setSelectedCatalog(e);

        var selectedDbCatalog = dbCatalogs.filter(x => x.name == e.name)[0];
        setSelectedCatalogObj(selectedDbCatalog);

        setSearchQuery("");
        openDropDownSearch();
        setSelectedIntent(null);
    }

    const updatePhrases = (event) => {
        var currIntent = {...selectedIntent};
        currIntent.content[selectedLanguage]["phrases"] = event.target.value
        setSelectedIntent(currIntent);
    }

    const updateIntentLanguageToggleProp = (event) => {
        var currIntent = {...selectedIntent};
        currIntent.content[selectedLanguage][event.target.name] = event.target.value
        setSelectedIntent(currIntent);
    }
    const updateIntentLanguageTogglePropQuestions = (event) => {
        var value = event.target.value.split('\n');
        var currIntent = {...selectedIntent};
        currIntent.content[selectedLanguage][event.target.name] = value
        setSelectedIntent(currIntent);
    }
    const updateIntentExamples = (event) => {
        var value = event.target.value.split('\n');
        var currIntent = {...selectedIntent};
        currIntent.examples = value
        setSelectedIntent(currIntent);
    }
    const updateIntentToggleProp = (event) => {
        setSelectedIntent(prev => ({...prev, [event.target.name]:event.target.checked}))
    } 
    const updateIntentTogglePropCustomField = (event) => {
        setSelectedIntent(prev => ({...prev, customFields:[...prev.customFields.map(x => x.id != event.target.name ? x : {...x, checked: event.target.checked})]}));
    } 
    const updateIntentProp = (event) => {
        setSelectedIntent(prev => ({...prev, [event.target.name]:event.target.value}))
    } 
    const updateIntentPropSelect = (event) => {
        setSelectedIntent(prev => ({...prev, [event.target.name]:event.target.value}))
    }
    const updateIntentPropSelectCustomFieldEnding = (event) => {
        var fields = [...selectedIntent.customFields];
        var catalogFieldArray = catalogFields('ending').map(i => i.id);
        var newFields = fields.map(x => {
            if(catalogFieldArray.indexOf(x.id)  == -1){return x}
            if(x.id != event.target.value){return {...x, checked: false}}
            return {...x, checked: true}
        });
        setSelectedIntent(prev => ({...prev, customFields:newFields}));
    }

    const createFinetunedModelAction = () => {
        let finetunedModel = {language:selectedLanguage, catalog: selectedCatalog.name};
        setIndicatorState("creating",  null);
        createFinetunedModel(finetunedModel).then(x => {
            resetIndicatorState("Finetuned model created!"); 
        })
    }

    const createVertexExportAction = () => {
        let exportRequest = {language:selectedLanguage}
        setIndicatorState("exporting",  null);
        createVertexExport(exportRequest).then(x => {
            resetIndicatorState("Export created!"); 

            const element = document.createElement("a");
            const textFile = new Blob([x], {type: 'text/plain'}); //pass data from localStorage API to blob
            element.href = URL.createObjectURL(textFile);
            element.download = "VertexExport_"+selectedLanguage.toUpperCase()+"_"+Date.now()+".jsonl";
            document.body.appendChild(element); 
            element.click();
        })
    }
    
    const updateCreateNewCategory = (type, value) => {
        setCreateNewCategory(prev => ({...prev, [type]:value}))
    }
    const updateCategoryParent = (event) => {
        var value = event.target.value;
        setCreateNewCategory(prev => ({...prev, parent:value}))
    }

    const updateParent = (event) => {
        var value = event.target.value;
        setCreateNewIntent(prev => ({...prev, parent:value}))
    }
    const updateCreateNewIntent = (type, value) => {
        setCreateNewIntent(prev => ({...prev, [type]:value}))
    }

    const updateCreateNewCatalogName = (event) => {
        setCreateNewCatalog(prev => ({...prev, name: event.target.value.toLowerCase()}));
    }

    const handleCloseNewCatalogDialogDisagree = () => {
        setOpenNewCatalogDialog(false);
        setCreateNewCatalog({name:'', fields:defaultFields});
    }
    const camelize = (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
      }
    const handleCloseDialogDisagree = () => {
        setOpenDialog(false);
        setCreateNewIntent({id: "",name:"",parent:""});
      };
      
    const handleCloseDialogDisagreeNewCategory = () => {
        setOpenDialogCategory(false);
        setCreateNewCategory({name:""});
    };
    const handleCloseDialogAgreeNewCategory = () => {
        setOpenDialogCategory(true);
        setCreateNewCategory({name:""});
    };
      
    const handleCloseNewCatalogDialogDelete = () => {

    }
    const handleCloseNewCatalogDialogAgree = () => {
        setOpenNewCatalogDialog(false);
        setSelectedIntent(null);
        let mappedCreateNewCatalog = {...createNewCatalog, name: createNewCatalog.name.toLowerCase(), fields: createNewCatalog.fields.filter(x => x.label != '' && x.label != null).map(y => ({...y, name:camelize(y.label), defaultChecked: y.defaultChecked ? true : false}))};
        
        mappedCreateNewCatalog.fields.filter(x => x.name == "callback(Prefered)")[0].name = "preferCallback";
        mappedCreateNewCatalog.fields.filter(x => x.name == "callback(Forced)")[0].name = "forceCallback";
        mappedCreateNewCatalog.fields.filter(x => x.name == "handoverToHuman")[0].name = "preferHandover";

        if(createNewCatalog.id == null){
            setIndicatorState("Saving new catalog");
            let c = {id:mappedCreateNewCatalog.name,name:mappedCreateNewCatalog.name,type:"collection"};
            createCatalogsService(mappedCreateNewCatalog).then(res => {
                setSelectedCatalog(c);
                
                //setCatalogs(prev => [...prev, c]);
                getCatalogs();
                setCreateNewCatalog({name:'', fields:[]});
                resetIndicatorState("Catalog has been successfully created.");
                updateUserActionHistoryList(currentUser.uid, mappedCreateNewCatalog.name, "Created catalog: \"" + mappedCreateNewCatalog.name + "\"");
            })
        }else{
            setIndicatorState("Updating catalog");
            updateCatalogsService(mappedCreateNewCatalog).then(res => {
                setCreateNewCatalog({name:'', fields:[]});
                getCatalogsService().then(result => {
                    setDbCatalogs(result);
                    // update all intents from catalog with fields
                    updateCatalogIntents(result);
                    resetIndicatorState("Catalog has been successfully updated.");
                    updateUserActionHistoryList(currentUser.uid, mappedCreateNewCatalog.name, "Updated catalog: \"" + mappedCreateNewCatalog.name + "\"");
                });
            })
        }
    }


    const updateCatalogIntents = async (catalogs) => {
        var intents = await getAllCoreIntentsByCollection(selectedCatalog.name);
        var fields = catalogs.filter(x => x.name == selectedCatalog.name)[0].fields;

        intents.map(x => {
            fields.map(field => {
                if(x.customFields.map(i => i.id).indexOf(field.id) == -1){
                    x.customFields.push({id:field.id, checked: field.defaultChecked, type: field.type});
                };
            })       
        });
        intents.map(x => updateCoreIntent(x));
    }

    const updateFieldValue = (id, field, value) => {
        var fields = [...createNewCatalog.fields];
        var editField = fields.filter(x => x.id == id)[0]?.type;
        if(editField == 'ending' && field == 'defaultChecked' && value == true){
            var newFields = fields.map(x => {
                if(x.type != 'ending'){return x}
                if(x.id != id){return {...x, defaultChecked: false}}
                return {...x, defaultChecked: true}
            });
            setCreateNewCatalog(prev => ({...prev, fields:newFields}));
        }else{
            setCreateNewCatalog(prev => ({...prev, fields: prev.fields.map(x=> x.id != id ? x : {...x, [field]: value})}));
        }
    }

    const getRandomNumber = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

    const addNewFieldsRow = (type) => {
        let fields = [...createNewCatalog.fields, {type:type, id: getRandomNumber(20)}];
        setCreateNewCatalog(prev => ({...prev, fields:fields}));
    }

    const deletefieldsRow = (id) => {
        setCreateNewCatalog(prev => ({...prev, fields: prev.fields.filter(x => x.id != id)}));
    }
    const setOpenNewCatalogDialogEdit = () => {
        var dbCatalog = dbCatalogs.filter(x => x.name == selectedCatalog.name)[0];
        if(dbCatalog != null){
          setCreateNewCatalog(dbCatalog);
        }
        setOpenNewCatalogDialog(true);
    }

    const getPrompt = () => {
        setIndicatorState("Retrieving prompt");
        getPromptByApi({catalog: selectedCatalog.name, language: selectedLanguage, question: promptQuestion}).then(res => {
            resetIndicatorState("Prompt successfully retrieved.");
            copyContent(res.object);
        })
    }
    const copyContent = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            resetIndicatorState('Content copied to clipboard');
        } catch (err) {
            resetIndicatorState('Failed to copy: ', err);
        }
    }

    const catalogFields = (type) =>  dbCatalogs.filter(x => x.name == selectedCatalog.collection)[0].fields.filter(x => x.type == type);

    const handleCloseDialogAgree = () => {
        setOpenDialog(false);
        var creatIntent = {...createNewIntent};

        var newIntent = new IntentModel().getNewJsonObject();
        var dbCatalog = dbCatalogs.filter(x => x.name == selectedCatalog.collection)[0];

        newIntent.customFields = dbCatalog.fields.map(x => ({id:x.id, checked:x.defaultChecked ? true : false, type: x.type}));

        let collection = selectedCatalog.collection.toLowerCase();
        
        if(selectedCatalog.type == 'agent'){
            newIntent.agentSpecific = selectedCatalog.idName;
        }else{
            newIntent.agentSpecific = null;
        }
       
        if(creatIntent.parent == "" || creatIntent.parent == undefined){
            newIntent.id = collection + "." + creatIntent.id.toLowerCase();
        }else{
            newIntent.id = creatIntent.parent + "." + creatIntent.id.toLowerCase();
        }
        
        newIntent.name = creatIntent.name.charAt(0).toUpperCase() + creatIntent.name.slice(1);
        newIntent.intId = allExistingIntentsForCollection.map(x => x.intId).filter(element => typeof element === 'number').sort(function(a, b) {return a - b;}).reverse()[0]+1 || 1;
        
        newIntent.collection = collection;
        newIntent.context = creatIntent.parent;
        
        var headCategory = newIntent.id.substring(newIntent.id.indexOf('.') + 1);
        var cat = headCategory.substring(0, headCategory.indexOf('.'));
        var catCap = cat.charAt(0).toUpperCase() + cat.slice(1);
        if (catCap == ""){
            catCap = creatIntent.id.charAt(0).toUpperCase() + creatIntent.id.slice(1);
        }
        newIntent.category = selectedIntent?.category || catCap;
        
        setIndicatorState("saving",  null);
        addCoreIntent(newIntent).then(x => {
            resetIndicatorState("Intent succesfully saved!"); 
            setSelectedIntent(newIntent);
            getAllIntents();
            setCreateNewIntent({id: "", name:"",parent:""});
            setIntentContexts([]);
        })
      };

    const saveIntent = () => {
        var saveIntent = {...selectedIntent};
        saveIntent.context = intentContexts.filter(x => x != "").join("|");

        setIndicatorState("saving",  null);
        updateCoreIntent(saveIntent).then(x => {
            resetIndicatorState("Intent succesfully updated!"); 
            getAllIntents();
        })
    }

    const deleteIntent = () => {
        if(selectedIntent == null)
            return;

        if (window.confirm("Are your sure you wan't to delete this intent including al his childs?")) {
            setIndicatorState("deleting",  null);
            deleteCoreIntent(selectedIntent.id).then(x => {
                setSelectedIntent(null);
                getAllIntents();
                resetIndicatorState("Intent succesfully deleted!"); 
            })
        }
    }

    const getLanguageTranslation = (lang) => {
        switch (lang) {
            case "nl":
                return "Netherlands";
                break;
            case "de":
                return "German";
                break;
            case "en":
                return "English";
                break;
            default:
                return "";
        }
    }
    const setLanguage = (lang) => {
        setSelectedLanguage(lang);
        setLangMenuOpen(false);
    }

    const finishValue = () => {
        var result = selectedIntent?.customFields.filter(x => catalogFields('ending').map(u => u.id).indexOf(x.id) > -1).filter(x => x.checked)[0]?.id;
        if(result == null)
        {
            result = "";
        }
        return result;
    }

    const getPhrasesForSummary = () => {
        var lang = getLanguageTranslation(selectedLanguage);
        setIndicatorState("loading",  null);
        GetGptExampleQuestionsForSummary({summary:selectedIntent.summary, language: lang}).then(resp => {
            resetIndicatorState("Example phrases loaded!"); 
            var currIntent = {...selectedIntent};
            var phrases = currIntent.content[selectedLanguage]["phrases"];
            if(phrases != ''){
                phrases += "\n";
            }
            resp.object.filter(x => {
                phrases += x + "\n";
            });
            currIntent.content[selectedLanguage]["phrases"] = phrases;
            setSelectedIntent(currIntent);
        })
    }

    const helpSolvingIssue = () => {
        setIntentSummaryHelper({triggeredIntent:'', intentThatMustBeTriggered:'', question:'', response:'', optimization:''});

        setIntentSummaryHelperDialog(true);
    }
    const triggerIntentSummaryHelper = () => {
        setIndicatorState("Retrieving data");
        let data = {triggeredIntent:intentSummaryHelper.triggeredIntent, intentThatMustBeTriggered:intentSummaryHelper.intentThatMustBeTriggered, Question:intentSummaryHelper.question};
        helpSolvingIssueApi(data).then(resp => {
            resetIndicatorState("Data succesfully retrieved.");
            setIntentSummaryHelper({...intentSummaryHelper, response: resp.response, optimization: resp.optimization});
        });
    }

    const setIntentSummaryHelperDialogClosed = () => {
        setIntentSummaryHelperDialog(false);
    }

    const updateIntentThatMustBeTriggered = (e) => {
        setIntentSummaryHelper({...intentSummaryHelper, intentThatMustBeTriggered: e.target.value});
    }
    const updateTriggeredIntent = (e) => {
        setIntentSummaryHelper({...intentSummaryHelper, triggeredIntent: e.target.value});
    }
    const updateQuestion = (question) => {
        setIntentSummaryHelper({...intentSummaryHelper, question: question});
    }

    // const createThree = (parentCategoryId, res) => {
    //     var item = selectedCatalogObj?.categories.filter(x => x.id == parentCategoryId)[0];
    //     var categoriesToBuild = selectedCatalogObj?.categories.filter(x => x.parentCategoryId == parentCategoryId);
    //     if(categoriesToBuild.length > 0){
    //         return categoriesToBuild?.map(x => {
    //             return createThree(x.id, res);
    //         });
    //     }
        
    //     if(item != null){
    //         res = res + item?.name;
    //         return res;
    //     }
    // }


    return (
        <div className={`${styles.intentStructure}`}>
            <div className={`${styles.header} ${styles.widget}`}>
                <h1>Intent structure</h1>
                <h3>Configure intent catalogs</h3>
                {/* <div onClick={createFinetunedModelAction} className={`${styles.buttonStyle} ${styles.rightButton}`}>Create finetuned model</div> */}
                <div onClick={helpSolvingIssue} className={`${styles.buttonStyle} ${styles.rightButton}`}>Help solving issue</div>
            </div>
            <div className={`${styles.catalogSelector} ${styles.widget}`}>
                <div className={styles.optionsAgentSelection} >
                    <div className={styles.optionsHeader}><MdOutlineManageAccounts
                        className={styles.optionsIcon}/> Select catalog
                    </div>
                    

                    <div className={styles.dropdowns}>

                        <div className={styles.dropdownContainer}>
                            <CategorizedDropdownSearch subHeader="type" data={catalogs} type="flow" selected={selectedCatalog} setSelected={catalogSelected}/>
                        </div>
                        
                        {/* <div className={styles.dropdown} >
                            <button onClick={openDropDownSearch} className={styles.dropbtn}>{selectedCatalog.name} <SlArrowDown className={`${styles.dropdownIcon} ${menuOpen ? styles.rotate : null}`}/></button>
                            <div id={styles.myDropdown} className={`${styles["dropdown-content"]} ${menuOpen ? styles.show : null}`}>
                                <input value={searchQuery} type="text" placeholder="Zoeken" id={styles.myInput} onChange={(e) => setSearchQuery(e.target.value)} />
                                {filteredCatalogs.sort((a,b) => a.name - b.name).map((s) => {return (<span key={s.name} onClick={() => catalogSelected(s)}>{s.name}</span>)})}
                                {filteredCatalogs.length === 0 ? <span className={styles.noResult}>Geen resultaten</span> : null}
                            </div>
                        </div> */}

                        <div className={styles.dropdown}>
                            <button onClick={openDropDownLangSearch} className={styles.dropbtn}>{selectedLanguage}
                                <SlArrowDown className={`${styles.dropdownIcon} ${langMenuOpen ? styles.rotate : null}`}/>
                            </button>
                            <div id={styles.myDropdown} className={`${styles["dropdown-content"]} ${langMenuOpen ? styles.show : null}`}>
                                {["nl","de","en"].sort().map((key) => {
                                    return <span key={key} onClick={() => setLanguage(key)}>{getLanguageTranslation(key)}</span>
                                })}
                            </div>
                        </div>
                        
                        <button onClick={() => setOpenNewCatalogDialog(true)} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}>NEW</button>
                        <button onClick={setOpenNewCatalogDialogEdit} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}>EDIT</button>

                    </div>
                    <div className={styles.dropdowns}>  
                        <div className={styles.flex}>                         
                            <TextField style={{flex:1}} label="Prompt question" id="promptQuestion" name="promptQuestion" size='small' value={promptQuestion}  onChange={e => setPromptQuestion(e.target.value)}/>
                            <button onClick={getPrompt} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}>GET PROMPT</button>
                        </div> 
                    </div>
                </div>
            </div>
            <div className={`${styles.intents} ${styles.widget}`}>
                <div className={styles.intentList}>
                {/* <button onClick={createNewCategoryPopup} className={`${styles.buttonStyle} ${styles.buttonNewCategory} ${styles.submitButton}  ${styles.deleteButton}`}>NEW CATEGORY</button> */}
                {/* <button onClick={editNewCategoryPopup} className={`${styles.buttonStyle} ${styles.buttonNewCategory} ${styles.submitButton}  ${styles.deleteButton}`}>EDIT CATEGORY</button> */}

                    {/* {createThree('', '')} */}
                    {intents.sort((a, b) => (a.id > b.id) ? 1 : -1).map(intent => (
                        <div key={intent.id} onClick={() => setIntent(intent)} className={`${styles.intentListRow} ${intent.isActive ? null : styles.intentInActive} ${intent.id == selectedIntent?.id ? styles.intentSelected : null}`} style={{marginLeft: (intent.id.split('.').length-2)*15+"px"}}>
                            {intent.id.substring(intent.id.lastIndexOf('.')+1)}
                            <div className={styles.intentId}>{intent.id.substring(intent.id.indexOf('.')+1)}</div>
                            
                            </div>
                    ))}
                </div>
            </div>

            
            <div className={`${styles.intentDetails} ${styles.widget}`}>
                <div className={styles.actionRow}>
                    <button onClick={createNewIntentPopup} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}>NEW INTENT</button>
                    {selectedIntent && (
                        <>
                            <button onClick={saveIntent} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}>SAVE</button>
                            <button onClick={deleteIntent} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}>DELETE</button>
                        </>
                    )}
                </div>
           
                {selectedIntent != null &&
                (
                    <div className={styles.intentDetailsForm}>
                        <div className={styles.formGroup}>
                            <div className={styles.formRowRight}>
                                <FormControlLabel control={<Switch size='small' />} checked={selectedIntent.isActive} name="isActive" onChange={updateIntentToggleProp} />
                            </div>
                            <div className={styles.formRow}>
                                <TextField disabled fullWidth label="Id" id="id" size='small' value={selectedIntent.id}/>
                                <TextField disabled fullWidth label="IntId" id="intId" size='small'value={selectedIntent.intId}/>
                            </div>
                           
                            <TextField fullWidth label="Name" id="name" name="name" size='small' value={selectedIntent.name}  onChange={updateIntentProp}/>
                            <TextField disabled fullWidth label="Category" id="category" size='small' value={selectedIntent.category}/>

                            {/* <FormControl fullWidth>
                                    <InputLabel id="parentLabel">Parent</InputLabel>
                                    <Select
                                    size='small'
                                    labelId="parentLabel"
                                    name="parent"
                                    id="parentSelect"
                                    value={selectedIntent.id.substring(0,selectedIntent.id.lastIndexOf('.'))}
                                    label="Parent"
                                    onChange={updateParent}
                                    >
                                        {intents.filter(x => x.id != selectedIntent.id).map(x => (
                                            <MenuItem value={x.id}>{x.id}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}

                            <FormControl>
                                <InputLabel id="demo-multiple-name-label">Context</InputLabel>
                                <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                defaultValue = ""
                                multiple
                                size='small'
                                value={intentContexts}
                                onChange={handleChange}
                                input={<OutlinedInput label="Name" />}
                                MenuProps={MenuProps}>
                                {intents.filter(x => x.id.indexOf(selectedIntent.id) <= -1).map((intent) => (
                                    <MenuItem key={intent.id} value={intent.id}>
                                        {intent.id}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>

                            <div className={styles.childContext}>
                                <div className={styles.childContextHeader}>Intents with this context as parent</div>
                                <div className={styles.childContextTags}>
                                    {intents.filter(x => x.context.indexOf(selectedIntent.id) > -1).map(intent => (
                                        <div key={intent.id} onClick={() => setIntent(intent)}  className={styles.intentTag} id={intent.id}>{intent.id}</div>
                                    ))}
                                </div>
                            </div>

                            <TextField fullWidth label="Labels" id="labels" size='small' value={selectedIntent.labels} name="labels" onChange={updateIntentProp}/>

                            <div className={styles.intentTrigger}>
                                <TextField fullWidth label="Required entities key/value comma seperated (color:green,size:large)" id="requiredEntities" size='small' value={selectedIntent.requiredEntities || ""} name="requiredEntities" onChange={updateIntentProp}/>
                                <TextField multiline rows={3} fullWidth label="Summary" id="summary" size='small' name="summary" value={selectedIntent.summary} onChange={updateIntentProp}/>
                                <TextField multiline rows={4} fullWidth label="Examples" id="examples" size='small' name="examples" value={selectedIntent?.examples?.join('\n') || ""}  onChange={updateIntentExamples} />
                            </div>
                            <div className={styles.formRow}>
                                <FormControl fullWidth>
                                    <InputLabel id="nextStepLabel">NextStep</InputLabel>
                                    <Select
                                    size='small'
                                    defaultValue = ""
                                    labelId="nextStepLabel"
                                    name="nextStep"
                                    id="nextStepSelect"
                                    value={selectedIntent.nextStep}
                                    label="NextStep"
                                    onChange={updateIntentPropSelect}
                                    >
                                    <MenuItem value={"clarification"}>Clarification</MenuItem>
                                    <MenuItem value={"finish"}>Finish</MenuItem>
                                    </Select>
                                </FormControl>

                                {selectedIntent.nextStep == "clarification" && (
                                    <FormControl fullWidth>
                                        <InputLabel id="clarificationQuestionTypeLabel">Clarification question type</InputLabel>
                                        <Select
                                        size='small'
                                        defaultValue = ""
                                        name="clarificationQuestionType"
                                        labelId="clarificationQuestionTypeLabel"
                                        id="clarificationQuestionTypeSelect"
                                        value={selectedIntent.clarificationQuestionType}
                                        label="Clarification question type"
                                        onChange={updateIntentPropSelect}
                                        >
                                        <MenuItem value=""></MenuItem>
                                        <MenuItem value={"closed"}>Closed</MenuItem>
                                        <MenuItem value={"open"}>Open</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            </div>

                            <div className={styles.formRow}>
                                <FormControl fullWidth>
                                    <InputLabel id="yesNoAnswerLabel">Yes or no answer</InputLabel>
                                    <Select
                                    size='small'
                                    defaultValue = ""
                                    name="isYesNoAnswer"
                                    labelId="yesNoAnswerLabel"
                                    id="yesNoAnswerSelect"
                                    value={selectedIntent.isYesNoAnswer}
                                    label="Yes or no answer"
                                    onChange={updateIntentPropSelect}
                                    >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={"yes"}>Yes</MenuItem>
                                    <MenuItem value={"no"}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControlLabel control={<Switch size='small' />} label="Is popular" checked={selectedIntent.isPopular} name="isPopular" onChange={updateIntentToggleProp} />
                            </div>
                            
                            <div className={styles.languageSpecific}>
                                <h3>Language specific</h3>
                                <TextField multiline rows={3} fullWidth label="Response" id="response" size='small' name="response" value={selectedIntent.content[selectedLanguage].response || ""}  onChange={updateIntentLanguageToggleProp} />
                                <TextField multiline rows={3} fullWidth label="Validation" id="validation" size='small' name="validation" value={selectedIntent.content[selectedLanguage].validation || ""}  onChange={updateIntentLanguageToggleProp} />
                                <TextField multiline rows={3} fullWidth label="Questions" id="questions" size='small' name="questions" value={selectedIntent.content[selectedLanguage].questions.join('\n') || ""}  onChange={updateIntentLanguageTogglePropQuestions} />
                                <TextField multiline rows={1} fullWidth label="Chip" id="chip" size='small' name="chip" value={selectedIntent.content[selectedLanguage].chip || ""}  onChange={updateIntentLanguageToggleProp} />
                                <TextField multiline rows={3} fullWidth label="Intent trigger confirmation" id="intentTriggerConfirmation" size='small' name="intentTriggerConfirmation" value={selectedIntent.content[selectedLanguage].intentTriggerConfirmation || ""}  onChange={updateIntentLanguageToggleProp} />
                            </div>

                            <TextField multiline rows={3} fullWidth label="Example question" id="exampleQuestion" size='small' name="exampleQuestion" value={selectedIntent.exampleQuestion}  onChange={updateIntentProp} />
                            
                            <h3>Select flows</h3>
                            <div className={`${styles.formRow} ${styles.fieldsContainer}`}>
                                {catalogFields('flow').map(field => (
                                    <FormControlLabel key={field.id} control={<Switch size='small' />} label={field.label} checked={selectedIntent?.customFields?.filter(x => x.id == field.id)[0]?.checked} name={field.id} onChange={updateIntentTogglePropCustomField} />
                                ))}
                            </div>

                            <h3>Select specific ending</h3>

                            <div className={styles.formRow}>
                                <FormControl fullWidth>
                                    <InputLabel id="endingLabel">Ending</InputLabel>
                                    <Select
                                        size='small'
                                        defaultValue = ""
                                        labelId="endingLabel"
                                        name="ending"
                                        id="nextStepSelect"
                                        value={finishValue()}
                                        label="Ending"
                                        onChange={updateIntentPropSelectCustomFieldEnding}
                                    >
                                        <MenuItem value=""></MenuItem>
                                        {catalogFields('ending')?.sort((a, b) => (a.label > b.label) ? 1 : -1).map(field => (<MenuItem value={field.id}>{field.label}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </div>
          
                        </div>
                    </div>
                )}

            </div>
            <div className={`${styles.intentPhrases} ${styles.widget}`}>
            {selectedIntent != null && (
                <>
                    <button onClick={getPhrasesForSummary} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}>GENERATE</button>
                    <textarea className={styles.phrasesTextArea} onChange={updatePhrases} value={selectedIntent.content[selectedLanguage].phrases || ""}></textarea>
                </>
            )}
            </div>

            <Dialog
                open={openNewCatalogDialog}
                onClose={handleCloseNewCatalogDialogDisagree}
                fullWidth={true}
                maxWidth='md'
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Create new catalog
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    <div className={styles.newIntentContainer}>
                        <TextField autoComplete="off" data-lpignore="true" fullWidth label="Name" id="name-search" size='small' value={createNewCatalog.name} onChange={updateCreateNewCatalogName}/>
                    </div>

                    <div className={styles.newIntentContainerFieldsHeader}>Flows:</div>
                    {createNewCatalog.fields.filter(x => x.type=='flow').map(obj => (
                        <div key={obj.id} className={styles.newIntentContainerRow}>
                            {defaultFields.map(u => u.id).indexOf(obj.id) == -1 && (
                                <CloseIcon className={styles.closeIcon} onClick={(e) => deletefieldsRow(obj.id)}/>
                            )}
                            <TextField disabled={defaultFields.map(u => u.id).indexOf(obj.id) > -1} autoComplete="off" data-lpignore="true" fullWidth label="Label" className={styles.fieldName} size='small' value={obj.label} onChange={(e) => updateFieldValue(obj.id, 'label', e.target.value)}/>
                            <TextField disabled={defaultFields.map(u => u.id).indexOf(obj.id) > -1} autoComplete="off" data-lpignore="true" fullWidth label="Description" className={styles.fieldDescription} size='small' value={obj.description} onChange={(e) => updateFieldValue(obj.id, 'description', e.target.value)}/>                          
                           <FormControlLabel className={styles.fieldDefault} control={<Checkbox size="large" disabled={defaultFields.map(u => u.id).indexOf(obj.id) > -1} checked={obj.defaultChecked} />} label="Default checked" onChange={(e) => updateFieldValue(obj.id, 'defaultChecked', e.target.checked)} />
                        </div>
                    ))}
                    <Button className={styles.addNewFieldsRow} onClick={() => addNewFieldsRow('flow')}>Add field</Button>

                    <div className={styles.newIntentContainerFieldsHeader}>Endings:</div>
                    {createNewCatalog.fields.filter(x => x.type=='ending').map(obj => (
                        <div key={obj.id} className={styles.newIntentContainerRow}>
                            {defaultFields.map(u => u.id).indexOf(obj.id) == -1 && (
                                <CloseIcon className={styles.closeIcon} onClick={(e) => deletefieldsRow(obj.id)}/>
                            )}
                            <TextField disabled={defaultFields.map(u => u.id).indexOf(obj.id) > -1} autoComplete="off" data-lpignore="true" fullWidth label="Label" className={styles.fieldName} size='small' value={obj.label} onChange={(e) => updateFieldValue(obj.id, 'label', e.target.value)}/>
                            <TextField disabled={defaultFields.map(u => u.id).indexOf(obj.id) > -1} autoComplete="off" data-lpignore="true" fullWidth label="Description" className={styles.fieldDescription} size='small' value={obj.description} onChange={(e) => updateFieldValue(obj.id, 'description', e.target.value)}/>                          
                           <FormControlLabel className={styles.fieldDefault} control={<Checkbox disabled={defaultFields.map(u => u.id).indexOf(obj.id) > -1} size="large" checked={obj.defaultChecked} />} label="Default checked" onChange={(e) => updateFieldValue(obj.id, 'defaultChecked', e.target.checked)} />
                        </div>
                    ))}
                    <Button className={styles.addNewFieldsRow} onClick={() => addNewFieldsRow('ending')}>Add field</Button>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleCloseNewCatalogDialogDelete}>Delete</Button> */}
                    <Button onClick={handleCloseNewCatalogDialogDisagree} autoFocus>Cancel</Button>
                    <Button onClick={handleCloseNewCatalogDialogAgree}>{createNewCatalog.id == null ? 'Create' : 'Update'}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialog}
                fullWidth={true}
                maxWidth='sm'
                onClose={handleCloseDialogDisagree}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Create new intent
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    <div className={styles.newIntentContainer}>
                        <TextField autoComplete="off" data-lpignore="true" fullWidth label="Id (last part)" id="name-search" size='small' value={createNewIntent.id} onChange={(e) => updateCreateNewIntent('id', e.target.value)}/>
                        <TextField autoComplete="off" data-lpignore="true" fullWidth label="Name" id="name-search" size='small' value={createNewIntent.name} onChange={(e) => updateCreateNewIntent('name', e.target.value)}/>


                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="parentLabel">Parent</InputLabel>
                            <Select
                            margin="dence"
                            placeholder='test'
                            defaultValue = ""
                            size='small'
                            multiline={false}
                            labelId="parentLabel"
                            name="parent"
                            id="parentSelect"
                            value={createNewIntent.parent}
                            label="Parent"
                            onChange={updateParent}
                            >
                                {intents.map(x => (
                                    <MenuItem key={x.id} value={x.id}>{x.id}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogDisagree} autoFocus>Cancel</Button>
                    <Button onClick={handleCloseDialogAgree}>Create</Button>
                </DialogActions>
            </Dialog>



            <Dialog
                open={intentSummaryHelperDialog}
                fullWidth={true}
                maxWidth='md'
                onClose={setIntentSummaryHelperDialogClosed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Ask Gpt why one intent is chosen over another intent
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    <div className={styles.newIntentContainer}>
                        <TextField autoComplete="off" data-lpignore="true" fullWidth label="Asked question" id="name-search" size='small' value={intentSummaryHelper.question} onChange={(e) => updateQuestion(e.target.value)}/>


                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="parentLabel">Triggerd intent</InputLabel>
                            <Select
                            margin="dence"
                            defaultValue = ""
                            size='small'
                            multiline={false}
                            labelId="parentLabel"
                            name="parent"
                            id="parentSelect"
                            value={intentSummaryHelper.triggeredIntent}
                            label="Parent"
                            onChange={updateTriggeredIntent}
                            >
                                {intents.map(x => (
                                    <MenuItem key={x.id} value={x.id}>{x.id}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="parentLabel">Intent that must be triggered</InputLabel>
                            <Select
                            margin="dence"
                            defaultValue = ""
                            size='small'
                            multiline={false}
                            labelId="parentLabel"
                            name="parent"
                            id="parentSelect"
                            value={intentSummaryHelper.intentThatMustBeTriggered}
                            label="Parent"
                            onChange={updateIntentThatMustBeTriggered}
                            >
                                {intents.map(x => (
                                    <MenuItem key={x.id} value={x.id}>{x.id}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <h3>Clarification:</h3>
                        <textarea disabled className={styles.textAreaStyle} rows={8} value={intentSummaryHelper.response}></textarea>

                        <h3>Optimization:</h3>
                        <textarea disabled className={styles.textAreaStyle} rows={8} value={intentSummaryHelper.optimization}></textarea>
                    </div>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={triggerIntentSummaryHelper} autoFocus>Ask</Button>
                    <Button onClick={setIntentSummaryHelperDialogClosed}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* <Dialog
                open={openDialogCategory}
                fullWidth={true}
                maxWidth='sm'
                onClose={handleCloseDialogDisagreeNewCategory}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Create new category
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    <div className={styles.newIntentContainer}>
                        <TextField autoComplete="off" data-lpignore="true" fullWidth label="Name" id="name-search" size='small' value={createNewCategory?.name} onChange={(e) => updateCreateNewCategory('name', e.target.value)}/>


                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="parentCategoryLabel">Parent category</InputLabel>
                            <Select
                            margin="dence"
                            placeholder='parent category'
                            defaultValue = ""
                            size='small'
                            multiline={false}
                            labelId="parentCategoryLabel"
                            name="parentCategory"
                            id="parentCategorySelect"
                            value={createNewCategory?.parent}
                            label="Parent category"
                            onChange={updateCategoryParent}
                            >
                                {selectedCatalogObj?.categories.map(x => (
                                    <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogDisagreeNewCategory} autoFocus>Cancel</Button>
                    <Button onClick={handleCloseDialogDisagreeNewCategory}>Create</Button>
                </DialogActions>
            </Dialog> */}
        </div>
    )
}
export default IntentStructure;
