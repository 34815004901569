import React, {useEffect, useState} from "react";
import styles from 'styles/admin/agent/intentResponse.module.css';



const IntentResponse = ({intent, lang, id, startSpeakParent, stopSpeakParent, isPlaying, selectedIntent}) => {
    const [response, setResponse] = useState();

    useEffect(() => {
        setResponse(intent?.response);

    }, []);

    useEffect(() => {
        setResponse(intent?.response);
    });



    return(<>
            <span className={styles.intentResponseLanguageLabel}>{lang.toUpperCase()}:</span>
        <div className={`${styles.intentResponseLanguage} ${selectedIntent ? styles.responseLarge : null}`}>

            {/*<span className={styles.bold}>{lang.toUpperCase()} - </span>*/}
            <span>{response}</span>
            {/*<textarea onBlur={saveResponse} value={response} onChange={updateTextArea}></textarea>*/}
        </div></>
    )
}
export default IntentResponse;
