import Snackbar from '@mui/material/Snackbar';
import { useProgressIndicatorContext } from 'contexts/ProgressIndicatorContext';
import { useEffect, useState } from 'react';
import styles from 'styles/admin/global/progressIndicator.module.css';

const ProgressIndicator = () => {
    const { loadingState, message } = useProgressIndicatorContext();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
    }, [loadingState]);

    useEffect(() => {
        if(message == null)
            return;

        const obj = {
            message:message,
            show:true,
            close: (o) => setMessages(prev => ([...prev.filter(x => x != o)])),
            hide: (o) =>  setTimeout(() => {
                setMessages(prev => ([...prev.filter(x => x != o)]));
            },5000)
        };
        setMessages(prev => ([...prev, obj]))
        obj.hide(obj);
    }, [message]);

    return (
        <>
        {loadingState && 
            <div className={styles.loading}>
                <div className={styles.ldsRipple}><div></div><div></div></div>
                <div className={styles.loadingText}>{loadingState}...</div>
            </div>
        }
        <div className={styles.snackbarContainer}>
            {messages?.map(x => (
                    <Snackbar 
                    key={x.message}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    sx={{position:"relative"}}
                    open={x.show}
                    severity="info"
                    onClick={() => x.close(x)}
                    message={x.message}
                    />
            ))

            }
            </div>
        </>
    )
}

export default ProgressIndicator;