import React, {useEffect, useState, useRef} from 'react';
import styles from 'styles/admin/agent/chatbox.module.css';
import {MdRefresh} from "react-icons/md";
import {IoClose} from "react-icons/io5";
import {FiSend} from "react-icons/fi";
import { useAgentContext } from 'contexts/AgentContext';
import { chat } from 'services/chatSessionService';
import Loading from 'components/global/loading';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { updateUserActionHistoryList } from 'services/userService';
import { useAuth } from 'contexts/AuthContext';
import DeleteIcon from '@mui/icons-material/Delete';

const Chatbox = () => {
    const { currentUser } = useAuth();
    const chatRef = useRef(null);
    const inputRef = useRef();
    const [sessionId, setSession] = useState("");
    const [language, SetLanguage] = useState("nl");
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isEnded, setIsEnded] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [historyMessages, setHistoryMessages] = useState([]);
    
    const { agent } = useAgentContext();
    
    const isTypingLines = [
        'Is aan het typen...',
        'ist am Tippen...',
        'Is typing...'
    ]

    useEffect(() => {
        if(expanded){
            startNewConversation();
        }
    }, [expanded, language, agent])

    useEffect(() => {
        if(messages.length > 0 && messages[messages.length -1].source == "human"){
            askNewResponse();
        }
        else if(messages.length > 0 && messages[messages.length -1].source == "ai" && historyMessages?.length != 0){
            setMessages([...messages, historyMessages[0]])
            
            let messArray = [...historyMessages];
            messArray.shift();
            setHistoryMessages(messArray);
        }
        if(messages.length > 0){
            chatRef.current?.scrollIntoView({behavior: 'smooth'});
        }

    }, [messages])

    const toggleExpand = () => {
        setExpanded(!expanded);
        if(!expanded){
            setMessages([]);
        }
    }
    const repeatConversation = async () => {  
        setMessages([]);
        const history = [...messages.filter(x => x.source == "human")]; 
        var newMessages = await startNewConversation();
        
        setMessages([...newMessages, {
            source:"human",
            text:history[0].text
        } ])
        history.shift();
        setHistoryMessages(history);
        
    }
    // const repeatMessage = async (message) => {
    //     let data = await doChatRequest(agent.dialogflowName, sessionId, message, language);
    //     setIsEnded(data.isEnded);
    //     setSession(data.sessionId);
    //     addMessages(data);
    // }
    


    const startNewConversation =  async () => {
        setLoading(true);
        setIsEnded(false);
        updateUserActionHistoryList(currentUser.uid, agent.name, "Tested conversation");
        let data = await doChatRequest(agent.dialogflowName, "", "", language);
        setSession(data.sessionId);
        return setStartMessages(data);
    }
    const setStartMessages = (data) => {
        var newMessages = [];
        if(data.messages != null){
            data.messages.forEach(message => {
                newMessages.push({
                    source:"ai",
                    text:message
                })
            });
            setMessages(newMessages);
        }
        setLoading(false);
        return newMessages;
    }

    const addMessages = (data) => {
        var newMessages = [];
        data.messages.forEach(message => {
            newMessages.push({
                source:"ai",
                text:message
            })
        });
        if(newMessages.length == 0){
            return;
        }
        setMessages([...messages, ...newMessages]);
    }
    const inputKeyDown = (e) => {
        if(e.keyCode == 13){
            submitInput();
        }
    }

    const submitInput = async () => {
        if(isEnded)
            return;

        let message = inputRef.current.value;
        setMessages([...messages, {
            source:"human",
            text:message
        } ])
        inputRef.current.value = "";
    }
    const askNewResponse = async () => {
        let message = messages[messages.length -1].text;
        let data = await doChatRequest(agent.dialogflowName, sessionId, message, language);
        setIsEnded(data.isEnded);
        setSession(data.sessionId);
        addMessages(data);
    }

    const doChatRequest = async (name, sessionId, message, language) => {
        setIsTyping(true);
        return await chat(name, sessionId, message, language).then(x => {
             if(!x.isError){
                setIsTyping(false);
                return x.object
             }else{
                //error
             }
        })
    }
    const handleText = () => expanded ? "Close me" : "Try me";

    const handleChange = (e) => {
        SetLanguage(e.target.value);
    }

    const languageToInt = () => {
        if(language == "nl"){
            return 0;
        }
        if(language == "de"){
            return 1;
        }
        if(language == "en"){
            return 2;
        }
    }
    return(
        <>
        {agent &&
            <div data-expanded={expanded} className={styles.chatboxContainer}>
                <div onClick={toggleExpand} className={styles.handle}><div className={styles.toggleText}>{handleText()}</div></div>
                <div className={styles.chatboxHeader}>
                    <div className={styles.headerTitle}>{agent.name}</div>
                        <Select
                            labelId="languageSelect"
                            id="languageSelect"
                            className={styles.select}
                            value={language}
                            label="Language"
                            onChange={handleChange}
                        >
                            {Object.entries(agent.languages).sort((a, b) => (a[0] > b[0]) ? 1 : -1).map((key) => {
                                return  <MenuItem key={key[0]} value={key[0]}>{key[0]}</MenuItem>
                            })}
                        </Select>
                    {loading ? <Loading/> : 
                        <DeleteIcon onClick={startNewConversation} className={styles.headerIcon}/>
                    }
                    {loading ? <Loading/> : 
                        <MdRefresh onClick={repeatConversation} className={styles.headerIcon}/>
                    }
                    <IoClose onClick={toggleExpand} className={styles.headerIcon}/>
                </div>
                <div className={styles.chatboxContent}>
                    {messages.map((message, index) => <div key={index} className={styles.chatMessage} data-source={message.source}>{message.text}</div>)}
                    {isTyping && (
                        <div className={styles.chatMessage} data-source="ai">{isTypingLines[languageToInt()]}</div>
                    )}
                    <div ref={chatRef}></div>
                </div>
                <div className={styles.chatboxInput}>
                    <input disabled={isEnded} onKeyDown={inputKeyDown} ref={inputRef} type="text"/>
                    <FiSend onClick={submitInput} className={`${styles.inputIcon} ${isEnded ? styles.disabledButton : null}`}/>
                </div>
            </div>
        }
        </>
    )
}
export default Chatbox;