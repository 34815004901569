import DropdownSearch from 'components/global/dropdownSearch';
import React, { useState, useEffect } from 'react';
import { getApiCompanies } from 'services/companyService';
import { getApiUsers, getApiUser, createUser, updateUser, deleteUser, updateUserActionHistoryList } from 'services/userService';
import Snackbar from '@mui/material/Snackbar';
import Loading from 'components/global/loading';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormText from 'components/global/formText';
import FormMultiSelect from 'components/global/formMultiSelect';
import FormSelect from 'components/global/formSelect';

import styles from 'styles/admin/configuration/createUserPanel.module.css';
import { useAuth } from 'contexts/AuthContext';
import { useProgressIndicatorContext } from 'contexts/ProgressIndicatorContext';

const CreateUserPanel = () => {
    const { currentUser } = useAuth();
    const { setIndicatorState, resetIndicatorState, setInfoMessage, loadingState } = useProgressIndicatorContext();
    const { signup, forgotPassword } = useAuth();
    const [users, setUsers] = useState([]);
    const [companys, setCompanys] = useState([]);

    const [isNewUser, setIsNewUser] = useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);

    const [user, setUser] = useState(null);

    
    useEffect(() => {
        getUsers();
        getCompanys();
    }, []);

    const getUsers = async () => {
        var usersApiResponse = await getApiUsers();
        setUsers(usersApiResponse);
    }
    const getCompanys = async () => {
        var companysApiResponse = await getApiCompanies();
        setCompanys(companysApiResponse);
    }
    const setSelected = (e) => {
        if(e == "new"){
            var d = {
                name: '',
                email: '',
                companyId:'',
                isActivated: false,
                isAdmin: false,
                isSuperAdmin: false,
                actionHistory: []
            }
            setIsNewUser(true);
            setUser(d);
        }else{
            getUser(e.id);
            setIsNewUser(false);
        }
    }

    const getUser = async (id) => {
        var retrievedUser = await getApiUser(id);
        retrievedUser.company = companys.filter(x => x.id == retrievedUser.companyId)[0];
        setUser(retrievedUser);
        setIsNewUser(false);
    }

    const handleChange = (prop) => (event) => {
        setUser({ ...user, [prop]: event.target.value });
    }

    const handleIsAdminChange = (value) => {
        setUser({ ...user, isAdmin: value });
    }
    const handleIsSuperAdminChange = (value) => {
        setUser({ ...user, isSuperAdmin: value });
    }

    const deleteUserAction = () => {
        setOpenDialog(true);
    }
    const setSelectedCompany = (e) => {
        setUser({...user, company: e});
    }

      const handleCloseDialogDisagree = () => {
        setOpenDialog(false);
      };
      const handleCloseDialogAgree = () => {
        setOpenDialog(false);
        setIndicatorState("saving", "Removing user.");
        deleteUser({id: user.id}).then(result => {    
            if(!result.isError){
                setSelected("new");
                getUsers();
                resetIndicatorState("User has been removed successfully.");
            }else{
                resetIndicatorState(result.message);
            }
        });
      };

      const submit = () => {
        var newUser = user;

        if(newUser.name == ""){
            setInfoMessage("Name not valid.");
        } 
        if(newUser.email == ""){
            setInfoMessage("Email not valid.");
        }
        if(newUser.company == null){
            setInfoMessage("Company not valid.");
        }
        newUser.companyId = newUser.company.id;

        setIndicatorState("saving", (isNewUser ? "Creating" : "Updating") + " user. this may take several minutes.");
        if(isNewUser){
            // todo create user in api because he wil get logged in 
           // signup(newUser.email, "Welkom01!").then(createdUser => {
               // newUser.id = createdUser.user.uid;
                createUser(newUser).then(result => {
                    if(!result.isError){
                        //forgotPassword(newUser.email);
                        setSelected("new");
                        getUsers();
                        resetIndicatorState("User has been successfully created.");
                        updateUserActionHistoryList(currentUser.uid, "Core", "Created user: \"" + newUser.name + "\"");
                    }else{
                        resetIndicatorState(result.message);
                    }
                });
           // });
        }else{
            updateUser(newUser).then(result => {
                if(!result.isError){
                    setUser(result.object);
                    resetIndicatorState("User has been successfully created.");
                    updateUserActionHistoryList(currentUser.uid, "Core", "Updated user: \"" + newUser.name + "\"");
                }else{
                    resetIndicatorState(result.message);
                }
            });
        }
        
    }
    
        
    return (
        <>
            <div className={styles.widgetHeader}>
                <div className={styles.headerTitle}>Users</div>
                <DropdownSearch data={users} type="users" selected={user} setSelected={setSelected}/>
                <div onClick={() => setSelected("new")} className={styles.buttonStyle}>add new user</div>
            </div>
            {user && (
            <div className={styles.formPanel}>
            <div className={styles.formRow}>
                <div className={styles.formElementContainer}>
                    <FormText isDisabled={!isNewUser} title="Name" formId="name" value={user?.name} handleChange={handleChange}/>
                </div>
            </div>
                <div className={styles.formRow}>
                    <div className={styles.formElementContainer}>
                        <FormText isDisabled={!isNewUser} title="Email" formId="email" value={user?.email} handleChange={handleChange}/>
                    </div>
                </div>
                <div className={styles.dropdownContainer}>
                        <div className={styles.dropdownHeader}>Company:</div>
                        <DropdownSearch data={companys} type="company" selected={user?.company} setSelected={setSelectedCompany}/>   
                    </div>

                    
                <div className={`${styles.formRow} ${styles.formRowLeft}`}>
                    <FormSelect title="Is admin" value="Yes"
                                isSelected={user?.isAdmin} handleChange={handleIsAdminChange}  />
                    <FormSelect title="Is super admin" value="Yes"
                                isSelected={user?.isSuperAdmin} handleChange={handleIsSuperAdminChange}  />
                </div>

                <div className={styles.buttons}>
                        {!loadingState &&
                            <>
                                <button disabled={user.email == ""} onClick={submit} className={`${styles.buttonStyle} ${styles.submitButton} ${user.email == "" ? styles.disabledButton : null}`}>{isNewUser ? 'Create' : 'Update'}</button>
                                {!isNewUser &&
                                    <button onClick={deleteUserAction} className={`${styles.buttonStyle} ${styles.submitButton}  ${styles.deleteButton}`}>Delete</button>
                                }
                            </>
                        }
                    </div>
            </div>
            )}

        <Dialog
            open={openDialog}
            onClose={handleCloseDialogDisagree}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
          {"Delete user confirmation!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete user: {user?.email}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDisagree} autoFocus>No</Button>
          <Button onClick={handleCloseDialogAgree}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
    )

}
export default CreateUserPanel;
