export class ConversationDemoTranlations{
    constructor(){
        this.callReason = {
            nl:"Zou je kort kunnen omschrijven waarvoor je belt?",
            en:"Could you briefly describe what you are calling for?",
            de:"Können sie mir vorher kurz Ihr Anliegen beschreiben?"
        }
        this.yes = {
            nl:"Ja",
            en:"Yes",
            de:"Ja"
        }
        this.no = {
            nl:"Nee",
            en:"No",
            de:"Nein"
        }
        this.moreHelp = {
            nl:"Is er nog iets anders waarmee ik je kan helpen?",
            en:"Is there anything else I can help you with?",
            de:"Gibt es sonst noch etwas, bei dem ich Ihnen helfen kann?"
        }
        this.noMoreHelp = {
            nl:"Prima, bedankt voor het bellen en een fijne dag.",
            en:"Great, thank you for calling and have a great day.",
            de:"Dann danke ich Ihnen für den Anruf und wünsche Ihnen noch einen schönen Tag."
        }
        this.didAnswerHelp = {
            nl:"Geeft dit antwoord op je vraag?",
            en:"Did this answer help you?",
            de:"Habe ich Ihre Frage damit beantwortet?"
        }
        this.ordernumberAvailable = {
            nl:"Heb je je ordernummer bij de hand?",
            en:"Do you have your order number available?",
            de:"Haben Sie Ihre Bestellnummer zur Hand?"
        }
        this.ordernumberFillIn = {
            nl:"Zou je het 9 cijferige ordernummer in kunnen toetsen. Sluit vervolgens af met een hekje.",
            en:"Could you enter the 9-digit order number. Ending with the hash key.",
            de:"Bitte geben Sie die Bestellnummer über das Tastenfeld Ihres Telefons ein, und schließen Sie die Eingabe dann mit dem Raute Symbol ab."
        }
        this.ordernumberRecieved = {
            nl:"Ik heb het volgende nummer ontvangen: 123456789. Is dit juist?",
            en:"I have received the following number: 123456789. Is this correct?",
            de:"Ich habe folgende Nummer verstanden: 123456789. Ist dies richtig?"
        }
        this.perfect = {
            nl:"Super.",
            en:"Great.",
            de:"Super."
        }
        this.yesPlease = {
            nl:"Ja graag.",
            en:"Yes please.",
            de:"Ja bitte."
        }
        this.canWeSendSms = {
            nl:"Mogen we een smsje sturen met een link naar het document?",
            en:"Can we send you a text message with a link to the document?",
            de:"Können wir eine SMS mit einem Link zum Dokument senden?"
        }
        this.sendSmsToThisNumber = {
            nl:"Mogen we het naar dit nummer sturen?",
            en:"Can we send it to this number?",
            de:"Können wir es an diese Nummer schicken?"
        }
        this.sendSmsNow = {
            nl:"Super. We hebben je een SMS verzonden.",
            en:"Great. A text message has been sent to you.",
            de:"Super. Wir haben Irh Eine SMS gesendet"
        }
        this.humanHandover = {
            nl:"Wil je doorverbonden worden met 1 van mijn collega's?",
            en:"Would you like to be transferred to one of my colleagues?",
            de:"Möchten Sie mit einem meiner Kollegen verbunden werden?"
        }
        this.humanHandoverYes = {
            nl:"Prima, we gaan je nu doorverbinden.",
            en:"No problem. You are now being transferred.",
            de:"Gut, Sie werden nun weitergeleitet."
        }
        this.callback = {
            nl:"Zal ik iemand terug laten bellen?",
            en:"Can we schedule a callback request?",
            de:"Dürfen wir einen Rückruf für Sie einrichten?"
        }
        this.ratherNot = {
            nl:"Nee liever niet.",
            en:"No rather not.",
            de:"Nein lieber nicht."
        }
        this.noProblem = {
            nl:"Geen probleem.",
            en:"No problem",
            de:"Kein Problem"
        }
        this.speakToEmployee = {
            nl:"Ik wil een medewerker spreken.",
            en:"I want to speak to an employee.",
            de:"Ich möchte mit einem Mitarbeiter sprechen.",
        }
        this.noEmployeeAvailable = {
            nl:"Momenteel zijn er geen medewerkers beschikbaar, maar ik kan een terugbelverzoek aanmaken.",
            en:"There are currently no employees available, but I can create a callback request.",
            de:"Momentan sind keine Mitarbeiter verfügbar, aber ich kann eine Rückrufbitte erstellen.",
        }

    }
}


