import React, { Component, useState, useEffect, useRef, setState } from 'react';
import { Table, Card, Button, Form, Alert, InputGroup } from 'react-bootstrap'
import { db } from '../firebase.js'
import { collection, getDocs, addDoc, updateDoc, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore'
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { MdEmail, } from "react-icons/md";

const Intent = () => {
    const synonymArray = [];
    const followUpSteps = ["default","cancel","guarantee","invoice", "in-depth-question"];

    const [intents, setIntents] = useState([]);
    const [contexts, setContexts] = useState([]);


    const [intent, setIntent] = useState();
    const [language, setLanguage] = useState("nl");

    const intentReference = collection(db, "intents");

    const intentRef = useRef();
    const contextRef = useRef();

    const nlPhraseRef = useRef();
    const enPhraseRef = useRef();
    const dePhraseRef = useRef();

    const followUpStepRef = useRef();

    useEffect(() => {
        getIntents();
    }, [])

    const getSynonyms = async () => {
        const entityReference = collection(db, "entities");
        await getDocs(entityReference).then((data) => {
            var parsedData = data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));

            parsedData.forEach((d) => {
                Object.keys(d["nl"]).forEach(key => {

                    d["nl"][key].split(";").forEach((syn) => {

                        synonymArray.push(
                            {
                                language: "nl",
                                key: key,
                                value: syn.toLowerCase()
                            }
                        );
                    })
                });
                Object.keys(d["de"]).forEach(key => {

                    d["de"][key].split(";").forEach((syn) => {

                        synonymArray.push(
                            {
                                language: "de",
                                key: key,
                                value: syn.toLowerCase()
                            }
                        );
                    })
                });
                Object.keys(d["en"]).forEach(key => {

                    d["en"][key].split(";").forEach((syn) => {

                        synonymArray.push(
                            {
                                language: "en",
                                key: key,
                                value: syn.toLowerCase()
                            }
                        );
                    })
                });

            })
        });
    }
    getSynonyms();

    const getIntents = async () => {
        await getDocs(intentReference).then((data) => {
            var parsedData = data.docs.map((doc) => ({ id: doc.id, context: doc.data().context }));
            setIntents(parsedData)
            setContexts(parsedData.map((x) => x.context));
            getIntent(parsedData[0].id);
        });
    }
    const getContexts = async () => {
        await getDocs(intentReference).then((data) => {
            var parsedData = data.docs.map((doc) => ({ id: doc.id, context: doc.data().context }));
            setContexts(parsedData.map((x) => x.context));
            setTimeout(() => {
                getIntent(intent.id);
            }, 200);
        });
    }


    const clickIntent = async (id) => {
        getIntent(id);
    }
    const getIntent = async (id) => {
        const docRef = doc(db, "intents", id);
        await getDoc(docRef).then((docSnap) => {
            const data = { ...docSnap.data(), id: id };
            setIntent(data);
            contextRef.current.value = data.context;
            followUpStepRef.current.value = data.followUpStep;
        })
    }
    const addIntent = async (intent) => {
        if (intent == "")
            return;

        const capitalized = await capitalizeFirstLetter(intent);
        const docRef = doc(db, 'intents', capitalized);
        setDoc(docRef, {
            nlPhrases: [],
            dePhrases: [],
            enPhrases: [],
            context: "",
            intentReference: "",
            followUpStep: "",
            nlAbout: "",
            enAbout: "",
            deAbout: ""
        }).then(() => {
            getIntents();
            intentRef.current.value = "";
        })
    }

    const SaveIntentReference = () => {
        if (intent.intentReference != "") {
            const newFields = { "intentReference": intent.intentReference };
            const docRef = doc(db, 'intents', intent.id);
            updateDoc(docRef, newFields);
            getIntent(intent.id);
        }
    }
    const SaveContext = () => {
        if (intent.context != "") {
            const newFields = { "context": intent.context };
            const docRef = doc(db, 'intents', intent.id);
            updateDoc(docRef, newFields);
            getContexts();
        }
    }
    const ContextChange = () => {
        const newFields = { "context": contextRef.current.value };
        const docRef = doc(db, 'intents', intent.id);
        updateDoc(docRef, newFields);
        getIntent(intent.id);
    }
    const FollowUpStepChange = () => {
        const newFields = { "followUpStep": followUpStepRef.current.value };
        const docRef = doc(db, 'intents', intent.id);
        updateDoc(docRef, newFields);
        getIntent(intent.id);
    }
    const SaveNlAbout = () => {
        if ("nlAbout" != "") {
            const newFields = { "nlAbout": intent.nlAbout };
            const docRef = doc(db, 'intents', intent.id);
            updateDoc(docRef, newFields);
            getIntent(intent.id);
        }
    }
    const SaveEnAbout = () => {
        if ("enAbout" != "") {
            const newFields = { "enAbout": intent.enAbout };
            const docRef = doc(db, 'intents', intent.id);
            updateDoc(docRef, newFields);
            getIntent(intent.id);
        }
    }
    const SaveDeAbout = () => {
        if ("deAbout" != "") {
            const newFields = { "deAbout": intent.deAbout };
            const docRef = doc(db, 'intents', intent.id);
            updateDoc(docRef, newFields);
            getIntent(intent.id);
        }
    }
    const deleteIntent = async (id) => {
        let isConfirmed = window.confirm("Are you sure you want to delete this intent?");
        if (isConfirmed) {
            const docRef = doc(db, "intents", id);
            deleteDoc(docRef).then(() => {
                getIntents();
            })
        }
    }
    const addPhrase = async (lang, phrase) => {
        if (phrase != "") {
            phrase = phrase.toLowerCase();
            const matchedSynonyms = synonymArray.filter(x => x.language == lang && (phrase.indexOf(x.value + ".") > -1 || phrase.indexOf(x.value + " ") > -1 || phrase.indexOf(" " + x.value) > -1));
            const sortedSynonyms = matchedSynonyms.sort((a, b) => b.value.length - a.value.length)

            sortedSynonyms.forEach((x) => {
                phrase = phrase.replaceAll(x.value, "[" + x.value + "]");
            })

            var lastSign = "";
            var deleteSign = "";
            for (var i = 0; i < phrase.length; i++) {
                lastSign = phrase.charAt(i);

                if (lastSign == deleteSign) {
                    phrase = phrase.replaceAt(i, " ");
                    deleteSign = "]";
                    if (lastSign == "]") {
                        deleteSign = "";
                        continue;
                    }
                    continue;
                }
                if (lastSign == "[") {
                    deleteSign = lastSign;
                    continue;
                }
                if (lastSign == "]") {
                    deleteSign = "";
                    continue;
                }


            }

            phrase = phrase.replaceAll(/ +(?= )/g, '').replaceAll(" ]", "]").replaceAll("[ ", "[");

            const data = [...intent[lang + "Phrases"], phrase];
            const newFields = { [lang + "Phrases"]: data };
            const docRef = doc(db, 'intents', intent.id);
            updateDoc(docRef, newFields);
            getIntent(intent.id);

            nlPhraseRef.current.value = "";
            enPhraseRef.current.value = "";
            dePhraseRef.current.value = "";
        }
    }
    String.prototype.replaceAt = function (index, replacement) {
        return this.substring(0, index) + replacement + this.substring(index + replacement.length);
    }

    const capitalizeFirstLetter = async (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const deletePhrase = async (phrase, lang) => {
        const data = [...intent[lang + "Phrases"]];
        data.splice(data.indexOf(phrase), 1);
        const newFields = { [lang + "Phrases"]: data };
        const docRef = doc(db, 'intents', intent.id);
        updateDoc(docRef, newFields);
        getIntent(intent.id);
    }

    function setKey(lang) {
        setLanguage(lang);
    }
    const handleKeyDown = (lang, phrase, event) => {
        if (event.key === 'Enter' && phrase != "") {
            addPhrase(lang, phrase);
        }
    };


    return (

        <div className='d-flex '>
            <div className='d-flex p-2 flex-column' style={{ height: "100vh", background: "#444" }}>
                <h1 style={{ color: "#858585" }}>Intents</h1>
                <ListGroup as="ol">
                    <div className='d-flow'>
                        <input ref={intentRef} type="text" />
                        <Button variant="primary" size="sm" onClick={() => addIntent(intentRef.current.value)}>ADD</Button>
                    </div>
                    {intents.map((e) => {
                        return (
                            <ListGroup.Item style={{ width: "200px" }} active={intent && intent.id == e.id}
                                as="li" key={encodeURIComponent.id} onClick={() => clickIntent(e.id)}
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{e.id}</div>
                                </div>
                                <Badge bg="warning" pill onClick={() => deleteIntent(e.id)}> X
                                </Badge>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </div>
            <div className='flex-fill' style={{ backgroundColor: "#565656" }}>
                {intent && (
                    <Form>
                        <Form.Group id="context">
                            <Form.Label>Context</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><MdEmail className='dark' /></InputGroup.Text>
                                <Form.Select aria-label="Existing context" ref={contextRef}
                                    onChange={() => ContextChange()}>
                                    {contexts.map((option, index) => (
                                        <option value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Select>

                                of

                                <Form.Control type="text" required value={intent.context}
                                    onChange={(e) => setIntent({ ...intent, context: e.target.value })}
                                    onBlur={(e) => SaveContext()}></Form.Control>

                            </InputGroup>
                        </Form.Group>
                        <Form.Group id="intent">
                            <Form.Label>Intent reference</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><MdEmail className='dark' /></InputGroup.Text>
                                <Form.Control type="text" required value={intent.intentReference}
                                    onChange={(e) => setIntent({ ...intent, intentReference: e.target.value })}
                                    onBlur={(e) => SaveIntentReference()}></Form.Control>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group id="followUpStep">
                        FollowUp step
                        <Form.Select aria-label="Existing context" ref={followUpStepRef}
                                    onChange={() => FollowUpStepChange()}>
                                    {followUpSteps.map((option, index) => (
                                        <option value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Select>
                        </Form.Group>
                    </Form>
                )}
                <Tabs
                    defaultActiveKey="nl"
                    id="uncontrolled-tab-example"
                    onSelect={(k) => setKey(k)}
                    className="mb-3">
                    <Tab eventKey="nl" title="NL">
                        {intent && (
                            <div className='p-2'>
                                <Form>
                                    <Form.Group id="nlAbout">
                                        <Form.Label>Klopt het dat je een vraag hebt over:</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1"><MdEmail className='dark' /></InputGroup.Text>
                                            <Form.Control type="text" required value={intent.nlAbout}
                                                onChange={(e) => setIntent({ ...intent, nlAbout: e.target.value })}
                                                onBlur={(e) => SaveNlAbout()}></Form.Control>
                                        </InputGroup>
                                    </Form.Group>
                                </Form>

                                <div className='d-flow'>
                                    <input ref={nlPhraseRef} type="text" onKeyDown={(event) => handleKeyDown("nl", nlPhraseRef.current.value, event)} />
                                    <Button variant="primary" size="sm" onClick={() => addPhrase("nl", nlPhraseRef.current.value)}>ADD</Button>
                                </div>
                                <Card style={{ width: '18rem' }}>
                                    {intent.nlPhrases && (
                                        <ListGroup variant="flush">
                                            {
                                                Object.keys(intent.nlPhrases).map((key, index) => {
                                                    return <ListGroup.Item>
                                                        <span contenteditable="true">
                                                            {intent.nlPhrases[key].split(/\[|\]/).map((i) => {
                                                                return intent.nlPhrases[key].indexOf("[" + i + "]") == -1 ? <span>{i}</span> : <span style={{ backgroundColor: "green", color: "white" }}>{i}</span>
                                                            })}
                                                        </span>
                                                        <Badge bg="warning" pill onClick={() => deletePhrase(intent.nlPhrases[key], "nl")}> X</Badge>
                                                    </ListGroup.Item>
                                                })
                                            }
                                        </ListGroup>
                                    )}
                                </Card>
                            </div>
                        )}
                    </Tab>
                    <Tab eventKey="en" title="EN">
                        {intent && (
                            <div className='p-2'>
                                <Form>
                                    <Form.Group id="enAbout">
                                        <Form.Label>Is it correct that you have a question about:</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1"><MdEmail className='dark' /></InputGroup.Text>
                                            <Form.Control type="text" required value={intent.enAbout}
                                                onChange={(e) => setIntent({ ...intent, enAbout: e.target.value })}
                                                onBlur={(e) => SaveEnAbout()}></Form.Control>
                                        </InputGroup>
                                    </Form.Group>
                                </Form>

                                <div className='d-flow'>
                                    <input ref={enPhraseRef} type="text" onKeyDown={(event) => handleKeyDown("en", enPhraseRef.current.value, event)} />
                                    <Button variant="primary" size="sm" onClick={() => addPhrase("en", enPhraseRef.current.value)}>ADD</Button>
                                </div>
                                <Card style={{ width: '18rem' }}>
                                    {intent.enPhrases && (
                                        <ListGroup variant="flush">
                                            {
                                                Object.keys(intent.enPhrases).map((key, index) => {
                                                    return <ListGroup.Item>
                                                        <span contenteditable="true">
                                                            {intent.enPhrases[key].split(/\[|\]/).map((i) => {
                                                                return intent.enPhrases[key].indexOf("[" + i + "]") == -1 ? <span>{i}</span> : <span style={{ backgroundColor: "green", color: "white" }}>{i}</span>
                                                            })}
                                                        </span>
                                                        <Badge bg="warning" pill onClick={() => deletePhrase(intent.enPhrases[key], "en")}> X</Badge>
                                                    </ListGroup.Item>
                                                })
                                            }
                                        </ListGroup>
                                    )}
                                </Card>
                            </div>
                        )}
                    </Tab>
                    <Tab eventKey="de" title="DE">
                        {intent && (
                            <div className='p-2'>
                                <Form>
                                    <Form.Group id="deAbout">
                                        <Form.Label>Stimmt es, dass Sie eine Frage haben:</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1"><MdEmail className='dark' /></InputGroup.Text>
                                            <Form.Control type="text" required value={intent.deAbout}
                                                onChange={(e) => setIntent({ ...intent, deAbout: e.target.value })}
                                                onBlur={(e) => SaveDeAbout()}></Form.Control>
                                        </InputGroup>
                                    </Form.Group>
                                </Form>
                                <div className='d-flow'>
                                    <input ref={dePhraseRef} type="text" onKeyDown={(event) => handleKeyDown("de", dePhraseRef.current.value, event)} />
                                    <Button variant="primary" size="sm" onClick={() => addPhrase("de", dePhraseRef.current.value)}>ADD</Button>
                                </div>
                                <Card style={{ width: '18rem' }}>
                                    {intent.dePhrases && (
                                        <ListGroup variant="flush">
                                            {
                                                Object.keys(intent.dePhrases).map((key, index) => {
                                                    return <ListGroup.Item>
                                                        <span contenteditable="true">
                                                            {intent.dePhrases[key].split(/\[|\]/).map((i) => {
                                                                return intent.dePhrases[key].indexOf("[" + i + "]") == -1 ? <span>{i}</span> : <span style={{ backgroundColor: "green", color: "white" }}>{i}</span>
                                                            })}
                                                        </span>
                                                        <Badge bg="warning" pill onClick={() => deletePhrase(intent.dePhrases[key], "de")}> X</Badge>
                                                    </ListGroup.Item>
                                                })
                                            }
                                        </ListGroup>
                                    )}
                                </Card>
                            </div>
                        )}
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default Intent;