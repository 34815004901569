import React, {useState, useEffect} from 'react';

import {MdOutlineAssignmentReturn} from "react-icons/md";
import styles from 'styles/admin/agent/agentListRow.module.css';

const AgentListRow = ({category, parentSelectCategory, selectedCategory}) => {

    const parsedDate = (datetime) => {
        return new Date(datetime).toLocaleDateString('nl-nl', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
    }

    const selectCategory = () => {
        parentSelectCategory(category);
    }


    const getCapitals = (str) => {
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        return matches.join('').toUpperCase(); // JSON
    }

    return(

        <div className={`${styles.agentCard} ${selectedCategory === category ? styles.selected : ''}`} onClick={selectCategory}>
            <MdOutlineAssignmentReturn className={styles.categoryIcon}/>
            <span className={styles.categoryName}>{category.name} </span>
            <span className={styles.categoryNumber}>({category.numberOfIntents})</span>
        </div>
        //
        // <div className={`${styles.agentCard} ${selectedAgent === agent ? styles.selected : ''}`} onClick={selectAgent}>
        //     <div className={styles.icon}>{getCapitals(agent.name)}</div>
        //     <div className={styles.data}>
        //         <div className={styles.title}>
        //             <span>{agent.name}</span>
        //             <div className={styles.langs}>
        //                 {
        //                     Object.entries(agent.languages).sort((a, b) => (a[0] > b[0]) ? 1 : -1).map((key) => {
        //                         return <span key={key[0]} className={styles.lang}>{key[0]}</span>
        //                     })
        //                 }
        //             </div>
        //         </div>
        //         <div className={styles.info}>
        //             <div className={styles.createdDate}>{parsedDate(agent.creationDate)}</div>
        //         </div>
        //     </div>
        //</div>
    )
}

export default AgentListRow;
