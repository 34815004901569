export default {
    integration:"",
    name:"",
    requestType:"GET",
    url:"",
    inputWebhook:"",
    invokeWebhook:"",
    settings: {
        requestOrdernumber: false,
        requestPhonenumber: false,
        loggingActive: false,
        isTokenRefreshWebhook: false,
        isCreateTicketWebhook: false,
        isStartStep: false,
    },
    headers: [{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""}],
    queryParams: [{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""}],
    responses: [{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""},{key:"", value:""}],
    testInputFields: [],
    exportParameters: [],
    authentication: {
        bearer:{
            token: ""
        }
    },
    failed: {
    },
    logging:[],
    fallbacks:[],
    mappingFallbackResponseValues: {
    },
    // mappingResponseValues: {
    //     REFERENCE:{
    //         parameters: {
    //             validateAnswer: true
    //         },
    //         responses: {
    //             nl: "",
    //             de: "",
    //             en: "",
    //         }
    //     }
    // },
    webhookResponses: {
        REFERENCE: {

        }
    },
    body: {},
    gptBody: "",
    gptModelBody: "",
    gptInputVarsBody: "",
    gptResponseMapping:"",
    gptResponseModelMapping:"",
    gptResponseInputVarsMapping:"",
    agentResponseMapping: [
        {
            operator: "",
            statements:[{
                    inOperator: "",
                    left:"",
                    operator:"",
                    right:"",
                    outOperator: "",
            }],
            then: {
                params: {
                },
                responses: {
                }
            }
        }
    ]

}