import DropdownSearch from "components/global/dropdownSearch";
import React, { useEffect, useState, useMemo } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SlArrowDown } from "react-icons/sl";

import styles from "styles/admin/optimization/optimization.module.css";
import {
  getApiAllAgents,
  triggerOutboundCallApi,
} from "../../../services/agentService";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Intents from "./intents";
import Entities from "./entities";
import SaveAndPublish from "./saveAndPublish";
import DialogflowResponses from "./dialogflowResponses";

const Configuration = () => {
  const { currentDbUser } = useAuth();
  const [tabValue, setTabValue] = React.useState(0);
  const [menuLanguageOpen, setMenuLanguageOpen] = useState(false);
  const [menuAgentOpen, setMenuAgentOpen] = useState(false);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("nl");
  const languages = { nl: "", en: "", de: "" };
  const [agents, setAgents] = useState([]);
  const [agent, setAgent] = useState({ name: "" });

  const [voornaam, setVoornaam] = React.useState("");
  const [number, setNumber] = React.useState("");

  useEffect(() => {
    if (!currentDbUser) return;

    if (!currentDbUser?.isAdmin) {
      navigate("/admin/agents");
    }

    const getAgents = async () => {
      var retrievedAgents = await getApiAllAgents();
      setAgents(retrievedAgents);
      setAgent({ name: retrievedAgents[0].name });
      // setAgents([...retrievedAgents, {name: "core"}, {name: "core_text"}]);
    };
    getAgents();
  }, [currentDbUser]);

  const tabChange = (event, val) => {
    console.log(val);
    setTabValue(val);
  };

  const getLanguageTranslation = (lang) => {
    switch (lang) {
      case "nl":
        return "Netherlands";
        break;
      case "de":
        return "German";
        break;
      case "en":
        return "English";
        break;
      default:
        return "";
    }
  };
  const setLanguage = (lang) => {
    setSelectedLanguage(lang);
    setMenuLanguageOpen(false);
  };
  const openDropDownLanguageSearch = () => {
    setMenuLanguageOpen(!menuLanguageOpen);
  };
  const openDropDownAgentSearch = () => {
    setMenuAgentOpen(!menuAgentOpen);
  };
  const agentSelected = (e) => {
    setAgent(e);
    localStorage.setItem("selectedAgent", e.name);
    setSearchQuery("");
    setMenuAgentOpen(false);
  };

  const filteredAgents = useMemo(() => {
    // Create copy of item list
    var updatedList = [...agents];
    // Include all elements which includes the search query
    return (updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
    }));
  }, [agents, searchQuery]);

  const triggerTestCall = () => {
    triggerOutboundCallApi(voornaam, number).then((x) => {});
  };
  return (
    <div className={`${styles.optimization}`}>
      <div className={`${styles.header} ${styles.widget}`}>
        <h1>Optimization</h1>
        <h3>Opimize the intents and entities</h3>
        <div className={styles.row}>
          <input
            value={voornaam}
            type="text"
            placeholder="Voornaam"
            id={styles.myInput}
            onChange={(e) => setVoornaam(e.target.value)}
          />
          <input
            value={number}
            type="text"
            placeholder="Telefoonnummer 316"
            id={styles.myInput}
            onChange={(e) => setNumber(e.target.value)}
          />
          <button onClick={triggerTestCall} className={styles.rowButton}>
            Trigger test call
          </button>
        </div>
      </div>
      <div className={`${styles.typeSelection} ${styles.widget}`}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={tabChange}
            aria-label="intent-entity-tabs"
          >
            {/* <Tab {...tabProps(0)} value={0} label="Intents"/>
                        <Tab {...tabProps(1)} value={1} label="Entities"/> */}
            {currentDbUser?.isSuperAdmin && (
              <Tab {...tabProps(0)} value={0} label="Save and publish" />
            )}
            <Tab {...tabProps(1)} value={1} label="Dialogflow responses" />
          </Tabs>
        </Box>

        <div className={styles.dropdownContainer}>
          <div className={styles.dropdown}>
            <button
              onClick={openDropDownLanguageSearch}
              className={styles.dropbtn}
            >
              {getLanguageTranslation(selectedLanguage)}
              <SlArrowDown
                className={`${styles.dropdownIcon} ${
                  menuLanguageOpen ? styles.rotate : null
                }`}
              />
            </button>
            <div
              id={styles.myDropdown}
              className={`${styles["dropdown-content"]} ${
                menuLanguageOpen ? styles.show : null
              }`}
            >
              {Object.entries(languages)
                .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                .map((key) => {
                  return (
                    <span key={key} onClick={() => setLanguage(key[0])}>
                      {getLanguageTranslation(key[0])}
                    </span>
                  );
                })}
            </div>
          </div>
          <div className={styles.dropdown}>
            <button
              onClick={openDropDownAgentSearch}
              className={styles.dropbtn}
            >
              {agent.name}{" "}
              <SlArrowDown
                className={`${styles.dropdownIcon} ${
                  menuAgentOpen ? styles.rotate : null
                }`}
              />
            </button>
            <div
              id={styles.myDropdown}
              className={`${styles["dropdown-content"]} ${
                menuAgentOpen ? styles.show : null
              }`}
            >
              <input
                value={searchQuery}
                type="text"
                placeholder="Zoeken"
                id={styles.myInput}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {filteredAgents.map((s) => {
                return (
                  <span key={s.name} onClick={() => agentSelected(s)}>
                    {s.name}
                  </span>
                );
              })}
              {filteredAgents.length === 0 ? (
                <span className={styles.noResult}>Geen resultaten</span>
              ) : null}
            </div>
          </div>
        </div>
        {/* <TabPanel className={styles.tabPanel} value={tabValue} index={0}>
                    <Intents/>
                </TabPanel>
                <TabPanel className={styles.tabPanel} value={tabValue} index={1}>
                    <Entities/>
                </TabPanel> */}
      </div>
      <div className={`${styles.tab}`}>
        {/* {tabValue == 0 && (
                <>
                <Intents language={selectedLanguage} agent={agent}/>
               </>
              )}
              {tabValue == 1 && (
                <>
                <Entities language={selectedLanguage} agent={agent}/>
               </>
              )} */}
        {tabValue == 0 && (
          <>
            <SaveAndPublish language={selectedLanguage} agent={agent} />
          </>
        )}
        {tabValue == 1 && (
          <>
            <DialogflowResponses agent={agent} />
          </>
        )}
      </div>
    </div>
  );
};
function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
export default Configuration;
