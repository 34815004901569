import { db } from "../firebase.js";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  where,
  query,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

const getDatasourceCollectionsByCompany = async (companyId) => {
  const reference = collection(db, "datasources");
  const q = query(reference, where("companyId", "==", companyId));

  return await getDocs(q).then((data) => {
    return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
  });
};
const addDatasourceCollectionToDb = async (data) => {
  var coll = collection(db, "datasources");
  return await addDoc(coll, data);
};
const updateDatasourceCollectionToDb = async (data) => {
  const docRef = doc(db, "datasources", data.id);
  updateDoc(docRef, data);
};
const deleteDatasourceCollection = async (data) => {
  const docRef = doc(db, "datasources", data.id);
  deleteDoc(docRef);
};

export {
  getDatasourceCollectionsByCompany,
  addDatasourceCollectionToDb,
  updateDatasourceCollectionToDb,
  deleteDatasourceCollection,
};
