import { db } from '../firebase.js'
import { collection, getDocs} from 'firebase/firestore'
import moment from 'moment'


const getLogging = async () => {
    const reference = collection(db, "logging");

    return await getDocs(reference).then((data) => {
        return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id, parsedData: moment(docSnap.data().dateTime, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") }));
    })
}

export {getLogging}
