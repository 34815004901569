import React, {useEffect, useState} from 'react';
import { MdAccessAlarm, MdAssistantPhoto, MdOutlineDashboard, MdOutlineRealEstateAgent, MdOutlineImportantDevices, MdEmail, MdAddchart, MdAppSettingsAlt } from "react-icons/md";
import { HiOutlineDocumentReport, HiOutlineDatabase  } from "react-icons/hi";
import { FiBookOpen, FiSettings } from "react-icons/fi";
import { TiFlowChildren } from "react-icons/ti";
import { MdOutlineSupportAgent, MdPhoneCallback, MdReportProblem, MdOutlineIntegrationInstructions } from "react-icons/md";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { SlGraph } from "react-icons/sl";
import { TbWebhook } from "react-icons/tb";

import styles from 'styles/admin/menu/menu.module.css';
import MenuItem from './menuItem';
import Toggle from './toggle';
import Search from './search';
import Logout from './logout';
import { Link, useNavigate } from 'react-router-dom'
import {useAuth} from "../../../contexts/AuthContext";
import { BsFillChatDotsFill } from 'react-icons/bs';

const Menu = () => {
    const { currentDbUser } = useAuth();
    const navigate = useNavigate();
    const [menuItem, setMenuItem] = useState('Dashboard');
    const [menuExpanded, setMenuExpandedState] = useState(false);

    useEffect(() => {
        let path = window.location.pathname.split("/");
        let p = path[path.length-1];
        setMenuItem(p);
    }, []);

    const toggleMenu = () => {
        setMenuExpandedState(!menuExpanded);
    }
    const openMenu = () => {
        setMenuExpandedState(true);
    }
    
    const selectMenuItem = (item) => {
        setMenuItem(item.route);
        navigate('/admin/'+ item.route);
    }

    return (
      <nav className={`${styles.nav} ${menuExpanded ? styles.navout : ''}`}>
        <header>
          <Toggle menuExpanded={menuExpanded} toggleParentMenu={toggleMenu}></Toggle>
        </header>

          {/*<div className={styles.searchdiv}>*/}
              {/*<Search menuExpanded={menuExpanded} openParentMenu={openMenu}></Search>*/}
          {/*</div>*/}

        {/*<section>*/}
            {/*<MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} title={'Dashboard'} selectedMenuItem={menuItem}><MdOutlineDashboard/></MenuItem>*/}
        {/*</section>*/}

        <section>
            {currentDbUser?.isAdmin && (<>
            <div className={styles.sectionTitle}>Optimize</div>
                <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'optimizationReports'} title={'Optimization reports'} selectedMenuItem={menuItem}><MdReportProblem/></MenuItem>
                <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'optimization'} title={'Optimization'} selectedMenuItem={menuItem}><SlGraph/></MenuItem>
                <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'logging'} title={'Logging'} selectedMenuItem={menuItem}><FiBookOpen/></MenuItem>
                <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'gpt'} title={'Gpt'} selectedMenuItem={menuItem}><BsFillChatDotsFill/></MenuItem>
                <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'intentStructure'} title={'Configuration'} selectedMenuItem={menuItem}><TiFlowChildren/></MenuItem>
                </>)}
            {currentDbUser?.isSuperAdmin && (<>
                <div className={styles.sectionTitle}>Admin</div>
                <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'configuration'} title={'Configuration'} selectedMenuItem={menuItem}><FiSettings/></MenuItem>
                
                <div className={styles.sectionTitle}>Hooks</div>
                <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'integrationConfigurator'} title={'Integration configurator'} selectedMenuItem={menuItem}><TbWebhook/></MenuItem>
                <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'integrations'} title={'Integrations'} selectedMenuItem={menuItem}><MdOutlineIntegrationInstructions/></MenuItem>
                </>)}
                
            <div className={styles.sectionTitle}>Agent</div>
            <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'agents'} title={'Assistents'} selectedMenuItem={menuItem}><MdOutlineSupportAgent/></MenuItem>
            <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'datasource'} title={'Datasource'} selectedMenuItem={menuItem}><HiOutlineDatabase /></MenuItem>
            <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'reports'} title={'Reports'} selectedMenuItem={menuItem}><HiOutlineDocumentReport/></MenuItem>
            <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'callbacks'} title={'Callbacks'} selectedMenuItem={menuItem}><MdPhoneCallback/></MenuItem>
            {/* <MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} route={'chatmessages'} title={'Chatmessages'} selectedMenuItem={menuItem}><ChatBubbleOutlineIcon/></MenuItem> */}
                
        </section>

        {/*<section>*/}
            {/*<div className={styles.sectionTitle} style={{marginLeft: "5px"}}>Reports</div>*/}
            {/*<MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} title={'Reports'} selectedMenuItem={menuItem}><MdOutlineImportantDevices/></MenuItem>*/}
            {/*<MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} title={'Statistics'} updates="4" selectedMenuItem={menuItem}><MdEmail/></MenuItem>*/}
            {/*<MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} title={'Graph'} selectedMenuItem={menuItem}><MdAddchart/></MenuItem>*/}
            {/*<MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} title={'Export'} selectedMenuItem={menuItem}><MdAppSettingsAlt/></MenuItem>*/}
        {/*</section>*/}

        {/*<section>*/}
            {/*<div className={styles.sectionTitle} style={{marginLeft: "4px"}}>Settings</div>*/}
            {/*<MenuItem menuExpanded={menuExpanded} selectMenuItem={selectMenuItem} title={'Preferences'} selectedMenuItem={menuItem}><MdAssistantPhoto/></MenuItem>*/}
        {/*</section>*/}

          <footer>
              <Logout menuExpanded={menuExpanded} title={'Logout'}><MdAccessAlarm/></Logout>
          </footer>
      </nav>
    )
}

export default Menu;
