import React, {useState, useMemo, useEffect} from 'react';
import styles from 'styles/admin/agent/agentList.module.css';

const WebhookStepList = ({integration, setParentIntegrationStep, selectedIntegrationStep, editIntegrationSettings}) => {

    const selectIntegrationStep = (integrationStep) => {
        setParentIntegrationStep(integrationStep);
    }

    const addNewWebhookStep = () => {
        setParentIntegrationStep("");
    }
    
    const [searchQuery, setSearchQuery] = useState("");

    const filteredIntegrationSteps = useMemo(() => {
        if(integration == null)
            return;

        // Create copy of item list
        var updatedList = integration.sort();
        //var updatedList = [...Object.entries(integration).sort((a, b) => (a[0] > b[0]) ? 1 : -1)].map(x => ({integration: x[0], name: Object.keys(x[1])[0]}));

        // Include all elements which includes the search query
        return updatedList.filter((item) => {
            return item?.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
    },[integration, searchQuery]);


    return (
        <div className={`${styles.flipCardInner}`}>
                <div className={styles.agentList}>
                    {/*<div onClick={createNewAgent} className={styles.createAgentButton}>Nieuwe agent aanmaken</div>*/}


                    <div>
                        <input value={searchQuery} placeholder="search" onChange={(e) => setSearchQuery(e.target.value)} className={styles.newAgentSearchInput}
                                type="text"/>
                        {/*<div className={styles.agentNumber}>{filteredAgents.length} assistent{filteredAgents.length > 1 ? "en" : ""}</div>*/}

                        {   filteredIntegrationSteps && (                             
                            filteredIntegrationSteps.map((integrationStep) => (
                                <div onClick={() => selectIntegrationStep(integrationStep)} className={`${styles.integrationRow} ${selectedIntegrationStep == integrationStep ? styles.selectedIntegrationRow : ''}`} key={integrationStep}>{integrationStep}</div>
                            )) 
                        )}
                    </div>
                </div>
                {integration != null && (
                    <div onClick={addNewWebhookStep} className={styles.addNewWebhook}>
                        Add step
                    </div>
                )}
        </div>
    );
}
export default WebhookStepList;
