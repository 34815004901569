import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { CiCircleInfo } from "react-icons/ci";

import styles from "styles/admin/gpt/gpt.module.css";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DropdownSearch from "components/global/dropdownSearch";
import {
  getGptApiResponsesService,
  getGptTemplatesService,
  setGptTemplatesService,
  deleteGptTemplatesService,
} from "services/gptService";
import { getApiCompanies } from "services/companyService";
import {
  getAgentDatasourcesService,
  getApiAgentsByCompany,
} from "services/agentService";
import { getIntegrationsForCompany } from "services/integrationService";
import { useProgressIndicatorContext } from "contexts/ProgressIndicatorContext";
import FormText from "components/global/formText";

const Gpt = () => {
  const { setIndicatorState, resetIndicatorState } =
    useProgressIndicatorContext();
  const [conversationItems, setConversationItems] = useState([]);
  const [chatInput, setChatInput] = useState("");
  const [gptTemplates, setGptTemplates] = useState(null);
  const [datasources, setDatasources] = useState([]);
  const [webhooks, setWebhooks] = useState([]);

  const [template, setTemplate] = useState("");
  const [gptTemplate, setGptTemplate] = useState("");
  const [open, setOpen] = useState(false);
  const [newGptTemplateName, setNewGptTemplateName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({ name: "core" });
  const [selectedDataindex, setSelectedDataindex] = useState("");
  const [creativity, setCreativity] = useState("");
  const [gptModel, setGptModel] = useState("");
  const [webhook, setWebhook] = useState("");

  const [jsonInfoOpen, setJsonInfoOpen] = useState(false);

  useEffect(() => {
    getGptTemplates();
    getCompanies();
  }, []);

  useEffect(() => {
    getGptTemplates();
    getDatasources();
    getWebhooks();
    handleGptTemplateSelectionChange("");
    setTemplate("");
  }, [company]);

  useEffect(() => {
    if (
      conversationItems.length == 0 ||
      conversationItems[conversationItems.length - 1]?.from == "assistant"
    )
      return;

    getGptResponse();
  }, [conversationItems]);

  const getCompanies = async () => {
    var retrievedCompanies = await getApiCompanies();
    setCompanies([...retrievedCompanies, { name: "core" }]);
  };

  const enterCheck = (e) => {
    if (e.keyCode == 13) {
      addText();
    }
  };

  const addText = () => {
    if (chatInput != "") {
      setConversationItems((prev) => [
        ...prev,
        { from: "user", message: chatInput + "." },
      ]);
      setChatInput("");
    }
  };
  const updateInstructions = (e) => {
    setTemplate(e.target.value);
  };

  const getGptResponse = async () => {
    var list = [{ from: "system", message: template }, ...conversationItems];
    var obj = { ChatList: list, creativity: creativity, gptModel: gptModel };

    setTimeout(() => {
      setIsLoading(true);
    }, 300);

    var res = await getGptApiResponsesService(obj);

    setIsLoading(false);
    setConversationItems((prev) => [...prev, res]);
  };

  const updateChatInput = (e) => {
    setChatInput(e.target.value);
  };

  const deleteChat = (index) => {
    setConversationItems((prev) => [...prev.filter((item, i) => i != index)]);
  };

  const clearChat = () => {
    setConversationItems([]);
  };

  const setSelectedCompany = (e) => {
    setCompany(e);
  };

  const handleGptTemplateSelectionChange = (e) => {
    if (e == "") {
      setGptTemplate(null);
      setTemplate("");
      setCreativity("");
      setWebhook("");
      return;
    }
    setGptTemplate(e.target.value);

    let a = gptTemplates?.filter((x) => x.name == e.target.value)[0]
      ?.datasourceIndex;
    let creative = gptTemplates?.filter((x) => x.name == e.target.value)[0]
      ?.creativity;
    let gptModel = gptTemplates?.filter((x) => x.name == e.target.value)[0]
      ?.gptModel;
    let webhook = gptTemplates?.filter((x) => x.name == e.target.value)[0]
      ?.webhookIntegration;
    if (creative == undefined) {
      setCreativity("0.01");
    } else {
      setCreativity(creative);
    }

    if (gptModel == undefined) {
      setGptModel("4-turbo");
    } else {
      setGptModel(gptModel);
    }

    if (webhook == undefined) {
      setWebhook("");
    } else {
      setWebhook(webhook);
    }

    let entities = gptTemplates?.filter((x) => x.name == e.target.value)[0]
      ?.includeEntities;
    if (entities == undefined) {
      setIncludeEntities("");
    } else {
      setIncludeEntities(entities);
    }

    setSelectedDataindex(a);

    setTemplate(
      gptTemplates.filter((x) => x.name == e.target.value)[0].template
    );
  };

  const handleGptTemplateDatasourceSelectionChange = (e) => {
    setGptTemplates((gptTemplates) => [
      ...gptTemplates.map((t) =>
        t.name == gptTemplate ? { ...t, datasourceIndex: e.target.value } : t
      ),
    ]);

    setSelectedDataindex(e.target.value);
  };

  const handleGptTemplateWebhookSelectionChange = (e) => {
    setWebhook(e.target.value);
  };

  const handleCreativitySelectionChange = (e) => {
    setCreativity(e.target.value);
  };
  const handleGptModelSelectionChange = (e) => {
    setGptModel(e.target.value);
  };

  const [includeEntities, setIncludeEntities] = useState();

  const includeEntitiesChange = (e) => {
    console.log(e.target.value);
    setIncludeEntities(e.target.value);
  };
  const handleClose = () => {
    var set = {
      name: newGptTemplateName + "_" + company.name,
      template: "",
      company: company.name,
    };
    //gptTemplates.push(set);
    //localStorage.setItem('gptTemplates', JSON.stringify(gptTemplates));

    setGptTemplatesService(set).then((x) => {
      setOpen(false);
      setNewGptTemplateName(set.name);
      getGptTemplates();

      setGptTemplate(set.name);
      setTemplate(set.template);
    });
  };

  const saveGptTemplate = () => {
    setIndicatorState("creating", "Saving template");
    var set = gptTemplates.filter((x) => x.name == gptTemplate)[0];
    set.template = template;
    set.creativity = creativity;
    set.gptModel = gptModel;
    set.webhookIntegration = webhook;
    set.includeEntities = includeEntities;
    setGptTemplatesService(set).then((x) => {
      resetIndicatorState("Template saved!");
      getGptTemplates();
    });
  };

  const deleteGptTemplate = () => {
    var set = gptTemplates.filter((x) => x.name == gptTemplate)[0];

    deleteGptTemplatesService(set.name).then((x) => {
      getGptTemplates();
      handleGptTemplateSelectionChange("");
      setTemplate("");
    });
  };

  const getGptTemplates = () => {
    getGptTemplatesService(company.name).then((x) => {
      console.log(x);
      setGptTemplates(x);
    });
  };
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  const getDatasources = () => {
    let indexes = [];
    getApiAgentsByCompany(company.id).then((x) => {
      x.forEach((u) => {
        let agentIndexes = u.datasource?.indexes?.map((i) => ({
          name: i.name,
        }));
        indexes = indexes.concat(agentIndexes);
      });

      setDatasources(indexes.filter((x) => x != undefined).filter(onlyUnique));
    });
  };

  const getWebhooks = async () => {
    var retrievedWebhooks = await getIntegrationsForCompany(company.id);

    var uu = Object.entries(retrievedWebhooks?.installedIntegrations)?.map(
      (i) => {
        const integrationGroup = [i[0]][0];
        const integration = [i[1]][0];
        return integration.map((x) => ({
          id: integrationGroup + "|" + x,
          name: x,
        }));
      }
    );
    setWebhooks(uu.flat());
  };

  const updateNewGptTemplateName = (e) => {
    setNewGptTemplateName(e.target.value);
  };
  return (
    <div className={styles.gptContainer}>
      <div className={styles.template}>
        <h2>Instructions:</h2>
        <div className={styles.templateContainer}>
          <div className={styles.templateMenu}>
            <div className={styles.templateNew} onClick={() => setOpen(true)}>
              NEW
            </div>
            <div className={styles.widgetHeader}>
              <DropdownSearch
                data={companies}
                type="company"
                selected={company}
                setSelected={setSelectedCompany}
              />
            </div>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth size="small">
                <label for="demo-simple-select">Template:</label>
                <Select
                  id="demo-simple-select"
                  value={gptTemplate}
                  onChange={handleGptTemplateSelectionChange}
                >
                  {gptTemplates?.map((x) => (
                    <MenuItem key={x.name} value={x.name}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth size="small">
                <label for="demo-simple-select-datasource">Datasource:</label>
                <Select
                  id="demo-simple-select-datasource"
                  value={selectedDataindex + ""}
                  onChange={handleGptTemplateDatasourceSelectionChange}
                >
                  <MenuItem value=""></MenuItem>
                  {datasources?.map((x) => (
                    <MenuItem key={x.name} value={x.name + ""}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth size="small">
                <label for="demo-simple-select-webhook">Webhook:</label>
                <Select
                  id="demo-simple-select-webhook"
                  value={webhook}
                  onChange={handleGptTemplateWebhookSelectionChange}
                >
                  <MenuItem value=""></MenuItem>
                  {webhooks?.map((x) => (
                    <MenuItem key={x.name} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth size="small">
                <label for="demo-simple-select-creativity">Creativity:</label>
                <Select
                  id="demo-simple-select-creativity"
                  value={creativity}
                  onChange={handleCreativitySelectionChange}
                >
                  {[
                    "0.01",
                    "0.1",
                    "0.2",
                    "0.3",
                    "0.4",
                    "0.5",
                    "0.6",
                    "0.7",
                    "0.8",
                    "0.9",
                    "1.0",
                  ]?.map((x) => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth size="small">
                <label for="demo-simple-select-gptmodel">Model:</label>
                <Select
                  id="demo-simple-select-gptmodel"
                  value={gptModel}
                  onChange={handleGptModelSelectionChange}
                >
                  {[
                    { key: 3, value: "3.5-turbo" },
                    { key: 4, value: "4-turbo" },
                  ]?.map((x) => (
                    <MenuItem key={x.key} value={x.key}>
                      {x.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth size="small">
                <label for="demo-simple-select-gptmodel">
                  Include entities (; seperated):
                </label>
                <input
                  type="text"
                  value={includeEntities}
                  className={styles.includeEntities}
                  onChange={includeEntitiesChange}
                />
                {/* <FormText value=""  /> */}
              </FormControl>
            </Box>
            <Box sx={{ width: "20%" }}>
              <CiCircleInfo
                onClick={() => {
                  setJsonInfoOpen(!jsonInfoOpen);
                }}
                className={styles.infoIconScale}
              />
            </Box>
            {gptTemplate != null && (
              <div className={styles.buttonsRight}>
                <div className={styles.templateSave} onClick={saveGptTemplate}>
                  SAVE
                </div>
                <div
                  className={styles.templateDelete}
                  onClick={deleteGptTemplate}
                >
                  DELETE
                </div>
              </div>
            )}
          </div>
          <textarea
            className={styles.instructionsTextarea}
            onChange={updateInstructions}
            value={template}
          ></textarea>
        </div>
      </div>
      <div className={styles.chat}>
        <h2>Conversation</h2>
        <div className={styles.chatContainer}>
          <div className={styles.chatMessagesContainer}>
            {conversationItems?.map((x, index) => (
              <div
                key={index}
                className={`${styles.chatMessage} ${
                  x.from == "user" ? styles.user : null
                }`}
              >
                {x.message}
                <IoCloseSharp
                  onClick={() => deleteChat(index)}
                  className={styles.removeIcon}
                />
              </div>
            ))}

            {isLoading && (
              <div className={`${styles.chatMessage}`}>Aan het typen...</div>
            )}
          </div>
          <div className={styles.chatInput}>
            <input
              id="chattext"
              onKeyDown={enterCheck}
              onChange={updateChatInput}
              value={chatInput}
              type="text"
            />
            <div className={styles.chatSubmit} onClick={addText}>
              SEND
            </div>
            <div className={styles.chatClear} onClick={clearChat}>
              CLEAR
            </div>
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>New instruction set</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Template name"
            type="name"
            value={newGptTemplateName}
            onChange={updateNewGptTemplateName}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleClose}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="md"
        open={jsonInfoOpen}
        onClose={() => setJsonInfoOpen(false)}
      >
        <DialogTitle>JSON response information</DialogTitle>
        <DialogContent>
          <p>
            Als het woord JSON gebruikt wordt in de prompt zal de backend het
            response proberen te parsen naar een JSON model. Hierdoor is het
            mogelijk om uit de prompt te stappen met eventuele vervolg stappen.
          </p>
          <p>
            Hanteer altijd het volgende JSON model: <br />(
            <b>let op: hoofdletter gevoelig</b>)
            <br /> (
            <b>
              let op: velden die niet nodig zijn mogen weg gelaten worden.
              Vooral Answer en Nextstep zullen gebruikt worden
            </b>
            )<br /> (
            <b>
              Als Nextstep meegestuurd wordt en een andere waarde heeft dan
              null, zal het gpt gesprek afgesloten worden <br />
              SearchParams zijn uitgevraagde properties die als parameters
              opgeslagen worden in het gesprek
              {/* Result geeft de mogelijkheid om data op een bepaalde manier terug te sturen<br/> */}
            </b>
            )
            <br />
            <br />
            &#123;
            <br />
            "Answer":"antwoord"
            <br />
            "Nextstep":"anythingelse/handover/callback/null"
            <br />
            "Options":["deze zullen als chips weergegeven worden"]
            <br />
            "SearchParams":"[&#123;"key":"value"&#125;]"
            <br />
            {/*"Result":"&#123;"Title":"title","Subtitle":"subtitle","Image":"image","Url":"url"&#125;"
            <br /> */}
            &#125;
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setJsonInfoOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Gpt;
