import React, { useContext, useState, useEffect } from 'react';
import { getApiUser } from 'services/userService';
import { auth } from '../firebase'
import {
    EmailAuthProvider, reauthenticateWithCredential
} from 'firebase/auth'
const AuthContext = React.createContext();

export function useAuth(){
    return useContext(AuthContext);
}
export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [currentDbUser, setCurrentDbUser] = useState();
    const [loading, setLoading] = useState(true);

    function signup(email, password){
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function login(email, password){
        return auth.signInWithEmailAndPassword(email, password);
    }

    function logout(){
        return auth.signOut();
    }

    function forgotPassword(email){
        return auth.fetchSignInMethodsForEmail(email)
        .then((signInTypes) => {
            if(signInTypes != []){
                return auth.sendPasswordResetEmail(email);
            }})
        .catch(() => {
            throw new Error("EMAIL_UNKNOWN")
        })
    }
    async function reAuth(credential){
        const cr = EmailAuthProvider.credential(currentUser.email, credential)

        // auth.currentUser as first param
        await reauthenticateWithCredential(currentUser, cr);
    }

    function updateEmail(email){
        return currentUser.updateEmail(email);
    }

    function updatePasswordRequired(newPassword){
        return currentUser.updatePassword(newPassword).then(() => {
            console.log("Password updated!");
        }).catch((error) => { console.log(error); });
    }
    function updatePassword(currentPassword, newPassword){
        return reauthenticate(currentPassword).then(() => {
            currentUser.updatePassword(newPassword).then(() => {
                console.log("Password updated!");
            }).catch((error) => { console.log(error); });
        }).catch((error) => { console.log(error); });
    }


    async function reauthenticate(currentPassword){
        var cred = EmailAuthProvider.credential(
            currentUser.email, currentPassword);
        return currentUser.reauthenticateWithCredential(cred);
      }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setLoading(false);
                setCurrentUser(user);
                getApiUser(user.uid).then(response => {
                    setCurrentDbUser(response);
                })
            }else{
                setLoading(false);
            }
        })
        return unsubscribe;
    }, [])
    

    const value = {
        currentUser,
        currentDbUser,
        signup, 
        login,
        logout,
        forgotPassword,
        updateEmail,
        reAuth,
        updatePassword,
        updatePasswordRequired
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}