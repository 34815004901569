import { db } from '../firebase.js'
import { collection, getDocs, addDoc, updateDoc, doc, getDoc, where, query, setDoc, deleteDoc } from 'firebase/firestore'


const getCatalogService = async (id) => {
    const docRef = doc(db, "catalogs", id);

    return getDoc(docRef).then((data) => {
        return {...data.data(), id: data.id};
    });
}
const getCatalogsService = async () => {
    const reference = collection(db, "catalogs");

    return await getDocs(reference).then((data) => {
        return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
    })
}
const createCatalogsService = async (catalog) => {
    var coll = collection(db, "catalogs");
    return await addDoc(coll, catalog);
}
const updateCatalogsService = async (catalog) => {    
    const docRef = doc(db, "catalogs", catalog.id);
    return await updateDoc(docRef, catalog);
}

export {getCatalogService, getCatalogsService, createCatalogsService, updateCatalogsService}