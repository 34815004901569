import React, { useCallback, useRef, useState } from 'react';
import { Form, Button, Card, Alert, InputGroup } from 'react-bootstrap'
import { useAuth } from 'contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { MdEmail, } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { BsFillPersonFill } from 'react-icons/bs';
import { HiOfficeBuilding } from 'react-icons/hi';
import { registerUser } from 'services/userService';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
  } from 'react-google-recaptcha-v3';

const Signup = () => {

    const navigate = useNavigate();
    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const nameRef = useRef()
    const companyRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()

    const { login } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    

    function setFormValidation(){
        if(nameRef.current.value != "" && 
            companyRef.current.value != "" && 
            emailRef.current.value != "" && 
            isValidEmail(emailRef.current.value) && 
            passwordRef.current.value != "" && 
            passwordConfirmRef.current.value != ""){
            setIsValid(true);
        }else {
            setIsValid(false);
        }
    }
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }


    async function handleSubmit(e){
        //return;
        e.preventDefault();

        if(!checkPasswordRequirements()){
            return setError("Passwords must contain: 1 lowercase, 1 uppercase, 1 number, 1 special sign and minimum 8 characters");
        }

        if(passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError("Passwords do not match!");
        }

        try{
            setError("");
            setLoading(true);
         
            const newUser = {
                name: nameRef.current.value,
                company: companyRef.current.value,
                email: emailRef.current.value,
                password: passwordRef.current.value,
            }
            registerUser(newUser).then(async result => {
                if(!result.isError){
                    setRefreshReCaptcha(r => !r);
                    await login(newUser.email, newUser.password);
                    navigate("/admin/agents");
                }
                setLoading(false);
            });
        }catch (err){
            setError(err.code);
        }
        setLoading(false);
    }

    function checkPasswordRequirements(){
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        return strongRegex.test(passwordRef.current.value);

    }


    const onVerify = useCallback((token) => {
        setToken(token);
    });

    return (
        <>
            <div className="d-flex">
                <div className="d-flex p-2 flex-fill justify-content-left align-items-center" style={{ minHeight: "100vh" }}>
                    <Card className='border-0'>
                        <Card.Body style={{width:"400px", backgroundColor:"#f8f8fa"}}>
                            <Card.Title><h1 className="text-left dark">Register</h1></Card.Title>
                            <Card.Subtitle className="mb-4 text-muted"><h3 className="text-left">Sign up and get access to Brain</h3></Card.Subtitle>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="name">
                                    <Form.Label>Name</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text style={{height:"3.5rem", width: "4rem"}}  id="basic-addon1"><BsFillPersonFill className='dark'/></InputGroup.Text>
                                        <Form.Control style={{height:"3.5rem", width: "4rem"}}  type="name" required ref={nameRef} onChange={(e) => setFormValidation()}></Form.Control>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group id="company">
                                    <Form.Label>Company</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text style={{height:"3.5rem", width: "4rem"}}  id="basic-addon1"><HiOfficeBuilding className='dark'/></InputGroup.Text>
                                        <Form.Control style={{height:"3.5rem", width: "4rem"}}  type="company" required ref={companyRef} onChange={(e) => setFormValidation()}></Form.Control>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text style={{height:"3.5rem", width: "4rem"}}  id="basic-addon1"><MdEmail className='dark'/></InputGroup.Text>
                                        <Form.Control style={{height:"3.5rem", width: "4rem"}}  type="email" required ref={emailRef} onChange={(e) => setFormValidation()}></Form.Control>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text style={{height:"3.5rem", width: "4rem"}}  id="basic-addon1"><FaLock className='dark'/></InputGroup.Text>
                                        <Form.Control style={{height:"3.5rem", width: "4rem"}}  type="password" required ref={passwordRef} onChange={(e) => setFormValidation()}></Form.Control>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password confirmation</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text style={{height:"3.5rem", width: "4rem"}}  id="basic-addon1"><FaLock className='dark'/></InputGroup.Text>
                                        <Form.Control style={{height:"3.5rem", width: "4rem"}}  type="password" required ref={passwordConfirmRef} onChange={(e) => setFormValidation()}></Form.Control>
                                    </InputGroup>
                                </Form.Group>

                                <GoogleReCaptchaProvider reCaptchaKey="6LcRVuglAAAAAOV6-UmIbj1ZJXA1Z66c6Gg7OgLb">
                                    <GoogleReCaptcha refreshReCaptcha={refreshReCaptcha} onVerify={onVerify} />
                                </GoogleReCaptchaProvider>

                                {/* <Button style={{height:"3.5rem", width: "4rem"}}  disabled={loading || !isValid} className="w-100 mt-3" type="submit">Sign up</Button> */}
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <div className="d-flex p-2 flex-column"  style={{ marginTop: "50px" }}>
                    <div><span className='mx-2'>Already have an account?</span><Link to="/auth/login"><Button className='btn-outline'>Log in</Button></Link></div>
                    <span className='d-flex justify-content-end mt-auto mb-3'>Copyright 2023 @ DGTL Assist</span>
                </div>
            </div>


        </>
    );
}
export default Signup;