import React, { setState } from 'react';
import { db } from '../firebase.js'
import { collection, getDocs, updateDoc, getDoc, doc, where, query, addDoc } from 'firebase/firestore'

const getApiUser = async (id) => {
    const docRef = doc(db, "users", id);
    return await getDoc(docRef).then((docSnap) => {
        return {...docSnap.data(), id: id};
    });
}

const getApiUsers = async () => {
    const reference = collection(db, "users");

    return await getDocs(reference).then((data) => {
        return data.docs.map((docSnap) => ({name: docSnap.data().email,  id: docSnap.id}));
    })
}
const registerUser = async (user) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'ApiKey': 'r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I' },
        body: JSON.stringify(user)
    };
    return await fetch(process.env.REACT_APP_BRAIN_BACKEND_URL+'/user/registerUser', requestOptions)
        .then(response => response.json())
        .then(data => {return data});
}

const createUser = async (user) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'ApiKey': 'r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I' },
        body: JSON.stringify(user)
    };
    return await fetch(process.env.REACT_APP_BRAIN_BACKEND_URL+'/user/createUser', requestOptions)
        .then(response => response.json())
        .then(data => {return data});
}

const activateUser = async (user) => {
    user.isActivated = true;
    const docRef = doc(db, "users", user.id);
    return await updateDoc(docRef, user);
}

const updateUserActionHistoryList = async (id, agentName, action) => {
    var user = await getApiUser(id);
    var actionHistoryRef = collection(db, "logging");

    var now = new Date();
    var dateTime = now.toLocaleDateString('nl-NL') + " " + now.toLocaleTimeString('nl-NL')
    return await addDoc(actionHistoryRef, {user: user.name, userid: user.id, agent:agentName, action:action, dateTime:dateTime});
}

const updateUser = async (user) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'ApiKey': 'r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I' },
        body: JSON.stringify(user)
    };
    return await fetch(process.env.REACT_APP_BRAIN_BACKEND_URL+'/user/updateUser', requestOptions)
        .then(response => response.json())
        .then(data => {return data});
}
const deleteUser = async (user) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'ApiKey': 'r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I' },
        body: JSON.stringify(user)
    };
    return await fetch(process.env.REACT_APP_BRAIN_BACKEND_URL+'/user/deleteUser', requestOptions)
        .then(response => response.json())
        .then(data => {return data});
}
export {registerUser, getApiUser, getApiUsers, createUser, activateUser, updateUser, deleteUser, updateUserActionHistoryList}
