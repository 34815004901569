export default {
    name: '',
    description: '',
    requiredFields:{},
    settings: {
        ordernumberRequired: false,
        phonenumberRequired: false,
        isTokenRefreshIntegration: false,
        isCreateTicketIntegration: false,
        isPhoneQueueIntegration: false,
        startStep:""
    },
    steps:{}
}