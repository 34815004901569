import React, {useState} from 'react';
import styles from 'styles/admin/menu/menuitem.module.css';    

const MenuItem = ({menuExpanded, title, selectedMenuItem, selectMenuItem, updates, route, children}) => {

    const isActive = () => selectedMenuItem == route;

    const select = () => selectMenuItem({title, route});
      
    return (
        <div onClick={select} 
        className={`${styles.subMenuItem}
        ${menuExpanded ? '' : styles.menuIn}
        ${isActive() ? styles.subMenuItemActive : ''}`}>
            {children}
            <div className={styles.linkText}>{title}</div>
            <span className={styles.tooltiptext}>{title}</span> 
        {updates > 0 && (
                    <div className={styles.updates}>{updates}</div>
        )}
          </div>
    )
}
export default MenuItem;
