import React, {useEffect, useContext, useState} from 'react';

import styles from 'styles/admin/agent/intent.module.css';
import IntentResponse from "./intentResponse";
import {BsCheck} from "react-icons/bs";
import { useAgentContext } from 'contexts/AgentContext';
import { IoClose } from 'react-icons/io5';
import { CiWarning } from 'react-icons/ci';

const Intent = ({intent, coreIntent, startSpeakParent, stopSpeakParent, isPlaying,  collapseToggleState, maxInvocations}) => {

    const [expanded, setExpanded] = useState(false);

    const { agent, selectedIntent, setContextSelectedIntent } = useAgentContext();
    useEffect(() => {
        setExpanded(collapseToggleState);
    }, [collapseToggleState]);


    useEffect(() => {
        setExpanded(false);
    }, [selectedIntent]);

    const startSpeak = (lang, response, id) => {
        startSpeakParent(lang, response, id);
    }
    const stopSpeak = () => {
        stopSpeakParent();
    }
    const editIntent = () => {
        if (selectedIntent === null || selectedIntent === undefined) {            
            setContextSelectedIntent(intent);
        }
    }
    return (
        <>
            <div className={`${styles.intent} ${expanded ? styles.intentActive : null}
            ${selectedIntent !== null &&
            selectedIntent !== undefined &&
            selectedIntent !== intent ? styles.disable : null}

            ${selectedIntent !== null &&
            selectedIntent !== undefined &&
            selectedIntent === intent ? styles.highlight : null}`} 
            onClick={editIntent}
            // onClick={setExpandedState}
            >
                <div className={styles.intentInfo}>
               
                    <div className={styles.intentName} data-tooltip={intent.id}>
                       <div className={styles.titles}>
                        <div className={styles.titleName}>{intent?.name}</div>
                        <div className={styles.titleId}>{intent?.id}</div>
                       </div>
                        {intent.status !== "ready" && ( 
                            <div className={styles.warningIcon}><CiWarning/></div> 
                        )}
                    </div>
                    <div className={styles.intentQuestion}>
                        {coreIntent?.exampleQuestion}
                    </div>
                </div>
                {!selectedIntent && (
                    <>
                        <div className={styles.state}>
                            {/* <div data-state={intent.status} className={`${styles.stateIcon} ${intent.status === "ready" ? styles.ready : styles.inProgress}`}><BsCheck/></div> */}
                            <div data-state={intent.isActive ? "active" : "inactive"} className={`${styles.stateIcon} ${intent.isActive ? styles.active : styles.inActive}`}>
                                {intent.isActive ? 
                                <BsCheck/> :
                                <IoClose/>}
                                </div>
                        </div>
                        <div className={styles.popularity}>
                            <div className={styles.line}>
                                <div style={{width: ((intent.invocations / maxInvocations) * 100) + "%"}}
                                     className={styles.innerLine}></div>
                            </div>
                        </div>
                    </>
                )}
                {/* <div onClick={editIntent} className={`${styles.edit} ${selectedIntent ? styles.editMargin : null}`}>
                    <span>Edit</span>
                </div> */}

                <div className={`${styles.intentResponses} ${expanded ? styles.intentResponsesExpanded : null}`}>
                    {
                        Object.entries(agent.languages).map((key) => {
                            return (
                                <IntentResponse selectedIntent={selectedIntent} isPlaying={isPlaying}
                                                startSpeakParent={startSpeak} stopSpeakParent={stopSpeak}
                                                key={agent.id + intent.id + key[0]} id={intent.id}
                                                intent={intent.content[key[0]]} lang={key[0]}/>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default Intent;
