import React, {useEffect, useState, useMemo} from 'react';

import styles from 'styles/admin/agent/agentInfo.module.css';
import {HiOutlineBuildingOffice2} from "react-icons/hi2";
import {MdSupportAgent, MdOutlineManageAccounts} from "react-icons/md";
import {SlArrowDown} from "react-icons/sl";
import {getApiCompany} from "../../../services/companyService";
import {useAuth} from "../../../contexts/AuthContext";
import {createAgent, getApiAgents, getApiAllAgents} from "../../../services/agentService";
import { useAgentContext } from 'contexts/AgentContext';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useQueryClient } from 'components/queryClients/queryClient';
import { Dialog, DialogContent } from '@mui/material';
import FormText from 'components/global/formText';
import FormMultiSelect from 'components/global/formMultiSelect';
import { useProgressIndicatorContext } from 'contexts/ProgressIndicatorContext';
import { updateUserActionHistoryList } from 'services/userService';
import DropdownSearch from 'components/global/dropdownSearch';
import CategorizedDropdownSearch from 'components/global/categorizedDropdownSearch';

const welcomeLinesVoice = [
    'Hallo. Ik ben de digitale assistent van AGENTNAME. Ik ga je met de juiste medewerker doorverbinden.',
    'Hallo. Ich bin der digital Assistent von AGENTNAME.  Ich verbinde Sie mit dem richtigen Agenten.',
    'Hello. I am the digital assistant of AGENTNAME. I will put you through to the right agent.'
]
const welcomeLinesChat = [
    'Hallo. Ik ben de digitale assistent van AGENTNAME. Waarmee kan ik je helpen?',
    'Hallo. Ich bin der digital Assistent von AGENTNAME.  Wie kann ich behilflich sein?',
    'Hello. I am the digital assistant of AGENTNAME. how can I be of assistance?'
]

const AgentInfo = () => {
    const { setIndicatorState, resetIndicatorState, setInfoMessage, loadingState } = useProgressIndicatorContext();
    const queryClient = useQueryClient();
    const { agent, setContextAgent } = useAgentContext();
    const [firstAgentPopup, setFirstAgentPopup] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [company, setCompany] = useState();
    const [userCompany, setUserCompany] = useState();
    const [menuOpen, setMenuOpen] = useState(false);
    
    const { currentUser, currentDbUser } = useAuth();
    const [agents, setAgents] = useState([]);
    const [firstAgent, setFirstAgent] = useState({
        name:"",
        agentType:"voice",
        languages:{"nl":{"phonenumber":"","conversationProfileId":"","scenarioId":"","applicationId":"","ruleId":""}}
    });
    

    useEffect(() => {
        if(currentDbUser == null)
            return;
        const getAgents = async () => {
            if(currentDbUser.isAdmin){
                var retrievedAgents = await getApiAllAgents();
                setAgents(retrievedAgents);
                getAndSetAgentFromLocalStorage(retrievedAgents);
                
            }else{
                var retrievedAgents = await getApiAgents(currentDbUser);
                if(retrievedAgents.length == 0){
                    setFirstCompany();
                }
                setAgents(retrievedAgents);
                getAndSetAgentFromLocalStorage(retrievedAgents);
            }
        };
        getAgents();
    }, [currentDbUser]);

    const setFirstCompany = async () => {
        var co = await getApiCompany(currentDbUser.companyId)
        setUserCompany(co);
        setFirstAgent(prev => ({...prev, name: co.companyName}))
        setFirstAgentPopup(true);
    }

    const getAndSetAgentFromLocalStorage = (retrievedAgents) => {
        const agentName = localStorage.getItem('selectedAgent');
        var selectAgent = retrievedAgents.filter(x => x.name == agentName)[0];
        if(selectAgent != null){
            setContextAgent(selectAgent);
        }else{
            setContextAgent(retrievedAgents[0]);
        }
    }

    useEffect(() => {
        if(agent != null) {
            getCompany();
        }
    }, [agent]);

    const
        getCompany = async () => {
            setCompany(await getApiCompany(agent.companyId));
        };

    const filteredAgents = useMemo(() => {
        // Create copy of item list
        var updatedList = [...agents];
        // Include all elements which includes the search query
        return updatedList = updatedList.filter((item) => {
            return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
    }, [agents, searchQuery]);


    const openDropDownSearch =() => {
        setMenuOpen(!menuOpen);
    }
    const agentSelected = (e) => {
        localStorage.setItem('selectedAgent', e.name);
        setSearchQuery("");
        openDropDownSearch();
        setContextAgent(e);
        invalidateCustomerAgentIntentsMutation.mutate();
    }

    const invalidateCustomerAgentIntentsMutation = useMutation(
        () => {
        },
        {
            onSuccess: () => {
            queryClient.invalidateQueries(["customerAgentIntents"]);
            queryClient.refetchQueries("customerAgentIntents", { force: true });
            },
        }
        );

    const createFirstAgent = () => {
        var newAgent = {
            name: firstAgent.name,
            idName: '',
            companyId: userCompany.id,
            collection: 'ecommerce',
            agentType: firstAgent.agentType,
            welcomeLine:{
                nl: welcomeLinesVoice[0],
                de: welcomeLinesVoice[1],
                en: welcomeLinesVoice[2]
            },
            languages:firstAgent.languages,
            intentRecommendations:{},
            settings: {
                handoverActivated: false,
                ticketingSystem:"Dashboard",
                createTicketIntegration:"",
                ticketEmail: "",
                ordernumberLength: "10",
                disableDeletion: true
            },
            humanHandoverSettings:{
                type:"",
                connectionString:"",
                username:"",
                password:"",
                workingHours:{
                    sunday: {isWorkingDay:false, from:"", to:""},
                    monday: {isWorkingDay:false, from:"", to:""},
                    tuesday: {isWorkingDay:false, from:"", to:""},
                    wednesday: {isWorkingDay:false, from:"", to:""},
                    thursday: {isWorkingDay:false, from:"", to:""},
                    friday: {isWorkingDay:false, from:"", to:""},
                    saturday: {isWorkingDay:false, from:"", to:""}
                },
                invokeWebhook:"",
                defaultHandoverQueue:"",
                createTicketBeforeHandover: false,
                phoneQueues:{}
            }
        }


        if(newAgent.name == ""){
            setInfoMessage("Name not valid.");
        }
        
        
        newAgent.name = newAgent.name.charAt(0).toUpperCase() + newAgent.name.slice(1); 
        newAgent.idName = newAgent.name.replaceAll(" ",".").toLowerCase();
        newAgent.dialogflowName = "implementation_" + newAgent.agentType + "_" + newAgent.idName;

        // todo set these values based on selected catalog
        // newAgent.collection = catalogs.filter(x => x.id == flow.catalogId)[0].name;
        // newAgent.coreAgentId = flow.id;
        // newAgent.agentType = flow.type.toLowerCase();

        Object.entries(newAgent.languages).map(lang =>{
            newAgent.welcomeLine[lang[0]] = newAgent.welcomeLine[lang[0]].replace("AGENTNAME",newAgent.name);
        });

        setIndicatorState("saving", "Creating agent. This may take several minutes.");
        
        createAgent(newAgent).then(result => {
            if(!result.isError){
                resetIndicatorState("Agent has been successfully created.");
                updateUserActionHistoryList(currentUser.uid, newAgent.name, "Created agent: \"" + newAgent.name + "\"");
                
                window.location.reload(false);
            }else{
                resetIndicatorState(result.message);
            }
        });
    }

    const updateFirstAgentFields = (prop) => (event) => {
        setFirstAgent(prev => ({...prev, [prop]:event.target.value}))
    }
    const handleLanguageChange = (e) => {
        var langs = {};
        Object.entries(e).map(o => {
            langs[o[0]] = "";
        })
        // always select nl
        if(langs["nl"] == null){
            langs["nl"] = "";
        }

        
        Object.entries(e).map(o => {
            langs[o[0]] = {"phonenumber":"","conversationProfileId":"","scenarioId":"","applicationId":"","ruleId":""};
        })
        setFirstAgent(prev => ({...prev, languages: langs}));
    }

    const handleSelectedAgentType = (type) => {
        var ag = {...firstAgent};
        ag.type = type.name
        if(type.name.toLowerCase() == "chat"){
            ag.welcomeLine = {
                nl: welcomeLinesChat[0],
                de: welcomeLinesChat[1],
                en: welcomeLinesChat[2]
            }
        }else{
            ag.welcomeLine = {
                nl: welcomeLinesVoice[0],
                de: welcomeLinesVoice[1],
                en: welcomeLinesVoice[2]
            }
        }
        setFirstAgent(ag);
    }
    const agentTypeOptions = () =>  [{name:"chat"}, {name:"voice"}];

    return (
        <>
            {(agent != null && company != null)  && (
                <>
                    
                    <div className={styles.optionsAgentSelection} >
                        <div className={styles.optionsHeader}><MdOutlineManageAccounts
                            className={styles.optionsIcon}/> Select agent 
                        </div>
                        <div className={styles.dropdown} >

                        <CategorizedDropdownSearch data={filteredAgents} subHeader="agentType" type="agent" selected={agent} setSelected={agentSelected}/>

                        <div className={styles.labelTypeContainer}>
                                <div className={`${styles.typeLabel} ${agent?.agentType == 'chat' ? styles.typeLabelChat : null}`}>{agent?.agentType}</div>
                            </div>

                            {/* <button onClick={openDropDownSearch} className={styles.dropbtn}>{agent.name} <SlArrowDown className={`${styles.dropdownIcon} ${menuOpen ? styles.rotate : null}`}/></button>
                            <div id={styles.myDropdown} className={`${styles["dropdown-content"]} ${menuOpen ? styles.show : null}`}>
                                <input value={searchQuery} type="text" placeholder="Zoeken" id={styles.myInput} onChange={(e) => setSearchQuery(e.target.value)} />
                                {filteredAgents.map((s) => {return (<span key={s.name} onClick={() => agentSelected(s)}>{s.name}</span>)})}
                                {filteredAgents.length === 0 ? <span className={styles.noResult}>Geen resultaten</span> : null}
                            </div> */}
                        </div>

                    </div>

                </>
            )}
            
            <Dialog 
                open={firstAgentPopup} 
                // onClose={handleClickCloseMappingResponseConfigurator}
                >
                <DialogContent>
                <div className={styles.firstAgentDialog}>
                    <div className={styles.firstAgentContainer}>
                        <div className={styles.firstAgentContainerTitle}>
                            Hi {currentDbUser?.name.split(' ')[0]},
                        </div>
                        <div className={styles.firstAgentContainerSubTitle}>
                            Are you ready to create your first agent? <br/><br/>
                            <div style={{color:"#b6239c"}}>Just fillout the form and let the magic happen!</div>
                        </div>


                        <div className={styles.firstAgentContent}>
                            
                        </div>
                    </div>
                    <div className={styles.fieldsContainer}>
                        <div className={styles.formRow}>
                            <div className={styles.formElementContainer}>
                            <FormText isPassword={false} title="Agent name (usually the company name)" formId="name" value={firstAgent.name} handleChange={updateFirstAgentFields}/>
                            </div>
                        </div>

                        <div className={styles.dropdownContainer}>
                            <div className={styles.dropdownHeader}>Agent type:</div>
                            <DropdownSearch data={agentTypeOptions()} type="agent type" selected={{name: firstAgent?.agentType}} setSelected={handleSelectedAgentType}/>   
                        </div>

                        <div className={styles.formRow}>
                            <FormMultiSelect title="Select the languages for this agent" 
                                values={[{key:"German", value:"de"},{key:"English", value:"en"},{key:"Dutch", value:"nl"}]} 
                                selectedValues={firstAgent == null ? [] : firstAgent?.languages} handleChange={handleLanguageChange}  />
                        </div>


                        {!loadingState ? (
                            <div className={styles.formRow}>
                                <div className={styles.formElementContainer}>
                                    <div onClick={createFirstAgent} className={`${styles.updateButton} ${styles.updateButtonPopup}`}>Create</div>
                                </div>
                            </div>
                        ) : (
                            <div className='d-flex justify-content-center'>
                                <div className={styles.ldsEllipsis}><div></div><div></div><div></div><div></div></div>
                            </div>
                        )}
                    </div>
                </div>
                </DialogContent>
            </Dialog>
            
        </>
    )
}

export default AgentInfo;
