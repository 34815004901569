import React from 'react';

function NoMatch(props) {
    return (
        <div>
           <h1>No Match</h1> 
        </div>
    );
}

export default NoMatch;