import React, { useEffect, useState } from 'react';
import { Outlet  } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

import styles from 'styles/admin/admin.module.css';
import Menu from './menu/menu';
import { ProgressIndicatorProvider } from 'contexts/ProgressIndicatorContext';
import ProgressIndicator from './global/progressIndicator';
import FormText from 'components/global/formText';
import { FiSettings } from 'react-icons/fi';
import { Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { AiOutlineCheck } from 'react-icons/ai';
import { activateUser } from 'services/userService';
import {
  multiFactor,
  getAuth,
  getMultiFactorResolver,
  TotpMultiFactorGenerator,
} from "firebase/auth";
import QRCode from 'react-qr-code';

const Admin = () => {
  const { currentUser, currentDbUser, updatePassword, updatePasswordRequired, reAuth, login } = useAuth();
  const notifications = true;
  const [userOptionsShow, setUserOptionsShow] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState("");
  const passwordStrength = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const passwordLength = /^(?=.{8,}).*$/;
  const passwordUppercase = /^(?=.*[A-Z])/;
  const passwordNumber = /^(?=.*\d)/;
  const passwordSymbol = /^(?=.*\W)/;
  const [updatePasswordPopup, setUpdatePasswordPopup] = useState(false);
  
  const [addMultiAuthentication, setAddMultiAuthentication] = useState(false);
  useEffect(() => {
    if(currentDbUser == null)
      return;
      
    if(!currentDbUser.isActivated){
      setUpdatePasswordPopup(true);
    }
  }, [currentDbUser]);
  
  const updateCurrentPassword = (prop) => (event) => {
    setCurrentPassword(event.target.value);
  }
  const updateNewPassword = (prop) => (event) => {
    setNewPassword(event.target.value);
  }
  const updatePasswordCheck = (prop) => (event) => {
    setNewPasswordCheck(event.target.value);
  }

  const toggleUserOptionsShow = () => {
    setUserOptionsShow(!userOptionsShow);
    resetInputFields();
  }
  
  const resetInputFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setNewPasswordCheck("");

  }
  const checkPassword = passwordStrength.test(newPassword);
  
  const checkPasswordLength = passwordLength.test(newPassword);
  const checkPasswordUppercase = passwordUppercase.test(newPassword);
  const checkPasswordNumber = passwordNumber.test(newPassword);
  const checkPasswordSymbol = passwordSymbol.test(newPassword);

  const arePasswordsEqual = newPassword == newPasswordCheck;

  
  const popupActionUpdatePassword = () => {
    updatePassword(currentPassword, newPassword).then(x => {
      activateUser(currentDbUser).then(res => {
        resetInputFields();
        setUpdatePasswordPopup(false);
      })
    });
  }

  const [qrGenerated, setQrGenerated] = useState();
  const [twofactorAlreadySet, setTwofactorAlreadySet] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const getQrCode = async () => {
    reAuth(currentPassword).then(async x => {
      const multiFactorSession = await multiFactor(currentUser).getSession();
      const totpSecret = await TotpMultiFactorGenerator.generateSecret(
        multiFactorSession
      );
  
      const totpUri = totpSecret.generateQrCodeUrl(
        currentUser.email,
        "Brain"
      );
  
      setQrGenerated({qr:totpUri, secret:totpSecret});
    }).catch(err => { 
      if(err.code == "auth/multi-factor-auth-required"){
          setLoginError(err);
          setTwofactorAlreadySet(true);
          setCurrentPassword(null);
        return;
      }else{
      }
    });
    
    
  }
  
  const [authenticatorCode, setAuthenticatorCode] = useState();
  const [twoFactorUpdatedScreen, setTwoFactorUpdatedScreen] = useState();
  const setAuthenticatorCodeMethod = (prop) => (event) => {
    setAuthenticatorCode(event.target.value);
  }

  const validateAuthenicatorCode = async () => {
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
      qrGenerated.secret,
      authenticatorCode
    );
    await multiFactor(currentUser).enroll(multiFactorAssertion, "Brain")
    .catch(function (error) {
      alert(`Error finishing second factor enrollment. ${error}`);
      throw error;
    }).then(x => {
      setCurrentPassword(null);
      setNewPassword(null);
      setNewPasswordCheck(null);
      setAuthenticatorCode(null);
      setQrGenerated(null);
      setTwofactorAlreadySet(null);
      setTwoFactorUpdatedScreen(true);
    });
  }

  const unsetTwofactorAuthentication = async () => {
    submitTwoFactorCode(loginError);
    // try {
    //     await login(currentUser.email, currentPassword);
    // } catch (err) {
    //     if(err.code == "auth/multi-factor-auth-required"){
    //     }
    // }
  }

  const submitTwoFactorCode = async (loginError) => {
    const mfaResolver = getMultiFactorResolver(getAuth(), loginError);
    const enrolledFactors = mfaResolver.hints.map(info => info);
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
            enrolledFactors[0].uid,
            authenticatorCode
        );
    try {
        await mfaResolver.resolveSignIn(
            multiFactorAssertion
        );
        
        await multiFactor(currentUser).unenroll(enrolledFactors[0].uid);
        setCurrentPassword(null);
        setAuthenticatorCode(null);
        setQrGenerated(null);
        setTwoFactorUpdatedScreen(true);
    } catch (error) {
    }
}

  const closeDialog = () => {
    setUpdatePasswordPopup(false);
    setAddMultiAuthentication(false);
    setUserOptionsShow(false);
    setCurrentPassword(null);
    setAuthenticatorCode(null);
    setQrGenerated(null);
    setTwofactorAlreadySet(null);
    setTwoFactorUpdatedScreen(null);
  }

  const setAddMultiAuthenticationTrigger = () => {
    setAddMultiAuthentication(true)
    setUserOptionsShow(false);
  }
  const setUpdatePasswordPopupTrigger = () => {
    setUpdatePasswordPopup(true)
    setUserOptionsShow(false);
  }
  return currentUser ? (

    <ProgressIndicatorProvider>
      <div className={styles.mainContainer}>
        <Menu></Menu>
        <div className={styles.content}>
          
              <div className={styles.header}>
                  <div className={styles.logo}></div>
                  {/*<div className={`${styles.icon} ${notifications ? styles.hasNotification : ''}`}>*/}
                      {/*<div className={styles.notification}>3</div>*/}
                      {/*<IoMdNotificationsOutline/>*/}
                  {/*</div>*/}
                  {/*<div className={styles.icon}><IoSettingsOutline/></div>*/}
                  <ProgressIndicator/>
                  {currentDbUser && (
                    <>
                      <div className={styles.user}>
                          <div className={styles.profile}>{currentDbUser.name[0].toUpperCase()}</div>
                          <div className={styles.account}>
                              <div className={styles.name}>{currentDbUser.name}</div>
                              <div className={styles.email}>{currentDbUser.email}</div>
                          </div>
                      </div>
                      <FiSettings className={styles.profileSettings} onClick={toggleUserOptionsShow}/>
                    </>
                  )}
              </div>
              <div className={styles.main}><Outlet /></div>

        </div>
        
        <div className={`${styles.userOptions} ${userOptionsShow && styles.userOptionsShow}`}>
          <div onClick={() => setAddMultiAuthenticationTrigger()} className={styles.updatePasswordWindow}>2 Factor authentication</div>
          <div onClick={(e) => setUpdatePasswordPopupTrigger()} className={styles.updatePasswordWindow}>Update password</div>
          
        </div>
      </div>

      <Dialog 
         open={updatePasswordPopup} 
        // onClose={handleClickCloseMappingResponseConfigurator}
        >
        <DialogContent>
          <div className={styles.updatePasswordDialog}>
            <div className={styles.requirementsContainer}>
              <div className={styles.requirementsContainerTitle}>
                Update Password
                <div onClick={closeDialog} className={styles.closeDialog}><CloseIcon/></div>
              </div>
              <div className={styles.requirementsContainerSubTitle}>
              To make your account secure, please update your password.
              </div>


              <div className={styles.requirementsContainerContent}>
                <div className={styles.requirementRowHeader}>Password must contain:</div>

                <div className={styles.requirementRow}><AiOutlineCheck className={checkPasswordLength ? styles.valid : styles.invalid}/>at least 8 characters</div>
                <div className={styles.requirementRow}><AiOutlineCheck className={checkPasswordUppercase ? styles.valid : styles.invalid}/>at least 1 uppercase</div>
                <div className={styles.requirementRow}><AiOutlineCheck className={checkPasswordNumber ? styles.valid : styles.invalid}/>at least 1 number</div>
                <div className={styles.requirementRow}><AiOutlineCheck className={checkPasswordSymbol ? styles.valid : styles.invalid}/>at least 1 symbol</div>
              </div>
            </div>
            <div className={styles.fieldsContainer}>
            <div className={styles.formRow}>
              <div className={styles.formElementContainer}>
                <FormText isPassword={true} title="Current password" formId="currentPassword" value={currentPassword} handleChange={updateCurrentPassword}/>
              </div>
            </div>

            <div className={styles.formRow}>
                <div className={styles.formElementContainer}>
                  <FormText isPassword={true} title="New password" formId="newPassword" value={newPassword} handleChange={updateNewPassword}/>
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formElementContainer}>
                  <FormText isPassword={true} title="Confirm new password" formId="passwordCheck" value={newPasswordCheck} handleChange={updatePasswordCheck}/>
                </div>
              </div>
              <div className={styles.formRow}>
              <div className={styles.formElementContainer}>
                <div onClick={popupActionUpdatePassword} className={`${styles.updateButton} ${styles.updateButtonPopup} ${!arePasswordsEqual || !checkPassword || currentPassword == ""  ? styles.disable : null}`}>update</div>
              </div>
            </div>
            </div>
          </div>
        </DialogContent>
    </Dialog>


    <Dialog 
         open={addMultiAuthentication} 
        // onClose={handleClickCloseMappingResponseConfigurator}
        >
        <DialogContent>
          <div>
            <div className={styles.requirementsContainer}>
              <div className={styles.requirementsContainerTitle}>
                Set 2factor authentication
                <div onClick={closeDialog} className={styles.closeDialog}><CloseIcon/></div>
              </div>
              <div className={styles.requirementsContainerSubTitle}>
                To make your account more secure, add 2factor authentication
              </div>


              <div className={styles.requirementsContainerContent}>
               
              </div>
            </div>
            <div className={styles.fieldsContainer}>
            {!twofactorAlreadySet && !qrGenerated && !twoFactorUpdatedScreen && (
              <>
                <div className={styles.formRow}>
                  <div className={styles.formElementContainer}>
                    <FormText isPassword={true} title="Current password" formId="currentPassword" value={currentPassword} handleChange={updateCurrentPassword}/>
                  </div>
                </div>

                
                <div className={styles.formRow}>
                  <div className={styles.formElementContainer}>
                    <div onClick={getQrCode} className={`${styles.updateButton} ${styles.updateButtonPopup}`}>Manage 2 factor authentication</div>
                  </div>
                </div>
              </>
            )}

            {twofactorAlreadySet && !twoFactorUpdatedScreen && (
              <>
                <div className={styles.qrInstructions}>Unset 2 factor authentication</div>
                  <div className={styles.formRow}>
                  <div className={styles.formElementContainer}>
                    <FormText isPassword={false} title="Enter code" formId="cdoe" value={authenticatorCode} handleChange={setAuthenticatorCodeMethod}/>
                  </div>
                </div>

                  <div className={styles.formRow}>
                    <div className={styles.formElementContainer}>
                      <div onClick={unsetTwofactorAuthentication} className={`${styles.updateButton} ${styles.updateButtonPopup}`}>Unset 2 factor authentication</div>
                    </div>
                  </div>
              </>
            )}
            {qrGenerated && !twoFactorUpdatedScreen && (
              <>
                <div className={styles.qrInstructions}>Scan the qr code with your authenticator app</div>
                <div style={{ height: "auto", margin: "1rem auto", maxWidth: 125, width: "100%" }}>
                  <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={qrGenerated.qr}
                  viewBox={`0 0 256 256`}
                  />
                </div>
              
                <div className={styles.qrInstructions}>Or add the following code:</div>
                <strong className={styles.qrInstructions}>{qrGenerated.secret.secretKey}</strong>

                <div className={styles.formRow}>
                  <div className={styles.formElementContainer}>
                    <FormText isPassword={false} title="Enter code" formId="cdoe" value={authenticatorCode} handleChange={setAuthenticatorCodeMethod}/>
                  </div>
                </div>

                <div className={styles.formRow}>
                  <div className={styles.formElementContainer}>
                    <div onClick={validateAuthenicatorCode} className={`${styles.updateButton} ${styles.updateButtonPopup}`}>Validate code</div>
                  </div>
                </div>
              </>
            )}

            {twoFactorUpdatedScreen && (
              <div className={styles.twoFactorUpdated}>
                <img width={200} src='/thumb.png'/>
                <div className={`${styles.qrInstructions} ${styles.twoFactorUpdated}`}>2 Factor authentication succesfully updated</div>
              </div>
            )}

            </div>
          </div>
        </DialogContent>
    </Dialog>
    </ProgressIndicatorProvider>


  ) : <Navigate to="/auth/login" />;
}

export default Admin;
