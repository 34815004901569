const NEWACTIONS = {
    UPDATE_INTEGRATION: "UPDATE_INTEGRATION",
    UPDATE_NAME: "UPDATE_NAME",
    UPDATE_DESCRIPTION: "UPDATE_DESCRIPTION",
    UPDATE_REQUIRED_FIELDS: "UPDATE_REQUIRED_FIELDS",
    DELETE_REQUIRED_FIELDS: "DELETE_REQUIRED_FIELDS",
    UPDATE_SETTINGS_ORDERNUMBER_REQUIRED: "UPDATE_SETTINGS_ORDERNUMBER_REQUIRED",
    UPDATE_SETTINGS_PHONENUMBER_REQUIRED: "UPDATE_SETTINGS_PHONENUMBER_REQUIRED",
    UPDATE_SETTINGS_IS_TOKEN_REFRESH_INTEGRATION: "UPDATE_SETTINGS_IS_TOKEN_REFRESH_INTEGRATION",
    UPDATE_SETTINGS_IS_CREATE_TICKET_INTEGRATION: "UPDATE_SETTINGS_IS_CREATE_TICKET_INTEGRATION",
    UPDATE_SETTINGS_IS_PHONE_QUEUE_INTEGRATION: "UPDATE_SETTINGS_IS_PHONE_QUEUE_INTEGRATION",
}

const IntegrationNewIntegrationReducer = (state, action) => {
    switch(action.type){
        case NEWACTIONS.UPDATE_INTEGRATION:
            return updateIntegration(state, action);
        case NEWACTIONS.UPDATE_NAME:
            return updateName(state, action);
        case NEWACTIONS.UPDATE_DESCRIPTION:
            return updateDescription(state, action);
        case NEWACTIONS.UPDATE_REQUIRED_FIELDS:
            return updateRequiredFields(state, action);
        case NEWACTIONS.DELETE_REQUIRED_FIELDS:
            return deleteRequiredFields(state, action);
        case NEWACTIONS.UPDATE_SETTINGS_ORDERNUMBER_REQUIRED:
            return updateSettingsOrdernumberRequired(state, action);
        case NEWACTIONS.UPDATE_SETTINGS_PHONENUMBER_REQUIRED:
            return updateSettingsPhonenumberRequired(state, action);
        case NEWACTIONS.UPDATE_SETTINGS_IS_TOKEN_REFRESH_INTEGRATION:
            return updateSettingsIsTokenRefreshIntegration(state, action);
        case NEWACTIONS.UPDATE_SETTINGS_IS_CREATE_TICKET_INTEGRATION:
            return updateSettingsIsCreateTicketIntegration(state, action); 
        case NEWACTIONS.UPDATE_SETTINGS_IS_PHONE_QUEUE_INTEGRATION:
            return updateSettingsIsPhoneQueueIntegration(state, action);  
        default:
            throw new Error();
    }
}

const updateIntegration = (state, action) => {return action.payload.integration};
const updateName = (state, action) => {return {...state, name: action.payload.name}};
const updateDescription = (state, action) => {return {...state, description: action.payload.description}};
const updateRequiredFields = (state, action) => {
    var newState = {...state};
    var field = action.payload.field;
    newState.requiredFields[field.name] = field
    return newState;
};
const deleteRequiredFields = (state, action) => {
    var newState = {...state};
    var field = action.payload.field;
    delete newState.requiredFields[field.name];
    return newState;
};

const updateSettingsOrdernumberRequired = (state, action) => {return {...state, settings:{...state.settings, ordernumberRequired: action.payload.ordernumberRequired}}};
const updateSettingsPhonenumberRequired = (state, action) => {return {...state, settings:{...state.settings, phonenumberRequired: action.payload.phonenumberRequired}}};
//const updateSettingsIsTokenRefreshIntegration = (state, action) => {return {...state, settings:{...state.settings, isTokenRefreshIntegration: action.payload.isTokenRefreshIntegration}}};

const updateSettingsIsTokenRefreshIntegration = (state, action) => {
    var tempState = {...state};
    tempState.settings.isTokenRefreshIntegration = action.payload.isTokenRefreshIntegration
    if(action.payload.isTokenRefreshIntegration){
        tempState.settings.isCreateTicketIntegration = false;
        tempState.settings.isPhoneQueueIntegration = false;
    }
    return tempState;
};

const updateSettingsIsCreateTicketIntegration = (state, action) => {
    var tempState = {...state};
    tempState.settings.isCreateTicketIntegration = action.payload.isCreateTicketIntegration
    if(action.payload.isCreateTicketIntegration){
        tempState.settings.isPhoneQueueIntegration = false;
        tempState.settings.isTokenRefreshIntegration = false;
    }
    return tempState;
};
const updateSettingsIsPhoneQueueIntegration = (state, action) => {
    var tempState = {...state};
    tempState.settings.isPhoneQueueIntegration = action.payload.isPhoneQueueIntegration
    if(action.payload.isPhoneQueueIntegration){
        tempState.settings.isTokenRefreshIntegration = false;
        tempState.settings.isCreateTicketIntegration = false;
    }
    return tempState;
};

export {NEWACTIONS, IntegrationNewIntegrationReducer};