import React, {useState, useEffect} from 'react';
import styles from 'styles/admin/agent/newAgentWizard.module.css';

const NewAgentWizard = ({createNewAgent}) => {

    const parentCreateNewAgent = () => {
        createNewAgent();
    }
    return (
        <div className={`${styles.newAgentContent}`}>
            <div className={styles.newAgentHeader}>
                <div className={styles.sectionInfo}>
                    <div className={styles.sectionTitle}>Create new agent</div>
                    <div className={styles.sectionComment}>
                        Fill in your agents name. (most of the time this is equal to your company name).
                    </div>
                    <input placeholder="bedrijfsnaam" className={styles.newAgentNameInput} type="text"/>
                </div>


            </div>
            <div className={styles.newAgentSection}>

                <div className={styles.sectionInfo}>
                    <div className={styles.sectionTitle}>Select language</div>
                    <div className={styles.sectionComment}>
                        Monthly costs for a telephone number are charged per selected language.
                    </div>
                </div>
                <div className={styles.languages}>
                    <div className={styles.languageLabel}>Dutch</div>
                    <div className={styles.languageLabel}>English</div>
                    <div className={styles.languageLabel}>German</div>
                </div>
            </div>

            <div className={styles.newAgentSection}>

                <div className={styles.sectionInfo}>
                    <div className={styles.sectionTitle}>Select voice</div>
                    <div className={styles.sectionComment}>
                        Select the voice the agent will use.
                    </div>
                </div>
                <div className={styles.languages}>
                    <div className={styles.languageLabel}>Wendy</div>
                    <div className={styles.languageLabel}>Karin</div>
                    <div className={styles.languageLabel}>Judith</div>
                    <div className={styles.languageLabel}>Frans</div>
                    <div className={styles.languageLabel}>Mark</div>
                </div>
            </div>
            <div className={styles.newAgentFooter}>
                <div className={styles.summary}>
                    <div className={styles.sectionInfo}>
                        <div className={styles.sectionTitle}>Cost overview</div>
                        <div className={styles.sectionComment}>
                            De totale kosten is een indicatie van wat u per maand gaat betalen
                            op basis van de configuratie.


                            <div className={styles.warning}>De kosten per maand kunnen hoger uitvallen
                                door de variabele kosten.</div>
                        </div>
                    </div>
                    <div className={styles.summaryItems}>
                        <div className={styles.summaryItem}>
                            <div>Abbonement (Max)</div>
                            <div>&euro;1500,-</div>
                        </div>
                        <div className={styles.summaryItem}>
                            <div>Taal Nederlands</div>
                            <div>&euro;10,-</div>
                        </div>
                        <div className={styles.summaryItem}>
                            <div>Taal Engels</div>
                            <div>&euro;10,-</div>
                        </div>
                        <div className={`${styles.summaryItem} ${styles.total}`}>
                            <div>Totaal</div>
                            <div>&euro;1520,-</div>
                        </div>
                    </div>
                </div>
                <div className={styles.action}>
                    <div className={`${styles.button} ${styles.confirm}`}>Agent aanmaken</div>
                    <div onClick={parentCreateNewAgent}
                         className={`${styles.button} ${styles.cancel}`}>Annuleren
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewAgentWizard;
