import { db } from '../firebase.js'
import { collection, getDocs, addDoc, updateDoc, doc, getDoc, where, query, setDoc, deleteDoc } from 'firebase/firestore'


const getGptTemplatesService = async (companyName) => {
    const reference = collection(db, "gptTemplates");
    const q = query(reference, where("company","==", companyName));

    return await getDocs(q).then((data) => {
        return data.docs.map((docSnap) => ({ ...docSnap.data(), id: docSnap.id }));
    })
}
const setGptTemplatesService = async (gptTemplate) => {
    return await setDoc(doc(db, "gptTemplates", gptTemplate.name), gptTemplate);
}
const deleteGptTemplatesService = async (name) => {
    return await deleteDoc(doc(db, "gptTemplates", name));
}

const getGptApiResponsesService = async (obj) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'ApiKey': 'r8FjiR1HwKEjav5oABYcaWNVtXQ9SE5xnVyQBzfxmisbSIjW5I' },
        body: JSON.stringify(obj)
    };
    return await fetch(process.env.REACT_APP_BRAIN_BACKEND_URL+'/agent/getResponse', requestOptions)
        .then(response => response.json())
        .then(data => {return data});
}

export {getGptTemplatesService, setGptTemplatesService, getGptApiResponsesService, deleteGptTemplatesService}
