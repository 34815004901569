import React from 'react';
import styles from 'styles/onlydesktop.module.css';
import {HiOutlineEmojiSad} from "react-icons/hi";


const OnlyDesktop = () => {
    return (
        <div className={styles.onlyDesktopContainer}>
            <div className={styles.onlyDesktopImage}><HiOutlineEmojiSad/></div>
            <div className={styles.onlyDesktopHeader}>We're sorry</div>
            <div className={styles.onlyDesktopText}>For the best user experience, Brain is only available on desktop size devices!</div>
            <a href="https://www.dgtlassist.com" target="_self" className={styles.onlyDesktopButton}>Go to DGTL Assist</a>
        </div>
    );
}

export default OnlyDesktop;