import React, {useState, useMemo} from 'react';
import styles from 'styles/global/dropdownSearch.module.css';
import {SlArrowDown} from "react-icons/sl";


const CategorizedDropdownSearch = ({data, type, selected, setSelected, isDisabled, subHeader}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);      

    const onlyUnique = (value, index, array) => {
        return array.indexOf(value) === index;
    }

    const filteredData = useMemo(() => {
        // Create copy of item list
        var updatedList = [...data];
        // Include all elements which includes the search query
        return updatedList = updatedList.filter((item) => {
            return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
    }, [data, searchQuery, type]);

    const types = useMemo(() => {
        var mappedList = filteredData.map(x => x[subHeader]);
        return mappedList.filter(onlyUnique);

    }, [filteredData]);


    const close = (e) => {
        const currentTarget = e.currentTarget;

        setTimeout(() => {
        if (!currentTarget.contains(document.activeElement)) {
           setMenuOpen(false);
        }
        }, 200);
    }
    const openDropDownSearch =() => {
        if(isDisabled)
            return;
        setMenuOpen(!menuOpen);
    }
    const rowSelected = (e) => {
        setSearchQuery("");
        openDropDownSearch();
        setSelected(e);
    }
    return (
        <div className={`${styles.dropdown} ${isDisabled ? styles.disabled : null}`} onBlur={close}>
            <button onClick={openDropDownSearch} className={styles.dropbtn}>{selected?.name || "Select "+type} <SlArrowDown className={`${styles.dropdownIcon} ${menuOpen ? styles.rotate : null}`}/></button>
            <div id={styles.myDropdown} className={`${styles["dropdown-content"]} ${menuOpen ? styles.show : null}`}>
                <input value={searchQuery} type="text" placeholder="Zoeken" id={styles.myInput} onChange={(e) => setSearchQuery(e.target.value)} />
                {types.map(type => (
                    <div className={styles.subGroup} key={type}>
                        <div className={styles.subHeader}>{type}</div>
                        {filteredData.filter(x => x[subHeader] == type).map((s) => {return (<span key={s.id} className={`${(selected?.name == s.name && selected[subHeader] == s[subHeader]) ? styles.highlightSelectedRow : null}`} onClick={() => rowSelected(s)}>{s.name}</span>)})}
                        {filteredData.length === 0 ? <span className={styles.noResult}>Geen resultaten</span> : null}
                    </div>
                ))}               
            </div>
        </div>
    )
}
export default CategorizedDropdownSearch;